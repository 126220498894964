import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import ThreadType from 'common/model/ThreadType';

export interface TransactionMessageParams {
  message: string;
  amendmentFlag: boolean;
  thread: ThreadType;
  flag?: boolean;
  amendmentId?: string | null;
}

interface CreateTransactionMessageQueryVariables {
  transactionChannelId: string | undefined;
  params: TransactionMessageParams;
}

const CREATE_TRANSACTION_MESSAGE = (
  transactionChannelId: string | undefined,
  message: string,
  thread: ThreadType,
  amendmentFlag: boolean,
  amendmentId?: string,
) => {
  if (!transactionChannelId) throw new Error('Create Transaction Message: transactionChannelId is required');

  const variables: CreateTransactionMessageQueryVariables = {
    transactionChannelId: transactionChannelId,
    params: {
      message,
      amendmentFlag,
      thread,
      amendmentId,
    },
  };

  return {
    mutation: gql`
      mutation ($transactionChannelId: ID!, $params: TransactionMessageInput!) {
        createTransactionMessage(transactionChannelId: $transactionChannelId, params: $params) {
          id
        }
      }
    `,
    variables,
  };
};

export const createTransactionMessage = createAsyncThunk(
  'transactions/createTransactionMessage',
  async ({
    message,
    channelId,
    amendmentFlag,
    thread,
    amendmentId,
  }: {
    message: string;
    channelId?: string;
    amendmentFlag: boolean;
    thread: ThreadType;
    amendmentId?: string;
    loading?: boolean;
  }) => {
    const response = await client.mutate(
      CREATE_TRANSACTION_MESSAGE(channelId, message, thread, amendmentFlag, amendmentId),
    );
    return response;
  },
);
