import { ReactNode, useState } from 'react';
import { Grid, Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { DropdownMenuItem } from 'atoms/DropdownCardMenu';
import ProgressBar from 'atoms/ProgressBar';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import CardViewTemplate, { Card, CardTemplateConfiguration, CardViewTemplateProps } from 'components/CardViewTemplate';
import { DefaultCardT, DefaultColumnT } from 'components/CardViewTemplate/CardViewDefaultTypes';
import { receiveGroupGuideline, updateGroupName } from 'store/parametersTab/parametersTabSlice';
import { updateShowParametersForm } from 'store/policies/policyDetailSlice';
import { updateTransactionParamTab } from 'store/transactions/transactionDetailSlice';
import Parameter from 'common/model/Parameter';
import { AnswerProps, GuidelineClone, ParameterCategoryBase } from 'common/api/policies';
import { checkIfNotDefined, checkIfPoliciesPage } from 'utils/tsHelper';
import { ParameterCount, checkIfFromDeviation, getAnswers, getParameterCount } from 'utils/utils-answer';
import { Icons } from 'utils/utils-icons';
import { CardElement } from '..';
import './CategoriesTabs.scss';

const cardHeaderTemplate = (parameter: CardElement): JSX.Element => {
  return (
    <div className="column-custom-template">
      <Popup
        className="popup-info"
        content={parameter.name}
        trigger={<span className="name color-black">{parameter.name}</span>}
      />
    </div>
  );
};

const getTabMenuConfig = (
  categoryList: ParameterCategoryBase[],
  getCardElementsList: (category: ParameterCategoryBase) => CardElement[],
  answers: AnswerProps[],
): TabMenuProps[] => {
  const dispatch = useAppDispatch();
  const checkIfTransaction: boolean = window.location.pathname.includes('transactions');

  const onOpenParametersForm = (groupName: string, groupId: string, guideline?: GuidelineClone) => {
    const formUpdateMethod = checkIfPoliciesPage() ? updateShowParametersForm : updateTransactionParamTab;
    dispatch(formUpdateMethod({ status: true }));
    dispatch(updateGroupName({ groupId, groupName }));
    dispatch(receiveGroupGuideline({ guideline }));
  };

  const checkIfDeviatedAnswer = (answersList: AnswerProps[], answerType: string, parameterId: string): boolean => {
    const getAnswers = answersList.filter(
      (obj: AnswerProps) =>
        obj.answerType === answerType &&
        obj.paramRef.parameterId === parameterId &&
        checkIfNotDefined(obj.paramRef.tableId) &&
        checkIfNotDefined(obj.paramRef.index),
    );
    const policyCheck = getAnswers.filter((obj: AnswerProps) => obj.fromPolicy === true).length;
    if (policyCheck === 1) {
      return checkIfFromDeviation(true, getAnswers);
    }
    return false;
  };

  const numberOfPolicyDeviations = (parametersListByGroup: Parameter[], answers: AnswerProps[]): number => {
    return parametersListByGroup.filter((parameter: Parameter) =>
      checkIfDeviatedAnswer(answers, parameter.answerType, parameter.id),
    ).length;
  };

  const cardBodyTemplate = (parameter: CardElement, parameterCount: ParameterCount): ReactNode => {
    const parametersListByGroup: Parameter[] = parameter?.parametersListByGroup ?? [];
    const deviatedPolicyCount = numberOfPolicyDeviations(parametersListByGroup, answers);

    return (
      <div className="component-card-body">
        <Grid>
          <Grid.Row className="p-t-xxs">
            {deviatedPolicyCount && checkIfTransaction ? (
              <Grid.Column width={16}>
                <p className="policy-card-parameters">
                  <span> {deviatedPolicyCount} Policy deviations</span>
                  <Icon
                    className="color-orange"
                    icon={Icons.SplitUpLeft}
                  />
                </p>
              </Grid.Column>
            ) : (
              ''
            )}

            <Grid.Column
              width={16}
              className="progress-bar-card-parameters"
            >
              <ProgressBar
                currentProgress={parameterCount.definedAnswers}
                totalProgress={parameterCount.totalQuestions}
                label="complete"
                showPercentage={false}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  const innerConfigs = (parameterCount: ParameterCount) => {
    return {
      header: {
        headerTemplate: (parameter: CardElement) => cardHeaderTemplate(parameter),
        cardMenuItems: (_parameter: CardElement): DropdownMenuItem[] => [],
      },
      bodyTemplate: (parameter: CardElement) => cardBodyTemplate(parameter, parameterCount),
      onClick: (parameter: CardElement) => onOpenParametersForm(parameter.name, parameter.id, parameter.guideline),
    };
  };

  const totalUnansweredParameters = (elementsList: CardElement[]): number => {
    let count = 0;
    for (let i = 0; i < elementsList.length; i++) {
      const parameterCount: ParameterCount = getParameterCount(elementsList[i]?.parametersListByGroup, answers);
      count += parameterCount.totalQuestions - parameterCount.definedAnswers;
    }
    return count;
  };

  const getCategoryParameters = (elementsList: CardElement[]): JSX.Element => {
    return (
      <Grid
        columns={4}
        className="category-parameters"
      >
        {elementsList.map((cardElement: CardElement) => {
          const parameterCount: ParameterCount = getParameterCount(cardElement?.parametersListByGroup, answers);
          if (parameterCount.totalQuestions !== 0) {
            return (
              <Grid.Column>
                <Card
                  key={cardElement.id}
                  cardElement={cardElement}
                  columnCategory={cardElement.category}
                  cardConfiguration={innerConfigs(parameterCount)}
                />
              </Grid.Column>
            );
          }
        })}
      </Grid>
    );
  };

  let tabs = [];

  for (let i = 0; i < categoryList.length; i++) {
    const name = categoryList[i].name.toLowerCase();

    const elementsList = getCardElementsList(categoryList[i]);

    const unansweredParameters = totalUnansweredParameters(elementsList);

    tabs.push({
      key: `categories-${name}`,
      dataTest: `categories-${name}`,
      label: categoryList[i].name,
      count: unansweredParameters,
      customTab: getCategoryParameters(elementsList),
    });
  }

  return tabs;
};

const CategoriesTabs = ({
  getCardElementsList,
  categoryList,
}: {
  getCardElementsList: (category: ParameterCategoryBase) => CardElement[];
  categoryList: ParameterCategoryBase[];
}): JSX.Element => {
  const { activePolicyAnswers } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransactionAnswers } = useAppSelector((state: RootState) => state.transactionDetail);

  const [activeTab, setActiveTab] = useState(0);

  const answers: AnswerProps[] = getAnswers(activePolicyAnswers, activeTransactionAnswers);

  const onTabChange = (tab: string | number | undefined) => {
    setActiveTab(tab as number);
  };

  return (
    <TabReact
      activeTabIndex={activeTab}
      customTabChangeAction={onTabChange}
      tabMenu={getTabMenuConfig(categoryList, getCardElementsList, answers)}
    />
  );
};

export default CategoriesTabs;
