import { createSlice, current } from '@reduxjs/toolkit';
import {
  CContract2,
  CContractEvent,
  Contract2EventsInputClone,
  Contract2InputClone,
} from 'common/_classes/contractsV2';
import { CValuationReport } from 'common/_classes/valuationReport2';
import { toast } from 'react-toastify';
import Contract2Events from 'common/model/Contract2Events';
import ContractValuation2 from 'common/model/ContractValuation2';
import {
  createContract2,
  createContractAndEvent,
  createContractEvent,
  getContract2,
  getContractValuationReport,
  prepContractDocsForUpload,
} from 'common/api/contracts2';
import { listLandlordAndTenantEntities } from 'common/api/entities';
import { DROPDOWN_OPTION } from 'utils/UI';
import { updateNestedObject } from 'utils/utils-nested-object-update';

interface LANDLORD_AND_TENANTS_OPTIONS extends DROPDOWN_OPTION {
  nickname: string;
}

interface ContractDetailsStateV2 {
  activeContract: Contract2InputClone | null;
  activeContractEvent: Contract2EventsInputClone | null;
  contractEvents: Contract2Events[];
  isLoading: boolean;
  landlordEntitiesOptions: LANDLORD_AND_TENANTS_OPTIONS[];
  tenantEntitiesOptions: LANDLORD_AND_TENANTS_OPTIONS[];
  valuationReport: CValuationReport[];
}

const initialState: ContractDetailsStateV2 = {
  activeContract: null,
  activeContractEvent: null,
  contractEvents: [],
  isLoading: false,
  landlordEntitiesOptions: [],
  tenantEntitiesOptions: [],
  valuationReport: [],
};

const contractDetailSliceV2 = createSlice({
  name: 'contractDetailV2',
  initialState,
  reducers: {
    cancelContractEventEdit: state => {
      const { id } = state.activeContractEvent || {};

      const event = state.contractEvents.find(({ id: eventId }) => eventId === id);

      if (event) {
        state.activeContractEvent = new CContractEvent(current(event));
      }
    },
    addContractDocs: (state, action) => {
      // TODO by Adebayo
    },
    deleteContractDocs: (state, action) => {
      // TODO by Adebayo
    },
    setActiveContractEvent: (state, action) => {
      const event = state.contractEvents.find(({ id: eventId }) => eventId === action.payload);

      if (event) {
        state.activeContractEvent = new CContractEvent(current(event));
      }
    },

    initContractAndContractEventCreation: state => {
      state.activeContract = new CContract2(null);
      state.activeContractEvent = new CContractEvent(null);
    },
    clearContractCreation: state => {
      state.activeContract = null;
      state.activeContractEvent = null;
      state.contractEvents = [];
    },
    updateActiveContractInput: (state, action) => {
      if (state.activeContract) {
        const { key, value } = action.payload;
        state.activeContract = updateNestedObject(state.activeContract, key, value);
      }
    },
    updateActiveContractEventInput: (state, action) => {
      if (state.activeContractEvent) {
        const { key, value } = action.payload;
        state.activeContractEvent = updateNestedObject(state.activeContractEvent, key, value);
      }
    },
  },
  extraReducers: builder => {
    // listLandlordAndTenantEntities
    builder.addCase(getContractValuationReport.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getContractValuationReport.fulfilled, (state, action) => {
      const reports = action.payload.data.valuationReport2;
      state.valuationReport = reports.filter((report: ContractValuation2)=>report!==null).map((report: ContractValuation2) => new CValuationReport(report));
      state.isLoading = false;
    });
    builder.addCase(getContractValuationReport.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getCOntractValuationReport API request rejected');
    });

    // listLandlordAndTenantEntities
    builder.addCase(listLandlordAndTenantEntities.fulfilled, (state, action) => {
      const lanlordsEdges = action.payload?.data?.landlord?.edges ?? [];
      const landlordsList = lanlordsEdges.map((element: any) => element.node);
      const tenantsEdges = action.payload?.data?.tenant?.edges ?? [];
      const tenantsList = tenantsEdges.map((element: any) => element.node);

      state.landlordEntitiesOptions = landlordsList.map((item: any, index: number) => ({
        key: index,
        text: item.name,
        value: item.id,
        nickname: item.nickname,
      }));

      state.tenantEntitiesOptions = tenantsList.map((item: any, index: number) => ({
        key: index,
        text: item.name,
        value: item.id,
        nickname: item.nickname,
      }));
    });
    builder.addCase(listLandlordAndTenantEntities.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('An unexpected error occured Updating Landlord and Tenant Options');
    });

    // createContractAndEvent
    builder.addCase(createContractAndEvent.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createContractAndEvent.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success('Contract created successfully');
    });
    builder.addCase(createContractAndEvent.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createContractAndEvent API request rejected');
    });

    // createContract2
    builder.addCase(createContract2.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createContract2.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success('Contract created successfully');
    });
    builder.addCase(createContract2.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createContract2 API request rejected');
    });

    // createContractEvent
    builder.addCase(createContractEvent.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createContractEvent.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success('Contract updated successfully');
    });
    builder.addCase(createContractEvent.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createContractEvent API request rejected');
    });

    // prepContractDocsForUpload
    builder.addCase(prepContractDocsForUpload.fulfilled, (state, action) => {});

    builder.addCase(prepContractDocsForUpload.rejected, (state, action) => {
      toast.error(action.error.message);
    });
    // getContract2
    builder.addCase(getContract2.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getContract2.fulfilled, (state, action) => {
      const contract = action.payload.data.getContract2;
      state.contractEvents = contract.events;
      state.activeContract = new CContract2(contract);
      state.activeContractEvent = new CContractEvent(contract.events[contract.events.length - 1]);
      state.isLoading = false;
    });
    builder.addCase(getContract2.rejected, (state, action) => {
      console.error(action.error);
      const errorMessage = action?.error?.message
        ? action.error.message
        : 'An error occured while trying to fetch the contract data';
      state.isLoading = false;
      toast.error(errorMessage);
    });
  },
});

export const {
  initContractAndContractEventCreation,
  updateActiveContractInput,
  updateActiveContractEventInput,
  deleteContractDocs,
  addContractDocs,
  clearContractCreation,
  setActiveContractEvent,
  cancelContractEventEdit,
} = contractDetailSliceV2.actions;

export default contractDetailSliceV2.reducer;
