import { useState } from 'react';
import TabContainer from 'components/TabContainer';
import DateSelect from './DateSelect';
import ForecastGraphs from './ForecastGraphs';
import RiskAnalysisGraphs from './RiskAnalysisGraphs';
import ShowMoreButton from './ShowMoreButton';
import StatisticsInfoBar from './StatisticsInfoBar';
import './Dashboard.scss';

const Dashboard = (): JSX.Element => {
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <TabContainer
      className={'dashboard-tab'}
      tabTitle="Dashboard"
    >
      <h2 className="infobar-header m-b-xs">Summary Statistics this Month</h2>
      <DateSelect />
      <StatisticsInfoBar />
      <h2 className="infobar-header m-b-xs">Forecast</h2>
      <ForecastGraphs />
      <>{showMore && <RiskAnalysisGraphs />}</>
      <ShowMoreButton
        isShow={showMore}
        setShow={setShowMore}
      />
    </TabContainer>
  );
};

export default Dashboard;
