import { DataType, FilterMode, RowAction, RowProps } from '../../RowProps';
import BooleanFilter from './BooleanFilter';
import DateFilter from './DateFilter';
import DurationFilter from './DurationFilter';
import ListFilter from './ListFilter';
import NumberFilter from './NumberFilter';
import PremiseFilter from './PremiseFilter';
import StringFilter from './StringFilter';

interface RowFilterContentProps {
  filter: RowAction & RowProps;
  onChange: (key: string, value: string | number | Duration) => void;
  onFilterModeChange: (key: string, value: string) => void;
}

export interface RowFilterChildProps {
  filterMode?: FilterMode;
  filterValue: any;
  dataType?: DataType;
  label: string;
  onChange: (key: string, value: any) => void;
  onFilterModeChange: (key: string, value: string) => void;
  options?: Array<any>;
}

/**
 * Contents for row filter in filter popup
 * @returns
 */
function RowFilterContent({ filter, onChange, onFilterModeChange }: RowFilterContentProps): JSX.Element {
  if (!filter) {
    return <></>;
  }

  const { dataType, filterValue, filterMode, filterElement, options, label } = filter;

  const filterProps = {
    filterValue,
    filterMode,
    onChange,
    onFilterModeChange,
    label,
    options,
    dataType,
  };

  if (filterElement) {
    return filterElement(filterProps);
  }

  switch (dataType) {
    case DataType.STRING:
      return <StringFilter {...filterProps} />;
    case DataType.NUMBER:
      return <NumberFilter {...filterProps} />;
    case DataType.DECIMAL:
      return <NumberFilter {...filterProps} />;
    case DataType.DATE:
      return <DateFilter {...filterProps} />;
    case DataType.DURATION:
      return <DurationFilter {...filterProps} />;
    case DataType.BOOLEAN:
      return <BooleanFilter {...filterProps} />;
    case DataType.LIST:
      return <ListFilter {...filterProps} />;
    case DataType.PREMISE:
      return <PremiseFilter {...filterProps} />;
    default:
      return <></>;
  }
}

export default RowFilterContent;
