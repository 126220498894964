import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import PremisesForm from 'components/PremisesForm';
import { PremiseSelectedProps } from 'components/PremisesForm/utils-premiseForm';
import { updateContractPremises } from 'store/contracts/contractDetailSlice';
import { ModeProps } from 'common/api/miscellaneous';

const Premises = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();
  const {
    premises,
    landlord: { id: landlordId, properties },
  } = useAppSelector((state: RootState) => state.contractDetail.activeContract);

  const onChange = (premises: PremiseSelectedProps[]) => {
    dispatch(updateContractPremises({ premises }));
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <PremisesForm
            mode={mode}
            properties={properties}
            landlordId={landlordId}
            value={premises}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default Premises;
