import { NEW_CHOICE_INDICATOR, ParameterChoiceBase } from 'common/_classes';
import { Button, Form, Popup } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { orderBy } from 'lodash';
import { Icon } from '@iconify/react';
import Badge, { BadgeColor } from 'atoms/Badge';
import DraggableList from 'atoms/DraggableList';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import PopUpInfo from 'atoms/PopUpInfo';
import {
  addChoice,
  deleteChoice,
  toggleChoiceArchiveStatus,
  updateActiveParameterChoice,
  updateActiveParameterChoiceIndex,
} from 'store/parameters/parameterDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import './Choices.scss';

interface ChoiceProps {
  choices: ParameterChoiceBase[] | null;
  mode: MODE_OPTIONS;
}

const Choices = ({ choices, mode }: ChoiceProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const disabled: boolean = mode === MODE_OPTIONS.READ;

  const onChoiceIndexChange = (sourceIndex: number, destinationIndex: number) =>
    dispatch(updateActiveParameterChoiceIndex({ sourceIndex, destinationIndex }));

  const orderedChoices: ParameterChoiceBase[] = orderBy(choices, 'index', 'asc');

  const rowChoiceTemplate = (choice: ParameterChoiceBase, index: number): JSX.Element => {
    const isNewChoice: boolean = choice.id.includes(NEW_CHOICE_INDICATOR);
    const choicesLength = choices?.length ?? 0;
    return (
      <div
        key={index}
        className={`multi-choice ${conditionalClassName(index !== 0, 'm-t-sm')}`}
      >
        <div className="choice-sn">
          {index + 1}. <span className="required-indicator-choices">*</span>
        </div>
        <InputField
          className="w-100 m-none"
          disabled={mode === MODE_OPTIONS.READ || choice.archive}
          fieldKey="choice"
          type={InputFieldType.TEXT}
          placeholder="Enter choice..."
          dataTest="enter-choice"
          value={choice.text || ''}
          onChange={(_key, value) =>
            dispatch(
              updateActiveParameterChoice({
                key: 'text',
                value,
                id: choice.id,
              }),
            )
          }
        />
        {mode === MODE_OPTIONS.READ && choice.archive && (
          <Badge
            badgeColor={BadgeColor.RED}
            className="m-l-s"
          >
            Archived
          </Badge>
        )}
        {mode !== MODE_OPTIONS.READ && choices && choices.length !== 1 && (
          <Popup
            trigger={
              <Button
                className="delete-btn bg-gray-very-light-v4 height-2_429 width-2_429 p-xs m-l-sm"
                onClick={e =>
                  e.detail === 1 && dispatch(isNewChoice ? deleteChoice(index) : toggleChoiceArchiveStatus(choice.id))
                }
              >
                <Icon
                  className="color-red-soft height-1_143 width-1_143"
                  icon={isNewChoice ? Icons.Delete : choice.archive ? Icons.ArrowCounterClockwise : Icons.Archive}
                />
              </Button>
            }
            content={isNewChoice ? 'Delete' : choice.archive ? 'Unarchive' : 'Archive'}
            size="mini"
            position="top left"
          />
        )}
      </div>
    );
  };

  return (
    <Form.Field className="field-style parameter-information-tab-choices m-t-xl">
      <label>
        Choices
        <span className="required-indicator">*</span>
        {mode === MODE_OPTIONS.EDIT && orderedChoices.length > 1 && (
          <PopUpInfo
            popUp={true}
            infoText="Choices can be dragged to desired position"
          />
        )}
      </label>

      <DraggableList<ParameterChoiceBase>
        onPositionChange={onChoiceIndexChange}
        disabled={mode === MODE_OPTIONS.READ}
        data={orderedChoices}
        rowTemplate={rowChoiceTemplate}
      />

      {!disabled && (
        <span
          className="add-choice color-blue-very-dark-grayish"
          onClick={() => dispatch(addChoice())}
          role="button"
          data-test="add-choice"
        >
          <Icon
            className="height-1_286 width-1_286 color-orange m-r-xxs"
            icon={Icons.CirclePlusFill}
          />
          Add choice
        </span>
      )}
    </Form.Field>
  );
};

export default Choices;
