import Decimal from 'decimal.js';
import TranslateToContract from 'common/model/TranslateToContract';
import { OmitMultiple } from 'utils/UI';
import { IReviewStatusClone } from './formatter';

export interface ITranslateToContractDetailClone
  extends OmitMultiple<
    TranslateToContract,
    'id' | 'insertedAt' | 'updatedAt' | 'reviewStatus' | 'name' | 'description' | 'function' | 'index' | 'reviewFlag'
  > {
  id: string;
  name: string | null;
  description: string | null;
  reviewFlag: boolean | null;
  function: string;
  index: Decimal;
  reviewStatus: IReviewStatusClone;
}

export class TranslateToContractDetailClone implements ITranslateToContractDetailClone {
  id: string;
  name: string | null;
  description: string | null;
  reviewFlag: boolean | null;
  function: string;
  index: Decimal;
  active: boolean;
  reviewStatus: IReviewStatusClone;

  constructor(input: ITranslateToContractDetailClone) {
    this.id = input.id;
    this.name = input.name;
    this.reviewFlag = input.reviewFlag;
    this.description = input.description;
    this.function = input.function;
    this.reviewFlag = input.reviewFlag;
    this.index = input.index;
    this.active = input.active;
    this.reviewStatus = input.reviewStatus;
  }

  static fragment() {
    return `
        id
        name
        description
        active
        index
        function
        reviewFlag
        reviewStatus {
          date
          user {
            id
            firstName
            lastName
          }
        }
        insertedAt
        updatedAt
    `;
  }
}
