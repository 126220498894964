import moment from 'moment';
import { Icon } from '@iconify/react';
import Event from 'common/model/Event';
import { Icons } from 'utils/utils-icons';
import Tooltip from './Tooltip';

interface TimeLineItemProps {
  left: string;
  eventType: Event;
  date: Date;
  description: string;
  color: string;
  maxLevel: number;
}

const getEventText = (event: string): string => {
  if (event === Event.Occupation) return 'term start';
  if (event === Event.RentReview) return 'rent review';
  if (event === Event.Expansion) return 'expansion option';
  if (event === Event.Surrender) return 'surrender option';

  return event;
};

const TimeLineItem = ({ left, eventType, date, description, color, maxLevel }: TimeLineItemProps) => {
  return (
    <>
      <Tooltip
        trigger={
          <div
            className="time-event-description"
            style={{ left: `calc(${left} + .4rem)` }}
          >
            <div className={`timeline-event-text timeline-event-type ${color}`}>{getEventText(eventType)}</div>

            {description && (
              <Icon
                className="info-icon color-black"
                icon={Icons.InfoCircle}
              />
            )}
          </div>
        }
        content={<div>{description}</div>}
        disabled={!description}
      />

      <div
        className={`timeline-event-point ${color} bg`}
        style={{ left: `calc(${left} + 1.6rem` }}
      ></div>

      <div
        className="timeline-event-text timeline-event-date bg-gray-very-light-v4 color-blue-dark-grayish"
        style={{
          left: `calc(${left})`,
          top: `calc(${maxLevel + 2} * 1.1428rem)`,
        }}
      >
        {moment(date).format('DD/MM/YYYY')}
      </div>
    </>
  );
};

export default TimeLineItem;
