import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import PeriodOverview from './PeriodOverview';

const RentForm = ({ mode }: ModeProps) => {
  const type = useAppSelector((state: RootState) => state.contractDetail.activeContract.rent.type);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column className="column-width">
          <InputField
            label="Rent type"
            value={type}
            disabled={true}
            required={mode === MODE_OPTIONS.EDIT}
            fieldKey="rentType"
            onChange={() => {}}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <PeriodOverview mode={mode} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RentForm;
