import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DimmerLoader from 'atoms/DimmerLoader';
import {
  updateSelectedContract,
  updateSelectedContractTimeline,
  updateSelectedFutureContract,
} from 'store/dashboard/stackingPlanSlice';
import { ContractTimeline, ContractTimelineEvent } from 'common/api/contracts';
import { getContract, getContractTimeline, getFutureContract } from 'common/api/dashboard/stackingPlan';
import { Contract, TimeLineEventsUI, TransactionProps } from 'utils/types/stackingPlan';
import { getContractEventTypeProperty } from 'utils/utils-contract';
import EventsTimeline from './components/EventsTimeline';
import LeaseTemplate from './components/LeaseTemplate';
import OngoingTransaction from './components/OngoingTransaction';

const CurrentLease = () => {
  const dispatch = useAppDispatch();

  const {
    selectedFloor,
    contracts,
    isContractLoading,
    monitoringDate,
    selectedContractTimeline,
    contractsTimeline,
    selectedContract,
    selectedFutureContracts,
    onGoingTransactions,
    blocks,
  } = useAppSelector((state: RootState) => state.stackingPlan);

  useEffect(() => {
    const { contractId, futureContractIds } = selectedFloor;
    if (contractId) {
      fetchContract(contractId);
      fetchContractTimeline(contractId);
      fetchFutureContracts(futureContractIds);
    }
  }, [selectedFloor]);

  // Get Stored contract info or fetch from api
  const fetchContract = (contractId: string) => {
    const contractInfo: Contract | undefined = contracts.find(({ id }: Contract) => id === contractId);
    if (contractInfo) {
      dispatch(updateSelectedContract({ contract: contractInfo }));
    } else {
      // Get Contract
      dispatch(getContract({ contractId }));
      dispatch(updateSelectedContract({ contract: null }));
    }
  };

  // Get Stored contract Timeline or fetch from api
  const fetchContractTimeline = (selectedContractId: string) => {
    const contractTimelineInfo: ContractTimeline | undefined = contractsTimeline.find(
      ({ contractId }: ContractTimeline) => contractId === selectedContractId,
    );
    if (contractTimelineInfo) {
      dispatch(
        updateSelectedContractTimeline({
          contractTimeline: contractTimelineInfo,
        }),
      );
    } else {
      // Get Contract Timeline
      dispatch(getContractTimeline({ contractId: selectedContractId }));
    }
  };

  // Get Future contracts or fetch from api
  const fetchFutureContracts = (contractIds: string[] | null) => {
    if (!contractIds) {
      dispatch(updateSelectedFutureContract({ contracts: [] }));
      return;
    }
    const contractsInfo: Contract[] = [];
    contractIds.forEach(contractId => {
      const contract = contracts.find(({ id }: Contract) => id === contractId);
      if (contract) {
        contractsInfo.push(contract);
      }
    });

    if (contractsInfo.length) {
      dispatch(updateSelectedFutureContract({ contracts: contractsInfo }));
    } else {
      dispatch(updateSelectedFutureContract({ contracts: [] }));
      // Get Contract
      contractIds.forEach(contractId => {
        dispatch(getFutureContract({ contractId }));
      });
    }
  };

  if (!selectedContract) {
    return (
      <>
        <p className="area-head color-blue-very-dark">Current Lease</p>
        <DimmerLoader status={isContractLoading} />
      </>
    );
  }

  let timeline: TimeLineEventsUI[] = [];
  if (selectedContractTimeline) {
    timeline = selectedContractTimeline.events.map(({ date, event, description }: ContractTimelineEvent) => {
      return {
        date,
        event,
        description,
        color: getContractEventTypeProperty(event, 'color'),
        background: getContractEventTypeProperty(event, 'backgroundColor'),
      };
    });
  }

  let futureLease = null;
  if (selectedFutureContracts && selectedFutureContracts.length) {
    futureLease = (
      <>
        <p className="area-head color-blue-very-dark">Future Lease Information</p>
        {selectedFutureContracts.map((contract, index) => (
          <LeaseTemplate
            contract={contract}
            monitoringDate={monitoringDate}
            key={index}
          />
        ))}
      </>
    );
  }

  let selectFloorUnitIds: string[] = [];
  let transactions: TransactionProps[] = [];
  blocks
    .filter(block => block.floorId === selectedFloor.floorId)
    .forEach(block => {
      if (block.unitIds && block.unitIds.length) {
        selectFloorUnitIds = [...selectFloorUnitIds, ...block.unitIds];
      }
    });

  if (selectFloorUnitIds && selectFloorUnitIds.length) {
    transactions = onGoingTransactions.filter(transaction => {
      return transaction.unitIds.filter(unitId => {
        return selectFloorUnitIds.includes(unitId);
      }).length;
    });
  }

  return (
    <div className="current-lease">
      <p className="area-head color-blue-very-dark">Current Lease</p>
      <LeaseTemplate
        contract={selectedContract}
        monitoringDate={monitoringDate}
        selectedFloor={selectedFloor}
      />
      <p className="area-head color-blue-very-dark">Timeline</p>
      <EventsTimeline timeline={timeline} />
      <hr className="w-100 m-t-l m-b-l border-sm-gray-very-light" />
      {futureLease}
      <p className="area-head color-blue-very-dark">Ongoing Transactions</p>
      {transactions.map((transaction, index) => (
        <OngoingTransaction
          transaction={transaction}
          key={index}
        />
      ))}
    </div>
  );
};

export default CurrentLease;
