import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Image } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import Footer from 'layouts/Footer';
import Navbar from 'layouts/Navbar';
import DimmerLoader from 'atoms/DimmerLoader';
import Steps from 'views/entities/components/Steps';
import { EntityTypeProps, getEntity } from 'common/api/entities';
import SideBarImage from 'assets/images/svg/sidebar.svg';
import GeneralInfo from '../GeneralInfo';
import LegalEntityInfo from '../LegalEntityInfo';
import SupportingDocs from '../SupportingDocs';
import CreatorHeader from './CreatorHeader';
import './EntityCreator.scss';
import { defaultToastContainerProps } from 'utils/utils-toast';

interface FormContentProps extends EntityTypeProps {
  activeStep: number;
}

const FormContent = ({ activeStep, entityType }: FormContentProps): JSX.Element => {
  if (activeStep === 1) {
    return <GeneralInfo entityType={entityType} />;
  } else if (activeStep === 2) {
    return <LegalEntityInfo />;
  } else {
    return <SupportingDocs />;
  }
};

const EntitiesCreator = ({ entityType }: EntityTypeProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeStep, isLoading } = useAppSelector((state: RootState) => state.entityDetail);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);
    const { id, step } = params;
    if (id) {
      dispatch(
        getEntity({
          type: entityType,
          id,
          step,
        }),
      );
    }
  }, [dispatch, entityType, searchParams]);

  return (
    <>
      <ToastContainer {...defaultToastContainerProps}/>
      <DimmerLoader status={isLoading} />
      <div className="entity-creator-layout">
        <Navbar />

        <main>
          <header>
            <CreatorHeader entityType={entityType} />
          </header>

          <aside>
            <Steps entityType={entityType} />

            <Image src={SideBarImage} />
          </aside>

          <section className="p-t-ml p-l-xl p-r-xl">
            <main>
              <FormContent
                activeStep={activeStep}
                entityType={entityType}
              />
            </main>

            <footer>
              <Footer />
            </footer>
          </section>
        </main>
      </div>
    </>
  );
};

export default EntitiesCreator;
