import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateContractSecurityAmount, updateContractSecurityType } from 'store/contracts/contractDetailSlice';
import ContractSecurityType from 'common/model/ContractSecurityType';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { SECURITY_TYPE_OPTIONS } from 'utils/UI';
import { checkNotEmpty } from 'utils/tsValidator';

const SecurityForm = ({ mode }: ModeProps) => {
  const {
    activeContract: {
      security: {
        type,
        amount: { bankGuaranteeAmount, depositCashAmount, otherDeposit },
      },
    },
  } = useAppSelector((state: RootState) => state.contractDetail);

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: any) => {
    // TODO to be improved : value is a date in the string format "2024-01-01"
    if (key === 'type') {
      dispatch(updateContractSecurityType({ key, value }));
    } else {
      dispatch(
        updateContractSecurityAmount({
          key,
          value,
        }),
      );
    }
  };

  const disableBankGuaranteeAmountField =
    mode === MODE_OPTIONS.READ || (type !== ContractSecurityType.Bank && type !== ContractSecurityType.Both);
  const bankGuaranteeAmountRequired =
    mode === MODE_OPTIONS.EDIT && (type === ContractSecurityType.Bank || type === ContractSecurityType.Both);

  const disableDepositCashAmount =
    mode === MODE_OPTIONS.READ || (type !== ContractSecurityType.Cash && type !== ContractSecurityType.Both);
  const depositCashAmountRequired =
    mode === MODE_OPTIONS.EDIT && (type === ContractSecurityType.Cash || type === ContractSecurityType.Both);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <SelectField
            label="Security type"
            dataTest="security-type-input-field"
            placeholder="Select security type"
            fieldKey="type"
            value={type}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            options={SECURITY_TYPE_OPTIONS}
            onChange={onChange}
            runValidation={!checkNotEmpty(type)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12}>
          <InputField
            label="Cash deposit amount"
            placeholder="Type here"
            dataTest="cash-deposit-amount-input-field"
            type={InputFieldType.NUMBER}
            unit="(HK$)"
            fieldKey="depositCashAmount"
            value={depositCashAmount}
            disabled={disableDepositCashAmount}
            required={depositCashAmountRequired}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12}>
          <InputField
            label="Bank guarantee amount"
            placeholder="Type here"
            dataTest="bank-guarantee-input-field"
            value={bankGuaranteeAmount}
            fieldKey="bankGuaranteeAmount"
            type={InputFieldType.NUMBER}
            unit="(HK$)"
            disabled={disableBankGuaranteeAmountField}
            required={bankGuaranteeAmountRequired}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12}>
          <TextAreaField
            label="Other deposit"
            dataTest="other-deposit-input-field"
            value={otherDeposit}
            disabled={mode === MODE_OPTIONS.READ || !type}
            onChange={onChange}
            fieldKey="otherDeposit"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SecurityForm;
