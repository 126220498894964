import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { TransactionProvisionInput } from './types';

interface UpdateTransactionProvisionQueryVariables {
  id: string | null | undefined;
  params: TransactionProvisionInput;
}

const UPDATE_TRANSACTION_PROVISION = (
  id: string | null | undefined,
  transactionProvision: TransactionProvisionInput,
) => {
  if (!id) throw new Error('Update Transaction Provision: id is required');

  const variables: UpdateTransactionProvisionQueryVariables = {
    id,
    params: transactionProvision,
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: TransactionProvisionInput!) {
        updateTransactionProvision(id: $id, params: $params) {
          id
          transaction {
            id
          }
          provision {
            id
            name
            provisionCategory {
              id
            }
            contents {
              content
              documentTypeId
            }
          }
          mandatory
          selected
        }
      }
    `,
    variables,
  };
};

export const updateTransactionProvision = createAsyncThunk(
  'transactions/updateTransactionProvision',
  async ({
    id,
    transactionProvision,
  }: {
    id: string | null | undefined;
    transactionProvision: TransactionProvisionInput;
  }) => {
    const response = await client.mutate(UPDATE_TRANSACTION_PROVISION(id, transactionProvision));
    return response;
  },
);
