import ContractDocument from 'common/model/ContractDocument';
import ContractDocumentInput from 'common/model/ContractDocumentInput';

export interface ContractDocumentInputClone extends Omit<ContractDocumentInput, 'dateSigned'> {
  dateSigned: Date | null;
  insertedAt: Date | null;
}

export class CContractDocument implements ContractDocumentInputClone {
  comment: string;
  dateSigned: Date | null;
  insertedAt: Date | null;
  name: string;
  path: string;
  public: boolean;

  constructor(input: ContractDocument | null) {
    this.comment = input ? input.comment : '';
    this.dateSigned = input && input.dateSigned ? new Date(input.dateSigned) : null;
    this.insertedAt = input && input.insertedAt ? new Date(input.insertedAt) : null;
    this.name = input ? input.name : '';
    this.path = input ? input.path : '';
    this.public = input ? input.public : true;
  }

  static fragment() {
    return `
        name
        public
        dateSigned
        comment
        url
        path
        insertedAt
    `;
  }
}
