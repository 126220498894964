import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateCell from 'atoms/DateCell';
import DropdownSelect from 'atoms/DropdownSelect';
import { updateContractRevenueBaseScale, updatecontractRevenueBaseDate } from 'store/contracts/contractDetailSlice';
import Scale from 'common/model/Scale';
import { getContractRevenue } from 'common/api/contracts';
import { DROPDOWN_OPTION } from 'utils/UI';
import './RevenueForecastFilter.scss';

interface SCALE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: Scale;
}

export const SCALE_OPTIONS: SCALE_OPTION[] = [
  { key: 1, value: Scale.Month, text: 'Month' },
  { key: 2, value: Scale.Quarter, text: 'Quarter' },
  { key: 3, value: Scale.Semester, text: 'Semester' },
  { key: 4, value: Scale.Year, text: 'Year' },
];

const RevenueForecastFilter = ({ contractID }: { contractID: string }): JSX.Element => {
  const dispatch = useAppDispatch();

  const [loaded, setLoaded] = useState<boolean>(false);
  const { startDate, endDate, scale } = useAppSelector((state: RootState) => state.contractDetail.contractRevenueBase);

  useEffect(() => {
    if (loaded && startDate && endDate) {
      // @ts-ignore
      dispatch(getContractRevenue({ id: contractID }));
    }
  }, [startDate, endDate, dispatch]);

  useEffect(() => {
    // @ts-ignore
    if (startDate && endDate) {
      dispatch(getContractRevenue({ id: contractID }));
    }
    setLoaded(true);
  }, [scale, dispatch]);

  return (
    <div className="revenue-forecast-filter m-b-ml d-flex">
      <div className="date-column">
        <span>From</span>
        <DateCell
          type="startDate"
          date={startDate}
          onChange={updatecontractRevenueBaseDate}
        />
      </div>

      <div className="date-column m-l-xs">
        <span className="m-r-xs">To</span>
        <DateCell
          type="endDate"
          date={endDate}
          onChange={updatecontractRevenueBaseDate}
        />
      </div>

      <DropdownSelect
        className="m-l-l"
        options={SCALE_OPTIONS}
        placeholder="Select scale"
        onChange={(key, value) => dispatch(updateContractRevenueBaseScale({ value }))}
        value={scale}
      />
    </div>
  );
};

export default RevenueForecastFilter;
