import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import PaidBy from 'common/model/PaidBy';
import RateType from 'common/model/RateType';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { PAID_BY_OPTIONS, RATE_TYPE_OPTIONS } from 'utils/UI';
import { getMaxDateBoundaryForTenancies, getMinDateBoundaryForTenancies } from 'utils/utils-date';
import { onChangeHandler } from '../utils.onChange';

interface StandaloneACFeesFormProps extends ModeProps {
  index: number;
}

const AcFeesForm = ({ mode, index }: StandaloneACFeesFormProps) => {
  const dispatch = useAppDispatch();

  const { activeContract, activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const acFees = activeContract?.acFees || [];
  const managementCharges = activeContract?.managementCharges || [];

  const acFee = acFees[index];
  const managementCharge = managementCharges[0];

  const {
    endDate,
    startDate,
    paidBy,
    type,
    standardHours,
    standardRate,
    standardRateDiscount,
    finalCharge,
    outOfHoursFee,
  } = acFee;

  const isStandardType = type === RateType.Standard;
  const isDiscountType = type === RateType.Discount;
  const isFixedType = type === RateType.Fixed;

  const key = 'acFees';

  const onChange = onChangeHandler(dispatch, key, index, acFee);

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  const doNotShow = managementCharge.paidBy && managementCharge.includeAcFee;

  useEffect(() => {
    if (managementCharge.paidBy) {
      dispatch(
        updateActiveContractInput({
          key: [key, `${index}`],
          value: {
            ...acFee,
            paidBy: managementCharge.includeAcFee ? PaidBy.Landlord : PaidBy.Tenant,
            type: null,
            standardRate: null,
            standardRateDiscount: null,
            finalCharge: null,
          },
        }),
      );
    }
  }, [managementCharge.includeAcFee, managementCharge.paidBy]);

  return (
    <div>
      <div className="grid m-t-s pm-none">
        <div className="col-2">
          <DateField
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              acFees,
              index,
              'startDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              acFees,
              index,
              'startDate',
            )}
            label="Start date"
            fieldKey="startDate"
            dataTest=""
            value={startDate}
            disabled={disabled}
            required={required}
            onChange={onChange.onChange}
          />
        </div>

        <div className="col-2">
          <DateField
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              acFees,
              index,
              'endDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              acFees,
              index,
              'endDate',
            )}
            label="End date"
            fieldKey="endDate"
            dataTest=""
            value={endDate}
            disabled={disabled}
            required={required}
            onChange={onChange.onChange}
          />
        </div>

        <div className="col-2">
          <SelectField
            label="Payable by landlord or tenant"
            fieldKey="paidBy"
            dataTest=""
            value={paidBy}
            disabled={true}
            options={PAID_BY_OPTIONS}
            onChange={onChange.onChange}
          />
        </div>
      </div>
      {!doNotShow && (
        <div className="grid m-t-s pm-none">
          <div className="col-2">
            <SelectField
              label="Standard/discount/fixed"
              fieldKey="type"
              dataTest=""
              value={type}
              disabled={disabled}
              required={required}
              options={RATE_TYPE_OPTIONS}
              onChange={onChange.onChangeType}
            />
          </div>
          <div className="col-2">
            <InputField
              label="Standard rate (psf pcm)"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(HK$/sf/mo)"
              fieldKey="standardRate"
              value={standardRate?.toString() || null}
              disabled={disabled}
              required={required}
              onChange={onChange.onChangeStandardRate}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
          <div className="col-2">
            <InputField
              label="Discount from standard rate"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(%)"
              fieldKey="standardRateDiscount"
              value={standardRateDiscount?.toString() || null}
              disabled={disabled || !type || isStandardType || isFixedType}
              required={required && type !== null && !isStandardType && !isFixedType}
              onChange={onChange.onChangeStandardRateDiscount}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
          <div className="col-2">
            <InputField
              label="Final mgt. charges (psf pcm)"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(HK$/sf/mo)"
              fieldKey="finalCharge"
              value={finalCharge?.toString() || null}
              disabled={disabled || !type || isStandardType || isDiscountType}
              required={required && type !== null && !isStandardType && !isDiscountType}
              onChange={onChange.onChangeFinalCharge}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
        </div>
      )}

      <div className="grid m-t-s pm-none">
        <div className="col-2">
          <InputField
            label="Standard hours"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(hours))"
            fieldKey="standardHours"
            value={standardHours?.toString() || null}
            disabled={disabled}
            onChange={onChange.onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>
        <div className="col-2">
          <InputField
            label="Out of hours AC fee"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/hr))"
            fieldKey="outOfHoursFee"
            value={outOfHoursFee?.toString() || null}
            disabled={disabled}
            onChange={onChange.onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>
      </div>
    </div>
  );
};

export default AcFeesForm;
