import { useNavigate } from 'react-router-dom';
import { Button, Image } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { updateActiveStep } from 'store/entities/entityDetailSlice';
import { EntityTypeProps, createEntity, updateEntity } from 'common/api/entities';
import { moveTo, moveToId } from 'utils/utils-actions';
import { Icons } from 'utils/utils-icons';
import headerLeft from 'assets/images/svg/header-left.svg';
import headerRight from 'assets/images/svg/header-mid.svg';
import { checkEntitiesSteps, gotoEntityCreatorNextStep } from '../../entityValidator';
import './CreatorHeader.scss';
import { createNetwork } from 'common/api/entities/createNetwork';

const CreatorHeader = ({ entityType }: EntityTypeProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { activeEntity, activeStep } = useAppSelector(state => state.entityDetail);

  const type: string = entityType.toLowerCase();

  const getRedirectURL = (): string => {
    const search = window.location.search;
    if (search.includes('from=transactions')) {
      return '/transactions/create';
    } else {
      return `/${type}-entities/${activeEntity.id || ''}/details`;
    }
  };

  const url = getRedirectURL();

  const onSave = (): void => {
    if (activeEntity.id) {
      moveTo({
        moveToEvent: dispatch(updateEntity({ type: entityType, saveAndExit: true })),
        navigate,
        url,
      });
    } else {
      const {
        parentId,
        sharePercentage
      } = activeEntity;
      moveToId({
        moveToEvent: dispatch(createEntity({ type: entityType, saveAndExit: true })).then(response => {
          if (response.meta.requestStatus === 'fulfilled') {
            // @ts-ignore
            const id  = response?.payload?.data?.createLandlordEntity?.id;
            if (parentId)
              dispatch(
                createNetwork({
                  childId: id,
                  parentId,
                  sharePercentage
                }),
              );
          }
          return response;
        }),
        navigate,
        entityType,
      });
    }
  };

  const onExit = (): void => {
    const selectedEntity: string | null = localStorage.getItem('sericin_selected_entity');
    if (selectedEntity === null) {
      navigate('/welcome');
    } else {
      const search = window.location.search;
      if (search.includes('from=transactions')) {
        navigate('/transactions/create');
        return;
      }

      const exitUrl = activeEntity.id ? url : `/${type}-entities/dashboard`;
      navigate(exitUrl);
    }
  };

  const checkDisabled = (): boolean => {
    return checkEntitiesSteps(activeEntity, entityType, activeStep);
  };

  const onGotoNextStep = (): void => {
    gotoEntityCreatorNextStep({
      dispatch,
      navigate,
      entityId: activeEntity.id,
      entityType,
      activeStep,
    });
  };

  const onGoBack = (): void => {
    if (activeStep > 1) dispatch(updateActiveStep({ activeStep: activeStep - 1 }));
  };

  const disabled: boolean = checkDisabled();

  return (
    <>
      <div className="entity-creator-header">
        <Image
          src={headerLeft}
          className="header-img"
        />
        <Image
          src={headerLeft}
          className="header-img m-l-l"
        />

        <div className="d-flex justify-space-between align-center p-ml">
          <h2 className="header m-l-xxl">Create new {entityType} Entity</h2>

          <div className="d-flex flex-gap-m z-index-1">
            {activeStep !== 1 && (
              <Button
                className="btn grey-outline"
                data-test="navigate-back-button"
                type="submit"
                onClick={onGoBack}
              >
                <Icon icon={Icons.ArrowLeft} />
                BACK
              </Button>
            )}

            <Button
              className="btn grey-outline"
              data-test="exit-button"
              onClick={onExit}
            >
              EXIT
            </Button>
            {activeStep !== 1 && (
              <Button
                className="btn grey-bg"
                data-test="save-and-exit-button"
                disabled={disabled}
                onClick={onSave}
              >
                SAVE AND EXIT
              </Button>
            )}

            {activeStep !== 3 && (
              <Button
                className="btn grey-bg"
                data-test="steps-next-button"
                type="submit"
                disabled={disabled}
                onClick={onGotoNextStep}
              >
                NEXT STEP <Icon icon={Icons.ArrowRight} />
              </Button>
            )}
          </div>
        </div>

        {/* position values for Background Image behind the buttons */}
        {['18rem', '12rem', '6rem', '-6rem'].map((right, index) => (
          <Image
            key={index}
            src={headerRight}
            className="header-img right"
            style={{ right }}
          />
        ))}
      </div>
    </>
  );
};

export default CreatorHeader;
