import { Button } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { updateTransactionContractViewsTab } from 'store/transactions/transactionDetailSlice';
import { evaluateAIQuestion } from 'common/api/aiQuestions';
import { translateToContract } from 'common/api/translatorsToContract';
import { TRANSACTION_CONTRACT_VIEW_TABS_OFFSET } from '.';

const OverviewTabToolbar = ({
  activeTabIndex,
}: {
  activeTabIndex: TRANSACTION_CONTRACT_VIEW_TABS_OFFSET;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const onTranslate = (): void => {
    // Change state to cause re-render (by modifying the index)
    dispatch(updateTransactionContractViewsTab({ tab: '' }));
    dispatch(translateToContract({ finalised: false })).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        // Reset to active tab
        dispatch(updateTransactionContractViewsTab({ tab: activeTabIndex }));
      }
    });
  };

  return (
    <>
      {/* TO BE REMOVED */}
      <Button
        className="ai-question-btn"
        onClick={() => dispatch(evaluateAIQuestion())}
      ></Button>
      <Button
        className="btn grey-bg translate-to-contract-btn"
        onClick={onTranslate}
      >
        TRANSLATE
      </Button>
    </>
  );
};

export default OverviewTabToolbar;
