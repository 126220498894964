import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { TRANSACTION_FRAGMENT } from './getLandlordTransaction';

interface GetTenantTransactionQueryVariables {
  currentTenantEntityId: string;
  transactionId: string;
}

const GET_TENANT_TRANSACTION = (currentTenantEntityId: string, id: string | undefined) => {
  if (!id) throw new Error('Get Tenant Transaction: id is required');
  if (!currentTenantEntityId) throw new Error('Get Tenant Transaction: currentTenantEntityId is required');

  const variables: GetTenantTransactionQueryVariables = {
    currentTenantEntityId,
    transactionId: id,
  };

  return {
    query: gql`
      query ($currentTenantEntityId: ID!, $transactionId: ID!) {
        getTenantTransaction(currentTenantEntityId: $currentTenantEntityId, transactionId: $transactionId) {
        ${TRANSACTION_FRAGMENT}
        }
      }
    `,
    variables,
  };
};

export const getTenantTransaction = createAsyncThunk(
  'transactions/getTenantTransaction',
  async ({ id }: { id: string | undefined }, { getState }) => {
    const {
      transactionsListing: { selectedTenant },
    } = getState() as RootState;
    const response = await client.query(GET_TENANT_TRANSACTION(selectedTenant, id));
    return response;
  },
);
