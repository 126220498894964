import { ColumnBodyOptions } from 'primereact/column';
import { Icon } from '@iconify/react';
import TableReactPrime, { CustomColumnProps } from 'atoms/TableReactPrime';
import TableActionButtons from 'atoms/TableReactPrime/TableActionButtons';
import { DocumentIntendedToBeUploaded } from 'common/api/miscellaneous';
import { deepClone } from 'utils/tsHelper';
import { convertDateToISO } from 'utils/utils-date';
import { Icons } from 'utils/utils-icons';
import { updateNestedObject } from 'utils/utils-nested-object-update';
import { FILES_UPLOAD_ACTIONS } from '.';
import DateField from '../DateInput';
import InputField from '../Input';
import TextAreaField from '../TextArea';
import { NewFileForUploadProps } from './UploadArea';

const contractColumnConfig = (
  onChange: (key: string, value: any) => void,
  onDelete: (rowIndex: number) => void,
  disabled?: boolean,
): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Document name',
      filterPlaceholder: 'Search by ID',
      filter: true,
      filterField: 'name',
      sortable: true,
      ...(!disabled && {
        body: (row: NewFileForUploadProps, { rowIndex }: ColumnBodyOptions) => {
          return (
            <InputField
              fieldKey={`${rowIndex}, name`}
              value={row.name}
              onChange={onChange}
            />
          );
        },
      }),
    },
    {
      field: 'dateSigned',
      header: 'Date signed',
      filter: true,
      filterPlaceholder: 'Search by Property',
      filterField: 'dateSigned',
      sortable: true,
      dataType: 'text',
      mandatory: true,
      ...(!disabled && {
        body: (row: NewFileForUploadProps, { rowIndex }: ColumnBodyOptions) => {
          return (
            <DateField
              fieldKey={`${rowIndex}, dateSigned`}
              value={row.dateSigned}
              onChange={(key: string, value: Date) => onChange(key, convertDateToISO(value))}
            />
          );
        },
      }),
    },
    ...(disabled
      ? [
          {
            field: 'insertedAt',
            header: 'Date uploaded',
            filter: true,
            filterPlaceholder: 'Search by Property',
            filterField: 'insertedAt',
            sortable: true,
            dataType: 'text',
            mandatory: true,
          },
        ]
      : []),
    {
      field: 'comment',
      header: 'Comment',
      filter: true,
      filterPlaceholder: 'Search by Property',
      filterField: 'comment',
      sortable: true,
      dataType: 'text',
      mandatory: true,
      ...(!disabled && {
        body: (row: NewFileForUploadProps, { rowIndex }: ColumnBodyOptions) => {
          return (
            <TextAreaField
              style={{ height: '6rem' }}
              fieldKey={`${rowIndex}, comment`}
              value={row.comment}
              onChange={onChange}
            />
          );
        },
      }),
    },
    ...(disabled
      ? [
          {
            field: 'url',
            header: 'Attachment',
            filter: true,
            filterPlaceholder: 'Search by Property',
            filterField: 'attachment',
            sortable: true,
            dataType: 'text',
            mandatory: true,
            body: (row: NewFileForUploadProps) => {
              return (
                <a
                  className="cursor d-flex align-center m-r-auto"
                  href={row.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <Icon
                      icon={Icons.Document}
                      width="1.3rem"
                      height="1.3rem"
                    />
                  </div>
                </a>
              );
            },
          },
        ]
      : []),
    ...(disabled
      ? []
      : [
          {
            mandatory: true,
            width: '3rem',
            field: 'options',
            header: 'Actions',
            body: (row: NewFileForUploadProps, { rowIndex }: ColumnBodyOptions) => {
              return (
                <TableActionButtons
                  buttons={[
                    {
                      icon: Icons.TrashFill,
                      tooltip: 'Delete document',
                      onClick: () => onDelete(rowIndex),
                      isPopup: true,
                      popupText: 'Do you really want to delete this document?',
                      popupConfirmationButton: 'DELETE',
                      dataTest: 'delete-button',
                    },
                  ]}
                />
              );
            },
            frozen: true,
          },
        ]),
  ];
};

interface DetailedListingProps {
  documents: DocumentIntendedToBeUploaded[];
  disabled?: boolean;
  onChange2?: (updatedFiles: any[], action: FILES_UPLOAD_ACTIONS) => void;
}

const DetailedListing = ({ documents, disabled, onChange2 }: DetailedListingProps) => {
  const onChange = (key: string, value: any) => {
    const docsClone = deepClone(documents);

    updateNestedObject(docsClone, key, value);

    onChange2 && onChange2(docsClone, FILES_UPLOAD_ACTIONS.UPDATE_FIELD);
  };

  const onDelete = (rowIndex: number) =>
    onChange2 &&
    onChange2(
      documents.filter((_doc, index: number) => index !== rowIndex),
      FILES_UPLOAD_ACTIONS.DELETE,
    );

  return (
    <TableReactPrime
      customHeader={false}
      fetchContent={({ ...rest }) => {}}
      content={documents}
      columnConfig={contractColumnConfig(onChange, onDelete, disabled)}
    />
  );
};

export default DetailedListing;
