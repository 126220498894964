import Decimal from 'decimal.js';
import RenewalRights from 'common/model/RenewalRights';
import RenewalRightsInput from 'common/model/RenewalRightsInput';
import RentReviewType from 'common/model/RentReviewType';
import WholeOrPartOptions from 'common/model/WholeOrPartOptions';
import { convertDateToISO } from 'utils/utils-date';

export interface RenewalRightsInputClone
  extends Omit<
    RenewalRightsInput,
    'cap' | 'collar' | 'increment' | 'noticePeriodMonths' | 'rent' | 'type' | 'startDate' | 'endDate' | 'wholeOrPart'
  > {
  cap: Decimal | null;
  collar: Decimal | null;
  increment: Decimal | null;
  noticePeriodMonths: number | null;
  rent: Decimal | null;
  type: RentReviewType | null;
  wholeOrPart: WholeOrPartOptions | null;
  startDate: Date | null;
  endDate: Date | null;
}

export class CRenewalRights implements RenewalRightsInputClone {
  cap: Decimal | null;
  collar: Decimal | null;
  increment: Decimal | null;
  noticePeriodMonths: number | null;
  rent: Decimal | null;
  type: RentReviewType | null;
  startDate: Date | null;
  endDate: Date | null;
  wholeOrPart: WholeOrPartOptions | null;

  constructor(input: RenewalRights | null) {
    this.cap = input && input.cap ? new Decimal(input.cap) : null;
    this.collar = input && input.collar ? new Decimal(input.collar) : null;
    this.increment = input && input.increment ? new Decimal(input.increment) : null;
    this.noticePeriodMonths = input ? input.noticePeriodMonths : null;
    this.rent = input && input.rent ? new Decimal(input.rent) : null;
    this.type = input ? input.type : null;
    this.wholeOrPart = input ? input.wholeOrPart : null;
    this.startDate = input && input.startDate ? new Date(input.startDate) : null;
    this.endDate = input && input.endDate ? new Date(input.endDate) : null;
  }

  static fragment() {
    return `
      cap
      collar
      increment
      noticePeriodMonths
      rent
      type
      wholeOrPart
      startDate
      endDate
    `;
  }

  static formatForAPI(right: CRenewalRights) {
    return {
      cap: right.cap ? parseFloat(right.cap.toString()) : null,
      collar: right.collar ? parseFloat(right.collar.toString()) : null,
      increment: right.increment ? parseFloat(right.increment.toString()) : null,
      noticePeriodMonths: right.noticePeriodMonths ? parseInt(right.noticePeriodMonths.toString()) : null,
      rent: right.rent ? parseInt(right.rent.toString()) : null,
      type: right.type,
      wholeOrPart: right ? right.wholeOrPart : null,
      startDate: convertDateToISO(right.startDate),
      endDate: convertDateToISO(right.endDate),
    };
  }
}
