import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TransactionListingClone } from 'common/_classes';
import { RootState } from 'store';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { getClientType } from 'utils/utils-authentication';

interface ListTransactionsQueryVariables {
  first: number | undefined;
  last: number | undefined;
  after: string | undefined;
  before: string | undefined;
  search: string | null;
  status: string | null;
}

const LIST_TRANSACTIONS = (
  searchValue: string | null,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
) => {
  const variables: ListTransactionsQueryVariables = {
    first: first,
    last: last,
    after: after,
    before: before,
    search: searchValue,
    status: null,
  };

  return {
    query: gql`
      query (
        $first: Int
        $last: Int
        $after: String
        $before: String
        $search: String
        $status: String
      ) {
        listTransactions(
          first: $first
          last: $last
          after: $after
          before: $before
          filter: { search: $search, status: $status }
        ) {
          edges {
            cursor
            node {
              ${TransactionListingClone.fragments()}
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    `,
    variables,
  };
};

export const listTransactions = createAsyncThunk(
  'transactions/listTransactions',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const {
      transactionsListing: { search },
    } = getState() as RootState;

    const response = await getClientType().query(LIST_TRANSACTIONS(search, first, last, after, before));
    return response.data;
  },
);
