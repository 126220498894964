import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import DocumentType from 'common/model/DocumentType';
import { DocumentsType, convertToContract } from 'common/api/contracts';
import { uploadToAWS } from 'utils/utils-upload';

interface UpdateContractQueryVariables {
  currentLandlordEntityId: string;
  contractId: string;
  params: any;
}

interface UpdateContractProps {
  activeContract: any;
  contractDocs: Partial<DocumentType>[];
  activeContractFreezedCopy: any;
}

export const UPDATE_CONTRACT = ({ activeContract, contractDocs, activeContractFreezedCopy }: UpdateContractProps) => {
  const currentLandlordEntityId: string = activeContract.landlord.id;

  if (!currentLandlordEntityId === null) {
    throw new Error('Current Landlord Entity could not be determined. Query aborted.');
  }
  const contractId = activeContract.id;

  if (!contractId) {
    throw new Error('Update Contract: Contract ID could not be determined. Query aborted.');
  }

  const queryVariables: UpdateContractQueryVariables = {
    currentLandlordEntityId,
    contractId: contractId,
    params: convertToContract(activeContract, contractDocs, activeContractFreezedCopy),
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $contractId: ID!, $params: ContractInput!) {
        updateContract(currentLandlordEntityId: $currentLandlordEntityId, contractId: $contractId, params: $params) {
          id
          description
          documents {
            id
            name
            public
            url
            path
          }
          endDate
          expansionFlag
          identifier
          insertedAt
          owner {
            id
          }
          premises {
            floorsStatus {
              floorId
              whole
            }
            property {
              id
              buildingName
            }
            spaceIds
          }
          assignmentFlag
          redevelopmentFlag
          refusalFlag
          renewFlag
          rentDescriptions {
            endDate
            monthlyBaseRent
            monthlyEffectiveRent
            startDate
            validated
            charges {
              airConCharges
              governementRates
              governementRent
              managementFees
            }
            rentFreePeriods {
              additionalFreeItems
              endDate
              startDate
            }
            rentReview {
              cap
              collar
              date
              increment
              rent
              type
            }
          }
          rentType
          securityAmount {
            bankGuaranteeAmount
            depositCashAmount
            otherDeposit
          }
          securityType
          startDate
          subletFlag
          surrenderFlag
          tenant {
            id
          }
          terminationDate
          terminationDescription
          terminationReason
          updatedAt
          capitalAllowance {
            flagOn
            value
          }
          oneOffCharges {
            fitOutDeposit
            fitOutFee
          }
          reviewFlag
          reviewStatus {
            date
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    `,
    variables: queryVariables,
  };
};

export const updateContract = createAsyncThunk('contracts/updateContract', async (arg, { getState }) => {
  const {
    contractDetail: { activeContract, activeContractFreeze },
  } = getState() as RootState;

  const contractDocs: Partial<DocumentsType>[] = [...activeContract.documents];

  if (contractDocs.length > 0) {
    for (let i = 0; i < contractDocs.length; i++) {
      const { local, uploadUrl, file, name, path, public: docPublic } = contractDocs[i];

      if (local) {
        await uploadToAWS(uploadUrl, file);
      }

      contractDocs.splice(i, 1, {
        name,
        path,
        public: local ? true : docPublic,
      });
    }
  }
  const response = await client.mutate(
    UPDATE_CONTRACT({ activeContract, contractDocs, activeContractFreezedCopy: activeContractFreeze }),
  );
  return response;
});
