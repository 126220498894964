import Condition from 'common/model/Condition';
import ConditionsOperator from 'common/model/ConditionsOperator';
import NodeType from 'common/model/NodeType';

export interface ParamRefInputProps {
  index?: number;
  parameterId: string;
  tableId?: string;
  position?: string;
  offset?: string;
}

export interface ConditionsInputProps {
  operator: ConditionsOperator;
  list: Condition[];
}

export interface NodeInputProps {
  id?: string;
  type: NodeType;
  provisionId?: string;
  formatterId?: string | null;
  level: number;
  name: string;
  paramRefs?: ParamRefInputProps[];
  conditions?: ConditionsInputProps[];
}

// Lowercase because content has types in lowercase
export enum ContentNodeType {
  TEXT = 'text',
  PARAMETER = 'parameter',
  CLAUSE = 'clause',
  PROVISION = 'provision',
  CLAUSE_INDEX = 'clause-index',
  SECTION = 'section',
}
