import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import TimeLine from 'components/GraphsTimeline/Timeline';
import TabContainer from 'components/TabContainer';
import { updateContractRevenueBaseScale, updatecontractRevenueBaseDate } from 'store/contracts/contractDetailSlice';
import Scale from 'common/model/Scale';
import { getContractTermDates, getContractTimeline } from 'common/api/contracts';
import RevenueForecast from './RevenueForecast';

const ContractOverviewTab = ({ contractId }: { contractId?: string }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const contractID = contractId ? contractId : id;

  useEffect(() => {
    // @ts-ignore
    dispatch(getContractTimeline({ id: contractID as string }));
    dispatch(getContractTermDates({ id: contractID as string }));
  }, [dispatch]);

  useEffect(() => {
    // This is to update startDate and endDate of contractRevenueBase to contract term dates
    // Without it, Revenue Forecast will not work
    // @ts-ignore
    dispatch(getContractTermDates({ id: contractID as string })).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const data = response.payload.data.getContract;

        // Update contractRevenueBase Scale
        const startDateSecs = new Date(data.startDate).getTime();
        const endDateSecs = new Date(data.endDate).getTime();

        const diffMilliseconds = Math.abs(endDateSecs - startDateSecs);

        const twoYearsInMilliseconds = 2 * 365 * 24 * 60 * 60 * 1000;

        // Compare the difference to 2 years
        if (diffMilliseconds > twoYearsInMilliseconds) {
          dispatch(
            updateContractRevenueBaseScale({
              value: Scale.Quarter,
            }),
          );
        }

        // Update contractRevenueBase startDate
        dispatch(
          updatecontractRevenueBaseDate({
            type: 'startDate',
            date: data.startDate,
          }),
        );

        // Update contractRevenueBase endDate
        dispatch(
          updatecontractRevenueBaseDate({
            type: 'endDate',
            date: data.endDate,
          }),
        );
      }
    });
  }, [dispatch]);

  return (
    <TabContainer tabTitle={'Overview'}>
      <h2 className="m-b-xs color-blue-very-dark-grayish">Events Timeline</h2>
      <TimeLine />

      <h2 className="m-b-xs color-blue-very-dark-grayish">Revenue Forecast</h2>
      <RevenueForecast contractID={contractID as string} />
    </TabContainer>
  );
};

export default ContractOverviewTab;
