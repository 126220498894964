import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { TransactionMessageClone } from '../policies';
import { TransactionMessageParams } from './createTransactionMessage';

interface UpdateTransactionMessageQueryVariables {
  transactionMessageId: string;
  params: TransactionMessageParams;
}

const UPDATE_TRANSACTION_MESSAGE = (message: TransactionMessageClone) => {
  const transactionMessageId = message.id;

  if (!transactionMessageId) throw new Error('Update Transaction Message: transactionMessageId is required');

  const params: TransactionMessageParams = {
    message: message.message,
    flag: message.flag,
    amendmentFlag: message.amendmentFlag,
    thread: message.thread,
    amendmentId: message.amendment?.id ? message.amendment.id : null,
  };

  const variables: UpdateTransactionMessageQueryVariables = {
    transactionMessageId,
    params,
  };

  return {
    mutation: gql`
      mutation ($transactionMessageId: ID!, $params: TransactionMessageInput!) {
        updateTransactionMessage(transactionMessageId: $transactionMessageId, params: $params) {
          id
        }
      }
    `,
    variables,
  };
};

export const updateTransactionMessage = createAsyncThunk(
  'transactions/updateTransactionMessage',
  async (message: TransactionMessageClone) => {
    const response = await client.mutate(UPDATE_TRANSACTION_MESSAGE(message));
    return response;
  },
);
