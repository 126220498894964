export * from './checkParameterUse';
export * from './createParameter';
export * from './createParameterCategory';
export * from './createParameterGroup';
export * from './createParameterTable';
export * from './deleteParameter';
export * from './deleteParameterCategory';
export * from './deleteParameterGroup';
export * from './deleteParameterTable';
export * from './getParameter';
export * from './getParameterGroupHtml';
export * from './getParameterGroupPdf';
export * from './getParameterTable';
export * from './listContextParameters';
export * from './listContextParametersGroup';
export * from './listParameterCategories';
export * from './listParameterCategoriesAndGroups';
export * from './listParameterGroups';
export * from './listParameters';
export * from './listParametersWithAnswerType';
export * from './listParameterTables';
export * from './listParameterTablesPage';
export * from './updateParameter';
export * from './updateParameterCategory';
export * from './updateParameterGroup';
export * from './updateParametersIndex';
export * from './updateParameterTable';
export * from './types';

//TODO move to the constructor of ParameterInput
export const formatChoices = (choices: any, isCreate = false) =>
  choices.map((element: any) => ({
    ...(!isCreate && { id: element.id }),
    default: element.default,
    text: element.text,
    archive: element.archive,
    index: element.index,
  }));
