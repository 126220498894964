import ContractValuation2 from 'common/model/ContractValuation2';
import { CContractValuationData } from './data';

export class CValuationReport {
  id: string;
  identifier: string;
  isBenchmark: boolean;
  landlordId: string;
  reference: boolean;
  underNegotiation: boolean;
  data: CContractValuationData;

  constructor(input: ContractValuation2) {
    console.log("input: ",input);
    this.id = input?.id;
    this.identifier = input?.identifier;
    this.landlordId = input?.landlordId;
    this.isBenchmark = input && input.isBenchmark ? input.isBenchmark : false;
    this.reference = input && input.reference ? input.reference : false;
    this.underNegotiation = input && input.underNegotiation ? input.underNegotiation : false;
    this.data = new CContractValuationData(input?.data);
  }

  static fragment() {
    return `
        id
        identifier
        isBenchmark        
        landlordId
        reference        
        underNegotiation      
        data {
          ${CContractValuationData.fragment()}
        }
    `;
  }
}
