import { AnyAction, Dispatch } from '@reduxjs/toolkit';

export const namesListHK: string[] = ['HK', 'HongKong'];
export const invalidEmailText: string = 'Invalid email format';

interface UploadPrepProps {
  uploadUrl: string | null;
  path: string | null;
}

interface FileDetailsProps {
  local: boolean | null;
  file: any | null;
  name: string | null;
}

export const uploadFile = async (
  { uploadUrl, path }: UploadPrepProps,
  fileDetails: FileDetailsProps,
  dispatch: Dispatch<AnyAction>,
): Promise<{
  name: string | null;
  path: string | null;
  public: boolean;
} | null> => {
  const { local, file, name } = fileDetails;

  let doc = null;
  if (local) {
    await uploadToAWS(uploadUrl, file);
    doc = {
      name,
      path,
      // @ts-ignore
      public: fileDetails.public,
    };
  }
  return doc;
};

export const uploadToAWS = async (
  presignedUrl: string | null | undefined,
  file: File | undefined,
): Promise<Response | unknown> => {
  if (presignedUrl && file) {
    try {
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
          'Content-disposition': 'inline',
        },
        body: file,
      });

      return response;
    } catch (err) {
      return err;
    }
  }
};
