import { ParameterTableColumnForm } from 'common/_classes';
import { ParameterGroupClone } from 'common/_classes';
import { Panel } from 'primereact/panel';
import { useState } from 'react';
import { ButtonGroup, Dropdown, Radio, Button as SemanticButton, Icon as SemanticIcon } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import AddPlus from 'atoms/AddPlus';
import ConditionEquationRow from 'components/ConditionsTemplate/ConditionEquationRow';
import { PREFIX_CONDITION } from 'components/ConditionsTemplate/ConditionUtils';
import 'components/ConditionsTemplate/ConditionsTemplate.scss';
import { handleMultiLevelSelector } from 'components/ParameterMultiLevelSelect/multiLevelInputHelper';
import { setShowCondition } from 'store/nodes/nodesSlice';
import { resetColumnConditions, updateColumnConditions } from 'store/parameters/parameterDetailSlice';
import ConditionType from 'common/model/ConditionType';
import Conditions from 'common/model/Conditions';
import ConditionsOperator from 'common/model/ConditionsOperator';
import Parameter from 'common/model/Parameter';
import ParameterTable from 'common/model/ParameterTable';
import { deepClone } from 'utils/tsHelper';
import './ColumnConditions.scss';

const newInnerCondition: any = {
  answer: {
    answer: '',
  },
  comparator: null,
  paramRef: {
    index: null,
    offset: null,
    parameterId: null,
    position: null,
    tableId: null,
  },
  paramRef2: null,
  position: null,
  type: ConditionType.ParamValue,
};

export interface ConditionsTemplateProps {
  disabled?: boolean;
  parameters: Parameter[];
  parameterTables: ParameterTable[];
  parameterGroups: ParameterGroupClone[];
  value: Conditions[];
  onChange: (value: Conditions[]) => void;
}

const ConditionItem = ({
  value,
  onChange,
  groupIndex,
  multiLevelOptions,
  condition,
}: {
  value: any;
  onChange: any;
  groupIndex: number;
  multiLevelOptions: any;
  condition: Conditions;
}) => {
  const { operator, list } = condition;

  const updateConditionGroupOperator = (operatorValue: any, groupIndex: number) => {
    const temp = deepClone(value);
    temp[groupIndex].operator = operatorValue;
    onChange(temp);
  };

  const removeConditionGroup = (groupIndex: number) => {
    const temp = deepClone(value);
    temp.splice(groupIndex, 1);
    onChange(temp);
  };

  const addInnerCondition = (groupIndex: number) => {
    const temp = deepClone(value);
    temp[groupIndex].list.push(newInnerCondition);
    onChange(temp);
  };

  return (
    <div
      className="bg-gray-very-light condition-group-box m-b-sm"
      key={`group-box-${groupIndex}`}
    >
      <div className="condition-group-box-header flex justify-content-between flex-wrap p-xxs border-sm-grayish-magenta-light bg-white">
        <Dropdown
          id={`dropdown-operator-${groupIndex}`}
          key={`dropdown-operator-${groupIndex}`}
          className="dropdown-prefix-logic"
          data-test={`dropdown-operator-${groupIndex}`}
          value={operator}
          fluid={true}
          options={PREFIX_CONDITION}
          selection={true}
          onChange={(_e, { value }) => updateConditionGroupOperator(value, groupIndex)}
        />
        <ButtonGroup basic>
          <SemanticButton
            aria-label="Cancel"
            data-test="condition-group-delete-button"
            onClick={() => removeConditionGroup(groupIndex)}
          >
            <SemanticIcon name="trash" />
          </SemanticButton>
          <SemanticButton onClick={() => addInnerCondition(groupIndex)}>
            <SemanticIcon name="add circle" />
          </SemanticButton>
        </ButtonGroup>
      </div>
      <div className="condition-group-box-body p-s">
        {list.map((innerList: any, index: number) => (
          <div
            // className="condition-inner"
            data-test="condition-inner-rows"
            key={`inner-list-${groupIndex}-${index}`}
          >
            <ConditionEquationRow
              groupIndex={groupIndex}
              index={index}
              innerList={innerList}
              multiLevelOptions={multiLevelOptions}
              value={value}
              onChange={onChange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const ConditionList = ({
  column,
  disabled,
  multiLevelOptions,
}: {
  column: ParameterTableColumnForm;
  disabled: boolean;
  multiLevelOptions: any;
}) => {
  const dispatch = useAppDispatch();
  const columnConditions: Conditions[] = column.conditions || [];
  const onChange = (value: Conditions[]) => dispatch(updateColumnConditions({ conditions: value, id: column.id }));
  const addConditionGroup = () => {
    const temp = deepClone(columnConditions);
    temp.push({
      operator: ConditionsOperator.Or,
      list: [newInnerCondition],
    });
    onChange(temp);
  };
  return (
    <div
      className={`conditions-group ${disabled && 'disabled'}`}
      data-test="conditions-group"
    >
      {columnConditions.map((condition: Conditions, index: number) => (
        <ConditionItem
          key={index}
          value={columnConditions}
          onChange={onChange}
          groupIndex={index}
          multiLevelOptions={multiLevelOptions}
          condition={condition}
        />
      ))}
      <div className="flex m-t-sm">
        <AddPlus
          onClick={() => addConditionGroup()}
          label="Add Condition Group"
          dataTest="condition-add-button"
          className="w-full"
        />
      </div>
    </div>
  );
};

export const ColumnConditions = ({ column, disabled }: { column: ParameterTableColumnForm; disabled: boolean }) => {
  const dispatch = useAppDispatch();
  const { parameterGroups, parameterTables, parameters } = useAppSelector((state: RootState) => state.multiLevelMenu);
  const multiLevelOptions = handleMultiLevelSelector(parameterGroups, parameterTables, parameters);
  const [expandCondition, setExpandCondition] = useState<boolean>(!!column.conditions.length);

  const toggleCondition = (newValue: boolean) => {
    setExpandCondition(newValue);
    dispatch(setShowCondition({ checked: newValue, key: column.id }));
    dispatch(resetColumnConditions({ id: column.id }));
  };
  const HeaderTemplate = (options: any) => {
    const className = `${options.className} justify-content-space-between`;
    return (
      <div className={className}>
        <div className="flex align-items-center gap-2">
          <span className="font-bold">Conditions</span>
        </div>
        <>
          <Radio
            disabled={disabled}
            className="m-l-sm"
            data-test="text-block-condition-button"
            toggle
            checked={expandCondition}
            onChange={(_event, data) => {
              if (data.checked === undefined) return;
              toggleCondition(data.checked);
            }}
          />
        </>
      </div>
    );
  };

  return (
    <Panel
      className={'m-t-sm m-b-sm condition-panel'}
      headerTemplate={HeaderTemplate}
      onToggle={() => {}}
      toggleable
      collapsed={!expandCondition}
    >
      <ConditionList
        column={column}
        disabled={disabled}
        multiLevelOptions={multiLevelOptions}
      />
    </Panel>
  );
};

export default ColumnConditions;
