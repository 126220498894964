import { ParameterCategoryBase, ParameterGroupClone } from 'common/_classes';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ParametersGroupQuestionsForm from 'components/ParametersGroupQuestionsForm';
import TabContainer from 'components/TabContainer';
import { selectParametersTabCollection } from 'store/parametersTab/parametersTabSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import Parameter from 'common/model/Parameter';
import { listParameterCategoriesAndGroups } from 'common/api/parameters';
import { deletePolicyAllAnswers, getPolicy } from 'common/api/policies';
import { AnswerProps } from 'common/api/policies';
import { checkIfNotDefined, checkIfPoliciesPage } from 'utils/tsHelper';
import { getAnswers } from 'utils/utils-answer';
import CategoriesTabs from './CategoriesTabs';
import ParametersTabFilter from './ParametersTabFilter';
import './CardParameters.scss';

export interface CardElement extends ParameterGroupClone {
  parametersListByGroup: Parameter[];
}

export const checkIfPolicyAnswer = (answersList: AnswerProps[], answerType: string, parameterId: string) => {
  const getAnswers = answersList.filter(
    (obj: AnswerProps) =>
      obj.answerType === answerType &&
      obj.paramRef.parameterId === parameterId &&
      checkIfNotDefined(obj.paramRef.tableId) &&
      checkIfNotDefined(obj.paramRef.index),
  );
  const policyCheck = getAnswers.filter((obj: AnswerProps) => obj.fromPolicy === true).length;
  if (policyCheck > 0) {
    return true;
  }
  return false;
};

interface CardParametersProps {
  showParametersForm: boolean;
}

// Receives the list of registered Parameters and displays
// it on the screen associating Category and Group
const CardParameters = ({ showParametersForm }: CardParametersProps) => {
  const dispatch = useAppDispatch();

  const { filteredCategoriesList, filteredGroupsList }: any = useSelector(
    (state: RootState) => state.parametersListing,
  );
  const { parameterTablesCollection } = useAppSelector((state: RootState) => state.parametersTab);
  const { activePolicy, activePolicyAnswers } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransaction, activeTransactionAnswers, activeMessageId, activeTabIndex } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );

  const checkIfTransaction: boolean = window.location.pathname.includes('transactions');
  const context: ExecuteContext = checkIfTransaction ? ExecuteContext.Transaction : ExecuteContext.Policy;
  const contextId = checkIfTransaction ? activeTransaction.id : activePolicy.id;

  useEffect(() => {
    if (checkIfPoliciesPage()) {
      dispatch(getPolicy({ id: activePolicy.id }));
    }
    dispatch(listParameterCategoriesAndGroups());
  }, []);

  const parametersList = useAppSelector(selectParametersTabCollection);

  const clearAnswers = () => {
    dispatch(deletePolicyAllAnswers());
  };

  // Filter groups that have parameters
  const filterElementsPerCategory = (category: ParameterCategoryBase): CardElement[] => {
    return (
      filteredGroupsList
        // @ts-ignore
        .map((filteredGroup: ParameterGroupClone) => {
          const parametersListByGroup: Parameter[] = parametersList.filter(
            obj => obj.parameterGroup.id === filteredGroup.id && obj.parameterGroup.category.id === category.id,
          );

          const tablesByGroup = parameterTablesCollection.filter(
            obj => obj.parameterGroup.id === filteredGroup.id && obj.parameterGroup.category.id === category.id,
          );

          return {
            ...filteredGroup,
            parametersListByGroup: [...parametersListByGroup, ...tablesByGroup],
          };
        })
        .filter((filteredGroup: any) => filteredGroup.parametersListByGroup.length !== 0)
    );
  };

  // Filter categories that have parameters
  const categoryList: ParameterCategoryBase[] = filteredCategoriesList.filter((category: ParameterCategoryBase) => {
    let cardParameters = parametersList.filter(obj => obj.parameterGroup.category.id === category.id);
    return cardParameters.length !== 0;
  });

  return (
    <TabContainer
      className="parameters-tab-container"
      tabTitle="Parameters"
      tabToolbar={
        <ParametersTabFilter
          context={context}
          contextId={contextId}
          checkIfTransaction={checkIfTransaction}
          clearAnswers={clearAnswers}
        />
      }
    >
      <div className={`card-parameters ${checkIfTransaction ? 'transaction-cards' : 'policies-cards'}`}>
        <CategoriesTabs
          getCardElementsList={(category: ParameterCategoryBase) => filterElementsPerCategory(category)}
          categoryList={categoryList}
        />
      </div>
      <ParametersGroupQuestionsForm trigger={showParametersForm} />
    </TabContainer>
  );
};

export default CardParameters;
