import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ImportContractProps {
  simulation: boolean;
  file: File;
}

interface ImportContractQueryVariables {
  currentLandlordEntityId: string;
  simulation: boolean;
  uploadFile: File;
}

const IMPORT_CONTRACT = ({ simulation, file }: ImportContractProps) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!currentLandlordEntityId) {
    throw new Error('Delete Contract: Landlord Entity could not be determined. Query aborted.');
  }

  const queryVariables: ImportContractQueryVariables = {
    currentLandlordEntityId,
    simulation,
    uploadFile: file,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $simulation: Boolean!, $uploadFile: Upload!) {
        importContract(
          currentLandlordEntityId: $currentLandlordEntityId
          simulation: $simulation
          uploadFile: $uploadFile
        ) {
          dataType
          items {
            description
            identifier
          }
          itemsDuplicateBase {
            log
            type
          }
          itemsDuplicateExcel {
            log
            type
          }
          itemsModified {
            identifier
          }
          logs {
            log
            type
          }
          simulation
        }
      }
    `,
    variables: queryVariables,
  };
};

export const importContract = createAsyncThunk(
  'contracts/importContract',
  async ({ simulation, file }: { simulation: boolean; file: File }) => {
    const response = await client.mutate(IMPORT_CONTRACT({ simulation, file }));
    return response;
  },
);
