import { Button } from 'semantic-ui-react';
import ModalTemplate from 'components/ModalTemplate';
import { RowAction, RowProps } from '../RowProps';
import RowFilterContent from './RowFilterContent';

interface RowFilterPopupProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  updateRowFilters: (filters: RowAction & RowProps) => void;
  rowFilters: (RowAction & RowProps) | null;
  rowActions: RowAction[];
  updateRowActions: (actions: RowAction[]) => void;
  onFilter: () => void;
}

const RowFilterPopup = ({
  isOpen,
  setIsOpen,
  updateRowFilters,
  updateRowActions,
  rowFilters,
  rowActions,
  onFilter,
}: RowFilterPopupProps) => {
  /**
   * onResetRowFilters gets event from the Filter popup. 'onResetRowFilters' indicates the row with the filter that is cleared
   */
  const onResetRowFilters = (): void => {
    if (rowFilters) {
      //close the popup
      setIsOpen(false);

      //find the associated element of the rowActions
      const index = rowActions.findIndex(row => row.id === rowFilters.id);

      //clears the associated rowAction
      if (index >= 0) {
        rowActions[index].filterMode = undefined;
        rowActions[index].filterValue = undefined;
      }

      updateRowActions(rowActions);
      onFilter();
    }
  };

  /**
   * onApplyRowFilters gets event from the Filter popup. 'activeRowFilter' indicates the row with the filter.
   * Then do to the valuationReport:
   *  1) reports translation + reports (column) deselection
   *  3) sorting (if there is one)
   */
  const onApplyRowFilters = (): void => {
    if (rowFilters) {
      //close the popup
      setIsOpen(false);

      //find the associated element of the rowActions
      const index: number = rowActions.findIndex(row => row.id === rowFilters.id);

      //if rowAction for the selected row does not exist: create it
      if (index === -1) {
        rowActions.push({
          filterMode: rowFilters.filterMode,
          filterValue: rowFilters.filterValue,
          id: rowFilters.id,
        });
      } else {
        rowActions[index].filterMode = rowFilters.filterMode;
        rowActions[index].filterValue = rowFilters.filterValue;
      }

      updateRowActions(rowActions);

      onFilter();
    }
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onToggleModalStatus={setIsOpen}
      title={`${rowFilters?.label}`}
      className="valuation-contract-view-row-filters-modal"
      showHeader={false}
    >
      <div className="filter-box">
        <div className="filter-content">
          {rowFilters ? (
            <RowFilterContent
              filter={rowFilters}
              onChange={(_key: string, value: any) =>
                updateRowFilters({
                  ...rowFilters,
                  filterValue: value,
                })
              }
              onFilterModeChange={(key: string, value: any) => updateRowFilters({ ...rowFilters, filterMode: value })}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="filter-buttons grid justify-center m-t-xxs">
          <div className="col-6">
            <Button
              className="btn orange-outline w-100"
              onClick={onResetRowFilters}
            >
              CLEAR
            </Button>
          </div>
          <div className="col-6">
            <Button
              className="btn orange-bg w-100"
              onClick={onApplyRowFilters}
            >
              APPLY
            </Button>
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
};

export default RowFilterPopup;
