import { Editor } from 'tinymce';
import { ContentNodeType, NodeInputProps } from 'common/api/nodes';

export const updateParameter = (editor: Editor, form: NodeInputProps) => {
  const { id, name } = form;
  const [node] = editor.dom.select(`[data-node-id="${id}"]`);

  if (!node) return;

  node.innerHTML = name || '';
};

export const deleteActiveNode = (editor: Editor, form: NodeInputProps) => {
  const [node] = editor.dom.select(`[data-node-id="${form.id}"]`);

  if (!node) return;

  const { innerText } = node;
  editor.dom.remove(node);
  editor.insertContent(innerText);
};

export const updatedConditionActiveNode = (editor: Editor, form: NodeInputProps) => {
  const { id, conditions } = form;

  if (!editor || !id) return;

  const queryId = `div[data-node-id="${id}"]`;
  const element = editor.dom.getRoot().querySelector(queryId);

  if (!element) return;

  let hasConditions = conditions?.length !== 0;

  // SOURCE: https://bobbyhadz.com/blog/javascript-get-element-by-attribute#get-dom-element-by-partial-match-of-an-attribute
  const queryIndex = 'div[data-node-type^="clause-index"]';
  const elementIndex = element.querySelector(queryIndex);
  const queryCondition = 'div[data-condition="true"]';

  const existTag = elementIndex?.querySelector(queryCondition);

  if (!existTag && hasConditions) {
    const { length } = Array.from(elementIndex?.childNodes || []);
    // clause-index-X is used in the tinymce-style.css file to add space around the 'C' of clauses with conditions
    elementIndex?.setAttribute('data-node-type', `clause-index-${length + 1}`);
    // Add ICON
    if (elementIndex) elementIndex.innerHTML += '<div data-condition="true">C</div>';
  } else if (existTag && !hasConditions) {
    // Remove ICON
    const node = elementIndex?.querySelector(queryCondition);
    node?.remove();

    elementIndex?.setAttribute('data-node-type', ContentNodeType.CLAUSE_INDEX);
  }
};
