import GraphWithOutLinedContainer, { GraphInputData } from 'atoms/GraphWithOutLinedContainer';
import AnalysisContext from 'common/model/AnalysisContext';
import Scale from 'common/model/Scale';
import { ContextAnalysisData, PortfolioRevenueAnalysis, PropertyRevenueAnalysis } from 'common/api/dashboard';
import { Colors } from 'utils/utils-colors';
import { getDateFormatForGraph } from 'utils/utils-date';
import { OccupancyRatePortfolioTooltip, OccupancyRatePropertyTooltip } from './Tooltip';
import './OccupancyRate.scss';

interface OccupancyRateGraphProps {
  propertyRevenueAnalysis: PropertyRevenueAnalysis;
  portfolioRevenueAnalysis: PortfolioRevenueAnalysis;
  period: Scale;
  isLoading: boolean;
  tab: string;
  context: AnalysisContext;
}

const OccupancyRateGraph = ({
  propertyRevenueAnalysis,
  portfolioRevenueAnalysis,
  period,
  isLoading,
  tab,
  context,
}: OccupancyRateGraphProps) => {
  // switch between portfolio and property
  const itemData =
    context === AnalysisContext.Property
      ? propertyRevenueAnalysis.propertyData
      : portfolioRevenueAnalysis.portfolioData;

  const tooltip =
    context === AnalysisContext.Property
      ? OccupancyRatePropertyTooltip(propertyRevenueAnalysis, period)
      : OccupancyRatePortfolioTooltip(portfolioRevenueAnalysis, period, tab);
  // get the labels
  const labels = itemData.map((data: ContextAnalysisData) =>
    getDateFormatForGraph(new Date(data.month), period as Scale),
  );

  const contractualOccupancy = itemData.map(
    (item: ContextAnalysisData) => `${parseFloat(item.statsArea.contractualOccupancyRate) * 100}`,
  );
  const forecastedOccupancy = itemData.map(
    (item: ContextAnalysisData) => `${parseFloat(item.statsArea.forecastOccupancyRate) * 100}`,
  );

  /* TODO, there is a trick in this graph : we are puting empty columns
   * between the real data so that they look like aligned with other graphics.
   * this will need to be fixed in the future
   */

  const adjustedLabels = [];
  const adjustedContractualOccupancy = [];
  const adjustedForecastedOccupancy = [];

  for (let i = 0; i < labels.length; i++) {
    adjustedLabels.push('');
    adjustedLabels.push(labels[i]);
  }
  adjustedLabels.push('');

  for (let i = 0; i < contractualOccupancy.length; i++) {
    adjustedContractualOccupancy.push(null);
    adjustedContractualOccupancy.push(contractualOccupancy[i]);
  }
  adjustedContractualOccupancy.push(null);

  for (let i = 0; i < forecastedOccupancy.length; i++) {
    adjustedForecastedOccupancy.push(null);
    adjustedForecastedOccupancy.push(forecastedOccupancy[i]);
  }
  adjustedForecastedOccupancy.push(null);
  //TODO end of the trick

  const data: GraphInputData[] = [
    {
      key: 1,
      label: 'Contractual occupancy',
      color: Colors.GREEN,
      data: adjustedContractualOccupancy,
    },
    {
      key: 2,
      label: 'Forecast occupancy',
      color: Colors.BLUE_LIGHT,
      data: adjustedForecastedOccupancy,
    },
  ];

  return (
    <GraphWithOutLinedContainer
      className="occupancy-rate"
      title="Occupancy Rate"
      loading={isLoading}
      labels={adjustedLabels}
      data={data}
      tooltipHTML={tooltip}
      chartType="line"
      yAxisTitle="%"
      displayVerticalGridLines={false}
    />
  );
};

export default OccupancyRateGraph;
