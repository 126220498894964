import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Grid } from 'semantic-ui-react';
import DetailedListing from './DetailedListing';
import FilesListing, { FilesListingProps } from './FilesListing';
import UploadArea, { NewFileForUploadProps, UploadAreaProps } from './UploadArea';
import './FileUpload.scss';

export enum FILES_UPLOAD_ACTIONS {
  ADD = 'ADD',
  DELETE = 'DELETE',
  UPDATE_FIELD = 'UPDATE FIELD',
}

interface FilesUploadProps extends FilesListingProps, UploadAreaProps {
  isCreatorPage?: boolean;
  onAddInTransaction?: ActionCreatorWithPayload<{
    files: NewFileForUploadProps[];
    name: string;
  }>;
  onChange2?: (updatedFiles: any[], action?: FILES_UPLOAD_ACTIONS) => void;
}

const FilesUpload = ({
  disabled,
  prepUpload,
  documents = [],
  onDelete,
  onAdd,
  isCreatorPage,
  onAddFunctionParams = {},
  isMultipleUploads = true,
  prepUploadParams = {},
  onDeleteFunctionParams = {},
  onAddInTransaction,
  onChange,
  onChange2,
}: FilesUploadProps): JSX.Element => {
  return (
    <Grid className="pm-none">
      <Grid.Row className="pm-none">
        <Grid.Column
          width={documents.length > 0 ? 5 : 16}
          className="pm-none"
        >
          <UploadArea
            disabled={disabled}
            prepUpload={prepUpload}
            onAdd={onAdd}
            onAddFunctionParams={onAddFunctionParams}
            isMultipleUploads={isMultipleUploads}
            prepUploadParams={prepUploadParams}
            onAddInTransaction={onAddInTransaction}
            onChange={onChange}
            onChange2={onChange2}
          />
        </Grid.Column>

        {documents.length > 0 && (
          <Grid.Column
            width={11}
            className="pm-none"
          >
            <DetailedListing
              documents={documents}
              disabled={disabled}
              onChange2={onChange2}
            />
            {/* <FilesListing
              disabled={disabled}
              documents={documents}
              onDelete={onDelete}
              disableDownload={isCreatorPage}
              onDeleteFunctionParams={onDeleteFunctionParams}
            /> */}
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default FilesUpload;
