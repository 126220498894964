import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProvisionDetailClone } from 'common/_classes';
import { toast } from 'react-toastify';
import Node from 'common/model/Node';
import NodeType from 'common/model/NodeType';
import Provision from 'common/model/Provision';
import {
  NodeInputProps,
  createNode,
  createParameterNode,
  createTextBlockNode,
  deleteNode,
  deleteUnusedNodes,
  getNode,
  updateNode,
} from 'common/api/nodes';
import { getClientProvision } from 'common/api/provisions';
import { EventHandlerNode } from 'utils/types/nodes';

export interface ProvisionWithType extends ProvisionDetailClone {
  type: NodeType;
}

interface NodesState {
  isLoading: boolean;
  activeNode?: Node | null;
  node?: NodeInputProps;
  eventHandler: EventHandlerNode;
  nodeConditions: any;
  showCondition: boolean;
  conditionSaveStatus: boolean;
  emptyString: string;
  activeClauseIndex: string;
  showContext: boolean;
  nodeError: boolean;
  provisionData: ProvisionWithType | null;
}

const initialState: NodesState = {
  isLoading: false,
  activeNode: undefined,
  eventHandler: EventHandlerNode.NONE,
  nodeConditions: [],
  showCondition: false,
  conditionSaveStatus: false,
  emptyString: '',
  activeClauseIndex: '',
  showContext: false,
  nodeError: false,
  provisionData: null,
};

const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    updateHandleEvent: (state, action: PayloadAction<EventHandlerNode>) => {
      state.eventHandler = action.payload;
    },
    updateForm: (state, action) => {
      const { key, value } = action.payload;
      if (state.node) {
        state.node = { ...state.node, [key]: value };
      } else {
        console.warn('### FORM WAS NOT INITIALIZED');
      }
    },
    updateNodeConditions: (state, action) => {
      state.nodeConditions = action.payload;
    },
    resetNodeConditions: state => {
      state.nodeConditions = [];
    },
    // TODO understand what is the function setConditionsInput used for?
    setConditionsInput: state => {
      const newConditions = state.nodeConditions.map((condition: any) => {
        let list = [];

        if (condition.list.length) {
          list = condition.list.map(
            // @ts-ignore
            ({ answer, comparator, paramRef2, paramRef, position, type }) => {
              return {
                type,
                paramRef,
                paramRef2,
                comparator,
                position,
                answer: JSON.stringify(answer),
              };
            },
          );
        }

        return { ...condition, list };
      });
      // @ts-ignore
      state.node.conditions = newConditions;
    },
    resetActiveNode: state => {
      state.activeNode = null;
      state.provisionData = null;
      state.nodeError = false;
    },
    setClauseIndex: (state, action) => {
      state.activeClauseIndex = action.payload;
    },
    setShowCondition: (state, action) => {
      if (typeof action.payload === 'boolean') {
        state.showCondition = action.payload;
      } else {
        state.showCondition = !state.showCondition;
      }
    },
    updateShowContext: (state, action) => {
      state.showContext = action.payload;
    },
  },
  extraReducers: builder => {
    // CREATE NODE
    builder.addCase(createNode.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createNode.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createNode.rejected, (state, action) => {
      state.isLoading = false;
      const nodeType = action.meta.arg.type.toLowerCase();
      toast.error(`Some error occurred when try to create a ${nodeType}`);
    });

    // GET NODE
    builder.addCase(getNode.pending, state => {
      state.activeNode = null;
      state.nodeError = false;
      state.provisionData = null;
      state.isLoading = false;
    });
    builder.addCase(getNode.fulfilled, (state, action) => {
      const { getNode } = action.payload;

      state.activeNode = getNode;
      if (getNode?.id) {
        const node: NodeInputProps = {
          id: getNode.id,
          level: Number(getNode?.level || '1'),
          name: getNode?.name || '',
          type: getNode?.type as NodeType,
          formatterId: getNode.formatter?.id,
          paramRefs: getNode.paramRefs || [],
          provisionId: getNode.provision?.id,
          conditions: getNode.conditions,
        };

        state.showCondition = getNode.conditions.length !== 0;

        state.nodeConditions = getNode.conditions;
        state.node = node;
      } else {
        state.nodeError = true;
      }

      state.isLoading = false;
    });
    builder.addCase(getNode.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
    });

    // DELETE NODE
    builder.addCase(deleteNode.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteNode.fulfilled, state => {
      state.isLoading = false;
      toast.success('The node was deleted successfully');
    });
    builder.addCase(deleteNode.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
    });

    // DELETE UNUSED NODE
    builder.addCase(deleteUnusedNodes.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteUnusedNodes.fulfilled, state => {
      state.isLoading = false;
      toast.success('The nodes were deleted successfully');
    });
    builder.addCase(deleteUnusedNodes.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('No Nodes Found');
    });

    // UPDATE NODE
    builder.addCase(updateNode.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateNode.fulfilled, state => {
      state.isLoading = false;
      state.conditionSaveStatus = false;
      toast.success('The node was updated successfully');
    });
    builder.addCase(updateNode.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
    });

    // CREATE NODE - PARAMETER
    builder.addCase(createParameterNode.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createParameterNode.fulfilled, state => {
      state.isLoading = false;
      toast.success('The parameter node was created successfully');
    });
    builder.addCase(createParameterNode.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
    });

    // CREATE NODE - Text Block
    builder.addCase(createTextBlockNode.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createTextBlockNode.fulfilled, state => {
      state.isLoading = false;
      toast.success('The text block node was created successfully');
    });
    builder.addCase(createTextBlockNode.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
    });

    //getClientProvision
    builder.addCase(getClientProvision.pending, state => {
      state.isLoading = false;
    });
    builder.addCase(getClientProvision.fulfilled, (state, action) => {
      const provision: ProvisionDetailClone = action.payload.data.getProvision;
      const provisionData: ProvisionWithType = {
        ...provision,
        type: NodeType.Provision,
      };
      state.provisionData = provisionData;
      state.activeNode = null;
      state.isLoading = false;
    });
    builder.addCase(getClientProvision.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('getClientProvision API request failed');
    });
  },
});

export const {
  updateHandleEvent,
  updateForm,
  resetNodeConditions,
  setConditionsInput,
  resetActiveNode,
  setClauseIndex,
  setShowCondition,
  updateShowContext,
  updateNodeConditions,
} = nodesSlice.actions;

export default nodesSlice.reducer;
