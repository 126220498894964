import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import {
  updateRentPeriodReview,
  updateRentPeriodReviewFixedType,
  updateRentPeriodReviewIncrementType,
  updateRentPeriodReviewType,
  updateRentReviewDate,
} from 'store/contracts/contractDetailSlice';
import { getIncrementByPercentage } from 'store/contracts/helper';
import {
  rentReviewCapValidations,
  rentReviewCollarValidations,
  reviewStartDateValidations,
} from 'store/contracts/validations';
import RentReviewType from 'common/model/RentReviewType';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { RENT_REVIEW_METHOD_OPTIONS } from 'utils/UI';
import { checkNotEmpty } from 'utils/tsValidator';
import { countDecimals } from 'utils/utils-number';
import { addSpaceOrComma } from 'utils/utils-number';
import './RentReview.scss';

const adjustpositionOfPercentageIncrease = () => {
  const parentElement = document.getElementsByClassName('rent-review');
  const childrenElements = parentElement[0].children;

  for (let i = 2; i < childrenElements.length; i++) {
    const errorElement = childrenElements[i].getElementsByClassName('input-validation-message error');

    const derivedCalculationElement = childrenElements[i].getElementsByClassName('calc-value');

    if (derivedCalculationElement.length > 0) {
      const derivedElement = derivedCalculationElement[0] as HTMLElement;

      if (errorElement.length > 0) {
        const errorHeight = errorElement[0].clientHeight;

        derivedElement.style.marginTop = `${22 + (errorHeight > 17 ? errorHeight - 17 : 0)}px`;
      } else {
        derivedElement.style.marginTop = '0';
      }
    }
  }
};

const RentReviewForm = ({ mode, periodIndex }: { mode: MODE_OPTIONS; periodIndex: number }) => {
  const {
    cap,
    collar,
    date,
    increment,
    rent,
    type,
    endDateOfCurrentPeriod,
    startDateOfPreviousPeriod,
    previousBaseRent,
  } = useAppSelector((state: RootState) => {
    const runContractValidation = state.contractDetail.runContractValidation;
    const periods = state.contractDetail.activeContract.rent.periods;
    return {
      // @ts-ignore
      ...periods[periodIndex].review,
      endDateOfCurrentPeriod: periods[periodIndex].description.endDate,
      startDateOfPreviousPeriod: periods[periodIndex - 1].description.startDate,
      runContractValidation,
      previousBaseRent: periods[periodIndex - 1].description.monthlyBaseRent,
    };
  });

  const dispatch = useAppDispatch();

  const onChangeRentReviewDate = (key: string, value: any) => {
    dispatch(updateRentReviewDate({ key, value: value.toString(), periodIndex }));
  };

  const onChangeRentPeriodReviewType = (key: string, value: any) => {
    dispatch(updateRentPeriodReviewType({ key, value, periodIndex }));
  };

  const onChangeRentPeriodReviewFixedType = (key: string, value: any) => {
    dispatch(updateRentPeriodReviewFixedType({ value, periodIndex }));
  };

  const onChangeIncrement = (key: string, value: any) => {
    const getDots = value.match(/\./g);
    // Prevent extra dot from entering
    // TODO : While migrating to typescript, 'value' will be defined as a Number and not a string.
    //        This problem might be solved at that time, thus we should be able to delete this piece of code
    const checkIfExtraDecimal = value !== null && getDots !== null ? getDots.length > 1 : false;

    if (countDecimals(value) <= 2 && !checkIfExtraDecimal) {
      dispatch(
        updateRentPeriodReviewIncrementType({
          value,
          periodIndex,
        }),
      );
    }
  };

  const onChange = (key: string, value: string) => {
    dispatch(updateRentPeriodReview({ key, value, periodIndex }));
  };

  const percentageChange = (value: string) => {
    return addSpaceOrComma(getIncrementByPercentage(parseInt(previousBaseRent), parseInt(value)));
  };

  useEffect(() => {
    window.addEventListener('resize', adjustpositionOfPercentageIncrease);

    return () => window.removeEventListener('resize', adjustpositionOfPercentageIncrease);
  });

  useEffect(() => {
    setTimeout(() => adjustpositionOfPercentageIncrease(), 600);
  }, [cap, collar]);

  // TODO : While migrating to typescript, maybe add a function in utils-number.ts (using number
  //        as input and returning a number)to define the precision of a number. Hence this
  //        function could be replaced with it.
  const getIncrementVal = () => {
    if (increment === null) {
      return increment;
    }
    if (countDecimals(increment) > 2) {
      return Number(increment).toFixed(2);
    }
    return increment;
  };

  const incrementVal = getIncrementVal();

  return (
    <Grid>
      <Grid.Row className="rent-review">
        <Grid.Column width={3}>
          <DateField
            label="Date"
            dataTest="date-input"
            placeholder="Select date"
            fieldKey="date"
            value={date}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChangeRentReviewDate}
            required={mode === MODE_OPTIONS.EDIT}
            runValidation={!checkNotEmpty(date)}
            // @ts-ignore
            validationFuncs={reviewStartDateValidations(startDateOfPreviousPeriod, endDateOfCurrentPeriod)}
            validationDependant={endDateOfCurrentPeriod}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <SelectField
            label="Review mechanism"
            placeholder="Select review mechanism"
            dataTest="select-review-mechanism"
            fieldKey="type"
            value={type}
            options={RENT_REVIEW_METHOD_OPTIONS}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChangeRentPeriodReviewType}
            required={mode === MODE_OPTIONS.EDIT}
            runValidation={!checkNotEmpty(type)}
          />
        </Grid.Column>
        {type === RentReviewType.OpenMarketUpDown && (
          <>
            <Grid.Column width={3}>
              <InputField
                label="Collar"
                dataTest="collar-input"
                placeholder="Enter collar"
                fieldKey="collar"
                value={collar}
                disabled={mode === MODE_OPTIONS.READ}
                type={InputFieldType.NUMBER}
                unit="(%)"
                onChange={onChange}
                isDecimal={true}
                required={mode === MODE_OPTIONS.EDIT}
                canBeNegative={true}
                delimiter="COMMA"
              />
              {collar && previousBaseRent && (
                <div className="calc-value bg-orange-light-grayish color-orange">{percentageChange(collar)}</div>
              )}
            </Grid.Column>
            <Grid.Column width={3}>
              <InputField
                label="Cap"
                dataTest="cap-input"
                placeholder="Enter cap"
                fieldKey="cap"
                value={cap}
                disabled={mode === MODE_OPTIONS.READ}
                type={InputFieldType.NUMBER}
                unit="(%)"
                onChange={onChange}
                isDecimal={true}
                required={mode === MODE_OPTIONS.EDIT}
                canBeNegative={true}
                delimiter="COMMA"
              />
              {cap && previousBaseRent && (
                <div className="calc-value bg-orange-light-grayish color-orange">{percentageChange(cap)}</div>
              )}
            </Grid.Column>
          </>
        )}
        {type === RentReviewType.Increment && (
          <Grid.Column width={3}>
            <InputField
              label="Increment"
              placeholder="Enter value"
              dataTest="increment-input"
              fieldKey="increment"
              value={incrementVal}
              disabled={mode === MODE_OPTIONS.READ}
              type={InputFieldType.NUMBER}
              unit="(%)"
              onChange={onChangeIncrement}
              isDecimal={true}
              required={mode === MODE_OPTIONS.EDIT}
              canBeNegative={true}
            />
          </Grid.Column>
        )}
        {type === RentReviewType.New && (
          <Grid.Column width={3}>
            <InputField
              label="Fixed rent"
              placeholder="Enter value"
              dataTest="fixed-rent-input"
              value={rent}
              disabled={mode === MODE_OPTIONS.READ}
              fieldKey="fixed"
              type={InputFieldType.NUMBER}
              unit="(HK$)"
              onChange={onChangeRentPeriodReviewFixedType}
              isDecimal={true}
              required={mode === MODE_OPTIONS.EDIT}
              delimiter="COMMA"
            />
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default RentReviewForm;
