import { Form } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import FilesUpload from 'atoms/FormField/FilesUpload';
import { TRANSACTION_TABS_OFFSET } from 'views/transactions/CreatorViewer/Tabs/TransactionsTabs';
import { addPolicyAnswerOfTypeImageFiles, deletePolicyAnswerOfTypeImageFiles } from 'store/policies/policyDetailSlice';
import {
  addTransactionAnswerOfTypeImageFiles,
  deleteTransactionAnswerOfTypeImageFiles,
} from 'store/transactions/transactionDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import { DocumentIntendedToBeUploaded } from 'common/api/miscellaneous';
import { QuestionProps } from 'common/api/parameters';
import { prepPolicyAnswerOfImageTypeForUpload } from 'common/api/policies';
import { Answer, AnswerProps } from 'common/api/policies';
import { prepTransactionAnswerOfImageTypeForUpload } from 'common/api/transactions';
import { checkIfPoliciesPage } from 'utils/tsHelper';
import { getAnswers } from 'utils/utils-answer';

export interface PrepUploadParams {
  answerType: AnswerTypes;
  paramRef: {
    parameterId: string;
    tableId: string | null;
    index: number | null;
  };
  index: number;
  userId: string | null;
  onChange?: () => void;
}

const ImageContent = ({ answerType, parameterId, index, tableId, tabIndex, onChange }: QuestionProps): JSX.Element => {
  const { id } = useAppSelector((state: RootState) => state.auth);
  const { activePolicyAnswers } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransactionAnswers } = useAppSelector((state: RootState) => state.transactionDetail);

  const answers: AnswerProps[] = getAnswers(activePolicyAnswers, activeTransactionAnswers);
  const getDocuments = (): Answer[] => {
    const results: Answer[] = [];

    const callback: (answer: AnswerProps, includeIndexCheck: boolean) => boolean = (
      {
        answerType: answerTypeValue,
        blockRef: answerIndex,
        paramRef: { parameterId: paramId, tableId: paramTableId, index: paramTabIndex },
      },
      includeIndexCheck,
    ) => {
      const changedUndefinedToNull = (value: any): any => (value === undefined ? null : value);

      const indexCheck: boolean = includeIndexCheck ? answerIndex === index : true;

      return (
        answerTypeValue === answerType &&
        indexCheck &&
        changedUndefinedToNull(tableId) === changedUndefinedToNull(paramTableId) &&
        changedUndefinedToNull(tabIndex) === changedUndefinedToNull(paramTabIndex) &&
        parameterId === paramId
      );
    };

    const imageContentIndex: number = answers.findIndex(answer => callback(answer, true));

    let getAnswers: AnswerProps[];

    if (imageContentIndex === -1) {
      getAnswers = answers.filter(answer => callback(answer, false));

      if (getAnswers.length !== 0) {
        const { answer } = getAnswers[getAnswers.length - 1];

        const { title } = answer;

        if (title) {
          results.push(answer);
        }
      }
    } else {
      const answer: Answer = answers[imageContentIndex].answer;

      const { title } = answer;

      if (title) {
        results.push(answer);
      }
    }

    return results;
  };

  const params: PrepUploadParams = {
    answerType,
    paramRef: {
      parameterId,
      tableId,
      index: tabIndex,
    },
    index,
    userId: id,
  };

  return (
    <Form>
      {/* @ts-ignore */}
      <FilesUpload
        disabled={false}
        prepUpload={
          checkIfPoliciesPage() ? prepPolicyAnswerOfImageTypeForUpload : prepTransactionAnswerOfImageTypeForUpload
        }
        prepUploadParams={params}
        documents={getDocuments() as DocumentIntendedToBeUploaded[]}
        onDelete={checkIfPoliciesPage() ? deletePolicyAnswerOfTypeImageFiles : deleteTransactionAnswerOfTypeImageFiles}
        onDeleteFunctionParams={params}
        onAdd={checkIfPoliciesPage() ? addPolicyAnswerOfTypeImageFiles : addTransactionAnswerOfTypeImageFiles}
        onAddFunctionParams={params}
        isMultipleUploads={false}
        onChange={onChange}
      />
    </Form>
  );
};

export default ImageContent;
