import { ParameterGroupClone } from 'common/_classes';
import React from 'react';
import { ButtonGroup, Dropdown, Radio, Button as SemanticButton, Icon as SemanticIcon } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import AddPlus from 'atoms/AddPlus';
import 'components/ConditionsTemplate/ConditionsTemplate.scss';
import { handleMultiLevelSelector } from 'components/ParameterMultiLevelSelect/multiLevelInputHelper';
import ConditionType from 'common/model/ConditionType';
import Conditions from 'common/model/Conditions';
import ConditionsOperator from 'common/model/ConditionsOperator';
import Parameter from 'common/model/Parameter';
import ParameterTable from 'common/model/ParameterTable';
import { deepClone } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import ConditionEquationRow from './ConditionEquationRow';
import { PREFIX_CONDITION } from './ConditionUtils';

const newInnerCondition: any = {
  answer: {
    answer: '',
  },
  comparator: null,
  paramRef: {
    index: null,
    offset: null,
    parameterId: null,
    position: null,
    tableId: null,
  },
  paramRef2: null,
  position: null,
  type: ConditionType.ParamValue,
};

export interface ConditionsTemplateProps {
  disabled?: boolean;
  parameters: Parameter[];
  parameterTables: ParameterTable[];
  parameterGroups: ParameterGroupClone[];
  value: Conditions[];
  onChange: (value: Conditions[]) => void;
}

const ConditionsTemplate = ({
  parameterGroups,
  parameterTables,
  parameters,
  value,
  onChange,
  disabled = false,
}: ConditionsTemplateProps) => {
  const multiLevelOptions = handleMultiLevelSelector(parameterGroups, parameterTables, parameters);

  const updateConditionGroupOperator = (operatorValue: any, groupIndex: number) => {
    const temp = deepClone(value);
    temp[groupIndex].operator = operatorValue;
    onChange(temp);
  };

  const removeConditionGroup = (groupIndex: number) => {
    const temp = deepClone(value);
    temp.splice(groupIndex, 1);
    onChange(temp);
  };

  const addInnerCondition = (groupIndex: number) => {
    const temp = deepClone(value);
    temp[groupIndex].list.push(newInnerCondition);
    onChange(temp);
  };

  const addConditionGroup = () => {
    const temp = deepClone(value);
    temp.push({
      operator: ConditionsOperator.Or,
      list: [newInnerCondition],
    });
    onChange(temp);
  };

  return (
    <div
      className={`conditions-group ${disabled && 'disabled'} p-b-xs`}
      data-test="conditions-group"
    >
      {value.map(({ list, operator }: { list: any; operator: string }, groupIndex: number) => (
        <React.Fragment key={groupIndex}>
          <div className="flex  justify-content-center p-xs">{`OR`} </div>
          <div
            className="condition-show-ground bg-gray-very-light p-xs"
            key={`group-box-${groupIndex}`}
          >
            <div className="flex justify-content-between m-b-xs">
              <Dropdown
                id={`dropdown-operator-${groupIndex}`}
                key={`dropdown-operator-${groupIndex}`}
                className="dropdown-prefix-logic"
                data-test={`dropdown-operator-${groupIndex}`}
                value={operator}
                fluid={true}
                options={PREFIX_CONDITION}
                selection={true}
                onChange={(event, { value }) => updateConditionGroupOperator(value, groupIndex)}
              />
              <ButtonGroup basic>
                <SemanticButton
                  aria-label="Cancel"
                  data-test="condition-group-delete-button"
                  onClick={() => removeConditionGroup(groupIndex)}
                >
                  <SemanticIcon name="trash" />
                </SemanticButton>
                <SemanticButton onClick={() => addInnerCondition(groupIndex)}>
                  <SemanticIcon name="add circle" />
                </SemanticButton>
              </ButtonGroup>
            </div>

            {list.map((innerList: any, index: number) => (
              <div
                // className="condition-inner"
                data-test="condition-inner-rows"
                key={`inner-list-${groupIndex}-${index}`}
              >
                <ConditionEquationRow
                  groupIndex={groupIndex}
                  index={index}
                  innerList={innerList}
                  multiLevelOptions={multiLevelOptions}
                  value={value}
                  onChange={onChange}
                />
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
      <div className="flex m-t-sm">
        <AddPlus
          onClick={() => addConditionGroup()}
          label="Add Condition Group"
          dataTest="condition-add-button"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default ConditionsTemplate;
