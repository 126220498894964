import { Popup } from 'semantic-ui-react';
import './Tooltip.scss';

interface TooltipProps {
  trigger: JSX.Element;
  content: JSX.Element;
  disabled?: boolean;
}

const Tooltip = ({ trigger, content, disabled }: TooltipProps) => (
  <Popup
    content={content}
    position="top center"
    className="timeline-tooltip"
    trigger={trigger}
    hoverable={true}
    basic={true}
    disabled={disabled}
  />
);

export default Tooltip;
