import GraphWithOutLinedContainer, { GraphInputData } from 'atoms/GraphWithOutLinedContainer';
import Event from 'common/model/Event';
import Scale from 'common/model/Scale';
import { BaseEvent, PropertiesEvents, PropertyMonthlyEvents } from 'common/api/dashboard';
import { getContractEventTypeProperty } from 'utils/utils-contract';
import { getDateFormatForGraph } from 'utils/utils-date';
import { toCapitalSnakeCase } from 'utils/utils-string';
import { UpcomingEventsPortfolioTooltip } from './tooltip';
import './UpcomingEventsGraph.scss';

interface UpcomingEventsGraphProps {
  propertiesEvents: PropertiesEvents;
  period: Scale;
  isLoading: boolean;
}

const UpcomingEventsGraph = ({ propertiesEvents, period, isLoading }: UpcomingEventsGraphProps) => {
  const itemData = propertiesEvents.propertiesEvents;

  // get the labels
  const labels = itemData.map((data: PropertyMonthlyEvents) =>
    getDateFormatForGraph(new Date(data.date), period as Scale),
  );

  // get the data
  // -> We have one graph per type of contract event.
  // -> Therefore, we loop on all the contract events.
  const eventKeys = Object.keys(Event).map(event => toCapitalSnakeCase(event));

  const data: GraphInputData[] = eventKeys.map((keyType, index) => {
    const bar = itemData.map((data: PropertyMonthlyEvents) => {
      const event = data.events.filter((event: BaseEvent) => event.event === (keyType as Event))[0];

      return event?.area;
    });

    let result: GraphInputData = {
      key: index + 1,
      label: getContractEventTypeProperty(keyType as Event, 'text'),
      color: getContractEventTypeProperty(keyType as Event, 'color'),
      data: bar,
    };
    return result;
  });

  return (
    <GraphWithOutLinedContainer
      className="upcoming-events"
      title="Upcoming Events"
      loading={isLoading}
      labels={labels}
      data={data}
      tooltipHTML={UpcomingEventsPortfolioTooltip(propertiesEvents, period)}
      yAxisTitle="Area (sqft)"
    />
  );
};

export default UpcomingEventsGraph;
