import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_CONTRACT } from 'common/api/contracts';
import { getClientType } from 'utils/utils-authentication';

export const getFutureContract = createAsyncThunk(
  'stackingPlan/getFutureContract',
  async ({ contractId }: { contractId: string }) => {
    const response = await getClientType().query(GET_CONTRACT(contractId));
    return response.data;
  },
);
