import Event from 'common/model/Event';
import Scale from 'common/model/Scale';

export const WholePortolioText = 'Whole Portfolio';

export const DashboardPageType = 'dashboard';

export interface PerformanceRevenueAnalysis {
  contractualRevenue: string;
  forecastRevenue: string;
  perfomance: string;
  variance: string;
}

export interface UnitAreaStats {
  area: string;
  contractualArea: string;
  forecastArea: string;
  performance: string;
  variance: string;
}

export interface StatsRevenue {
  contractualRevenue: string;
  effectiveRent: string;
  forecastRevenue: string;
  headlineRent: string;
  revenueOverPerformance: PerformanceRevenueAnalysis;
  revenueUnderPerformance: PerformanceRevenueAnalysis;
}

export type PerformanceStatsAnalysis = StatsRevenue;

export interface PerformanceAreaAnalysis {
  contractualArea: string;
  forecastArea: string;
  performance: string;
  variance: string;
}

export interface PropertyAreaStats {
  area: string;
  contractualArea: string;
  contractualOccupancyRate: string;
  forecastArea: string;
  forecastOccupancyRate: string;
  occupancyOverPerformance: PerformanceAreaAnalysis;
  occupancyUnderPerformance: PerformanceAreaAnalysis;
}

export interface PropertyConnectionStats {
  contractIds: string[];
  tenantIds: string[];
}

export type UnitConnectionStats = PropertyConnectionStats;

export interface UnitAnalysisStats {
  contractualRevenue: string;
  effectiveRent: string;
  forecastRevenue: string;
  headlineRent: string;
  performance: string;
  variance: string;
}

export interface ContextAnalysisData {
  month: string;
  statsRevenue: StatsRevenue;
  statsArea: PropertyAreaStats;
  statsConnection: PropertyConnectionStats;
}

export interface UnitAnalysisData {
  floorId: string;
  floorIndex: number;
  floorName: string;
  month: string;
  statsRevenue: UnitAnalysisStats;
  statsArea: UnitAreaStats;
  statsConnection: UnitConnectionStats;
  unitId: string;
  unitName: string;
}

export interface PropertyAnalysisData {
  month: string;
  propertyId: string;
  propertyName: string;
  statsRevenue: StatsRevenue;
  statsArea: PropertyAreaStats;
  statsConnection: PropertyConnectionStats;
}

export interface PropertyRevenueAnalysis {
  endDate: string;
  period: Scale;
  propertyId: string;
  propertyData: ContextAnalysisData[];
  propertyIdentifier: string;
  propertyName: string;
  startDate: string;
  unitsData: UnitAnalysisData[];
}

export interface PortfolioRevenueAnalysis {
  endDate: string;
  period: Scale;
  portfolioData: ContextAnalysisData[];
  portfolioId?: string;
  portfolioName?: string;
  propertiesData: PropertyAnalysisData[];
  startDate: string;
  unitsData: UnitAnalysisData[];
}

export interface ContractEventFloors {
  floorId: string;
  floorIndex: number;
  floorName: string;
}

export interface ContractEventUnits {
  floorId: string;
  floorIndex: number;
  floorName: string;
  unitId: string;
  unitName: string;
}

export interface ContractEvents {
  area: string;
  contractId: string;
  contractIdentifier: string;
  date: string;
  description?: string;
  event: Event;
  floors: ContractEventFloors[];
  tenantId: string;
  tenantName: string;
  units: ContractEventUnits[];
}

export interface BaseEvent {
  area: string;
  event: Event;
}

export interface PropertyMonthlyEvents {
  date: string;
  events: BaseEvent[];
}

export interface PropertiesEvents {
  contractEvents: ContractEvents[];
  endDate: string;
  propertiesEvents: PropertyMonthlyEvents[];
  propertyId?: string;
  startDate: string;
}

export interface RiskAnalysisData {
  accumulativeRevenue: string;
  revenue: string;
  accumulativeContribution: string;
  contribution: string;
  contractNb: number;
  reference: string;
  referenceId: string;
}

export interface RevenueAnalysisStats {
  contractualRevenue: string;
  headlineRent: string;
  forecastRevenue: string;
}

export interface RevenueAnalysisData {
  month: string;
  statsRevenue: PerformanceStatsAnalysis;
}

export enum DASHBOARD_PAGE_SCOPE {
  ALL = 'All',
  PROPERTY = 'Property',
  PORTFOLIO = 'Portfolio',
}

export enum DASHBOARD_TABS_OFFSET {
  DASHBOARD = 0,
  STACKING_PLAN = 1,
  FORECASTING = 2,
}
