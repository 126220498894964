import { sortBy } from 'lodash';
import AnswerTypes from 'common/model/AnswerTypes';
import Parameter from 'common/model/Parameter';
import { AnswerJsonDuration, AnswerJsonTime, PeriodJsonContent } from 'common/api/formatters';
import BooleanContent from '../Questions/BooleanContent';
import DateContent from '../Questions/DateContent';
import DurationContent from '../Questions/DurationContent';
import ImageContent from '../Questions/ImageContent';
import MultiChoice from '../Questions/MultiChoice';
import NumberContent from '../Questions/NumberContent';
import PeriodContent from '../Questions/PeriodContent';
import SingleChoice from '../Questions/SingleChoice';
import TextContent from '../Questions/TextContent';
import TimeContent from '../Questions/TimeContent';
import './ParameterAnswerSwitch.scss';

const ParameterAnswerSwitch = (
  value: any,
  parameter: Parameter,
  index: number,
  tableId: string | null,
  tabIndex: number | null,
  onChange: (value: any) => void,
  onImageChange: () => void,
): JSX.Element => {
  switch (parameter.answerType) {
    case AnswerTypes.SingleChoice:
      return (
        <SingleChoice
          value={value}
          choices={sortBy(parameter.choices, 'index')}
          onChange={(value: string | object) => onChange(value)}
        />
      );
    case AnswerTypes.MultiChoice:
      return (
        <MultiChoice
          value={value}
          choices={sortBy(parameter.choices, 'index')}
          mandatory={parameter.mandatoryChoice}
          onChange={(value: string | object) => onChange(value)}
        />
      );
    case AnswerTypes.Text:
      return (
        <TextContent
          value={value}
          onChange={(value: string | object) => onChange(value)}
        />
      );
    case AnswerTypes.Date:
      return (
        <DateContent
          value={value}
          onChange={(value: string) => onChange(value)}
        />
      );
    case AnswerTypes.Duration:
      return (
        <DurationContent
          value={value}
          onChange={(value: AnswerJsonDuration) => onChange(value)}
        />
      );
    case AnswerTypes.Period:
      return (
        <PeriodContent
          value={value}
          onChange={(value: PeriodJsonContent) => onChange(value)}
        />
      );
    case AnswerTypes.Number:
      return (
        <NumberContent
          value={value}
          answerType={AnswerTypes.Number}
          onChange={(value: string) => onChange(value)}
        />
      );
    case AnswerTypes.NumberPercent:
      return (
        <NumberContent
          value={value}
          answerType={AnswerTypes.NumberPercent}
          unit="%"
          onChange={(value: string) => onChange(value)}
        />
      );
    case AnswerTypes.NumberUnit:
      return (
        <NumberContent
          value={value}
          answerType={AnswerTypes.NumberUnit}
          unit={parameter.unit}
          onChange={(value: string) => onChange(value)}
        />
      );
    case AnswerTypes.Boolean:
      return (
        <BooleanContent
          value={value}
          onChange={(value: boolean | null) => onChange(value)}
        />
      );
    case AnswerTypes.Image:
      return (
        <ImageContent
          answerType={AnswerTypes.Image}
          parameterId={parameter.id}
          index={index}
          tableId={tableId}
          tabIndex={tabIndex}
          onChange={onImageChange}
        />
      );
    case AnswerTypes.Time:
      return (
        <TimeContent
          value={value}
          onChange={(value: AnswerJsonTime) => onChange(value)}
        />
      );
    default:
      return <></>;
  }
};

export default ParameterAnswerSwitch;
