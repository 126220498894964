import { createSlice, current } from '@reduxjs/toolkit';
import {
  ParameterChoiceBase,
  ParameterDetailClone,
  ParameterGroupClone,
  ParameterTableDetailClone,
  ParameterTableDetailColumnClone,
} from 'common/_classes';
import { toast } from 'react-toastify';
import { PARAMETER_TABS_OFFSET } from 'views/parameters/ParameterCreatorViewer/ParametersTabs';
import AnswerTypes from 'common/model/AnswerTypes';
import {
  createParameter,
  createParameterTable,
  getParameter,
  getParameterGroupHtml,
  getParameterGroupPdf,
  getParameterTable,
  updateParameter,
  updateParameterTable,
} from 'common/api/parameters';
import { RowNumberType } from 'common/api/parameters';
import { downloadPdf } from 'utils/utils-download';

interface ParameterDetailState {
  activeParameter: ParameterDetailClone;
  activeTableParameter: ParameterTableDetailClone;
  paramViewSelectedGroup: ParameterGroupClone | null;
  isLoading: boolean;
  activeTabIndex: PARAMETER_TABS_OFFSET;
  showCondition: { [key: string]: boolean };
}

const initialState: ParameterDetailState = {
  activeParameter: ParameterDetailClone.init(),
  activeTableParameter: ParameterTableDetailClone.init(),
  paramViewSelectedGroup: null,
  isLoading: false,
  activeTabIndex: PARAMETER_TABS_OFFSET.GENERAL,
  showCondition: {},
};

// Set group param in URL
const setGroupInURL = (groupId: string): void => {
  const url = new URL(window.location.href);
  url.searchParams.set('group', groupId);
  window.history.pushState({}, '', url);
};

const parameterDetailSlice = createSlice({
  name: 'parameterDetail',
  initialState,
  reducers: {
    updateParamViewSelectedGroup: (state, action) => {
      state.paramViewSelectedGroup = action.payload?.group || null;
    },
    updateParameterViewDetailsTab: (state, action) => {
      state.activeTabIndex = action.payload.tab;
    },
    updateActiveParameterGuideLine: (state, action) => {
      const { type, value } = action.payload;
      // @ts-ignore
      state.activeParameter.guideline[type] = value;
    },
    createNewParameter: state => {
      // If group is selected
      if (state.paramViewSelectedGroup !== null) {
        const parameterClone: ParameterDetailClone = ParameterDetailClone.init();
        parameterClone.parameterGroup = state.paramViewSelectedGroup;
        state.activeParameter = parameterClone;
        setGroupInURL(parameterClone.parameterGroup!.id);
      } else {
        state.activeParameter = ParameterDetailClone.init();
      }
    },
    createNewTableParameter: state => {
      // If group is selected
      if (state.paramViewSelectedGroup !== null) {
        const groupId: string = state.paramViewSelectedGroup.id;
        const parameterTableClone: ParameterTableDetailClone = ParameterTableDetailClone.init();
        //parameterTableClone.parameterGroupId = groupId as any;
        state.activeTableParameter = parameterTableClone;
        setGroupInURL(groupId);
      } else {
        state.activeTableParameter = ParameterTableDetailClone.init();
      }
    },
    updateActiveTableParameter: (state, action) => {
      //TODO delocalize the logic directly in the RowConstrain component (componentize it at the same time)
      const { key, value, type } = action.payload;
      if (key === 'rowNumber') {
        let rowNumber;
        if (type === RowNumberType.PARAMETER) {
          rowNumber = {
            parameter: { id: value },
            table: null,
          };
        } else {
          rowNumber = {
            parameter: null,
            table: { id: value },
          };
        }
        // @ts-ignore
        state.activeTableParameter[key] = rowNumber;
      } else if (key === 'parameterGroup') {
        // proxy name added to overcome type issue
        state.activeTableParameter.parameterGroup = { id: value, name: '' };
      } else {
        // @ts-ignore
        state.activeTableParameter[key] = value;
      }
    },
    updateActiveParameter: (state, action) => {
      const { key, value } = action.payload;
      // @ts-ignore
      state.activeParameter[key] = value;
      if (key === 'hasInventory' && !value) {
        state.activeParameter.inventoryFunction = null;
      }
      if (key === 'answerType') {
        if (value === AnswerTypes.SingleChoice || value === AnswerTypes.MultiChoice) {
          state.activeParameter.choices = [ParameterChoiceBase.init(0)];
        } else {
          state.activeParameter.choices = [];
        }

        if (value !== AnswerTypes.NumberUnit) {
          state.activeParameter.unit = null;
        }
      }
      if (key === 'parameterGroup') {
        // proxy name added to overcome type issue
        state.activeParameter.parameterGroup = { id: value, name: '' };
      }
    },
    updateActiveParameterChoice: (state, action) => {
      const { key, value, id } = action.payload;
      const choices: ParameterChoiceBase[] = state.activeParameter.choices!;
      const choiceIndex: number = choices.findIndex((choice: any) => choice.id === id);

      if (choices && choiceIndex !== -1) {
        const choice = { ...choices[choiceIndex], [key]: value };
        choices.splice(choiceIndex, 1, choice);
        state.activeParameter.choices = choices;
      }
    },
    // we are moving an element from sourceIndex to destinationIndex.
    // Once the list is rearranged, we are updating the status with same
    updateActiveParameterChoiceIndex: (state, action) => {
      const { sourceIndex, destinationIndex } = action.payload;
      const choices: ParameterChoiceBase[] = state.activeParameter.choices!;
      const [removed] = choices.splice(sourceIndex, 1);
      choices.splice(destinationIndex, 0, removed);
      const reorderedChoiceIndexes = choices.map((choice: ParameterChoiceBase, index: number) => {
        return { ...choice, index: index };
      });
      state.activeParameter.choices = reorderedChoiceIndexes;
    },
    addChoice: state => {
      let choices = state.activeParameter.choices;
      // @ts-ignore
      choices = [...choices, ParameterChoiceBase.init(choices.length)];
      state.activeParameter.choices = choices;
    },
    deleteChoice: (state, action) => {
      const index = action.payload;
      if (state.activeParameter.choices?.length !== 1) {
        state.activeParameter.choices?.splice(index, 1);
      }
    },
    toggleChoiceArchiveStatus: (state, action) => {
      const id = action.payload;
      const choice = state.activeParameter.choices?.find((choice: any) => choice.id === id);

      if (choice) {
        // @ts-ignore
        choice.archive = !choice.archive;
      }
    },
    updateActiveTableParameterColumn: (state, action) => {
      const { key, value, index } = action.payload;
      if (key == 'parameterId') {
        state.activeTableParameter.columns[index].parameter = { id: value };
      } else {
        // @ts-ignore
        state.activeTableParameter.columns[index][key] = value;
      }
    },
    addColumn: state => {
      let columns: ParameterTableDetailColumnClone[] = state.activeTableParameter.columns;
      const tableId: string = state.activeTableParameter?.id as string;
      const index = columns.length + 1;
      columns = [...columns, ParameterTableDetailColumnClone.init(index, tableId)];
      state.activeTableParameter.columns = columns;
    },
    deleteColumn: (state, action) => {
      const { index } = action.payload;
      state.activeTableParameter.columns.splice(index, 1);
    },
    updateColumnPosition: (state, action) => {
      const columns = state.activeTableParameter.columns;
      const { currentIndex, nextIndex } = action.payload;
      let tempIndex = columns[currentIndex]['index'];
      columns[currentIndex]['index'] = columns[nextIndex]['index'];
      columns[nextIndex]['index'] = tempIndex;
      let temp = columns[currentIndex];
      columns[currentIndex] = columns[nextIndex];
      columns[nextIndex] = temp;
      state.activeTableParameter.columns = columns;
    },
    resetRowNumber: state => {
      const rowNumber = state.activeTableParameter.rowNumber;
      if (rowNumber !== null) {
        state.activeTableParameter.rowNumber = null;
      } else {
        state.activeTableParameter.rowNumber = {
          parameter: null,
          table: null,
        };
      }
    },
    initRowNumber: state => {
      state.activeTableParameter.rowNumber = {
        parameter: null,
        table: null,
      };
    },
    updateColumnConditions: (state, action) => {
      const columnId = action.payload.id;
      const columnIndex = state.activeTableParameter.columns.findIndex(parameter => parameter.id == columnId);
      if (columnIndex > -1) {
        state.activeTableParameter.columns[columnIndex].conditions = action.payload.conditions;
      }
    },
    resetColumnConditions: (state, action) => {
      const columnId = action.payload.id;
      const columnIndex = state.activeTableParameter.columns.findIndex(parameter => parameter.id == columnId);
      if (columnIndex > -1) {
        state.activeTableParameter.columns[columnIndex].conditions = [];
      }
    },
    setShowCondition: (state, action) => {
      if (typeof action.payload.checked === 'boolean') {
        state.showCondition[action.payload.key] = action.payload.checked;
      } else {
        state.showCondition[action.payload.key] = !!!state.showCondition[action.payload.key];
      }
    },
    updateActiveTableColumns: (state, action) => {
      state.activeTableParameter.columns = action.payload;
    },
  },
  extraReducers: builder => {
    //createParameter
    builder.addCase(createParameter.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createParameter.fulfilled, (state, action) => {
      const { createParameter } = action.payload.data;
      state.activeParameter = createParameter;
      state.isLoading = false;
      toast.success('Parameter Created Successfully');
    });
    builder.addCase(createParameter.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createParameter API call rejected');
    });

    //updateParameter
    builder.addCase(updateParameter.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateParameter.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeParameter = data.updateParameter;
      state.isLoading = false;
      toast.success(`Parameter Updated Successfully`);
    });
    builder.addCase(updateParameter.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateParameter API call rejected');
    });

    //getParameter
    builder.addCase(getParameter.pending, (state, action) => {
      // @ts-ignore
      const { loading } = action.meta.arg;
      state.isLoading = loading !== undefined ? false : true;
    });
    builder.addCase(getParameter.fulfilled, (state, action) => {
      const data = action.payload.data.getParameter;
      state.activeParameter = data;
      state.isLoading = false;
    });
    builder.addCase(getParameter.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getParameter API call rejected');
    });

    //getParameterTable
    builder.addCase(getParameterTable.pending, (state, action) => {
      // @ts-ignore
      const { loading } = action.meta.arg;
      state.isLoading = loading !== undefined ? false : true;
    });
    builder.addCase(getParameterTable.fulfilled, (state, action) => {
      const data = action.payload.data.getParameterTable;
      state.activeTableParameter = data;
      state.activeTableParameter.columns = data.columns
        .sort((a: any, b: any) => a.index - b.index)
        .map((data: any, index: number) => {
          if (data && data.conditions && data.conditions.length) {
            state.showCondition[data.id] = true;
          }
          return { ...data, index };
        });
      state.isLoading = false;
    });
    builder.addCase(getParameterTable.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
      toast.error('getParameterTable API call rejected');
    });

    //createParameterTable
    builder.addCase(createParameterTable.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createParameterTable.fulfilled, (state, action) => {
      const { createParameterTable } = action.payload.data;
      state.activeTableParameter = createParameterTable;
      state.activeTableParameter.columns = createParameterTable.columns.sort((a: any, b: any) => a.index - b.index);
      state.isLoading = false;
      toast.success(`Parameter table created Successfully`);
    });
    builder.addCase(createParameterTable.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createParameterTable API call rejected');
    });

    //updateParameterTable
    builder.addCase(updateParameterTable.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateParameterTable.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeTableParameter = data.updateParameterTable;
      state.activeTableParameter.columns = data.updateParameterTable.columns.sort(
        (a: any, b: any) => a.index - b.index,
      );
      state.isLoading = false;
      toast.success(`Parameter table updated Successfully`);
    });
    builder.addCase(updateParameterTable.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateParameterTable API call rejected');
    });

    // getParameterGroupHtml
    builder.addCase(getParameterGroupHtml.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getParameterGroupHtml.fulfilled, (state, action) => {
      const html = action.payload.data.getParameterGroupPdfHtml;
      const tab = window.open();
      tab?.document.write(html);
      tab?.document.close();
      state.isLoading = false;
    });
    builder.addCase(getParameterGroupHtml.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getParameterGroupHtml API call rejected');
    });

    //getParameterGroupPdf
    builder.addCase(getParameterGroupPdf.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getParameterGroupPdf.fulfilled, (state, action) => {
      downloadPdf(action.payload.data.getParameterGroupPdfDocument, 'Parameter Group');
      state.isLoading = false;
    });
    builder.addCase(getParameterGroupPdf.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getParameterGroupPdf API call rejected');
    });
  },
});

export const {
  addColumn,
  addChoice,
  createNewParameter,
  createNewTableParameter,
  deleteChoice,
  deleteColumn,
  initRowNumber,
  resetRowNumber,
  toggleChoiceArchiveStatus,
  updateActiveParameter,
  updateActiveParameterChoice,
  updateActiveParameterChoiceIndex,
  updateActiveParameterGuideLine,
  updateActiveTableParameter,
  updateActiveTableParameterColumn,
  updateColumnPosition,
  updateParameterViewDetailsTab,
  updateParamViewSelectedGroup,
  updateColumnConditions,
  resetColumnConditions,
  setShowCondition,
  updateActiveTableColumns,
} = parameterDetailSlice.actions;

export default parameterDetailSlice.reducer;
