enum ConditionComparator {
  All = 'ALL',
  Any = 'ANY',
  Equal = 'EQUAL',
  NotAll = 'NOT_ALL',
  NotEqual = 'NOT_EQUAL',
  None = 'NONE',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL'
}
export default ConditionComparator;
