import { AnyAction, Dispatch, PayloadActionCreator } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';

interface MoveTo {
  moveToEvent: Promise<any>;
  navigate: NavigateFunction;
  url: string;
}

export const moveTo = ({ moveToEvent, navigate, url }: MoveTo): void => {
  moveToEvent.then(response => {
    if (response.meta.requestStatus === 'fulfilled') {
      navigate(url);
    }
  });
};

interface MoveToId {
  moveToEvent: Promise<any>;
  navigate: NavigateFunction;
  entityType: string;
}

export const moveToId = ({ moveToEvent, entityType, navigate }: MoveToId): void => {
  moveToEvent.then(response => {
    if (response?.meta?.requestStatus === 'fulfilled') {
      const data = response.payload.data;
      const search = window.location.search;
      let url = null;
      if (search.includes('from=transactions')) {
        url = '/transactions/create';
      } else {
        const newEntity = data[`create${entityType}Entity`];
        url = `/${entityType.toLowerCase()}-entities/${newEntity.id}/details`;
      }
      navigate(url);
    }
  });
};

interface MoveToNextStep {
  current: number;
  next: number;
  updateActiveStep: PayloadActionCreator<any>;
  stepChecked: (step: number) => boolean;
  dispatch: Dispatch<AnyAction>;
}

export const moveToNextStep = ({ current, next, updateActiveStep, stepChecked, dispatch }: MoveToNextStep): void => {
  let checkDisabled: HTMLButtonElement = document.getElementsByClassName('steps-next')[0] as HTMLButtonElement;
  let disabled = checkDisabled && checkDisabled.disabled;
  if (disabled) {
    return;
  }
  if ((stepChecked(current) && next === current + 1) || stepChecked(next)) {
    dispatch(updateActiveStep({ activeStep: next }));
  } else if (next === 3 && stepChecked(1) && stepChecked(2)) {
    dispatch(updateActiveStep({ activeStep: next }));
  } else {
    if (next === current + 1) {
      if (!disabled) {
        checkDisabled.click();
      }
    }
  }
};

export const updateUrlParams = (step: string, id: string | null, completed: number): void => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('step') === null) {
    return;
  }
  url.searchParams.set('step', step);
  if (id !== null) {
    url.searchParams.set('id', id);
  }
  if (completed !== null) {
    url.searchParams.set('completed', completed.toString().split(',').join('-'));
  }
  window.history.pushState({ path: url.href }, '', url.href);
};

export const resetUrlParams = (): void => {
  const url = new URL(window.location.href);
  let params = url.searchParams;
  if (params.get('step')) {
    url.searchParams.set('step', '1');
    url.searchParams.delete('completed');
  }
  window.history.pushState({ path: url.href }, '', url.href);
};

export const getStep = (): number => {
  let params = new URL(document.location.href).searchParams;
  const id = params.get('id');
  if (id) {
    resetUrlParams();
    return 1;
  }
  let step = params.get('step');
  return step ? parseInt(step) : 1;
};

export const getCompleted = (): number[] => {
  let params = new URL(document.location.href).searchParams;
  let id = params.get('id');
  if (id) {
    return [];
  }
  let completed = params.get('completed');
  return completed ? completed.split('-').map(Number) : [];
};
