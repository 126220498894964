import Decimal from 'decimal.js';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { CONTRACT_SECURITY_OPTIONS } from 'utils/UI';

const SecurityForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const securityAmount = activeContractEvent?.securityAmount || null;

  const onChange = (key: string, value: string) => {
    dispatch(updateActiveContractEventInput({ key: ['securityAmount', key], value: value || null }));
  };

  const onChangeDecimal = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({ key: ['securityAmount', key], value: value ? new Decimal(value) : null }),
    );
  };

  const disabled = mode === MODE_OPTIONS.READ;

  return (
    <div className="grid">
      <div className="col-2">
        <InputField
          label="Security months"
          dataTest=""
          type={InputFieldType.NUMBER}
          fieldKey="months"
          value={securityAmount?.months?.toString() || null}
          disabled={disabled}
          onChange={onChange}
          delimiter="COMMA"
        />
      </div>

      <div className="col-2">
        <SelectField
          label="Security components"
          fieldKey="components"
          dataTest=""
          value={securityAmount?.components}
          disabled={disabled}
          multiple={true}
          options={CONTRACT_SECURITY_OPTIONS}
          onChange={onChange}
        />
      </div>

      <div className="col-2">
        <InputField
          label="Secured amount"
          dataTest="cash-deposit-amount-input-field"
          type={InputFieldType.NUMBER}
          unit="(HK$)"
          fieldKey="amount"
          value={securityAmount?.amount?.toString() || null}
          disabled={disabled}
          onChange={onChangeDecimal}
          isDecimal={true}
          delimiter="COMMA"
        />
      </div>

      <div className="col-2">
        <InputField
          label="Max Bank guarantee"
          placeholder="Type here"
          dataTest="bank-guarantee-input-field"
          unit="(%)"
          value={securityAmount?.maxBankGuaranteeAmount?.toString() || null}
          fieldKey="maxBankGuaranteeAmount"
          type={InputFieldType.NUMBER}
          disabled={disabled}
          onChange={onChangeDecimal}
          isDecimal={true}
        />
      </div>
    </div>
  );
};

export default SecurityForm;
