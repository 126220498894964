import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { updateContractRentPeriodAdditionalCharges } from 'store/contracts/contractDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';

interface AdditionalFeesAndChargesProps {
  mode: MODE_OPTIONS;
  periodIndex: number;
}

const AdditionalFeesAndCharges = ({ mode, periodIndex }: AdditionalFeesAndChargesProps) => {
  const { managementFees, governementRates, governementRent, airConCharges } = useAppSelector(
    (state: RootState) => state.contractDetail.activeContract.rent.periods[periodIndex].additionalFeesAndCharges,
  );

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: string) =>
    dispatch(updateContractRentPeriodAdditionalCharges({ key, value, periodIndex }));

  const unit = '(HK$ per month)';

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <InputField
            label="Management fees"
            type={InputFieldType.NUMBER}
            dataTest="management-fees-input-field"
            unit={unit}
            fieldKey="managementFees"
            value={managementFees}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <InputField
            label="AC charges"
            type={InputFieldType.NUMBER}
            dataTest="ac-charges-input-field"
            unit={unit}
            fieldKey="airConCharges"
            value={airConCharges}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <InputField
            label="Government rent"
            type={InputFieldType.NUMBER}
            dataTest="government-rent-input-field"
            unit={unit}
            fieldKey="governementRates"
            value={governementRates}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <InputField
            label="Government rates"
            type={InputFieldType.NUMBER}
            dataTest="government-rates-input-field"
            unit={unit}
            fieldKey="governementRent"
            value={governementRent}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            isDecimal={true}
            info={true}
            delimiter="COMMA"
            popUp={true}
            infoText="Government rates refer to a type of property tax levied by the Hong Kong government on property owners. It is calculated based on the annual rental value (ARV) of a property, which is determined by the government's Rating and Valuation Department. The ARV is based on the market rental value of the property as estimated by the government, and is adjusted periodically to reflect changes in the property market. 
            The government rate is currently set at a rate of 5% ARV for for commercial and industrial properties and 3% ARV for residential properties. Individual property owners may be eligible for for certain exemptions or discounts. It is typically paid by the property owner, but may be passed on to tenants through the lease. The government rate is separate from other types of property taxes , such as the stamp duty and property tax on rental income."
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AdditionalFeesAndCharges;
