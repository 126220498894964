import { Dropdown } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import ConditionType from 'common/model/ConditionType';
import { Icons } from 'utils/utils-icons';
import './ComparisonTypeSelector.scss';

interface ComparisonTypeSelectorProps {
  selectedValue: string;
  onChange: (operator: ConditionType) => void;
}

/**
 * This component is responsible to determine the Parameter type comparison
 * Options are :
 *  - compare paramRef against specific value
 *  - compare paramRef against another paramRef
 *  - compare table information (index, offset...)
 *
 * @param selectedValue string | value selected
 * @param onChange Function | callback to determine a new selection
 */
const ComparisonTypeSelector = ({ selectedValue, onChange }: ComparisonTypeSelectorProps) => {
  const acronymSelectedValue = (textValue: string) => {
    if (ConditionType.ParamValue === textValue) return 'p/v';
    if (ConditionType.ParamParam === textValue) return 'p/p';
    if (ConditionType.TableIndex === textValue) return 't.i.';
    if (ConditionType.TablePosition === textValue) return 't.p.';
    if (ConditionType.TableSize === textValue) return 't.s.';
    return '';
  };

  const comparisonType = acronymSelectedValue(selectedValue);

  const parametersCircle = [
    {
      prefixName: ConditionType.ParamValue,
      name: 'Parameter/value comparison',
    },
    {
      prefixName: ConditionType.ParamParam,
      name: 'Parameter/parameter comparison',
    },
    {
      prefixName: ConditionType.TableIndex,
      name: 'Table information comparison',
    },
  ];

  return (
    <Dropdown
      icon={
        <div className="circle-l comparison-type-select-ball border-xl-orange t-xs m-r-xs bg-white">
          {comparisonType}
        </div>
      }
      value={selectedValue}
    >
      <Dropdown.Menu>
        {parametersCircle.map(option => (
          <Dropdown.Item
            key={option.prefixName}
            onClick={() => onChange(option.prefixName)}
          >
            <div className="parameter-row">
              <div className="circle-l comparison-type-select-ball border-xl-orange inner-icon m-r-xs bg-white">
                {acronymSelectedValue(option.prefixName)}
              </div>
              <span>{option.name}</span>
              <span className="comparison-type-check-icon">
                {option.prefixName === selectedValue && (
                  <Icon
                    className="color-orange"
                    icon={Icons.Check}
                  />
                )}
              </span>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default ComparisonTypeSelector;
