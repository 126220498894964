import { Button, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { addRentFreePeriod } from 'store/contracts/contractDetailSlice';
import { LocalRentFreePeriod } from 'common/api/contracts';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { getDifferenceInDates, getNumberOfDays } from 'utils/utils-date';
import { Icons } from 'utils/utils-icons';
import { calculatePercent } from 'utils/utils-number';
import PeriodRow from './PeriodRow';

interface PeriodDuration {
  startDate: Date;
  endDate: Date;
}
const totalNumberOfDays = (periods: PeriodDuration[]): number => {
  return periods.reduce((sum, period) => {
    return sum + getNumberOfDays(period.startDate, period.endDate);
  }, 0);
};

const RentFreePeriodForm = ({ mode, periodIndex }: { mode: MODE_OPTIONS; periodIndex: number }) => {
  const {
    rent: { periods },
    startDate,
    endDate,
  } = useAppSelector((state: RootState) => state.contractDetail.activeContract);
  const { freePeriods } = periods[periodIndex].freePeriod;

  const dispatch = useAppDispatch();
  let totalRentFreeDays = totalNumberOfDays(freePeriods);
  const totalContractDays = getNumberOfDays(startDate ? new Date(startDate) : null, endDate ? new Date(endDate) : null);

  return (
    <div className="premises-sub-section no-bg-padding">
      <Grid className="sub-section">
        {freePeriods.map(({ startDate, endDate, additionalFreeItems }: LocalRentFreePeriod, index: number) => (
          <PeriodRow
            key={index}
            periodIndex={periodIndex}
            rentFreePeriodIndex={index}
            startDate={startDate || ''}
            endDate={endDate || ''}
            additionalFreeItems={additionalFreeItems}
            mode={mode}
            isOnlyOne={freePeriods.length === 1}
          />
        ))}
        <Grid.Row className="m-b-sm">
          <Grid.Column
            className="btn-container"
            width={8}
          >
            {mode === MODE_OPTIONS.EDIT && (
              <Button
                className="add-btn border-sm-blue-very-dark bg-blue-very-dark-grayish"
                data-test="add-rent-free-period"
                onClick={() => dispatch(addRentFreePeriod({ periodIndex }))}
              >
                <Icon icon={Icons.Add} />
                <span>ADD RENT FREE PERIOD</span>
              </Button>
            )}
          </Grid.Column>

          <Grid.Column
            className="area"
            width={8}
          >
            <span className="bold min-width">{totalRentFreeDays} days : </span>
            <span>
              {getDifferenceInDates(startDate ? new Date(startDate) : null, endDate ? new Date(endDate) : null)}( ~{' '}
              {calculatePercent(totalRentFreeDays, totalContractDays)}% of the whole lease period)
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default RentFreePeriodForm;
