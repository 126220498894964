import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import DateField from 'atoms/FormField/DateInput';
import SelectField from 'atoms/FormField/Select';
import {
  deleteRentFreePeriod,
  updateRentFreePeriodAdditionalFees,
  updateRentFreePeriodDate,
} from 'store/contracts/contractDetailSlice';
import { rentFreePeriodEndDateValidations, rentFreePeriodStartDateValidations } from 'store/contracts/validations';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { ADDITIONAL_FEES_OPTIONS } from 'utils/UI';
import { checkNotEmpty } from 'utils/tsValidator';
import { getDifferenceInDates, getNumberOfDays } from 'utils/utils-date';
import { Icons } from 'utils/utils-icons';

interface PeriodRowProps {
  mode: MODE_OPTIONS;
  startDate: string;
  endDate: string;
  additionalFreeItems: string[];
  isOnlyOne: boolean;
  periodIndex: number;
  rentFreePeriodIndex: number;
}

const PeriodRow = ({
  mode,
  startDate,
  endDate,
  additionalFreeItems,
  isOnlyOne,
  periodIndex,
  rentFreePeriodIndex,
}: PeriodRowProps) => {
  const {
    activeContract: {
      rent: { periods },
    },
  } = useAppSelector((state: RootState) => state.contractDetail);

  const { startDate: periodStartDate, endDate: periodEndDate } = periods[periodIndex].description;

  const dispatch = useAppDispatch();

  const onChange = (key: string, value: any) => {
    if (key === 'startDate' || key === 'endDate') {
      dispatch(
        updateRentFreePeriodDate({
          key,
          value: value.toString(),
          rentFreePeriodIndex,
          periodIndex,
        }),
      );
    } else {
      dispatch(
        updateRentFreePeriodAdditionalFees({
          value,
          periodIndex,
          rentFreePeriodIndex,
        }),
      );
    }
  };

  const onDelete = () => dispatch(deleteRentFreePeriod({ periodIndex, rentFreePeriodIndex }));

  let totalDays = getNumberOfDays(startDate, endDate);

  return (
    <Grid.Row>
      <Grid.Column
        width={11}
        className="btn-container"
      >
        <Grid className="sub-section pm-none">
          <Grid.Row className="pm-none">
            <Grid.Column className="sn-container">
              <div className={`sn ${mode === MODE_OPTIONS.READ && 'blur'} v-center`}>{rentFreePeriodIndex + 1}</div>
            </Grid.Column>
            <Grid.Column width={5}>
              <DateField
                label="Start date"
                dataTest="rent-free-start-date-input"
                fieldKey="startDate"
                value={startDate}
                disabled={mode === MODE_OPTIONS.READ}
                onChange={onChange}
                required={mode === MODE_OPTIONS.EDIT}
                runValidation={!checkNotEmpty(startDate)}
                // @ts-ignore
                validationFuncs={rentFreePeriodStartDateValidations(periodStartDate, endDate)}
                validationDependant={endDate}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <DateField
                label="End date"
                dataTest="rent-free-end-date-input"
                fieldKey="endDate"
                value={endDate}
                disabled={mode === MODE_OPTIONS.READ}
                onChange={onChange}
                required={mode === MODE_OPTIONS.EDIT}
                runValidation={!checkNotEmpty(endDate)}
                // @ts-ignore
                validationFuncs={rentFreePeriodEndDateValidations(periodEndDate, startDate)}
                validationDependant={startDate}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <SelectField
                label="Additional fees and charges"
                dataTest="additional-fees-and-charges-input"
                fieldKey="additionalFreeItems"
                value={additionalFreeItems}
                options={ADDITIONAL_FEES_OPTIONS}
                disabled={mode === MODE_OPTIONS.READ}
                onChange={onChange}
                multiple={true}
                info={true}
                popUp={true}
                infoText="Here the user may specify, which of management fees, AC charges, Government rent and Government rates the tenant is required to pay during a rent free period. If there are more than Different charges can be applied to different rent free periods"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column
        className="area v-centered"
        width={4}
      >
        <span className="bold">{totalDays} days : </span>
        <span>{getDifferenceInDates(startDate ? new Date(startDate) : null, endDate ? new Date(endDate) : null)}</span>
      </Grid.Column>
      {mode === MODE_OPTIONS.EDIT && !isOnlyOne && (
        <div
          className="delete-btn bg-gray-very-light-v4 d-flex p-xs"
          onClick={() => onDelete()}
          role="button"
          tabIndex={0}
        >
          <Icon
            className="color-red-soft width-0_857 height-1_143"
            icon={Icons.Delete}
          />
        </div>
      )}
    </Grid.Row>
  );
};

export default PeriodRow;
