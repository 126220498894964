import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import Footer from 'layouts/Footer';
import Navbar from 'layouts/Navbar';
import PageHeader, { PageHeaderProps } from 'layouts/PageHeader';
import DimmerLoader from 'atoms/DimmerLoader';
import ErrorFallback from 'components/ErrorFallback';
import './PageLayout.scss';
import { defaultToastContainerProps } from 'utils/utils-toast';

interface PageLayoutProps {
  pageSpinner?: boolean;
  children?: ReactNode;
  className?: string;
  flexHeight?: boolean;
  pageHeader?: PageHeaderProps | undefined;
  id?: string;
  /**
   * flexHeight is used when you want children to not exceed screen height and have overflow-y
   *
   * <main className={`${className} ${flexHeight && 'flex-height'}`}>
   *    {children}
   *  </main>
   *
   * When flexHeight prop is set to true, children containers can then be "overflow-y: auto" rule in
   * css.
   */
}

const PageLayout = (props: PageLayoutProps) => {
  return (
    <>
      <ToastContainer {...defaultToastContainerProps}/>
      <DimmerLoader status={props.pageSpinner} />
      <Navbar />
      <div className={`page-layout ${props?.className}`}>
        {props.pageHeader && <PageHeader {...props.pageHeader} />}
        <main
          className={`page-layout-content ${props.className} ${props.flexHeight && 'flex-height'}`}
          id={props.id}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>{props.children}</ErrorBoundary>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default PageLayout;
