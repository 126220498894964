import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

export interface GeneratePortfolioRevenueAnalysisBaseQueryVariables {
  currentLandlordEntityId?: string | null;
  portfolioId?: string | null;
  startDate?: string;
  endDate?: string;
}

export const GENERATE_PORTFOLIO_REVENUE_ANALYSIS_BASE = (
  portfolioId: string | null,
  startDate: string,
  endDate: string,
) => {
  if (!startDate || !endDate) {
    throw new Error(
      'Generate Portfolio Revenue Analysis Base: Start Date or End Date could not be determined. Query aborted.',
    );
  }

  const variables: GeneratePortfolioRevenueAnalysisBaseQueryVariables = {
    currentLandlordEntityId: getCurrentLandlordEntityId(),
    portfolioId,
    startDate,
    endDate,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $portfolioId: ID, $startDate: Date!, $endDate: Date!) {
        generatePortfolioRevenueAnalysisBase(
          currentLandlordEntityId: $currentLandlordEntityId
          portfolioId: $portfolioId
          startDate: $startDate
          endDate: $endDate
        ) {
          portfolioId
          portfolioData {
            month
            statsRevenue {
              contractualRevenue
              forecastRevenue
              headlineRent
              revenueOverPerformance {
                contractualRevenue
                forecastRevenue
                performance
                variance
              }
              revenueUnderPerformance {
                contractualRevenue
                forecastRevenue
                performance
                variance
              }
            }
            statsArea {
              area
              contractualArea
              contractualOccupancyRate
              forecastArea
              forecastOccupancyRate
              occupancyOverPerformance {
                contractualArea
                forecastArea
                performance
                variance
              }
              occupancyUnderPerformance {
                contractualArea
                forecastArea
                performance
                variance
              }
            }
          }
          propertiesData {
            month
            propertyName
            propertyId
            statsRevenue {
              contractualRevenue
              forecastRevenue
              headlineRent
              revenueOverPerformance {
                contractualRevenue
                forecastRevenue
                performance
                variance
              }
              revenueUnderPerformance {
                contractualRevenue
                forecastRevenue
                performance
                variance
              }
            }
            statsArea {
              area
              contractualArea
              contractualOccupancyRate
              forecastArea
              forecastOccupancyRate
              occupancyOverPerformance {
                contractualArea
                forecastArea
                performance
                variance
              }
              occupancyUnderPerformance {
                contractualArea
                forecastArea
                performance
                variance
              }
            }
          }
          unitsData {
            month
            unitId
            statsRevenue {
              performance
              variance
              contractualRevenue
              forecastRevenue
              headlineRent
            }
            unitName
            statsArea {
              area
              contractualArea
              forecastArea
              performance
              variance
            }
            floorIndex
          }
        }
      }
    `,
    variables,
  };
};

export const generatePortfolioRevenueAnalysisBase = createAsyncThunk(
  'forecasts/generatePortfolioRevenueAnalysisBase',
  async (_args, { getState }) => {
    const {
      forecasts: {
        dateFilter: { startDate, endDate },
      },
      dashboard: { activePortfolio },
    } = getState() as RootState;

    const portfolioId: string | null = activePortfolio ? activePortfolio.id : null;

    const response = await client.query(GENERATE_PORTFOLIO_REVENUE_ANALYSIS_BASE(portfolioId, startDate, endDate));
    return response;
  },
);
