import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CheckboxField from 'atoms/FormField/Checkbox';
import { updateHasRentFreePeriod } from 'store/contracts/contractDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import RentFreePeriodForm from './RentFreePeriodForm';

const RentFreePeriod = ({ mode, periodIndex }: { mode: MODE_OPTIONS; periodIndex: number }) => {
  const { hasRentFreePeriods } = useAppSelector(
    (state: RootState) => state.contractDetail.activeContract.rent.periods[periodIndex].freePeriod,
  );

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: any) => dispatch(updateHasRentFreePeriod({ value, periodIndex }));

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>
          <CheckboxField
            fieldKey="hasRentFreePeriod"
            dataTest="rent-free-period-toggle"
            value={hasRentFreePeriods}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            toggle={true}
          />
        </Grid.Column>
      </Grid.Row>

      {hasRentFreePeriods && (
        <RentFreePeriodForm
          mode={mode}
          periodIndex={periodIndex}
        />
      )}
    </Grid>
  );
};

export default RentFreePeriod;
