import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { CContract2 } from 'common/_classes/contractsV2';
import { ID } from 'common/api/miscellaneous';

interface GetContractQueryVariables {
  contractId: string;
}

export const GET_CONTRACT2 = (id: string) => {
  if (!id) {
    throw new Error('Get Contract: Contract ID could not be determined. Query aborted.');
  }

  const queryVariables: GetContractQueryVariables = {
    contractId: id,
  };

  return {
    query: gql`
      query ($contractId: ID!) {
        getContract2(contractId: $contractId) {
          ${CContract2.fragment()}
        }
      }
    `,
    variables: queryVariables,
  };
};

export const getContract2 = createAsyncThunk('contractDetailV2/getContract2', async ({ id }: ID) => {
  const response = await client.query(GET_CONTRACT2(id));
  return response;
});
