// @ts-ignore
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import TableReactPrime from 'atoms/TableReactPrime';
import { listContracts } from 'common/api/contracts';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { ContractRow, contractColumnConfig } from './ContractTableConfig';
import ContractsHeaderToolbar from './ContractsHeaderToolbar';

const ContractDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, contractsList } = useAppSelector((state: RootState) => state.contractsListing);

  const onCreateNew = () => {
    navigate(`/contracts/create`);
  };

  const onViewDetails = (id: string): void => {
    const url = `/contracts/${id}/details`;
    navigate(url);
  };

  const currentList: ContractRow[] = contractsList.map((contract: any) => new ContractRow(contract));

  const getContracts = (params: PaginationAPIProps) => {
    dispatch(listContracts({ ...params }));
  };

  const getPageHeaderProps = () => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Contracts' }];

    const createNewButton: CreateNewButtonProps = {
      btnText: 'CREATE CONTRACT',
      url: '/contracts/create',
      dataTest: 'create-new-contract-button',
      onCreateNew: onCreateNew,
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <ContractsHeaderToolbar />,
      createNewButton,
    };

    const pageHeader: PageHeaderProps = {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
    return pageHeader;
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={({ ...rest }) => {
          getContracts(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={contractColumnConfig(onViewDetails)}
        dataTest="contracts-data-table"
        onRowClick={onViewDetails}
      />
    </PageLayout>
  );
};

export default ContractDashboard;
