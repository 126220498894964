import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import InputField from 'atoms/FormField/Input';
import TextAreaField from 'atoms/FormField/TextArea';
import ReviewBox, { ReviewBoxValues } from 'components/ReviewBox';
import { updateContractInput } from 'store/contracts/contractDetailSlice';
import { updateContractReviewedStatus } from 'store/contracts/contractDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import './ContractGeneralForm.scss';

type TranslationTransaction = {
  id: string;
  identifier: string;
};

const ContractGeneralForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const {
    activeContract: { identifier, description, reviewFlag, reviewStatus },
    translationTransaction,
  } = useAppSelector((state: RootState) => state.contractDetail);

  const onChange = (key: string, value: string) => dispatch(updateContractInput({ key, value }));

  const transaction = translationTransaction as TranslationTransaction | null;

  const reviewBoxValues: ReviewBoxValues = {
    reviewFlag,
    reviewStatus,
  };

  return (
    <Grid>
      <Grid.Row className="p-b-none">
        <Grid.Column width={11}>
          <Grid>
            {transaction !== null ? (
              <Grid.Row className="transaction-link">
                <h2 className="transaction-name">
                  Transaction link:{' '}
                  <a
                    className="link"
                    href={`/transactions/${transaction.id}/details`}
                  >
                    {transaction.identifier}
                  </a>
                </h2>
              </Grid.Row>
            ) : (
              ''
            )}
            <Grid.Row>
              <Grid.Column>
                <InputField
                  label="Contract identifier"
                  dataTest="contract-identifier-input"
                  value={identifier}
                  disabled={mode === MODE_OPTIONS.READ}
                  required={mode === MODE_OPTIONS.EDIT}
                  onChange={onChange}
                  fieldKey="identifier"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <TextAreaField
                  label="Contract summary description"
                  dataTest="contract-summary-description"
                  value={description}
                  disabled={mode === MODE_OPTIONS.READ}
                  onChange={onChange}
                  fieldKey="description"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={3}>
          <SectionComponent className="review-form">
            <ReviewBox
              mode={mode}
              values={reviewBoxValues}
              onChange={updateContractReviewedStatus}
            />
          </SectionComponent>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ContractGeneralForm;
