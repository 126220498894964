import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import _ from 'lodash';
import DocumentType from 'common/model/DocumentType';
import { DocumentsType, convertToContract } from 'common/api/contracts';
import { uploadToAWS } from 'utils/utils-upload';

interface CreateContractQueryVariables {
  currentLandlordEntityId: string;
  params: any;
}

interface CreateContractParams {
  activeContract: any;
  contractDocs: Partial<DocumentType>[];
}

export const CREATE_CONTRACT = ({ activeContract, contractDocs }: CreateContractParams) => {
  const currentLandlordEntityId = activeContract.landlord.id;
  if (_.isEmpty(currentLandlordEntityId)) {
    throw new Error('Create Contract: Landlord Entity could not be determined. Query aborted.');
  }

  const queryVariables: CreateContractQueryVariables = {
    currentLandlordEntityId: currentLandlordEntityId,
    params: convertToContract(activeContract, contractDocs),
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $params: ContractInput!) {
        createContract(currentLandlordEntityId: $currentLandlordEntityId, params: $params) {
          id
        }
      }
    `,
    variables: queryVariables,
  };
};

export const createContract = createAsyncThunk('contracts/createContract', async (arg, { getState }) => {
  const {
    contractDetail: { activeContract },
  } = getState() as RootState;

  const contractDocs: Partial<DocumentsType>[] = [...activeContract.documents];

  if (contractDocs.length > 0) {
    for (let i = 0; i < contractDocs.length; i++) {
      const { uploadUrl, file, name, path } = contractDocs[i];

      await uploadToAWS(uploadUrl, file);

      contractDocs.splice(i, 1, {
        name,
        path,
        public: true,
      });
    }
  }

  const response = await client.mutate(CREATE_CONTRACT({ activeContract, contractDocs }));
  return response;
});
