import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import AnalysisContext from 'common/model/AnalysisContext';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GenerateSummaryStatisticsOfTheMonthQueryVariables {
  currentLandlordEntityId: string | null;
  context: AnalysisContext;
  contextId: string | null;
  date?: string;
}

const GENERATE_SUMMARY_STATISTICS_OF_THE_MONTH = (context: AnalysisContext, contextId: string | null, date: string) => {
  if (!context) {
    throw new Error('Generate Summary Statistics of the Month: Context could not be determined. Query aborted.');
  }

  const variables: GenerateSummaryStatisticsOfTheMonthQueryVariables = {
    currentLandlordEntityId: getCurrentLandlordEntityId(),
    date,
    context,
    contextId,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $date: Date!, $context: AnalysisContext!, $contextId: ID) {
        generateSummaryStatisticsOfTheMonth(
          currentLandlordEntityId: $currentLandlordEntityId
          context: $context
          contextId: $contextId
          date: $date
        ) {
          context
          contextId
          date
          stats {
            month
            nbContracts
            nbTenants
            statsAvailability {
              occupiedArea
              totalArea
              vacantArea
            }
            statsOccupancy {
              occupiedArea
              totalArea
              vacantArea
            }
            statsRent {
              contractualRevenue
              contractualRevenuePerArea
              effectiveRent
              effectiveRentPerArea
              headlineRent
              headlineRentPerArea
            }
          }
        }
      }
    `,
    variables,
  };
};

export const generateSummaryStatisticsOfTheMonth = createAsyncThunk(
  'dashboardTab/generateSummaryStatisticsOfTheMonth',
  async (args, { getState }) => {
    const {
      dashboard: { activePortfolio, context },
      dashboardTab: { date },
      stackingPlan: { activeProperty },
    } = getState() as RootState;

    let contextId: string | null = null;

    if (context === AnalysisContext.Property && activeProperty) {
      contextId = activeProperty.id;
    } else if (context === AnalysisContext.Portfolio && activePortfolio) {
      contextId = activePortfolio.id;
    }

    const response = await client.query(GENERATE_SUMMARY_STATISTICS_OF_THE_MONTH(context, contextId, date));
    return response;
  },
);
