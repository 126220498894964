import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { formatRentDescripitionForServer } from 'utils/utils-contract';
import { RentDescriptionStringify } from '.';

interface CalculateEffictRentQueryVariables {
  currentLandlordEntityId: string;
  rentDescription: RentDescriptionStringify;
}

export const CALCULATE_EFFECTIVE_RENT = (rentDescription: RentDescriptionStringify) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId === null) {
    throw new Error('Current Landlord Entity could not be determined. Query aborted.');
  }

  const queryVariables: CalculateEffictRentQueryVariables = {
    currentLandlordEntityId: String(currentLandlordEntityId),
    rentDescription,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $rentDescription: RentDescriptionInput!) {
        calculateEffectiveRent(currentLandlordEntityId: $currentLandlordEntityId, rentDescription: $rentDescription) {
          charges {
            airConCharges
            governementRates
            governementRent
            managementFees
          }
          endDate
          startDate
          monthlyBaseRent
          monthlyEffectiveRent
          rentFreePeriods {
            additionalFreeItems
            endDate
            startDate
          }
          rentReview {
            cap
            collar
            date
            increment
            rent
            type
          }
          validated
        }
      }
    `,
    variables: queryVariables,
  };
};

export const calculateEffectiveRent = createAsyncThunk(
  'contracts/calculateEffectiveRent',
  async (periodIndex: number, { getState }) => {
    const {
      contractDetail: { activeContract },
    } = getState() as RootState;

    const descriptions = activeContract.rent.periods[periodIndex];

    const response = await client.query(CALCULATE_EFFECTIVE_RENT(formatRentDescripitionForServer(descriptions)));
    return response;
  },
);
