import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ParameterTableDetailClone } from 'common/_classes';
import { getClientType } from 'utils/utils-authentication';

interface GetParameterTableQueryVariables {
  parameterTableId: string;
}

const GET_PARAMETER_TABLE = (id: string) => {
  if (!id) throw new Error('GET_PARAMETER_TABLE: id is required');

  const variables: GetParameterTableQueryVariables = { parameterTableId: id };

  return {
    query: gql`
      query ($parameterTableId: ID!) {
        getParameterTable(parameterTableId: $parameterTableId) {
          ${ParameterTableDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const getParameterTable = createAsyncThunk('parameters/getParameterTable', async ({ id }: { id: string }) => {
  const response = await getClientType().query(GET_PARAMETER_TABLE(id));
  return response;
});
