import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { GuidelineClone } from 'common/_classes';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import Parameter from 'common/model/Parameter';
import ParameterTable from 'common/model/ParameterTable';
import { listContextParameters, listContextParametersGroup } from 'common/api/parameters';

export interface ParametersTabState {
  parametersCollection: Parameter[];
  parameterTablesCollection: ParameterTable[];
  parametersCollectionOld: Parameter[];
  parameterTablesCollectionOld: ParameterTable[];
  groupName: string;
  activeGroupId: string | null;
  groupGuideline: GuidelineClone | null;
  allParameters: Parameter[];
}

const initialState: ParametersTabState = {
  parametersCollection: [],
  parameterTablesCollection: [],
  parametersCollectionOld: [],
  parameterTablesCollectionOld: [],
  groupName: '',
  activeGroupId: null,
  groupGuideline: null,
  allParameters: [],
};

const parametersTabSlice = createSlice({
  name: 'parametersTab',
  initialState,
  reducers: {
    receiveGroupGuideline: (state, action: PayloadAction<any>) => {
      const { guideline } = action.payload;
      state.groupGuideline = guideline;
    },
    updateGroupName: (state, action: PayloadAction<any>) => {
      const { groupId, groupName } = action.payload;
      state.activeGroupId = groupId;
      state.groupName = groupName;
    },
    resetParametersList: state => {
      state.parametersCollection = current(state.parametersCollectionOld);
      state.parameterTablesCollection = current(state.parameterTablesCollectionOld);
    },
    saveOldParametersList: state => {
      // Save old context parameters list locally
      state.parametersCollectionOld = current(state.parametersCollection);
      state.parameterTablesCollectionOld = current(state.parameterTablesCollection);
    },
  },
  extraReducers: builder => {
    builder.addCase(listContextParameters.pending, state => {
      state.parametersCollection = [];
      state.parameterTablesCollection = [];
    });
    builder.addCase(listContextParameters.fulfilled, (state, action) => {
      const { parameters, parameterTables } = action.payload.listContextParameters;
      state.parametersCollection = parameters;
      state.parameterTablesCollection = parameterTables;
      if (action.payload.activeGroupIds.length === 0) {
        state.allParameters = parameters;
      }
    });
    builder.addCase(listContextParameters.rejected, (_state, action) => {
      toast.error('An error occurred when loading the Parameters');
    });

    builder.addCase(listContextParametersGroup.fulfilled, (state, action) => {
      const { parameters, parameterTables } = action.payload.listContextParametersGroup;
      // Update new context parameters list
      state.parametersCollection = parameters;
      state.parameterTablesCollection = parameterTables;
    });
    builder.addCase(listContextParametersGroup.rejected, () => {
      toast.error('An error occurred when loading the Parameters');
    });
  },
});

export const { updateGroupName, receiveGroupGuideline, resetParametersList, saveOldParametersList } =
  parametersTabSlice.actions;

/* Selectors */
export const selectParametersTabCollection = (state: RootState) => state.parametersTab.parametersCollection;

export default parametersTabSlice.reducer;
