import { CValuationReport } from 'common/_classes/valuationReport2';
import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getContractValuationReport } from 'common/api/contracts2';
import { generateUniqueId } from 'utils/utils-random';
import { RowConfigs } from './RowConfigs';
import TableHead from './TableHead';
import { applyGlobalFilters } from './ValuationRow';
import { RowAction, RowProps } from './components/RowTemplate/RowProps';
import TableBody from './components/TableBody';
import { handleScrollShadow } from './utils.comparables-table';
import './ComparablesTable.scss';

const ComparablesTable = ({ contractId }: { contractId: string }) => {
  const [scrollableWrapperId] = useState<string>(generateUniqueId('comparables-table-scrollable-wrapper-id'));
  const [shadowElementId] = useState<string>(generateUniqueId('shadow-element-id'));

  const dispatch = useAppDispatch();
  const { valuationReport } = useAppSelector((state: RootState) => state.contractDetailV2);

  const [removedItemsIds, setRemovedItemsIds] = useState<string[]>([]);
  const [rowActions, updateRowActions] = useState<RowAction[]>([]);
  const [filteredValuationReport, setFilteredValuationReport] = useState<CValuationReport[]>([]);
  const [activeRowFilter, updateActiveRowFilter] = useState<(RowAction & RowProps) | null>(null);

  const rearrangeReports = (): CValuationReport[] => {
    const selectedReports = valuationReport.filter(contract => !removedItemsIds.includes(contract.id));

    //put the current contract as the first one
    const rearrangedReports: CValuationReport[] = selectedReports
      .filter(contract => contract.id === contractId)
      .concat(selectedReports.filter(contract => contract.id !== contractId));

    return rearrangedReports;
  };

  /**
   * On Filter
   *
   */
  const onFilter = (): void => {
    const filteredReports = applyGlobalFilters(rearrangeReports(), RowConfigs, rowActions);
    setFilteredValuationReport(filteredReports);
  };

  /**
   * OnRemoveColumn
   *
   */
  const OnRemoveColumn = (columnId: string): void => {
    const newRemovedItemsIds = [...removedItemsIds, columnId];
    setRemovedItemsIds(newRemovedItemsIds);
    setFilteredValuationReport(filteredValuationReport.filter(contract => !newRemovedItemsIds.includes(contract.id)));
  };

  useEffect(() => {
    dispatch(
      getContractValuationReport({
        id: contractId,
      }),
    );
  }, []);

  useEffect(() => {
    setFilteredValuationReport(rearrangeReports());
  }, [valuationReport]);

  useEffect(() => {
    handleScrollShadow(scrollableWrapperId, shadowElementId);
  }, []);

  return (
    <div className="comparables-table-container">
      <div
        className="scrollable-wrapper"
        id={scrollableWrapperId}
      >
        <table className="ui table dashboard-table comparables-table">
          <TableHead
            content={filteredValuationReport}
            setContent={setFilteredValuationReport}
            onCancelColumn={OnRemoveColumn}
            rowActions={rowActions}
            setRowActions={updateRowActions}
          />

          <TableBody
            rowConfigs={RowConfigs}
            rowActions={rowActions}
            contents={filteredValuationReport}
            updateRowFilters={updateActiveRowFilter}
            rowFilters={activeRowFilter}
            setContents={setFilteredValuationReport}
            updateRowActions={updateRowActions}
            onFilter={onFilter}
          />
        </table>
      </div>

      <div
        className="shadow-right"
        id={shadowElementId}
      ></div>
    </div>
  );
};

export default ComparablesTable;
