import { FLOOR_PORTION_OPTIONS } from 'components/PremisesForm/utils-premiseForm';
import Event from 'common/model/Event';
import RentFreePeriod from 'common/model/RentFreePeriod';
import RentFreePeriodAdditionalFreeItem from 'common/model/RentFreePeriodAdditionalFreeItem';
import RentReviewType from 'common/model/RentReviewType';
import Scale from 'common/model/Scale';
import { ReviewStatusLocalType } from 'common/api/miscellaneous';
import { FloorPlanProps } from 'common/api/properties';
import { getReviewFlagAndReviewStatus } from 'utils/tsHelper';
import { convertDateToISO } from 'utils/utils-date';

export interface PremiseProps {
  floorsStatus: FloorStatusProps[];
  property: PropertyProps;
  spaceIds: string[];
  floorIds?: string[];
}

export interface FloorStatusProps {
  floorId: string;
  whole: boolean;
}

export interface PropertyProps {
  id: string;
  floors: FloorProps[];
}

export interface FloorProps {
  spaces: SpaceProps[];
  id: string;
  floorPlans: FloorPlanProps[];
}

export interface SpaceProps {
  id: string;

  grossArea: string;
}

export type GetReviewFlagAndReviewStatusParamType = {
  reviewFlag: boolean;
  reviewStatus: ReviewStatusLocalType;
  [key: string]: any;
};

export interface DocumentsType {
  cdnUrl: string;
  file: File;
  id: string;
  local: boolean;
  name: string;
  path: string;
  public: boolean;
  uploadUrl: string;
  url: string;
}

export interface LocalRentFreePeriod {
  additionalFreeItems: RentFreePeriodAdditionalFreeItem[];
  endDate: string | null;
  startDate: string | null;
}

export interface LocalRentPeriod {
  additionalFeesAndCharges: {
    airConCharges: string | null;
    governementRates: string | null;
    governementRent: string | null;
    managementFees: string | null;
  };
  description: {
    endDate: string | null | Date;
    monthlyBaseRent: string | null;
    monthlyEffectiveRent: string | null;
    startDate: string | null;
    validated: boolean;
  };
  freePeriod: {
    freePeriods: LocalRentFreePeriod[];
    hasRentFreePeriods: boolean;
  };
  review: {
    cap: string | null;
    collar: string | null;
    date: string | null;
    increment: string | null;
    rent: string | null;
    type: RentReviewType | null;
  } | null;
}

export interface RentDescriptionStringify {
  startDate: string;
  endDate: string;
  monthlyBaseRent: string;
  monthlyEffectiveRent: string;
  validated: boolean;
  charges: {
    airConCharges: string | null;
    governementRates: string | null;
    governementRent: string | null;
    managementFees: string | null;
  };
  rentReview: {
    cap: string | null;
    collar: string | null;
    date: string | null;
    increment: string | null;
    rent: string | null;
    type: RentReviewType;
  } | null;
  rentFreePeriods: {
    additionalFreeItems: RentFreePeriodAdditionalFreeItem[];
    endDate: string;
    startDate: string;
  }[];
}

export interface Message {
  msg: string;
}

export interface ContractRevenueStats {
  contractualRevenue: number;
  headlineRent: number;
  effectiveRent: number;
}

export interface ContractData {
  alerts: Message[];
  events: Message[];
  month: string;
  stats: ContractRevenueStats;
}

export interface ContractRevenueUnitData {
  floorName: string;
  month: string;
  stats: ContractRevenueStats;
  unitId: string;
  unitName: string;
}

export interface ContractRevenueBase {
  contractId: string;
  contractData: ContractData[];
  unitsData: ContractRevenueUnitData[];
  endDate: string;
  startDate: string;
  scale: Scale;
}

export interface ContractTimelineEvent {
  event: Event;
  date: string;
  description: string;
}

export interface ContractTimelinePeriod {
  description: string;
  endDate: string;
  event: RentFreePeriod;
  startDate: string;
}

export interface ContractTimeline {
  contractId: string;
  events: ContractTimelineEvent[];
  periods: ContractTimelinePeriod[];
}

export const convertToContract = (
  activeContract: GetReviewFlagAndReviewStatusParamType,
  contractDocs: Partial<DocumentType>[],
  activeContractFreezedCopy?: GetReviewFlagAndReviewStatusParamType,
) => {
  const {
    tenantId,
    description,
    endDate,
    startDate,
    flags: { redevelopmentFlag, expansionFlag, assignmentFlag, refusalFlag, renewFlag, subletFlag, surrenderFlag },
    identifier,
    premises,
    rent: { type: rentType, periods },
    security: { type: securityType, amount: securityAmount },
    termination: { terminationDate, terminationDescription, terminationReason },
    capitalAllowance,
    oneOffCharges,
  }: any = activeContract;

  const [reviewFlag, reviewStatus] = getReviewFlagAndReviewStatus(activeContract, activeContractFreezedCopy);

  const newPremises: any[] = premises.map((premise: any) => {
    const {
      floorIds: selectedFloors,
      property: { id: propertyId },
    } = premise;

    let spaceIds: string[] = [];
    const floorsStatus = selectedFloors.map((selectedFloor: any) => {
      const { id: floorId, spaceIds: selectedSpaces, floorPortion } = selectedFloor;
      spaceIds = [...spaceIds, ...selectedSpaces];
      return {
        floorId,
        whole: floorPortion === FLOOR_PORTION_OPTIONS.WHOLE,
      };
    });

    return {
      floorsStatus,
      propertyId,
      spaceIds,
    };
  });

  const newRentDescription = periods.map((period: any) => {
    const {
      review,
      description,
      freePeriod: { freePeriods },
      additionalFeesAndCharges,
    } = period;

    const rentFreePeriods = freePeriods.map((freePeriod: any) => {
      const { additionalFreeItems, startDate, endDate } = freePeriod;
      return {
        additionalFreeItems,
        endDate: convertDateToISO(endDate),
        startDate: convertDateToISO(startDate),
      };
    });

    return {
      ...description,
      endDate: convertDateToISO(description.endDate),
      startDate: convertDateToISO(description.startDate),
      charges: additionalFeesAndCharges,
      rentReview: review
        ? {
            ...review,
            date: convertDateToISO(review.date),
          }
        : review,
      rentFreePeriods,
    };
  });

  return {
    tenantId,
    description,
    documents: contractDocs,
    endDate: convertDateToISO(endDate),
    startDate: convertDateToISO(startDate),
    expansionFlag,
    surrenderFlag,
    subletFlag,
    refusalFlag,
    renewFlag,
    assignmentFlag,
    redevelopmentFlag,
    identifier,
    premises: newPremises,
    rentType,
    rentDescriptions: newRentDescription,
    securityAmount,
    securityType,
    terminationDate: convertDateToISO(terminationDate),
    terminationDescription,
    terminationReason,
    capitalAllowance,
    oneOffCharges,
    reviewFlag,
    reviewStatus,
  };
};
