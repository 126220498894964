import Decimal from 'decimal.js';
import FitOut from 'common/model/FitOut';
import FitOutInput from 'common/model/FitOutInput';
import { convertDateToISO } from 'utils/utils-date';

export interface FitOutInputClone extends Omit<FitOutInput, 'fitOutDeposit' | 'fitOutFee' | 'startDate' | 'endDate'> {
  fitOutDeposit: Decimal | null;
  fitOutFee: Decimal | null;
  startDate: Date | null;
  endDate: Date | null;
}

export class CFitOut implements FitOutInputClone {
  fitOutDeposit: Decimal | null;
  fitOutFee: Decimal | null;
  startDate: Date | null;
  endDate: Date | null;

  constructor(input: FitOut | null) {
    this.fitOutDeposit = input && input.fitOutDeposit ? new Decimal(input.fitOutDeposit) : null;
    this.fitOutFee = input && input.fitOutFee ? new Decimal(input.fitOutFee) : null;
    this.startDate = input && input.startDate ? new Date(input.startDate) : null;
    this.endDate = input && input.endDate ? new Date(input.endDate) : null;
  }

  static fragment() {
    return `
      fitOutDeposit
      fitOutFee
      startDate
      endDate
    `;
  }

  static formatForAPI(fitOut: CFitOut) {
    return {
      fitOutDeposit: fitOut.fitOutDeposit ? parseFloat(fitOut.fitOutDeposit.toString()) : null,
      fitOutFee: fitOut.fitOutFee ? parseFloat(fitOut.fitOutFee.toString()) : null,
      startDate: convertDateToISO(fitOut.startDate),
      endDate: convertDateToISO(fitOut.endDate),
    };
  }
}
