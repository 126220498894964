import GraphWithOutLinedContainer, { GraphInputData } from 'atoms/GraphWithOutLinedContainer';
import Scale from 'common/model/Scale';
import { ContractData, ContractRevenueBase } from 'common/api/contracts';
import { Colors } from 'utils/utils-colors';
import { getDateFormatForGraph } from 'utils/utils-date';
import { ContractRevenueForecastTooltip } from './Tooltip';
import './ContractRevenueForecast.scss';

interface ContractRevenueForecastGraphProps {
  contractRevenueBaseLoading: boolean;
  contractTermDatesLoading: boolean;
  contractRevenueBase: ContractRevenueBase;
}

const ContractRevenueForecastGraph = ({
  contractRevenueBaseLoading,
  contractTermDatesLoading,
  contractRevenueBase,
}: ContractRevenueForecastGraphProps) => {
  // get the labels
  const labels = contractRevenueBase.contractData.map((data: ContractData) =>
    getDateFormatForGraph(new Date(data.month), contractRevenueBase.scale as Scale),
  );

  // get the data
  const contractualRevenueData: string[] = contractRevenueBase.contractData.map((data: ContractData) =>
    data.stats.contractualRevenue.toString(),
  );

  const headlineRentData: string[] = contractRevenueBase.contractData.map((data: ContractData) =>
    data.stats.headlineRent.toString(),
  );

  const effectiveRentData: string[] = contractRevenueBase.contractData.map((data: ContractData) =>
    data.stats.effectiveRent.toString(),
  );

  const data: GraphInputData[] = [
    {
      key: 1,
      label: 'Contractual Revenue',
      data: contractualRevenueData,
      color: Colors.RED_SOFT,
    },
    {
      key: 2,
      label: 'Headline Rent',
      data: headlineRentData,
      color: Colors.CYAN_MODERATE,
    },
    {
      key: 3,
      label: 'Effective Rent',
      data: effectiveRentData,
      color: Colors.GREEN_LIME,
    },
  ];

  return (
    <GraphWithOutLinedContainer
      className="revenue-forecast color-blue-very-dark"
      loading={contractRevenueBaseLoading || contractTermDatesLoading}
      labels={labels}
      data={data}
      tooltipHTML={ContractRevenueForecastTooltip(contractRevenueBase as ContractRevenueBase)}
    />
  );
};

export default ContractRevenueForecastGraph;
