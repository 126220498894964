import { Grid, Image } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { monthlyBaseRentWithOpenMarketReviewTypeValidation } from 'store/contracts/validations';
import { Icons } from 'utils/utils-icons';
import triangleCheck from 'assets/images/svg/triangle-check.svg';
import triangleDash from 'assets/images/svg/triangle-dash.svg';
import './MonthlyBaseRentFeedBack.scss';

interface MonthlyBaseRentFeedBackProps {
  collar?: number | string;
  cap?: number | string;
  previousBaseRent?: number | string | null;
  currentBaseRent?: number | string | null;
}

const MonthlyBaseRentFeedBack = ({ collar, cap, previousBaseRent, currentBaseRent }: MonthlyBaseRentFeedBackProps) => {
  const validations = monthlyBaseRentWithOpenMarketReviewTypeValidation(collar, cap, previousBaseRent, true);

  let notValid: any = true;
  let message: any = '';
  let messageType = '';

  for (let i = 0; i < validations.length; i++) {
    [notValid, message] = validations[i](currentBaseRent ? currentBaseRent : null);

    if (notValid) {
      if (!currentBaseRent && message.includes('should')) {
        messageType = 'warning';
        break;
      }
      if (message.includes('should')) {
        messageType = 'error';
        break;
      } else {
        messageType = 'warning';
        break;
      }
    } else {
      messageType = 'success';
    }
  }

  return (
    <>
      <Grid.Row className="m-l-sm m-r-sm">
        <div className={`feedback-box feedback ${messageType}`}>
          {messageType === 'warning' ? (
            <Icon
              icon={Icons.Warning}
              className="feedback-svg height-0_857 width-0_857"
            />
          ) : (
            <Image
              className="feedback-svg height-0_857 width-0_857"
              src={messageType === 'error' ? triangleDash : triangleCheck}
            />
          )}
          {message}
        </div>
      </Grid.Row>
    </>
  );
};

export default MonthlyBaseRentFeedBack;
