import { IEntityClone } from 'common/_classes/entities';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { LANDLORD_ENTITY_DETAILS_TABS_OFFSET } from 'views/entities/landlordEntities/EntityDetails/LandlordEntityTabs';
import { TENANT_ENTITY_DETAILS_TABS_OFFSET } from 'views/entities/tenantEntities/EntityDetails/TenantTabs';
import { EntityCreatorTypes, getEntity, updateEntity } from 'common/api/entities';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { entityInfoDetailsPageValidator } from '../../entityValidator';
import { updateNetwork } from 'common/api/entities/updateNetwork';
import { createNetwork } from 'common/api/entities/createNetwork';

interface EntityInformationTabToolbarProps {
  mode: MODE_OPTIONS;
  setMode: (value: MODE_OPTIONS) => void;
  entityData: IEntityClone;
}

const EntityInformationTabToolbar = ({ mode, setMode, entityData }: EntityInformationTabToolbarProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeEntityType, activeTabIndex, selectedSubsidiary } = useAppSelector((state: RootState) => state.entityDetail);
  const id = String(entityData.id)
  const onCancel = (): void => {
    dispatch(getEntity({ type: activeEntityType as EntityCreatorTypes, id }));
  };

  const onSave = (): void => {
    dispatch(updateEntity({ type: activeEntityType as EntityCreatorTypes, entityData })).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        if (selectedSubsidiary) {
          const {
            id,
            parentId,
            sharePercentage,
            landlordNetworks
          } = selectedSubsidiary
          if (parentId) {
            if (landlordNetworks && landlordNetworks.length) {
              // Network already exist 
              dispatch(
                updateNetwork({
                  id: landlordNetworks[0].id,
                  childId: id || "",
                  parentId: parentId || "",
                  sharePercentage
                }),
              );
            } else {
              dispatch(
                createNetwork({
                  childId: id || "",
                  parentId: parentId || "",
                  sharePercentage
                }),
              );
            }

          }

        }
      }
    });
  };

  const checkDisabled = (): boolean => {
    if (
      activeTabIndex === LANDLORD_ENTITY_DETAILS_TABS_OFFSET.ENTITY_INFORMATION ||
      activeTabIndex === TENANT_ENTITY_DETAILS_TABS_OFFSET.ENTITY_INFORMATION
    ) {
      return entityInfoDetailsPageValidator(entityData, activeEntityType as EntityCreatorTypes);
    }

    return true;
  };

  return (
    <DetailsControlButtons
      mode={mode}
      setMode={setMode}
      checkDisabled={checkDisabled()}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
};

export default EntityInformationTabToolbar;
