import { useNavigate } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setUnsavedModalStatus } from 'store/contracts/contractDetailSlice';
import { resetFormattersSearch } from 'store/formatters/formattersListingSlice';
import { updateParamViewSelectedGroup } from 'store/parameters/parameterDetailSlice';
import { resetParametersSearch } from 'store/parameters/parametersListingSlice';
import { resetProvisionsSearch } from 'store/provisions/provisionsListingSlice';
import { resetTransactionsSearch } from 'store/transactions/transactionsListingSlice';
import { resetTranslatorsToContractSearch } from 'store/translatorsToContract/translatorsListingSlice';
import { checkIfUnsavedContract } from 'utils/tsHelper';
import { preventReload } from 'utils/tsHelper';

interface ParentMenuItemProps {
  name: string;
  url: string;
  activeRoutes: boolean;
  dataTest: string;
  moveTo?: (x: number, y: number) => void;
}

const ParentMenuItem = ({ name, url, dataTest, activeRoutes }: ParentMenuItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { activeContract, oldContractVersion } = useAppSelector((state: RootState) => state.contractDetail);

  const moveTo = () => {
    if (checkIfUnsavedContract(activeContract, oldContractVersion)) {
      dispatch(setUnsavedModalStatus({ status: true, url }));
      return;
    }
    if (url === '/parameters/dashboard') {
      dispatch(resetParametersSearch());
      dispatch(updateParamViewSelectedGroup({}));
    } else if (url === '/provisions/dashboard') {
      dispatch(resetProvisionsSearch());
    } else if (url === '/formatters/dashboard') {
      dispatch(resetFormattersSearch());
    } else if (url === '/transactions/dashboard') {
      dispatch(resetTransactionsSearch());
    } else if (url === '/translate-to-contract/dashboard') {
      dispatch(resetTranslatorsToContractSearch());
    }
    navigate(url);
  };

  return (
    <>
      <div
        className="circle-xs active-menu active-menu-position bg-green"
        active={activeRoutes.toString()}
      />
      <Menu.Item
        content={name}
        href={url}
        data-test={dataTest}
        active={activeRoutes}
        onClick={e => {
          preventReload(e);
          moveTo();
        }}
      />
    </>
  );
};

export default ParentMenuItem;
