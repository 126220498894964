import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { ChangesBoxType } from 'views/transactions/CreatorViewer/Tabs/ApprovalTab/ApprovalChanges';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { AnswerProps, SetAnswerInputResult } from 'common/api/policies';
import { setAnswerInput } from 'utils/utils-answer';

interface CreateTransactionApprovalAnswersQueryVariables {
  answers: SetAnswerInputResult[];
}

const CREATE_TRANSACTION_APPROVAL_ANSWERS = async (answers: AnswerProps[]) => {
  const answersParam = await setAnswerInput(answers);

  const variables: CreateTransactionApprovalAnswersQueryVariables = {
    answers: answersParam,
  };

  return {
    mutation: gql`
      mutation ($answers: [TransactionApprovalAnswerInput]) {
        createTransactionApprovalAnswers(answers: $answers) {
          id
          answer
          answerType
          dateOfAnswer
          fromPolicy
          fromInventory
          paramRef {
            parameterId
            tableId
            index
          }
          user {
            id
            firstName
            lastName
          }
          transaction {
            id
          }
          approvalState
          note
          changedValue
          transactionMessage {
            id
            transactionChannel {
              id
              title
            }
          }
          transactionParameterApproval {
            id
            dateSubmitted
          }
          transactionDiscussionApproval {
            id
            dateSubmitted
            draftMessage
          }
          transactionProvisionApproval {
            id
            dateSubmitted
          }
        }
      }
    `,
    variables,
  };
};

// createTransactionApprovalAnswers API may also save some answers that have files embedded.
// Those files are uploaded to AWS S3 buckets and we need to wait for this action.
//  => this is why there is a 'await' inside the 'client.mutate'
export const createTransactionApprovalAnswers = createAsyncThunk(
  'transactions/createTransactionApprovalAnswers',
  async ({ approvalType, approvalId }: { approvalType: ChangesBoxType; approvalId?: string }, { getState }) => {
    const {
      transactionDetail: { activeTransactionAnswers },
    } = getState() as RootState;
    let newAnswers: AnswerProps[] = activeTransactionAnswers.filter(
      (answer: AnswerProps) => answer.blockRef !== undefined && answer.approvalState === ValidApprovalStates.Pending,
    );
    if (approvalType === ChangesBoxType.Parameter) {
      for (let i = 0; i < newAnswers.length; i++) {
        newAnswers[i] = { ...newAnswers[i], transactionParameterApprovalId: approvalId };
      }
    } else if (approvalType === ChangesBoxType.Discussion) {
      for (let i = 0; i < newAnswers.length; i++) {
        newAnswers[i] = { ...newAnswers[i], transactionDiscussionApprovalId: approvalId };
      }
    }

    const response = await client.mutate(await CREATE_TRANSACTION_APPROVAL_ANSWERS(newAnswers));
    return response;
  },
);
