import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { ID } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface DeleteContractQueryVariables {
  currentLandlordEntityId: string;
  contractId: string;
}

const DELETE_CONTRACT = (id: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!currentLandlordEntityId) {
    throw new Error('Delete Contract: Landlord Entity could not be determined. Query aborted.');
  }

  if (!id) {
    throw new Error('Delete Contract: Contract ID could not be determined. Query aborted.');
  }

  const queryVariables: DeleteContractQueryVariables = {
    currentLandlordEntityId: currentLandlordEntityId,
    contractId: id,
  };

  return {
    mutation: gql`
      mutation ($contractId: ID!, $currentLandlordEntityId: ID!) {
        deleteContract(currentLandlordEntityId: $currentLandlordEntityId, contractId: $contractId) {
          id
        }
      }
    `,
    variables: queryVariables,
  };
};

export const deleteContract = createAsyncThunk('contracts/deleteContract', async ({ id }: ID) => {
  const response = await client.mutate(DELETE_CONTRACT(id));
  return response;
});
