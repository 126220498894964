import { CRentReview } from 'common/_classes/contractsV2';
import moment from 'moment';
import { useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import AddPlus from 'atoms/AddPlus';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { getDateOfAddedDays } from 'utils/utils-date';
import TabLabel from '../TabLabel';
import FirstRentPeriodForm from './FirstRentPeriod';
import SubsequentRentPeriodForm from './SubsequentRentPeriod';

const RentAndRentReview = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const formDisabled = mode === MODE_OPTIONS.READ;

  const [tabIndex, setTabIndex] = useState(0);

  const rentPeriods = activeContractEvent?.rentReview || [];

  const onAddReview = () => {
    const value = [...rentPeriods, new CRentReview(null)];
    dispatch(updateActiveContractEventInput({ key: 'rentReview', value }));
    setTabIndex(value.length - 1);
  };

  const onDelete = (index: number) => {
    dispatch(
      updateActiveContractEventInput({ key: 'rentReview', value: rentPeriods.filter((_item, idx) => idx !== index) }),
    );
  };

  const getTabsMenu = (): TabMenuProps[] => {
    return rentPeriods.map((rentReview, index) => {
      let startDate: string | null = null;
      let endDate: string | null = null;

      if (index === 0) {
        startDate = moment(activeContractEvent?.startDate).isValid()
          ? moment(activeContractEvent?.startDate).format('DD/MM/YYYY')
          : '';
      } else {
        const dayAfterReviewDate = getDateOfAddedDays(rentReview.reviewDate, 1);
        startDate = moment(dayAfterReviewDate).isValid() ? moment(dayAfterReviewDate).format('DD/MM/YYYY') : '';
      }

      if (index === rentPeriods.length - 1) {
        endDate = moment(activeContractEvent?.endDate).isValid()
          ? moment(activeContractEvent?.endDate).format('DD/MM/YYYY')
          : '';
      } else {
        const nextPeriod = rentPeriods[index + 1];
        endDate = moment(nextPeriod.reviewDate).isValid() ? moment(nextPeriod.reviewDate).format('DD/MM/YYYY') : '';
      }

      const dateText = `${startDate}${startDate && endDate ? ' - ' : ''}${endDate}`;

      return {
        key: `rent-period-tab-${index}`,
        label: (
          <TabLabel
            key={index}
            title="Rent Period"
            disabled={mode === MODE_OPTIONS.READ}
            deletable={index !== 0}
            index={index}
            dateText={dateText}
            onDelete={onDelete}
            activeIndex={tabIndex}
            tabsLength={rentPeriods.length}
            setTabIndex={setTabIndex}
          />
        ),
        customTab:
          index === 0 ? (
            <FirstRentPeriodForm
              key={index}
              mode={mode}
            />
          ) : (
            <SubsequentRentPeriodForm
              key={index}
              mode={mode}
              index={index}
            />
          ),
      };
    });
  };

  const onTabChange = (index: number) => setTabIndex(index);

  return (
    <>
      {!formDisabled && (
        <div className="d-flex">
          <AddPlus
            label="Add rent period / rent review"
            onClick={onAddReview}
            className="m-l-auto"
          />
        </div>
      )}

      <TabReact
        dataTest=""
        activeTabIndex={tabIndex}
        tabMenu={getTabsMenu()}
        onTabChange={onTabChange}
      />
    </>
  );
};

export default RentAndRentReview;
