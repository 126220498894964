import moment from 'moment';
import DropdownSelect from 'atoms/DropdownSelect';
import DateField from 'atoms/FormField/DateInput';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { RowFilterChildProps } from '.';
import { ComparablesTableFilterDropdownOptions, FilterMode, RowAction, RowProps } from '../../RowProps';

const FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [
  { key: 1, text: 'Date is', value: FilterMode.EQUALS },
  { key: 2, text: 'Date is not', value: FilterMode.NOT_EQUALS },
  { key: 3, text: 'Date is before', value: FilterMode.LESS_THAN },
  { key: 4, text: 'Date is after', value: FilterMode.GREATER_THAN },
];

export function applyDateFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyDateFilter: field key is missing');

  let value: Date = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }
  if (!rowAction.filterValue) return true;

  switch (rowAction.filterMode) {
    case FilterMode.EQUALS:
      return moment(value).isSame(rowAction.filterValue, 'day');
    case FilterMode.NOT_EQUALS:
      return !moment(value).isSame(rowAction.filterValue, 'day');
    case FilterMode.LESS_THAN:
      return moment(value).isBefore(rowAction.filterValue, 'day');
    case FilterMode.GREATER_THAN:
      return moment(value).isAfter(rowAction.filterValue, 'day');
  }
  return true;
}

const DateFilter = ({
  filterMode,
  label,
  filterValue,
  onChange,
  onFilterModeChange,
}: RowFilterChildProps): JSX.Element => {
  return (
    <div className="grid">
      <div className="col-6">
        <DropdownSelect
          placeholder="Select..."
          fieldKey="mode"
          value={filterMode}
          options={FILTER_OPTIONS}
          onChange={(key, value) => onFilterModeChange(key, value as string)}
        />
      </div>
      <div className="col-6">
        <DateField
          fieldKey="value"
          value={filterValue}
          onChange={onChange}
          placeholder={'Search By ' + label}
        />
      </div>
    </div>
  );
};

export default DateFilter;
