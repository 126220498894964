import { Grid } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import { deleteRentPeriod } from 'store/contracts/contractDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { Icons } from 'utils/utils-icons';
import AdditionalFeesAndCharges from '../AdditionalFeesAndCharges';
import RentFreePeriod from '../RentFreePeriod';
import RentPeriodForm from '../RentPeriodForm';
import RentReviewForm from '../RentReviewForm';
import './Container.scss';

interface ReviewAndPeriodAndFreePeriodProps {
  mode: MODE_OPTIONS;
  periodIndex: number;
}

const ReviewAndPeriodAndFreePeriod = ({ mode, periodIndex }: ReviewAndPeriodAndFreePeriodProps) => {
  const dispatch = useAppDispatch();

  const enabled: boolean = mode === MODE_OPTIONS.EDIT;

  const deletePeriod = () => {
    if (enabled) {
      dispatch(deleteRentPeriod({ periodIndex }));
    }
  };

  return (
    <Grid className="review-and-period">
      {/* This is because you cannot delete rent period 1 */}
      {periodIndex !== 0 && enabled && (
        <div
          className="period-delete p-xs"
          role="button"
          data-test="nav-delete-rent-period"
          tabIndex={0}
          onClick={deletePeriod}
        >
          <span className="icon">
            <Icon icon={Icons.Delete} />
          </span>
          <span>Delete this rent period</span>
        </div>
      )}

      {/* This is because there is no review for first rent period */}
      {periodIndex !== 0 && (
        <Grid.Row>
          <Grid.Column
            width={2}
            className="p-t-m"
          >
            <h6 className="title">Rent Review</h6>
          </Grid.Column>
          <Grid.Column
            width={12}
            className="p-t-m"
          >
            <RentReviewForm
              mode={mode}
              periodIndex={periodIndex}
            />
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column
          width={2}
          className="p-t-m"
        >
          <h6 className="title">Rent Period</h6>
        </Grid.Column>
        <Grid.Column
          width={12}
          className="p-t-m"
        >
          <RentPeriodForm
            mode={mode}
            periodIndex={periodIndex}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          width={2}
          className="p-t-m"
        >
          <h6 className="title no-mt">Rent Free Period</h6>
        </Grid.Column>
        <Grid.Column
          width={14}
          className="p-t-m"
        >
          <RentFreePeriod
            mode={mode}
            periodIndex={periodIndex}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          width={2}
          className="p-t-m"
        >
          <h6 className="title">Additional Fees and Charges</h6>
        </Grid.Column>
        <Grid.Column
          width={14}
          className="p-t-m"
        >
          <AdditionalFeesAndCharges
            mode={mode}
            periodIndex={periodIndex}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ReviewAndPeriodAndFreePeriod;
