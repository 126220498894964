import { CValuationReport } from 'common/_classes/valuationReport2';
import { useState } from 'react';
import SortOrder from 'common/model/SortOrder';
import { applySorting } from '../ValuationRow';
import RowTemplate from './RowTemplate';
import RowFilterPopup from './RowTemplate/RowFilterPopup';
import { RowAction, RowProps } from './RowTemplate/RowProps';

interface TableBodyProps {
  rowActions: RowAction[];
  updateRowActions: (actions: RowAction[]) => void;
  contents: CValuationReport[];
  setContents: (value: CValuationReport[]) => void;
  updateRowFilters: (filters: RowAction & RowProps) => void;
  rowFilters: (RowAction & RowProps) | null;
  rowConfigs: RowProps[];
  onFilter: () => void;
}

const TableBody = ({
  rowActions,
  contents,
  updateRowFilters,
  setContents,
  rowFilters,
  updateRowActions,
  rowConfigs,
  onFilter,
}: TableBodyProps) => {
  const [isRowFilterModalOpen, updateRowFilterModalStatus] = useState<boolean>(false);
  /**
   * On Row filter Modal open
   * @param row
   */
  const onOpenRowFilterModal = (rowAction: RowAction, rowConfig: RowProps): void => {
    updateRowFilters({ ...rowAction, ...rowConfig });
    updateRowFilterModalStatus(true);
  };

  /**
   * On Sorting Change
   * @param row
   * @param sortOrder
   *
   * Note: onSortingChange gets event from the RowTemplate indicating a row with a sorting.
   */
  const onSortingChange = (rowConfig: RowProps, sortOrder: SortOrder): void => {
    if (rowConfig) {
      const index: number = rowActions.findIndex(row => row.id === rowConfig.id);

      const rowActionsTemp = [...rowActions];

      //if rowActions for the selected row does not exist: create it
      if (index === -1) {
        rowActionsTemp.push({
          id: rowConfig.id,
          sortOrder: sortOrder,
        });
      }
      // Update sorting type for the newly selected rowAction, clears the sorting type for the other rows
      const newRowActions = rowActionsTemp.map(row => {
        row.sortOrder = row.id === rowConfig.id ? sortOrder : undefined;
        return row;
      });

      updateRowActions(newRowActions);

      setContents(applySorting(contents, rowConfig, sortOrder));
    }
  };

  return (
    <>
      <tbody>
        <RowTemplate
          rowConfigs={rowConfigs}
          rowActions={rowActions}
          contents={contents}
          onOpenRowFilterModal={onOpenRowFilterModal}
          onSortingChange={onSortingChange}
        />
      </tbody>

      {/* Row filter popup */}
      <RowFilterPopup
        isOpen={isRowFilterModalOpen}
        setIsOpen={updateRowFilterModalStatus}
        rowFilters={rowFilters}
        updateRowFilters={updateRowFilters}
        rowActions={rowActions}
        updateRowActions={updateRowActions}
        onFilter={onFilter}
      />
    </>
  );
};

export default TableBody;
