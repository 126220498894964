import { CDuration } from 'common/_classes/valuationReport2';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { RowFilterChildProps } from '.';
import { ComparablesTableFilterDropdownOptions, FilterMode, RowAction, RowProps } from '../../RowProps';

const FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [{ key: 1, text: 'Equals', value: FilterMode.EQUALS }];

export function applyDurationFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyDurationFilter: field key is missing');

  let value: CDuration | null = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }

  if (!rowAction.filterValue || typeof rowAction.filterValue === 'string') return true;

  switch (rowAction.filterMode) {
    case FilterMode.EQUALS:
      return (
        value.days === rowAction.filterValue.days &&
        value.months === rowAction.filterValue.months &&
        value.years === rowAction.filterValue.years
      );
  }
  return true;
}

const DurationFilter = ({
  filterMode,
  onChange,
  onFilterModeChange,
  filterValue = {
    years: 0,
    days: 0,
    months: 0,
  },
}: RowFilterChildProps): JSX.Element => {
  return (
    <>
      <div className="grid">
        <div className="col-4">
          <SelectField
            label="Mode"
            placeholder="Select..."
            fieldKey="mode"
            value={filterMode}
            options={FILTER_OPTIONS}
            onChange={onFilterModeChange}
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-4">
          <InputField
            label="Years"
            type={InputFieldType.NUMBER}
            value={filterValue.years}
            placeholder="Years..."
            onChange={(key, years) => onChange(key, { ...filterValue, years: Number(years) })}
            fieldKey="value"
          />
        </div>
        <div className="col-4">
          <InputField
            label="Months"
            type={InputFieldType.NUMBER}
            value={filterValue.months}
            placeholder="Months..."
            onChange={(key, months) => onChange(key, { ...filterValue, months: Number(months) })}
            fieldKey="value"
          />
        </div>
        <div className="col-4">
          <InputField
            label="Days"
            type={InputFieldType.NUMBER}
            value={filterValue.days}
            placeholder="Days..."
            onChange={(key, days) => onChange(key, { ...filterValue, days: Number(days) })}
            fieldKey="value"
          />
        </div>
      </div>
    </>
  );
};

export default DurationFilter;
