import { ActionCreatorWithPayload, Dispatch } from '@reduxjs/toolkit';
import ContractSecurityType from 'common/model/ContractSecurityType';
import RentReviewType from 'common/model/RentReviewType';
import { checkEmpty } from 'utils/tsValidator';
import { checkNotEmpty } from 'utils/tsValidator';
import { getTimeInSecs } from 'utils/utils-date';
import { isValidNumber } from 'utils/utils-number';
import { addSpaceOrComma } from 'utils/utils-number';
import { getIncrementByPercentage } from './helper';

export const reviewStartDateValidations = (startDateOfPreviousPeriod: any, endDateOfCurrentPeriod: any) => [
  (value: any) => {
    const previousStartDate = getTimeInSecs(startDateOfPreviousPeriod);
    const startDate = getTimeInSecs(value);

    if (startDate && previousStartDate && previousStartDate > startDate) {
      return [true, 'date cannot be before previous period start date'];
    }

    return [false, ''];
  },
  (value: any) => {
    const startDate = getTimeInSecs(value);
    const reviewEndDate = getTimeInSecs(endDateOfCurrentPeriod);

    if (startDate && reviewEndDate && startDate > reviewEndDate) {
      return [true, 'date cannot be after end date'];
    }

    return [false, ''];
  },
];

export const contractEndDateValidations = (start: string) => [
  (value: any) => {
    const startDate = getTimeInSecs(start);
    const endDate = getTimeInSecs(value);

    if (startDate && endDate && startDate > endDate) {
      return [true, 'end date cannot be before start date'];
    }

    return [false, ''];
  },
];

export const contractStartDateValidations = (end: string) => [
  (value: any) => {
    const startDate = getTimeInSecs(value);
    const endDate = getTimeInSecs(end);

    if (startDate && endDate && startDate > endDate) {
      return [true, 'start date cannot be after end date'];
    }

    return [false, ''];
  },
];

export const rentFreePeriodStartDateValidations = (periodStart: string, end: string) => [
  (value: any) => {
    const periodStartDate = getTimeInSecs(periodStart);
    const startDate = getTimeInSecs(value);

    if (periodStartDate && startDate && startDate < periodStartDate) {
      return [true, 'start date cannot be before rent period starts'];
    }

    return [false, ''];
  },
  (value: any) => {
    const startDate = getTimeInSecs(value);
    const endDate = getTimeInSecs(end);

    if (startDate && endDate && startDate > endDate) {
      return [true, 'start date cannot be after end date'];
    }

    return [false, ''];
  },
];

export const rentFreePeriodEndDateValidations = (periodEnd: string, start: string) => [
  (value: any) => {
    const periodEndDate = getTimeInSecs(periodEnd);
    const endDate = getTimeInSecs(value);

    if (periodEndDate && endDate && endDate > periodEndDate) {
      return [true, 'end date cannot be after rent period ends'];
    }

    return [false, ''];
  },
  (value: any) => {
    const endDate = getTimeInSecs(value);
    const startDate = getTimeInSecs(start);

    if (startDate && endDate && endDate < startDate) {
      return [true, 'end date cannot be before start date'];
    }

    return [false, ''];
  },
];

export const rentReviewCapValidations = (collar: string) => [
  (value: any) => {
    if (isValidNumber(value) && isValidNumber(collar) && parseInt(collar) > parseInt(value)) {
      return [true, 'cap cannot be less than collar'];
    }

    return [false, ''];
  },
];

export const rentReviewCollarValidations = (cap: any) => [
  (value: any) => {
    if (isValidNumber(value) && isValidNumber(cap) && parseInt(value) > parseInt(cap)) {
      return [true, 'collar cannot be greater than cap'];
    }

    return [false, ''];
  },
];

export const monthlyBaseRentWithOpenMarketReviewTypeValidation = (
  collar: any,
  cap: any,
  previousBaseRent: any,
  isContractPage?: boolean,
) => [
  (value: any) => {
    const parsedBasedRent = parseInt(value);
    const parsedCollar = parseInt(collar);
    const parsedCap = parseInt(cap);
    const parsedPreviousBasedRent = parseInt(previousBaseRent);
    const minRentBasedOnCollar = getIncrementByPercentage(parsedPreviousBasedRent, parsedCollar);
    const maxRentBasedOnCap = getIncrementByPercentage(parsedPreviousBasedRent, parsedCap);

    if (
      isValidNumber(parsedPreviousBasedRent) &&
      isValidNumber(parsedCap) &&
      isValidNumber(parsedCollar) &&
      parsedCap > parsedCollar &&
      isContractPage &&
      (!isValidNumber(parsedBasedRent) || parsedBasedRent > maxRentBasedOnCap || parsedBasedRent < minRentBasedOnCollar)
    ) {
      return [
        true,
        `value should be in range ${addSpaceOrComma(minRentBasedOnCollar)} HK$ - ${addSpaceOrComma(
          maxRentBasedOnCap,
        )} HK$`,
      ];
    } else if (
      isValidNumber(parsedBasedRent) &&
      isValidNumber(minRentBasedOnCollar) &&
      parsedBasedRent < minRentBasedOnCollar
    ) {
      return [true, `value cannot be less than ${addSpaceOrComma(minRentBasedOnCollar)} HK$`];
    } else if (
      isValidNumber(parsedBasedRent) &&
      isValidNumber(maxRentBasedOnCap) &&
      parsedBasedRent > maxRentBasedOnCap
    ) {
      return [true, `value cannot be greater than ${addSpaceOrComma(maxRentBasedOnCap)} HK$`];
    }

    return [
      false,
      `value in range ${addSpaceOrComma(minRentBasedOnCollar)} HK$ - ${addSpaceOrComma(maxRentBasedOnCap)} HK$`,
    ];
  },
];

const runValidations = ({ value, otherValidations }: { value: any; otherValidations?: Function[] }) => {
  let notValid = !checkNotEmpty(value);

  if (Array.isArray(value)) {
    notValid = value.length === 0;
  }

  if (notValid) return true;

  const validationsFuncs = otherValidations ? otherValidations : [];

  for (let i = 0; i < validationsFuncs.length; i++) {
    const [notValid] = validationsFuncs[i](value);

    if (notValid) return true;
  }

  return false;
};

const validateMonthlyBaseRentForOpenMarket = (periods: any, index: number) => {
  const {
    description: { monthlyBaseRent },
    review,
  } = periods[index];

  if (!isValidNumber(monthlyBaseRent)) {
    return false;
  }

  const { collar, cap } = review;
  const { monthlyBaseRent: previousBaseRent } = periods[index - 1].description;

  return runValidations({
    value: monthlyBaseRent,
    otherValidations: monthlyBaseRentWithOpenMarketReviewTypeValidation(collar, cap, previousBaseRent),
  });
};

export const validateContract = (contract: any) => {
  let isInValid = false;
  for (const key in contract) {
    if (key === 'tenantId' || key === 'identifier') {
      isInValid = runValidations({
        value: contract[key],
      });

      if (isInValid) return isInValid;
    } else if (key === 'startDate' || key === 'endDate') {
      const startDate = contract.startDate;
      const endDate = contract.endDate;

      isInValid = runValidations({
        value: contract[key],
        otherValidations:
          key === 'startDate' ? contractStartDateValidations(endDate) : contractEndDateValidations(startDate),
      });

      if (isInValid) return isInValid;
    } else if (key === 'landlord') {
      isInValid = runValidations({
        value: contract[key].id,
      });

      if (isInValid) return isInValid;
    } else if (key === 'premises') {
      for (let i = 0; i < contract.premises.length; i++) {
        const { property, floorIds } = contract.premises[i];

        // check that a property has been selected
        isInValid = runValidations({
          value: property,
        });

        if (isInValid) return isInValid;

        for (let j = 0; j < floorIds.length; j++) {
          const { id, floorPortion, spaceIds } = floorIds[j];

          // check that a floor has been selected
          isInValid = runValidations({
            value: id,
          });

          if (isInValid) return isInValid;

          // check that units/licensable spaces have been selected
          isInValid = runValidations({
            value: spaceIds,
          });

          if (isInValid) return isInValid;

          // check that floor portion has been selected
          isInValid = runValidations({
            value: floorPortion,
          });

          if (isInValid) return isInValid;
        }
      }
    } else if (key === 'rent') {
      const { type, periods } = contract.rent;
      isInValid = runValidations({
        value: type,
      });

      if (isInValid) return isInValid;

      for (let i = 0; i < periods.length; i++) {
        const {
          description: { endDate, startDate, monthlyBaseRent, monthlyEffectiveRent },
          freePeriod: { hasRentFreePeriods, freePeriods },
          review,
        } = periods[i];

        const descriptionObj = {
          endDate,
          monthlyBaseRent,
          monthlyEffectiveRent,
          startDate,
        };

        for (const key in descriptionObj) {
          const type = review ? review.type : null;
          const isOpenMarketRent = i > 0 && type === RentReviewType.OpenMarketUpDown;

          if (isOpenMarketRent && key === 'monthlyBaseRent') {
            const currentIndex = i;

            isInValid = validateMonthlyBaseRentForOpenMarket(periods, currentIndex);
          } else {
            isInValid = runValidations({
              // @ts-ignore
              value: descriptionObj[key],
            });
          }

          if (isInValid) return isInValid;
        }

        if (hasRentFreePeriods) {
          for (let i = 0; i < freePeriods.length; i++) {
            const freePeriodObj = {
              endDate: freePeriods[i].endDate,
              startDate: freePeriods[i].startDate,
            };

            for (const key in freePeriodObj) {
              isInValid = runValidations({
                // @ts-ignore
                value: freePeriodObj[key],
                otherValidations:
                  key === 'startDate'
                    ? rentFreePeriodStartDateValidations(startDate, freePeriodObj.endDate)
                    : rentFreePeriodEndDateValidations(endDate, freePeriodObj.startDate),
              });

              if (isInValid) return isInValid;
            }
          }
        }

        if (review) {
          const { cap, collar, date, increment, rent, type } = review;
          let reviewObj: any = { date, type };

          if (type === RentReviewType.Increment) {
            reviewObj = { ...reviewObj, increment };
          } else if (type === RentReviewType.OpenMarketUpDown) {
            reviewObj = { ...reviewObj, cap, collar };
          } else if (type === RentReviewType.New) {
            reviewObj = { ...reviewObj, rent };
          }

          for (const key in reviewObj) {
            if (key === 'date') {
              const {
                description: { startDate: startDateOfPreviousPeriod },
              } = periods[i - 1];
              isInValid = runValidations({
                value: reviewObj[key],
                otherValidations: reviewStartDateValidations(startDateOfPreviousPeriod, endDate),
              });
            } else if (key === 'cap' || key === 'collar') {
              isInValid = runValidations({
                value: reviewObj[key],
                otherValidations:
                  key === 'collar' ? rentReviewCollarValidations(cap) : rentReviewCapValidations(collar),
              });
            } else {
              isInValid = runValidations({
                value: reviewObj[key],
              });
            }

            if (isInValid) return isInValid;
          }
        }
      }
    } else if (key === 'security') {
      const {
        type,
        amount: { bankGuaranteeAmount, depositCashAmount },
      } = contract.security;

      isInValid = runValidations({
        value: type,
      });

      if (isInValid) return isInValid;

      if (type === ContractSecurityType.Bank) {
        isInValid = runValidations({
          value: bankGuaranteeAmount,
        });

        if (isInValid) return isInValid;
      } else if (type === ContractSecurityType.Cash) {
        isInValid = runValidations({
          value: depositCashAmount,
        });

        if (isInValid) return isInValid;
      } else if (type === ContractSecurityType.Both) {
        const bankIsInvalid = runValidations({
          value: bankGuaranteeAmount,
        });

        if (bankIsInvalid) return bankIsInvalid;

        const cashIsInvalid = runValidations({
          value: depositCashAmount,
        });

        if (cashIsInvalid) return cashIsInvalid;
      }
    } else if (key === 'termination') {
      const { terminationDate, terminationDescription, terminationReason } = contract.termination;

      if (terminationDate) {
        isInValid = runValidations({
          value: terminationDescription,
        });

        if (isInValid) return isInValid;

        isInValid = runValidations({
          value: terminationReason,
        });

        if (isInValid) return isInValid;
      }
    }
  }

  return false;
};

const getIndexOfRentError = (rent: any) => {
  const { periods } = rent;
  let isInValid = false;

  for (let i = 0; i < periods.length; i++) {
    const {
      description: { endDate, monthlyBaseRent, startDate },
      freePeriod: { hasRentFreePeriods, freePeriods },
      review,
    } = periods[i];

    const descriptionObj = { endDate, monthlyBaseRent, startDate };

    for (const key in descriptionObj) {
      const type = review ? review.type : null;
      const isOpenMarketRent = i > 0 && type === RentReviewType.OpenMarketUpDown;

      if (isOpenMarketRent && key === 'monthlyBaseRent') {
        const currentIndex = i;

        isInValid = validateMonthlyBaseRentForOpenMarket(periods, currentIndex);
      } else {
        isInValid = runValidations({
          // @ts-ignore
          value: descriptionObj[key],
        });
      }

      if (isInValid) return i;
    }

    if (review) {
      const { cap, collar, date, increment, rent, type } = review;
      let reviewObj: any = { date, type };

      if (type === RentReviewType.Increment) {
        reviewObj = { ...reviewObj, increment };
      } else if (type === RentReviewType.OpenMarketUpDown) {
        reviewObj = { ...reviewObj, cap, collar };
      } else if (type === RentReviewType.New) {
        reviewObj = { ...reviewObj, rent };
      }

      for (const key in reviewObj) {
        if (key === 'date') {
          const {
            description: { startDate: startDateOfPreviousPeriod },
          } = periods[i - 1];

          isInValid = runValidations({
            value: reviewObj[key],
            otherValidations: reviewStartDateValidations(startDateOfPreviousPeriod, endDate),
          });
        } else if (key === 'cap' || key === 'collar') {
          isInValid = runValidations({
            value: reviewObj[key],
            otherValidations: key === 'collar' ? rentReviewCollarValidations(cap) : rentReviewCapValidations(collar),
          });
        } else {
          isInValid = runValidations({
            value: reviewObj[key],
          });
        }

        if (isInValid) return i;
      }
    }

    if (hasRentFreePeriods) {
      for (let j = 0; j < freePeriods.length; j++) {
        const freePeriodObj = {
          endDate: freePeriods[j].endDate,
          startDate: freePeriods[j].startDate,
        };

        for (const key in freePeriodObj) {
          isInValid = runValidations({
            // @ts-ignore
            value: freePeriodObj[key],
            otherValidations:
              key === 'startDate'
                ? rentFreePeriodStartDateValidations(startDate, freePeriodObj.endDate)
                : rentFreePeriodEndDateValidations(endDate, freePeriodObj.startDate),
          });

          if (isInValid) return i;
        }
      }
    }
  }
};

export const giveUsersErrorFeedbackInContractForm = (dispatch: Dispatch, func: Function) => {
  dispatch(func({ value: true }));
  setTimeout(() => dispatch(func({ value: false })), 0);
};

const scrollToErrorInContractFormFunc = (
  dispatch: Dispatch,
  setTabOfErrorInRentPeriod: ActionCreatorWithPayload<any, string>,
  rentObj: any,
  makeAllFormInputShowErrorIfAny: any,
  incompleteFields: any,
) => {
  const errors = document.getElementsByClassName('input-validation-message error');

  if (errors.length > 0) {
    if (incompleteFields.length !== 0) {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      errors[0].scrollIntoView();
      window.scrollBy(0, -200);
    }
  } else {
    const rentPeriodSection = document.getElementById('rentSection');
    if (rentPeriodSection) rentPeriodSection.scrollIntoView();

    dispatch(
      setTabOfErrorInRentPeriod({
        activeIndex: getIndexOfRentError(rentObj),
      }),
    );

    giveUsersErrorFeedbackInContractForm(dispatch, makeAllFormInputShowErrorIfAny);
  }
};

export const scrollToErrorInContractForm = (
  dispatch: Dispatch,
  setTabOfErrorInRentPeriod: ActionCreatorWithPayload<any, string>,
  rentObj: any,
  makeAllFormInputShowErrorIfAny: ActionCreatorWithPayload<any, string>,
  incompleteFields: any,
) => {
  setTimeout(
    () =>
      scrollToErrorInContractFormFunc(
        dispatch,
        setTabOfErrorInRentPeriod,
        rentObj,
        makeAllFormInputShowErrorIfAny,
        incompleteFields,
      ),
    200,
  );
};

export const rentFieldsValidation = (periods: any, i: number) => {
  const { description, freePeriod, review } = periods;

  let checkError = false;
  const { startDate, endDate, monthlyBaseRent, monthlyEffectiveRent } = description;
  if (
    (!checkNotEmpty(startDate) ||
      !checkNotEmpty(endDate) ||
      !checkNotEmpty(monthlyBaseRent) ||
      !checkNotEmpty(monthlyEffectiveRent)) &&
    i === 1
  ) {
    checkError = true;
  } else {
    if (!checkNotEmpty(monthlyEffectiveRent) && i !== 1) {
      checkError = true;
    }

    if (!checkError && review !== null && i !== 1) {
      const { type, cap, collar, increment, rent } = review;
      if (type !== null) {
        if (type === RentReviewType.OpenMarketUpDown) {
          if (!checkNotEmpty(cap) || !checkNotEmpty(collar)) {
            checkError = true;
          }
        } else if (type === RentReviewType.Increment) {
          if (!checkNotEmpty(increment)) {
            checkError = true;
          }
        } else if (type === RentReviewType.New) {
          if (!checkNotEmpty(rent)) {
            checkError = true;
          }
        }
      } else {
        checkError = true;
      }
    }
    if (!checkError) {
      const freePeriodArray = freePeriod.freePeriods;
      for (let i = 0; i < freePeriodArray.length; i++) {
        const { startDate, endDate } = freePeriodArray[i];
        if (!checkNotEmpty(startDate) || !checkNotEmpty(endDate)) {
          checkError = true;
          break;
        }
      }
    }
  }
  return checkError;
};

export const checkInvalidFieldsInContract = (contract: any) => {
  const { landlord, tenantId, startDate, endDate, identifier, premises, rent, security, termination } = contract;

  let partiesTermInvalidFields = [],
    generalFields = [],
    premisesFields = [],
    rentFields = [],
    securityFields = [],
    terminationFields = [];

  // Parties / Term Fields
  if (!checkNotEmpty(landlord.id)) {
    partiesTermInvalidFields.push('Landlord');
  }
  if (!checkNotEmpty(tenantId)) {
    partiesTermInvalidFields.push('Tenant');
  }
  if (!checkNotEmpty(startDate)) {
    partiesTermInvalidFields.push('Start date');
  }
  if (!checkNotEmpty(endDate)) {
    partiesTermInvalidFields.push('End date');
  }

  // General
  if (!checkNotEmpty(identifier)) {
    generalFields.push('Identifier');
  }

  // Premises Fields
  for (let i = 0; i < premises.length; i++) {
    const { floorIds, property } = premises[i];
    if (property === null) {
      premisesFields.push('Property');
    }
    for (let j = 0; j < floorIds.length; j++) {
      const { id, spaceIds, floorPortion } = floorIds[j];
      if (!checkNotEmpty(id) || spaceIds.length === 0 || floorPortion === '') {
        premisesFields.push(`Floor ${j + 1}`);
      }
    }
  }

  // Rent Fields
  for (let i = 1; i <= rent.periods.length; i++) {
    const checkError = rentFieldsValidation(rent.periods[i - 1], i);
    if (checkError) {
      rentFields.push(`Rent period ${i}`);
    }
  }

  // Secuity Fields
  const {
    type,
    amount: { bankGuaranteeAmount, depositCashAmount },
  } = security;
  if (checkEmpty(type)) {
    securityFields.push('Security type');
  }

  if (type === ContractSecurityType.Bank || type === ContractSecurityType.Both) {
    if (checkEmpty(bankGuaranteeAmount)) {
      securityFields.push('Bank gurantee amount');
    }
  }

  if (type === ContractSecurityType.Cash || type === ContractSecurityType.Both) {
    if (checkEmpty(depositCashAmount)) {
      securityFields.push('Deposit gurantee amount');
    }
  }

  // Termination Fields
  const { terminationDate, terminationDescription, terminationReason } = termination;

  if (terminationDate !== null) {
    if (terminationReason === null) {
      terminationFields.push('Termination reason');
    }
    if (terminationDescription === null) {
      terminationFields.push('Termination description');
    }
  }

  // When no field is empty
  if (
    partiesTermInvalidFields.values.length === 0 &&
    generalFields.length === 0 &&
    premisesFields.length === 0 &&
    rentFields.length === 0 &&
    securityFields.length === 0 &&
    terminationFields.length === 0
  ) {
    return [];
  }

  return [
    {
      title: '1. Parties / Term:',
      values: partiesTermInvalidFields.join(', '),
    },
    {
      title: '2. General:',
      values: generalFields,
    },
    {
      title: '3. Premises:',
      values: premisesFields.join(', '),
    },
    {
      title: '4. Rent:',
      values: rentFields.join(', '),
    },
    {
      title: '5. Security:',
      values: securityFields.join(', '),
    },
    {
      title: '9. Exercised Surrender or Termination Date:',
      values: terminationFields.join(', '),
    },
  ];
};
