import { CValuationReport } from 'common/_classes/valuationReport2';
import SortOrder from 'common/model/SortOrder';
import { checkIfNotDefined } from 'utils/tsHelper';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { applyBooleanFilter } from './components/RowTemplate/RowFilterPopup/RowFilterContent/BooleanFilter';
import { applyDateFilter } from './components/RowTemplate/RowFilterPopup/RowFilterContent/DateFilter';
import { applyDurationFilter } from './components/RowTemplate/RowFilterPopup/RowFilterContent/DurationFilter';
import { applyListFilter } from './components/RowTemplate/RowFilterPopup/RowFilterContent/ListFilter';
import { applyNumbersFilter } from './components/RowTemplate/RowFilterPopup/RowFilterContent/NumberFilter';
import { applyStringFilter } from './components/RowTemplate/RowFilterPopup/RowFilterContent/StringFilter';
import { DataType, RowAction, RowProps, getRowAction } from './components/RowTemplate/RowProps';

/**
 * Apply Global Filters to contents
 * @param contents
 * @param rows
 * @param rowActions
 * @returns
 */
export const applyGlobalFilters = (
  contents: CValuationReport[],
  rows: RowProps[],
  rowActions: RowAction[],
): CValuationReport[] => {
  const filteredContent = contents.filter((content, index) =>
    index === 0 ? true : applyRowFilters(rows, content, rowActions),
  );

  // If a row with sort order exists, sort!
  const rowActionWithSortOrder = rowActions.find(row => !!row.sortOrder);

  if (rowActionWithSortOrder) {
    const rowConfig = rows.find(row => row.id === rowActionWithSortOrder.id);

    if (rowConfig) return applySorting(filteredContent, rowConfig, rowActionWithSortOrder.sortOrder);
  }

  return filteredContent;
};

export const applyRowFilters = (rows: RowProps[], content: CValuationReport, rowActions: RowAction[]): boolean => {
  let output: boolean = true;
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    const rowAction = getRowAction(row, rowActions);
    output = applyFilter(row, content, rowAction);
    if (!output) break;
  }
  return output;
};

const applyFilter = (row: RowProps, content: CValuationReport, rowAction?: RowAction): boolean => {
  if (!rowAction || checkIfNotDefined(rowAction.filterValue)) return true;

  if (row.filterFunction) {
    return row.filterFunction(row, content, rowAction);
  }

  switch (row.dataType) {
    case DataType.STRING:
      return applyStringFilter(row, content, rowAction);
    case DataType.NUMBER:
      return applyNumbersFilter(row, content, rowAction);
    case DataType.DECIMAL:
      return applyNumbersFilter(row, content, rowAction);
    case DataType.DATE:
      return applyDateFilter(row, content, rowAction);
    case DataType.DURATION:
      return applyDurationFilter(row, content, rowAction);
    case DataType.BOOLEAN:
      return applyBooleanFilter(row, content, rowAction);
    case DataType.LIST:
      return applyListFilter(row, content, rowAction);
  }
  return true;
};

export const ascSort = (
  contents: CValuationReport[],
  field: string | string[],
  rowConfig: RowProps,
): CValuationReport[] => {
  return contents.sort((a, b) => {
    let fieldA = getNestedValue(a, field);
    let fieldB = getNestedValue(b, field);

    if (rowConfig.dataType === DataType.STRING) {
      fieldA = fieldA.trim().toLowerCase();
      fieldB = fieldB.trim().toLowerCase();
    }

    if (rowConfig.dataType === DataType.NUMBER) {
      return fieldA - fieldB;
    }

    if (fieldA < fieldB) {
      return -1;
    }

    if (fieldA > fieldB) {
      return 1;
    }

    return 0;
  });
};

export const descSort = (
  contents: CValuationReport[],
  field: string | string[],
  rowConfig: RowProps,
): CValuationReport[] => {
  return contents.sort((a, b) => {
    let fieldA = getNestedValue(a, field);
    let fieldB = getNestedValue(b, field);

    if (rowConfig.dataType === DataType.STRING) {
      fieldA = fieldA.trim().toLowerCase();
      fieldB = fieldB.trim().toLowerCase();
    }

    if (rowConfig.dataType === DataType.NUMBER) {
      return fieldB - fieldA;
    }

    if (fieldA < fieldB) {
      return 1;
    }

    if (fieldA > fieldB) {
      return -1;
    }

    return 0;
  });
};

/**
 * Apply Sorting
 * @param row
 * @param sortOrder
 * @returns
 *
 * Note: the sorting is done on all columns except the first one.
 */
export const applySorting = (
  contracts: CValuationReport[],
  rowConfig: RowProps,
  sortOrder?: SortOrder,
): CValuationReport[] => {
  const firstContract = contracts.shift();
  let sortedContracts: CValuationReport[] =
    sortOrder === SortOrder.Asc
      ? ascSort([...contracts], rowConfig.field!, rowConfig)
      : descSort([...contracts], rowConfig.field!, rowConfig);
  if (firstContract) sortedContracts.unshift(firstContract);
  return sortedContracts;
};
