import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import DateField from 'atoms/FormField/DateInput';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { clearTermination, updateContractTermination } from 'store/contracts/contractDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { CONTRACT_TERMINATION_OPTIONS } from 'utils/UI';
import { checkNotEmpty } from 'utils/tsValidator';
import { Icons } from 'utils/utils-icons';

const KnownFutureSurrenderOrTermination = ({ mode }: ModeProps) => {
  const {
    activeContract: {
      termination: { terminationDate, terminationReason, terminationDescription },
    },
  } = useAppSelector((state: RootState) => state.contractDetail);

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: string) => {
    dispatch(
      updateContractTermination({
        key,
        value: key === 'terminationDate' ? value.toString() : value,
      }),
    );
  };

  const clear = () => dispatch(clearTermination());

  const valueExistsForTermination = terminationDate ? true : false;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <DateField
            label="Date"
            fieldKey="terminationDate"
            dataTest="termination-date-input-field"
            value={terminationDate}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            required={valueExistsForTermination}
          />
        </Grid.Column>
        {valueExistsForTermination && mode === MODE_OPTIONS.EDIT && (
          <Grid.Column width={2}>
            <div
              className="clear-btn color-red-soft"
              onClick={() => clear()}
              role="button"
              tabIndex={0}
            >
              <Icon icon={Icons.Cross} />
              clear
            </div>
          </Grid.Column>
        )}
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={12}>
          <SelectField
            label="Reason"
            fieldKey="terminationReason"
            dataTest="termination-reason-input-field"
            value={terminationReason}
            options={CONTRACT_TERMINATION_OPTIONS}
            disabled={mode === MODE_OPTIONS.READ || !valueExistsForTermination}
            required={valueExistsForTermination}
            onChange={onChange}
            runValidation={!checkNotEmpty(terminationReason)}
            validationDependant={!valueExistsForTermination}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={12}>
          <TextAreaField
            label="Description"
            value={terminationDescription}
            dataTest="termination-description-input-field"
            disabled={mode === MODE_OPTIONS.READ || !valueExistsForTermination}
            fieldKey="terminationDescription"
            onChange={onChange}
            required={valueExistsForTermination}
            runValidation={!checkNotEmpty(terminationDescription)}
            validationDependant={!valueExistsForTermination}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default KnownFutureSurrenderOrTermination;
