import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_CONTRACT_TIMELINE } from 'common/api/contracts';
import { getClientType } from 'utils/utils-authentication';

export const getContractTimeline = createAsyncThunk(
  'stackingPlan/getContractTimeline',
  async ({ contractId }: { contractId: string }) => {
    const response = await getClientType().query(GET_CONTRACT_TIMELINE(contractId));
    return response.data;
  },
);
