import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import { setUnsavedModalStatus } from 'store/contracts/contractDetailSlice';
import { checkIfUnsavedContract } from 'utils/tsHelper';
import ContractTabs from './ContractTabs';

const ContractDetails = () => {
  const { contractIsLoading, identifier } = useAppSelector((state: RootState) => {
    return {
      identifier: (() => {
        const identifier1 = state.contractDetail.contractRevenueBase.contractIdentifier;
        const identifier2 = state.contractDetail.activeContract.identifier;

        return identifier1 && identifier2 ? identifier2 : identifier1 || identifier2;
      })(),
      contractIsLoading: state.contractDetail.contractIsLoading,
      tabIndex: state.contractDetail.activeTabIndex,
    };
  });

  const getPageHeaderProps = (): PageHeaderProps => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { activeContract, oldContractVersion } = useAppSelector((state: RootState) => state.contractDetail);
    const saveContract = () => {
      const url = '/contracts/dashboard';
      if (checkIfUnsavedContract(activeContract, oldContractVersion)) {
        dispatch(setUnsavedModalStatus({ status: true, url }));
        return;
      }
      navigate(url ? url : '');
    };
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Contracts',
        url: '/contracts/dashboard',
        onClick: () => saveContract(),
      },
      {
        title: identifier || '',
      },
    ];
    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };
  return (
    <PageLayout
      pageSpinner={contractIsLoading}
      pageHeader={getPageHeaderProps()}
      className="contract-page"
    >
      <ContractTabs />
    </PageLayout>
  );
};

export default ContractDetails;
