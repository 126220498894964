import { Icon } from '@iconify/react';
import SortOrder from 'common/model/SortOrder';
import { conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { getNestedValue } from 'utils/utils-nested-object-update';
import RowFilterIcon from '../RowFilterIcon';
import { RowAction, RowProps, getRowAction } from './RowProps';

interface RowTemplateProps {
  rowConfigs: RowProps[];
  rowActions: RowAction[];
  contents: any[];
  onOpenRowFilterModal: Function;
  onSortingChange: (rowConfig: RowProps, sortOrder: SortOrder) => void;
}

/**
 * Row Template for row items
 * Contains value, filters,sorting icons
 * @returns
 */
function RowTemplate({
  rowConfigs,
  contents,
  onOpenRowFilterModal,
  onSortingChange,
  rowActions,
}: RowTemplateProps): JSX.Element {
  const getSortingElement = (rowConfig: RowProps, rowAction?: RowAction): JSX.Element => {
    const icon = () => {
      switch (rowAction?.sortOrder) {
        case SortOrder.Asc:
          return (
            <Icon
              className="m-l-xs"
              icon={Icons.DescSort}
              onClick={() => onSortingChange(rowConfig, SortOrder.Desc)}
            />
          );

        case SortOrder.Desc:
          return (
            <Icon
              className="m-l-xs"
              icon={Icons.AscSort}
              onClick={() => onSortingChange(rowConfig, SortOrder.Asc)}
            />
          );

        default:
          return (
            <Icon
              className="m-l-xs"
              icon={Icons.AllSort}
              onClick={() => onSortingChange(rowConfig, SortOrder.Asc)}
            />
          );
      }
    };

    return <span className={`sort-icon ${conditionalClassName(rowAction?.sortOrder, 'active')}`}>{icon()}</span>;
  };

  return (
    <>
      {rowConfigs.map((rowConfig, index) => {
        const rowAction = getRowAction(rowConfig, rowActions);
        return (
          <tr
            className={rowConfig.isHeading ? 'row-header' : 'row-sub-header'}
            key={`rows_${rowConfig.label}_${rowConfig.id}-${index}`}
          >
            {/* 1st column, with name of the row and the filtering/sorting buttons */}
            <td className={`font-600 ${rowConfig.columnClass}`}>
              <div className="d-flex align-center">
                <span>{rowConfig.label}</span>

                {!rowConfig.isHeading ? (
                  <span className="action-icons d-inline-flex align-center flex-gap-xs">
                    {!rowConfig.hideFilter ? (
                      <RowFilterIcon
                        onClick={() => onOpenRowFilterModal(rowAction, rowConfig)}
                        value={rowAction?.filterValue}
                        dataType={rowConfig.dataType}
                      />
                    ) : (
                      <></>
                    )}

                    {!rowConfig.hideSorting ? getSortingElement(rowConfig, rowAction) : <></>}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </td>
            {/* all other columns for the body with the contents */}
            {contents.map((content, index) =>
              rowConfig.isHeading ? (
                <td
                  className="font-600"
                  key={`content.id-${index}`}
                ></td>
              ) : (
                <td
                  className={rowConfig.columnClass}
                  key={`${rowConfig.label}_${rowConfig.id}_${content.id}-${index}`}
                >
                  {/* select custom body if it exist, else the field*/}
                  {rowConfig.body
                    ? rowConfig.body(content)
                    : rowConfig.field
                      ? getNestedValue(content, rowConfig.field)
                      : '---'}
                </td>
              ),
            )}
          </tr>
        );
      })}
    </>
  );
}

export default RowTemplate;
