import { Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import EditorField from 'atoms/FormField/EditorField';
import { updateActiveTranslateToContract } from 'store/translatorsToContract/translatorDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import TranslateToContractDefinition from './TranslateToContractDefinition';

const TranslatorToContractContent = ({ mode }: ModeProps): JSX.Element => {
  const { activeTranslateToContract } = useAppSelector((state: RootState) => state.translatorDetail);

  const onChange = (key: string, value: string) => updateActiveTranslateToContract({ key, value });

  return (
    <Form>
      <Grid>
        <Grid.Row className="pm-none">
          <Grid.Column
            width={8}
            className="p-l-none"
          >
            <SectionComponent title="1. Translate to contract definition">
              <TranslateToContractDefinition mode={mode} />
            </SectionComponent>
          </Grid.Column>

          <Grid.Column width={8}>
            <SectionComponent
              title={
                <>
                  2. Function <span className="required-indicator-title">*</span>
                </>
              }
            >
              <EditorField
                onChange={onChange}
                value={activeTranslateToContract.function}
                placeholder="Type here..."
                disabled={mode === MODE_OPTIONS.READ}
                fieldKey="function"
                height="500"
                codeSample={true}
                code={true}
              />
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default TranslatorToContractContent;
