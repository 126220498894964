import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import { deleteContract, exportContract, importContract, listContracts } from 'common/api/contracts';
import { listLandlordAndTenantEntities } from 'common/api/entities';
import { DROPDOWN_OPTION } from 'utils/UI';
import { autoDownloadExportedFile, dataURLtoFile } from 'utils/tsHelper';

const initialState = {
  contractsList: [],
  landlordEntitiesOptions: [] as DROPDOWN_OPTION[],
  tenantEntitiesOptions: [] as DROPDOWN_OPTION[],
  isLoading: false,
  search: null,
  isSearching: false,
  importedContract: null,
  exportSuccess: false,
  exportFail: false,
  isExporting: false,
};

const contractsListingSlice = createSlice({
  name: 'contractsListing',
  initialState,
  reducers: {
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    clearImportedContract: state => {
      state.importedContract = null;
    },
  },
  extraReducers: builder => {
    // listLandlordAndTenantEntities
    builder.addCase(listLandlordAndTenantEntities.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listLandlordAndTenantEntities.fulfilled, (state, action) => {
      const landlordEdges = action.payload?.data?.landlord?.edges ?? [];
      const landlordsList = landlordEdges.map((element: any) => element.node);
      const tenantsEdges = action.payload?.data?.tenant?.edges ?? [];
      const tenantsList = tenantsEdges.map((element: any) => element.node);

      state.landlordEntitiesOptions = landlordsList.map((item: any, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: item.name,
          value: item.id,
        };
        return result;
      });

      state.tenantEntitiesOptions = tenantsList.map((item: any, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: item.name,
          value: item.id,
        };
        return result;
      });

      state.isLoading = false;
    });
    builder.addCase(listLandlordAndTenantEntities.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('listLandlordAndTenantEntities API request rejected');
    });

    // listContracts
    builder.addCase(listContracts.pending, (state, action) => {
      const { searchValue } = action.meta.arg;
      if (searchValue || searchValue === '') {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });

    builder.addCase(listContracts.fulfilled, (state, action) => {
      const contracts = action.payload.data.listContracts;
      state.contractsList = contracts?.edges ? contracts.edges.map((element: any) => element.node) : [];
      state.search = null;
      state.isLoading = false;
      state.isSearching = false;
    });

    builder.addCase(listContracts.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.error(action.error);
      toast.error('listContracts API request rejected');
    });

    // deleteContract
    builder.addCase(deleteContract.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteContract.fulfilled, (state, action) => {
      state.contractsList = state.contractsList.filter((obj: any) => obj.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Contract deleted successfully');
    });
    builder.addCase(deleteContract.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('deleteContract API request rejected');
    });

    // importContract
    builder.addCase(importContract.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(importContract.fulfilled, (state, action) => {
      const { importContract } = action.payload.data;
      state.importedContract = importContract;
      state.isLoading = false;
      toast.success(
        `Contract(s) ${importContract.simulation ? 'import simulation successful' : 'imported successfully'} `,
      );
    });
    builder.addCase(importContract.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      if (action.error.message) {
        toast.error(action.error.message);
      } else {
        toast.error('importContract API request rejected');
      }
    });

    // exportContract
    builder.addCase(exportContract.pending, state => {
      state.isExporting = true;
      state.exportSuccess = false;
      state.exportFail = false;
    });
    builder.addCase(exportContract.fulfilled, (state, action) => {
      const {
        payload: {
          data: { exportContract },
        },
        meta: {
          arg: { landlordName },
        },
      } = action;

      const formattedDate = moment().format('DD - MM - YYYY');

      const fileName = landlordName
        ? `${landlordName.toLowerCase()} - contracts - ${formattedDate}.xlsx`
        : `Contracts - ${formattedDate}.xlsx`;

      autoDownloadExportedFile(dataURLtoFile(exportContract, fileName));
      state.exportSuccess = true;
      state.isExporting = false;
      toast.success('Tenant(s) exported successfully');
    });
    builder.addCase(exportContract.rejected, (state, action) => {
      state.exportFail = true;
      state.isExporting = false;
      console.error(action.error);
      toast.error('exportContract API request rejected');
    });
  },
});

export const { updateSearch, clearImportedContract } = contractsListingSlice.actions;

export default contractsListingSlice.reducer;
