import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import TabContainer from 'components/TabContainer';
import ValidDocumentStatus from 'common/model/ValidDocumentStatus';
import { DocumentIntendedToBeUploaded } from 'common/api/miscellaneous';
import { HistoryDocumentsProps, UserProps } from 'common/api/transactions';
import { getCurrentDateAndTime } from 'utils/utils-date';
import SendToTenant from './Boxes/SendToTenant';
import Signature from './Boxes/Signature';
import './Doc.scss';

const DocHistory = ({ documentTypeId, title }: { documentTypeId: string; title?: string }) => {
  // Status of Accordians inside the Tab
  const { activeTransaction } = useAppSelector((state: RootState) => state.transactionDetail);
  const { historyDocuments } = activeTransaction;
  const index = historyDocuments.findIndex((obj: HistoryDocumentsProps) => obj.documentTypeId === documentTypeId);

  const { history } = historyDocuments[index];

  const GetBox = ({
    user,
    date,
    document,
    note,
    pendingChanges,
    version,
    index,
    status,
  }: {
    user: UserProps | undefined;
    date: string;
    document: DocumentIntendedToBeUploaded | null;
    note: string | null;
    pendingChanges: boolean;
    version: number | undefined;
    index: number;
    status: ValidDocumentStatus;
  }) => {
    if (status === ValidDocumentStatus.SentToTenant) {
      return (
        <SendToTenant
          user={user}
          date={date}
          document={document}
          note={note}
          pendingChanges={pendingChanges}
          version={version}
          index={index}
        />
      );
    } else {
      return (
        <Signature
          documentTypeId={documentTypeId}
          user={user}
          date={date}
          document={document}
          signed={true}
        />
      );
    }
  };

  return (
    <TabContainer
      tabTitle={title}
      nested={true}
    >
      <div className={`doc-boxes move-box-down m-t-xs`}>
        <div className="docs-box">
          {[...history]
            .reverse()
            .map(({ user, date, document, note, pendingChanges, version, status }, index: number) => (
              <GetBox
                key={`history-${index}`}
                pendingChanges={pendingChanges}
                date={getCurrentDateAndTime(date)}
                note={note}
                user={user}
                version={version}
                document={document}
                index={index}
                status={status}
              />
            ))}
        </div>
      </div>
      <div className="hidden-line"></div>
    </TabContainer>
  );
};

export default DocHistory;
