import moment from 'moment';
import { Fragment } from 'react';
import { orderBy } from 'lodash';
import Event from 'common/model/Event';
import { ContractTimeline, ContractTimelineEvent, ContractTimelinePeriod } from 'common/api/contracts';
import { getContractTimelineEventColorName } from 'utils/utils-contract';
import TimeLineItem from '.';
import PeriodLine from './PeriodLine';
import TodayIndicator from './TodayIndicator';

const MapTimelineItems = (timeline: ContractTimeline): JSX.Element[] => {
  if (!timeline) return [];

  const { events, periods } = timeline;

  if (!events.length && !periods.length) return [];

  // GET POSITIONS (IN PERCENTAGE) WHERE ALL DATES WILL BE ON X_AXIS
  const datePosition: { [key: string]: string } = {};

  const allDates: string[] = [];

  const { date: timelineStart } = events.filter(({ event }) => Event.Occupation === event)[0];

  const { date: timelineEnd } = events.filter(({ event }) => Event.Expiry === event)[0];

  const today = moment(new Date()).format('YYYY-MM-DD');

  const todayIsInTimelineRange =
    moment(today).isSameOrAfter(timelineStart) && moment(today).isSameOrBefore(timelineEnd);

  const defaultValue = '-1';

  events.forEach(({ date }) => {
    if (!datePosition[date]) {
      datePosition[date] = defaultValue;
      allDates.push(date);
    }
  });

  periods.forEach(({ startDate, endDate }) => {
    if (!datePosition[startDate]) {
      datePosition[startDate] = defaultValue;
      allDates.push(startDate);
    }

    if (!datePosition[endDate]) {
      datePosition[endDate] = defaultValue;
      allDates.push(endDate);
    }
  });

  if (todayIsInTimelineRange && !datePosition[today]) {
    datePosition[today] = defaultValue;
    allDates.push(today);
  }

  const orderedDates = orderBy(allDates, date => new Date(date), 'asc');

  const spaceBetween = orderedDates.length - 1;
  const spacePercentage = 100 / spaceBetween;

  orderedDates.forEach((date, index) => {
    if (index === 0) {
      datePosition[date] = '0%';
    } else if (index === orderedDates.length - 1) {
      datePosition[date] = '100%';
    } else {
      datePosition[date] = `${index * spacePercentage}%`;
    }
  });

  let eventsIndex = 0;
  let periodsIndex = 0;

  const elements: JSX.Element[] = [];

  const orderedPeriod = orderBy(periods, ({ endDate }) => new Date(endDate), 'asc');

  const periodLineLevels: number[] = [];

  // MAP PERIODS
  while (periodsIndex < orderedPeriod.length) {
    const { description, endDate, startDate }: ContractTimelinePeriod = orderedPeriod[periodsIndex];

    let level = 0;

    if (periodsIndex > 0) {
      const prev = orderedPeriod[periodsIndex - 1];
      let dateOverlaps = false;

      const { startDate: prevStartDate, endDate: prevEndDate } = prev;

      dateOverlaps =
        dateOverlaps ||
        moment(startDate).isSameOrBefore(prevStartDate) ||
        moment(startDate).isSameOrBefore(prevEndDate);

      if (dateOverlaps) {
        const lastValue = periodLineLevels[periodLineLevels.length - 1];
        level = lastValue + 1;
      }
    }

    periodLineLevels.push(level);

    elements.push(
      <PeriodLine
        left={datePosition[startDate]}
        right={datePosition[endDate]}
        level={level}
        startDate={new Date(startDate)}
        endDate={new Date(endDate)}
      />,
    );

    periodsIndex++;
  }

  const maxLevel = periodLineLevels.length > 0 ? Math.max(...periodLineLevels) : 0;

  // MAP EVENTS
  while (eventsIndex < events.length) {
    const { description, date, event }: ContractTimelineEvent = events[eventsIndex];

    const color = getContractTimelineEventColorName(event);

    elements.push(
      <TimeLineItem
        color={color}
        left={datePosition[date]}
        description={description}
        eventType={event}
        date={new Date(date)}
        maxLevel={maxLevel}
      />,
    );

    eventsIndex++;
  }

  // ADD TODAY INDICATOR
  if (todayIsInTimelineRange) {
    elements.push(
      <TodayIndicator
        today={new Date(today)}
        left={datePosition[today]}
        maxLevel={maxLevel}
      />,
    );
  }

  return elements.map((element, index) => <Fragment key={index}>{element}</Fragment>);
};

export default MapTimelineItems;
