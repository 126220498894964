import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientType } from 'utils/utils-authentication';
import { StackingPlanProps } from '.';

export interface GetStackingPlanQueryVariables {
  propertyId: string;
  date: string;
}

const GET_STACKING_PLANS = (propertyId: string, date: string) => {
  if (!propertyId) {
    throw new Error('Get Stacking Plans: Property ID could not be determined. Query aborted.');
  }

  const variables: GetStackingPlanQueryVariables = {
    propertyId,
    date,
  };

  return {
    query: gql`
      query ($propertyId: ID!, $date: Date!) {
        getStackingPlan(propertyId: $propertyId, date: $date) {
          date
          propertyId
          floorsNumber
          buildingNumberOfUndergroundFloors
          floors {
            id
            index
            name
            area
          }
          blocks {
            area
            tags {
              availableDate
            }
            contractId
            effectiveRent
            headlineRent
            tenantId
            floorId
            tenantName
            futureContracts {
              contractId
              tenantId
            }
            unitIds
          }
        }
      }
    `,
    variables,
  };
};

/**
 * Get Property's Stacking Plan
 */
export const getStackingPlans = createAsyncThunk(
  'stackingPlan/getStackingPlans',
  async ({ propertyId, date }: StackingPlanProps) => {
    const response = await getClientType().query(GET_STACKING_PLANS(propertyId, date));
    return response.data;
  },
);
