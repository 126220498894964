import { useAppDispatch } from 'hooks';
import { ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { EntityCreatorTypes, deleteEntity } from 'common/api/entities';
import { Icons } from 'utils/utils-icons';
import { deleteNetwork } from 'common/api/entities/deleteNetwork';
import Network from 'common/model/Network';

interface LandlordEntitiesRowActionButtonsParams {
  rowId: string;
  landlordNetworks: Network[];
  onViewDetails: ViewDetailsProps;
}

export const LandlordEntitiesRowActionButtons = ({ rowId, landlordNetworks, onViewDetails }: LandlordEntitiesRowActionButtonsParams) => {
  const dispatch = useAppDispatch();

  const onDelete = (id: string) => {
    dispatch(deleteEntity({ id, type: EntityCreatorTypes.Landlord }));
    if (landlordNetworks && landlordNetworks.length) {
      dispatch(deleteNetwork({ id: landlordNetworks[0].id }));
    }
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Details',
      onClick: () => onViewDetails(rowId),
      dataTest: 'View Details',
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete',
      onClick: () => onDelete(rowId),
      isPopup: true,
      popupText: 'Do you really want to delete this Landlord entity?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
