import { createSlice } from '@reduxjs/toolkit';
import CEntity, { IEntityClone } from 'common/_classes/entities';
import { toast } from 'react-toastify';
import { LANDLORD_ENTITY_DETAILS_TABS_OFFSET } from 'views/entities/landlordEntities/EntityDetails/LandlordEntityTabs';
import { TENANT_ENTITY_DETAILS_TABS_OFFSET } from 'views/entities/tenantEntities/EntityDetails/TenantTabs';
import EntityType from 'common/model/EntityType';
import { DocumentsType } from 'common/api/contracts';
import {
  EntityCreatorTypes,
  createEntity,
  getEntity,
  listIndustries,
  listSubsidiaries,
  prepEntityDocsForUpload,
  updateEntity,
} from 'common/api/entities';
import { DROPDOWN_OPTION } from 'utils/UI';
import { getCompleted, getStep, updateUrlParams } from 'utils/utils-actions';
import Entity from 'common/model/Entity';
import { createNetwork } from 'common/api/entities/createNetwork';
import { updateNetwork } from 'common/api/entities/updateNetwork';

// let newEntity = {
//   id: null,
//   address: null,
//   addressLocal: null,
//   businessAddress: null,
//   cardId: null,
//   contacts: [
//     {
//       firstName: '',
//       lastName: '',
//       email: '',
//       phone: '',
//       position: '',
//     },
//   ],
//   details: null,
//   documents: [] as any[],
//   industry: null,
//   identifier: null,
//   legalDomicile: null,
//   logo: null,
//   name: null,
//   nickname: null,
//   overseasRegistrationId: null,
//   passportId: null,
//   registrationCountry: null,
//   registrationId: null,
//   residentialAddress: null,
//   type: null,git stat
// };

let newEntity = CEntity.init();

interface EntityDetailState {
  activeTabIndex: LANDLORD_ENTITY_DETAILS_TABS_OFFSET | TENANT_ENTITY_DETAILS_TABS_OFFSET;
  isLoading: boolean;
  industryListOptions: DROPDOWN_OPTION[];
  selectedSubsidiary: IEntityClone | null;
  [key: string]: any;
}

const initialState: EntityDetailState = {
  activeStep: getStep(),
  activeEntity: CEntity.init(),
  activeEntityType: '',
  industryListOptions: [],
  completedSteps: getCompleted(),
  activeTab: 0,
  isLoading: false,
  activeTabIndex: LANDLORD_ENTITY_DETAILS_TABS_OFFSET.ENTITY_INFORMATION,
  selectedSubsidiary: null,
  subsidiariesListOptions: [],
};

const entityDetailSlice = createSlice({
  name: 'entityDetail',
  initialState,
  reducers: {
    updateViewDetailsTab: (state, action) => {
      state.activeTabIndex = action.payload.tab;
    },
    updateSelectedSubsidiary: (state, action) => {
      state.selectedSubsidiary = action.payload;
    },
    updateActiveStep: (state, action) => {
      const { activeStep, addStep } = action.payload;
      state.activeStep = activeStep;
      if (addStep) {
        const checkStep = state.completedSteps.indexOf(1);
        if (checkStep === -1) {
          state.completedSteps = [1];
        }
      }
      window.scrollTo(0, 0);
      const steps = addStep ? addStep : null;
      updateUrlParams(activeStep, null, steps);
    },
    updateInputKey: (state, action) => {
      const key = action.payload.key;
      const value = action.payload.value;
      const isChild = action.payload?.isChild ?? false;

      const entityClone = isChild ? { ...state.selectedSubsidiary } : { ...state.activeEntity };
      // @ts-ignore
      entityClone[key] = value;

      if (key === 'type') {
        // This is for better UX, so country does not change when type changes
        let data =
          value === EntityType.Individual
            ? [
              {
                key: 'legalDomicile',
                value: state.activeEntity.registrationCountry,
              },
              {
                key: 'registrationCountry',
                value: null,
              },
            ]
            : [
              {
                key: 'registrationCountry',
                value: state.activeEntity.legalDomicile,
              },
              {
                key: 'legalDomicile',
                value: null,
              },
            ];
        // @ts-ignore
        data.forEach(({ key, value }) => (entityClone[key] = value));
      }
      if (isChild) {
        state.selectedSubsidiary = entityClone;
      } else {
        state.activeEntity = entityClone;
      }
    },
    updateContactInputKey: (state, action) => {
      const { key, value, index, isChild = false } = action.payload;
      if (isChild) {
        if(state?.selectedSubsidiary && index > -1 && key){
          // @ts-ignore
          state.selectedSubsidiary.contacts[index][key] = value;
        }
      } else {
        if(state?.activeEntity && index > -1 && key){
          // @ts-ignore
          state.activeEntity.contacts[index][key] = value;
        }
      }
    },
    createNewEntity: (state, action) => {
      const newEntity = CEntity.init();
      state.activeEntity = action.payload?.parent ? { ...newEntity, parentId: action.payload?.parent } : newEntity;
      state.activeStep = 1;
      state.activeTab = 0;
      state.completedSteps = [];
    },
    createContact: state => {
      let newContact = {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        position: null,
      };
      let contacts = state.activeEntity.contacts;
      contacts = [...contacts, newContact];
      state.activeEntity.contacts = contacts;
      state.activeTab = contacts.length - 1;
    },
    deleteContact: (state, action) => {
      const { index } = action.payload;
      if (state.activeEntity.contacts.length !== 1) {
        state.activeEntity.contacts.splice(index, 1);
      }
      if (index === 0) {
        state.activeTab = 0;
      } else {
        state.activeTab = state.activeTab - 1;
      }
    },
    deleteSupportingFiles: (state, action) => {
      const { id } = action.payload;
      state.activeEntity.documents = state.activeEntity.documents.filter((doc: DocumentsType) => doc.id !== id);
    },
    addSupportingFiles: (state, action) => {
      const { files } = action.payload;
      state.activeEntity.documents = [...files, ...state.activeEntity.documents];
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload.tab;
    },
  },
  extraReducers: builder => {
    //createEntity
    builder.addCase(createEntity.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createEntity.fulfilled, (state, action) => {
      const { type, saveAndExit } = action.meta.arg;
      const data = action.payload.data;
      const newEntity = data[`create${type}Entity`];
      state.completedSteps = [1, 2];
      if (!saveAndExit) {
        // TODO : Need to check function
        //  @ts-ignore
        updateUrlParams('3', newEntity.id, state.completedSteps);
      }
      state.activeStep = 3;
      state.activeEntity = newEntity;
      if (type === EntityCreatorTypes.Landlord) {
        const selectedEntity = localStorage.getItem('sericin_selected_entity');
        if (selectedEntity === null) {
          localStorage.setItem('sericin_selected_entity', newEntity.id);
        }
      }
      state.isLoading = saveAndExit ? true : false;
      window.scrollTo(0, 0);
      toast.success('Entity Created Successfully');
    });
    builder.addCase(createEntity.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createEntity API reuqest rejected');
    });

    //getEntity
    builder.addCase(getEntity.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getEntity.fulfilled, (state, action) => {
      const { type, step, isChild } = action.meta.arg;
      const data = action.payload.data;
      const entity = data[`get${type}Entity`];
      if (isChild) {
        state.selectedSubsidiary = new CEntity(entity)
        state.activeTab = 1;
      } else {
        state.activeStep = step ? Number(step) : 1;
        state.activeEntity = entity;
        state.activeEntityType = type;
        state.activeTab = 0;
      }
      state.isLoading = false;
    });
    builder.addCase(getEntity.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getEntity API request rejected');
    });

    //updateEntity
    builder.addCase(updateEntity.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateEntity.fulfilled, (state, action) => {
      const { type, saveAndExit } = action.meta.arg;
      const data = action.payload.data;
      const updatedEntity = data[`update${type}Entity`];
      state.activeStep = state.activeStep === 3 ? state.activeStep : state.activeStep + 1;
      if (!saveAndExit) {
        // TODO need to check
        // @ts-ignore
        updateUrlParams(state.activeStep, null, null);
      }
      state.activeEntity = updatedEntity;
      state.isLoading = saveAndExit ? true : false;
      window.scrollTo(0, 0);
      toast.success(`${type} Entity Updated Successfully`);
    });
    builder.addCase(updateEntity.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateEntity API request rejected');
    });

    //listSubsidiaries
    builder.addCase(listSubsidiaries.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listSubsidiaries.fulfilled, (state, action) => {
      const subsidiariesList = action.payload.data.landlord.edges.map((element: { node: Entity }) => element.node);;
      let dropdownOptions = [];
      let cache = {};
      if (subsidiariesList && subsidiariesList.length) {
        for (let i = 0; i < subsidiariesList.length; i++) {
          // @ts-ignore
          if (!cache[subsidiariesList[i].name]) {
            // @ts-ignore
            cache[subsidiariesList[i].name] = true;
            dropdownOptions.push({
              key: subsidiariesList[i].id,
              text: subsidiariesList[i].name,
              value: subsidiariesList[i].id,
            });
          }
        }
      }
      state.subsidiariesListOptions = dropdownOptions;
      state.isLoading = false;
    });
    builder.addCase(listSubsidiaries.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('listSubsidiaries API request rejected');
    });

    //listIndustries
    builder.addCase(listIndustries.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listIndustries.fulfilled, (state, action) => {
      const industryListOptions = action.payload.data.listIndustries;

      let dropdownOptions = [];
      let cache = {};

      for (let i = 0; i < industryListOptions.length; i++) {
        // @ts-ignore
        if (!cache[industryListOptions[i].name]) {
          // @ts-ignore
          cache[industryListOptions[i].name] = true;
          dropdownOptions.push({
            key: industryListOptions[i].id,
            text: industryListOptions[i].name,
            value: industryListOptions[i].id,
          });
        }
      }

      state.industryListOptions = dropdownOptions;
      state.isLoading = false;
    });
    builder.addCase(listIndustries.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('listIndustries API request rejected');
    });

    //prepEntityDocsForUpload
    builder.addCase(prepEntityDocsForUpload.fulfilled, (state, action) => {
      const documentsCopy = [...state.activeEntity.documents];
      Object.keys(action.payload.data).map(key => {
        const id = key.replace('prepEntityDoc', '');
        const index = documentsCopy.findIndex(doc => doc.id === id);
        documentsCopy.splice(index, 1, {
          ...documentsCopy[index],
          ...action.payload.data[key],
        });
      });

      state.activeEntity.documents = documentsCopy;
    });

    builder.addCase(prepEntityDocsForUpload.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('prepEntityDocsForUpload API request rejected');
    });

    // create Network
    builder.addCase(createNetwork.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createNetwork.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createNetwork.rejected, state => {
      state.isLoading = false;
      toast.error('Some Error Occured');
    });

    // Update Network
    builder.addCase(updateNetwork.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateNetwork.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateNetwork.rejected, (state, action) => {
      state.isLoading = false;
      toast.error('Some Error Occured');
    });
  },
});

export const {
  updateInputKey,
  updateContactInputKey,
  createNewEntity,
  updateActiveTab,
  createContact,
  deleteContact,
  updateActiveStep,
  deleteSupportingFiles,
  addSupportingFiles,
  updateViewDetailsTab,
  updateSelectedSubsidiary,
} = entityDetailSlice.actions;

export default entityDetailSlice.reducer;
