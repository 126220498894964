import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import {
  addSpaceOrComma,
  getArrowDirection,
  getNumberWithSign,
  getPercentageIncrease,
  isPositive,
  shortenNumber,
} from 'utils/utils-number';
import './RentBox.scss';

export enum RentBoxType {
  Headline = 'Headline',
  Effective = 'Effective',
  Contractual = 'Contractual',
}

const RentBox = ({ type, dataTest }: { type: RentBoxType; dataTest: string }) => {
  const { summaryStats } = useAppSelector((state: RootState) => state.dashboardTab);

  let percentTotal: number = 0;
  let percentTotalPerSqft: number = 0;

  const PREVIOUS_MONTH = 0;
  const CURRENT_MONTH = 1;

  let total: number[] = [0, 0];
  let totalPerSqFt: number[] = [0, 0];

  if (summaryStats !== null && summaryStats.stats.length > 0) {
    for (let i = 0; i < summaryStats.stats.length; i++) {
      const { statsRent } = summaryStats.stats[i];
      total[i] =
        type === RentBoxType.Headline
          ? statsRent.headlineRent
          : type === RentBoxType.Effective
            ? statsRent.effectiveRent
            : statsRent.contractualRevenue;

      totalPerSqFt[i] =
        type === RentBoxType.Headline
          ? statsRent.headlineRentPerArea
          : type === RentBoxType.Effective
            ? statsRent.effectiveRentPerArea
            : statsRent.contractualRevenuePerArea;
    }

    percentTotal = getPercentageIncrease(Number(total[PREVIOUS_MONTH]), Number(total[CURRENT_MONTH]));
    percentTotalPerSqft = getPercentageIncrease(
      Number(totalPerSqFt[PREVIOUS_MONTH]),
      Number(totalPerSqFt[CURRENT_MONTH]),
    );
  }

  const formatFigure = (num: number) => {
    const { shortenedNumber, suffix } = shortenNumber(num);

    return suffix ? `${shortenedNumber}${suffix}` : addSpaceOrComma(num);
  };

  return (
    <div
      className="rent-box p-m"
      data-test={dataTest}
    >
      <h2 className="header color-blue-very-dark-grayish">
        {type} rent
        <Icon
          className="info-icon"
          icon={Icons.InfoCircle}
        />
      </h2>

      <div className="d-flex remaining-height">
        <div className="m-t-xs w-50 border-right p-r-m">
          <h5 className="sub-header color-blue-desaturated-dark">Total</h5>

          <div className="total-row m-t-s">
            <span
              className="main-count color-blue-very-dark-grayish"
              data-test="total-rent"
            >
              {formatFigure(total[CURRENT_MONTH])}
              <span className="unit m-l-xs">HK$</span>
              <Icon
                className="info-icon"
                icon={Icons.InfoCircle}
              />
            </span>
            <span
              className={`sub-count ${isPositive(percentTotal) ? 'color-green' : 'color-red-soft'}`}
              data-test="total-variation"
            >
              {getNumberWithSign(percentTotal, 1)}%
              <Icon
                icon={getArrowDirection(percentTotal)}
                className="m-l-xxs"
              />
            </span>
          </div>
        </div>

        <div className="m-t-xs w-50 border-left p-l-m">
          <h5 className="sub-header color-blue-desaturated-dark">Total per sqft</h5>

          <div className="total-row m-t-s">
            <span
              className="main-count color-blue-very-dark-grayish"
              data-test="total-per-sqft"
            >
              {formatFigure(totalPerSqFt[CURRENT_MONTH])}
              <span className="unit m-l-xs">HK$/sqft</span>
              <Icon
                className="info-icon"
                icon={Icons.InfoCircle}
              />
            </span>
            <span
              className={`sub-count ${isPositive(percentTotalPerSqft) ? 'color-green' : 'color-red-soft'}`}
              data-test="total-per-sqft-variation"
            >
              {getNumberWithSign(percentTotalPerSqft, 1)}%
              <Icon
                icon={getArrowDirection(percentTotalPerSqft)}
                className="m-l-xxs"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentBox;
