import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Grid } from 'semantic-ui-react';
import { checkIfNotDefined } from 'utils/tsHelper';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { RowFilterChildProps } from '.';
import { RowAction, RowProps } from '../../RowProps';

export function applyBooleanFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyBooleanFilter: field key is missing');

  let value: string = getNestedValue(content, rowConfig.field);
  if (checkIfNotDefined(value)) {
    return false;
  }
  return rowAction.filterValue === value;
}

const BooleanFilter = ({ filterValue, label, onChange }: RowFilterChildProps): JSX.Element => {
  return (
    <div className="grid">
      <div className="col-6">
        <label
          htmlFor="verified-filter"
          className="font-bold"
        >
          {label}
        </label>
      </div>
      <div className="col-6">
        <TriStateCheckbox
          value={filterValue}
          onChange={e => onChange('key', e.value as boolean)}
        />
      </div>
    </div>
  );
};

export default BooleanFilter;
