import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import ToggleCheckbox from 'atoms/ToggleCheckbox';
import { updateContractCapitalAllowance } from 'store/contracts/contractDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const CapitalAllowance = ({ mode }: ModeProps) => {
  const {
    activeContract: {
      capitalAllowance: { flagOn, value },
    },
    runContractValidation,
  } = useAppSelector((state: RootState) => state.contractDetail);

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: string | boolean) => dispatch(updateContractCapitalAllowance({ key, value }));

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <ToggleCheckbox
            isDisabled={mode === MODE_OPTIONS.READ}
            label="Capital Allowance"
            dataTest="capital-Allowance-toggle"
            fieldKey="flagOn"
            value={flagOn}
            onChange={onChange}
            popUp={true}
            infoText={
              <>
                <p>{`A capital allowance is a capital contribution to the tenant’s fit-out works. Alongside rent free periods or general cash payments, capital allowances can operate as incentive to attract tenants to lease a particular premises.`}</p>
                <p>{`There are a typically conditions to be met for the landlord to be able to claim capital allowances for the capital contributions it makes towards the costs of equipment and fixtures purchased by the tenant.`}</p>
              </>
            }
          />
        </Grid.Column>
        <Grid.Column
          width={8}
          className="p-t-xs"
        >
          <InputField
            type={InputFieldType.NUMBER}
            unit="(HK$)"
            fieldKey="value"
            dataTest="capital-allowance-text-field"
            value={value}
            required={mode === MODE_OPTIONS.EDIT && flagOn}
            disabled={mode === MODE_OPTIONS.READ || !flagOn}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CapitalAllowance;
