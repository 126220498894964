import { Icon } from '@iconify/react';
import { checkIfNotDefined, conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { DataType } from '../RowTemplate/RowProps';

interface RowFilterProps {
  onClick: Function;
  value?: any | Duration;
  dataType?: DataType;
}

/**
 * Row Filter element
 * @returns
 */
const RowFilterIcon = ({ onClick, value, dataType }: RowFilterProps): JSX.Element => {
  let isFilterSelected: boolean = false;

  if (!checkIfNotDefined(value)) {
    isFilterSelected = true;

    if (dataType === DataType.DURATION) {
      isFilterSelected =
        value.years || value.months || value.days || value.weeks || value.hours || value.minutes || value.seconds;
    }
  }

  return (
    <span className={`filter-icon ${conditionalClassName(isFilterSelected, 'active')}`}>
      <Icon
        icon={Icons.Filter}
        onClick={() => onClick()}
      />
    </span>
  );
};

export default RowFilterIcon;
