import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const TermForm = ({ mode }: ModeProps) => {
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const dispatch = useAppDispatch();

  const onChange = (key: string, value: Date) => {
    dispatch(updateActiveContractEventInput({ key, value: value || null }));
  };

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  return (
    <div className="grid">
      <div className="col-2">
        <DateField
          label="Term start date"
          fieldKey="startDate"
          dataTest="term-start-date-input"
          value={activeContractEvent?.startDate || ''}
          disabled={disabled}
          required={required}
          onChange={onChange}
        />
      </div>
      <div className="col-2">
        <DateField
          minDate={activeContractEvent?.startDate}
          label="Term end date"
          fieldKey="endDate"
          dataTest="term-end-date-input"
          value={activeContractEvent?.endDate || ''}
          disabled={disabled}
          required={required}
          onChange={onChange}
        />
      </div>
      <div className="col-2">
        <DateField
          maxDate={activeContractEvent?.startDate}
          label="Early occupation start date"
          fieldKey="earlyOccupationStartDate"
          dataTest=""
          value={activeContractEvent?.earlyOccupationStartDate || ''}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TermForm;
