import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { CContract2, Contract2InputClone } from 'common/_classes/contractsV2';
import { RootState } from 'store';
import _ from 'lodash';
import DocumentType from 'common/model/DocumentType';
import { DocumentsType, convertToContract } from 'common/api/contracts';
import { deepClone } from 'utils/tsHelper';
import { uploadToAWS } from 'utils/utils-upload';

export const CREATE_CONTRACT_2 = (activeContract: Contract2InputClone | null) => {
  if (!activeContract) {
    throw new Error('Create Contract: Contract could not be determined. Query aborted.');
  }

  return {
    mutation: gql`
      mutation ($params: Contract2Input!) {
        createContract2(params: $params) {
          ${CContract2.fragment()}
        }
      }
    `,
    variables: {
      params: CContract2.formatForAPI(activeContract),
    },
  };
};

export const createContract2 = createAsyncThunk('contractDetailV2/createContract2', async (arg, { getState }) => {
  const {
    contractDetailV2: { activeContract },
  } = getState() as RootState;

  // const contractDocs: Partial<DocumentsType>[] = [...activeContract.documents];

  // if (contractDocs.length > 0) {
  //   for (let i = 0; i < contractDocs.length; i++) {
  //     const { uploadUrl, file, name, path } = contractDocs[i];

  //     await uploadToAWS(uploadUrl, file);

  //     contractDocs.splice(i, 1, {
  //       name,
  //       path,
  //       public: true,
  //     });
  //   }
  // }
  const response = await client.mutate(CREATE_CONTRACT_2(activeContract));
  return response;
});
