// @ts-ignore
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ExportDropdown from 'atoms/ExportDropdown';
import ImportDropdown from 'atoms/ImportDropdown';
import ImportReport from 'atoms/ImportReport';
import { DataObjectName } from 'atoms/ImportReport/utils-interfaces';
import SearchFilter from 'components/SearchFilter';
import { clearImportedContract } from 'store/contracts/contractsListingSlice';
import { updateContractsListingSearchValue } from 'store/contractsV2/contractsListingSliceV2';
import { exportContract, importContract, listContracts } from 'common/api/contracts';
import { listContracts2 } from 'common/api/contracts2';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

const ContractsHeaderToolbar = (): React.ReactElement => {
  const { importedContract, isExporting, exportSuccess, exportFail } = useAppSelector(
    (state: RootState) => state.contractsListing,
  );

  const { search } = useAppSelector(({ contractsListingV2 }: RootState) => contractsListingV2);
  const { members } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const clearReport = () => {
    dispatch(clearImportedContract());
  };

  const onImportContract = (simulation: boolean, file: File) => {
    dispatch(importContract({ simulation, file })).then(({ meta: { requestStatus }, payload }: any) => {
      if (requestStatus === 'fulfilled') {
        if (payload.data.importContract.simulation === false) {
          dispatch(listContracts({ first: 10 }));
        }
      }
    });
  };

  const onExportContract = () => {
    const {
      entity: { name: landlordName },
    }: any = members.find(({ entity: { id } }) => id === getCurrentLandlordEntityId());
    dispatch(exportContract({ landlordName }));
  };

  const onGetData = (value?: string) => listContracts2({ searchValue: value, first: 1000 });

  return (
    <>
      <SearchFilter
        search={search}
        onUpdateSearch={updateContractsListingSearchValue}
        onGetData={onGetData}
      />
      <ImportDropdown
        onImport={onImportContract}
        className="m-r-xs"
      />
      <ExportDropdown
        onExport={onExportContract}
        isExporting={isExporting}
        exportSuccess={exportSuccess}
        exportFail={exportFail}
      />
      {importedContract && (
        <ImportReport
          className="m-t-l"
          importReport={importedContract}
          clearReport={clearReport}
          dataObjectName={DataObjectName.CONTRACT}
        />
      )}
    </>
  );
};

export default ContractsHeaderToolbar;
