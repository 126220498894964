import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface EvaluateAIQuestionQueryVariables {
  question: string;
  params: any;
}

export enum AIQuestions {
  Question1 = 'Does this deal impact any upcoming rent reviews?',
  Question2 = 'When is the earliest date that JP Morgan can surrender the premises?',
  Question3 = `What is JP Morgan's ROFO option?`,
  Question4 = `What is JP Morgan's upcoming rent review?`,
  Question5 = `What percentage higher or lower is JP Morgan's rent compared to a similar premise size in MetroPlex?`,
}

export const EVALAUTE_AI_QUESTION = (question: AIQuestions, transactionId: string | undefined) => {
  if (!transactionId) throw new Error('Evalaute AI Question: Transaction ID is missing');
  let params;
  if (question === AIQuestions.Question1) {
    params = JSON.stringify({
      landlordId: getCurrentLandlordEntityId(),
      transactionId,
    });
  } else if (
    question === AIQuestions.Question2 ||
    question === AIQuestions.Question3 ||
    question === AIQuestions.Question4
  ) {
    params = JSON.stringify({
      tenantName: 'JP Morgan',
    });
  } else if (question === AIQuestions.Question5) {
    params = JSON.stringify({
      tenantName: 'JP Morgan',
      buildingName: 'MetroPlex',
    });
  }

  const queryVariables: EvaluateAIQuestionQueryVariables = {
    question,
    params,
  };

  return {
    query: gql`
      query ($question: String!, $params: Json) {
        evaluateAiQuestion(question: $question, params: $params) {
          result
        }
      }
    `,
    variables: queryVariables,
  };
};

export const evaluateAIQuestion = createAsyncThunk('miscellaneous/evaluateAIQuestion', async (_, { getState }) => {
  const {
    transactionDetail: { activeTransaction },
  } = getState() as RootState;

  const response = await client.query(EVALAUTE_AI_QUESTION(AIQuestions.Question1, activeTransaction.id));
  return response;
});
