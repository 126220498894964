import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { useEffect, useState } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column, ColumnProps } from 'primereact/column';
import { TreeNode } from 'primereact/treenode';
import './TreeTablePrime.scss'

type DataTableProps = typeof DataTable;

export type ViewDetailsProps = (id: string) => void;

const TreeTablePrime = ({
    content,
    columnConfig,
    dataTableProps,
    fetchContent,
    noRecordMessage,
    minWidth,
    width,
    dataTest
}: TreeTablePrimeProps) => {

    const [paginator] = useState<{
        first: number;
        rows: number;
    }>({
        first: 0,
        rows: 10,
    });
    useEffect(() => {
        if (fetchContent) {
            // TODO: need to add pagination
            const { after, first } = resolvePageRange(1, 1000);
            fetchContent({ after, first });
        }
    }, []);
    return (
        <div
            className="card data-table"
            data-test="porfolio-data-table"
        >
            <TreeTable
                scrollable={true}
                stripedRows
                data-test={dataTest}
                emptyMessage={noRecordMessage || 'No Records Found'}
                value={content}
                tableStyle={{
                    minWidth: minWidth || '100%',
                    width: width || '100%',
                }}
                rows={10}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown  CurrentPageReport PrevPageLink PageLinks NextPageLink"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
                {...dataTableProps}
            >
                {columnConfig.map((props, index) => {
                    return (
                        <Column
                            key={`${index}`}
                            {...props}
                            style={{
                                minWidth: props.minWidth,
                                width: props.width,
                                marginLeft: 0,
                            }}
                        />
                    );
                })}
                <Paginator
                    first={paginator.first}
                    rows={paginator.rows}
                    totalRecords={content.length}
                />
            </TreeTable>
        </div>
    );
};

export default TreeTablePrime;

interface TreeTablePrimeProps {
    content: TreeNode[],
    columnConfig: CustomColumnProps[];
    dataTableProps?: DataTableProps;
    customHeader?: React.ReactNode;
    fetchContent?: (request: any) => void;
    noRecordMessage?: string;
    minWidth?: string;
    width?: string;
    dataTest?: string;
    showPagination?: boolean;
}

const resolvePageRange = (currentPage: number, quantity: number) => {
    const last = currentPage * Number(quantity) - Number(quantity);
    const first = currentPage * Number(quantity);

    return {
        after: btoa('arrayconnection:' + (last - 1)),
        first,
    };
};

export interface CustomColumnProps extends ColumnProps {
    isHide?: boolean;
    mandatory?: boolean;
    width?: string;
    minWidth?: string;
}
