import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { CPageInfo } from 'common/_classes';
import { CContract2 } from 'common/_classes/contractsV2';
import { RootState } from 'store';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ListContractsQueryVariables extends Omit<PaginationAPIProps, 'searchValue'> {
  currentLandlordEntityId: string;
  contractFilter: { search?: string };
}

const LIST_CONTRACTS2 = ({ searchValue, first, last, after, before }: PaginationAPIProps) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) {
    throw new Error('List Contracts: Current Landlord Entity could not be determined. Query aborted.');
  }

  const queryVariables: ListContractsQueryVariables = {
    currentLandlordEntityId,
    first: first,
    last: last,
    after: after,
    before: before,
    contractFilter: { search: searchValue || '' },
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $first: Int, $last: Int, $after: String, $before: String, $contractFilter: ContractFilter) {
        listContracts2(
          currentLandlordEntityId: $currentLandlordEntityId
          first: $first
          last: $last
          after: $after
          before: $before
          contractFilter: $contractFilter
        ) {
          edges {
            cursor
            node {
              ${CContract2.fragment()}
            }
          }
          pageInfo {
            ${CPageInfo.fragments()}
          }
        }
      }
    `,
    variables: queryVariables,
  };
};

export const listContracts2 = createAsyncThunk(
  'contractListingV2/listContracts2',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const {
      contractsListingV2: { search },
    } = getState() as RootState;

    const response = await client.query(LIST_CONTRACTS2({ searchValue: search, first, last, after, before }));
    return response;
  },
);
