import Decimal from 'decimal.js';
import ManagementCharge from 'common/model/ManagementCharge';
import ManagementChargeInput from 'common/model/ManagementChargeInput';
import PaidBy from 'common/model/PaidBy';
import RateType from 'common/model/RateType';
import { convertDateToISO } from 'utils/utils-date';

export interface ManagementChargeInputClone
  extends Omit<
    ManagementChargeInput,
    'finalCharge' | 'paidBy' | 'standardRate' | 'standardRateDiscount' | 'type' | 'startDate' | 'endDate'
  > {
  finalCharge: Decimal | null;
  paidBy: PaidBy | null;
  standardRate: Decimal | null;
  standardRateDiscount: Decimal | null;
  type: RateType | null;
  startDate: Date | null;
  endDate: Date | null;
}

export class CManagementCharge implements ManagementChargeInputClone {
  finalCharge: Decimal | null;
  includeAcFee: boolean;
  paidBy: PaidBy | null;
  standardRate: Decimal | null;
  standardRateDiscount: Decimal | null;
  type: RateType | null;
  startDate: Date | null;
  endDate: Date | null;

  constructor(input: ManagementCharge | null) {
    this.finalCharge = input && input.finalCharge ? new Decimal(input.finalCharge) : null;
    this.paidBy = input ? input.paidBy : null;
    this.includeAcFee = input ? input.includeAcFee : false;
    this.standardRate = input && input.standardRate ? new Decimal(input.standardRate) : null;
    this.standardRateDiscount = input && input.standardRateDiscount ? new Decimal(input.standardRateDiscount) : null;
    this.type = input ? input.type : null;
    this.startDate = input && input.startDate ? new Date(input.startDate) : null;
    this.endDate = input && input.endDate ? new Date(input.endDate) : null;
  }

  static fragment() {
    return `
      finalCharge
      includeAcFee
      paidBy
      standardRate 
      standardRateDiscount
      type
      startDate
      endDate
    `;
  }

  static formatForAPI(charge: CManagementCharge) {
    return {
      finalCharge: charge.finalCharge ? parseFloat(charge.finalCharge.toString()) : null,
      includeAcFee: charge.includeAcFee,
      paidBy: charge.paidBy,
      standardRate: charge.standardRate ? parseFloat(charge.standardRate.toString()) : null,
      standardRateDiscount: charge.standardRateDiscount ? parseFloat(charge.standardRateDiscount.toString()) : null,
      type: charge.type,
      endDate: convertDateToISO(charge.endDate),
      startDate: convertDateToISO(charge.startDate),
    };
  }
}
