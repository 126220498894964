import MParameterChoice from 'common/model/ParameterChoice';
import { OmitMultiple } from 'utils/UI';
import { generateUniqueId } from 'utils/utils-random';

//TODO: why do we need to do that => we need to explore the implementation
export const NEW_CHOICE_INDICATOR: string = 'new-choice';

type TParameterChoiceBase = OmitMultiple<MParameterChoice, 'insertedAt' | 'updatedAt'>;

export class ParameterChoiceBase implements TParameterChoiceBase {
  id: string;
  text: string;
  index: number;
  default: boolean;
  archive: boolean;

  constructor(input: TParameterChoiceBase) {
    this.id = input.id;
    this.text = input.text;
    this.index = input.index;
    this.default = input.default;
    this.archive = input.archive;
  }

  static fragments(): string {
    return `
      id
      text
      index
      default
      archive
    `;
  }
  static init(index: number): TParameterChoiceBase {
    return {
      id: generateUniqueId(NEW_CHOICE_INDICATOR),
      text: '',
      index: index,
      default: false,
      archive: false,
    };
  }
}
