import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import { LandlordsEntitiesProps } from 'utils/types/entities';
import { addSpaceOrComma } from 'utils/utils-number';
import { LandlordEntitiesRowActionButtons } from './LandlordEntitiesRowActionButtons';
import Network from 'common/model/Network';

export class LandlordEntitiesRow {
  id: string;
  name: string;
  propertyCount: number;
  transactionCount: number;
  floorArea: string;
  floorAreaNumber: number;
  parentId: string | null;
  landlordNetworks: Network[];

  constructor(entity: LandlordsEntitiesProps) {
    this.id = entity.id;
    this.name = entity.name;
    this.propertyCount = entity.propertyCount;
    this.transactionCount = entity.transactionCount;
    this.floorArea = entity.floorArea;
    this.floorAreaNumber = entity.floorArea
      ? parseInt(entity.floorArea.split('(')[0] ? entity.floorArea.split('(')[0].trim().split(',').join('') : '')
      : 0;
    // @ts-ignore
    this.landlordNetworks = entity?.landlordNetworks || [];

    if (entity?.landlordNetworks && entity.landlordNetworks.length) {
      this.parentId = entity.landlordNetworks[0].parentId;
    } else {
      this.parentId = null;
    }
  }
}

/*
 * Definition of the column (names, format...)
 */
export const landlordEntitiesColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Legal entity name',
      filter: true,
      filterPlaceholder: 'Search by Legal entity name',
      filterField: 'name',
      sortable: true,
      dataType: 'text',
      mandatory: true,
      expander: true
    },
    {
      field: 'propertyCount',
      header: 'Properties under entity',
      filterPlaceholder: 'Search by Properties under entity',
      filter: true,
      filterField: 'propertyCount',
      sortable: true,
      dataType: 'numeric',
      body: row => <span>{addSpaceOrComma(row.data.propertyCount, false)}</span>,
    },
    {
      field: 'transactionCount',
      header: 'Transactions',
      filter: true,
      filterPlaceholder: 'Search by Transactions',
      filterField: 'transactionCount',
      sortable: true,
      dataType: 'numeric',
      body: row => <span>{addSpaceOrComma(row.data.transactionCount, false)}</span>,
    },

    {
      field: 'floorArea',
      header: 'Floor area',
      filter: true,
      filterPlaceholder: 'Search by Floor area',
      filterField: 'floorAreaNumber',
      dataType: 'numeric',
      sortable: true,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: { data: LandlordEntitiesRow }) => (
        <LandlordEntitiesRowActionButtons
          rowId={row.data.id}
          landlordNetworks={row.data.landlordNetworks}
          onViewDetails={onViewDetails}
        />
      ),
    },
  ];
};
