import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AcFeeInputClone, ManagementChargeInputClone } from 'common/_classes/contractsV2';
import Decimal from 'decimal.js';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import RateType from 'common/model/RateType';

export const onChangeHandler = (
  dispatch: Dispatch<AnyAction>,
  rootKey: string,
  index: number,
  charge: AcFeeInputClone | ManagementChargeInputClone,
) => {
  const handleOnChange = (key: string, value: any) => {
    dispatch(
      updateActiveContractInput({
        key: [rootKey, `${index}`, key],
        value: value || null,
      }),
    );
  };

  const calcDiscountedFinalCharge = (
    standardRate: Decimal | null,
    standardRateDiscount: Decimal | null,
  ): Decimal | null => {
    if (!standardRate || !standardRateDiscount) return null;

    if (charge.type === RateType.Fixed) return charge.finalCharge;

    const percentage = Decimal.div(standardRateDiscount, new Decimal('100'));
    const discountAmount = Decimal.mul(standardRate, percentage);

    return Decimal.sub(standardRate, discountAmount);
  };

  return {
    onChange: handleOnChange,

    onChangeType: (key: string, value: string) => {
      switch (value) {
        case RateType.Standard:
          dispatch(
            updateActiveContractInput({
              key: [rootKey, `${index}`],
              value: {
                ...charge,
                standardRateDiscount: new Decimal('0'),
                finalCharge: charge.standardRate,
              },
            }),
          );
          break;

        case RateType.Fixed:
          dispatch(
            updateActiveContractInput({
              key: [rootKey, `${index}`],
              value: {
                ...charge,
                standardRateDiscount: new Decimal('0'),
              },
            }),
          );
          break;

        case RateType.Discount:
          dispatch(
            updateActiveContractInput({
              key: [rootKey, `${index}`, 'standardRateDiscount'],
              value: null,
            }),
          );
          break;
      }

      handleOnChange(key, value);
    },

    onChangeStandardRate: (_key: string, value: string) => {
      const newStandardRate = value ? new Decimal(value) : null;

      dispatch(
        updateActiveContractInput({
          key: [rootKey, `${index}`],
          value: {
            ...charge,
            standardRate: newStandardRate,
            finalCharge: calcDiscountedFinalCharge(newStandardRate, charge.standardRateDiscount),
          },
        }),
      );
    },

    onChangeFinalCharge: (key: string, value: string) => {
      dispatch(
        updateActiveContractInput({
          key: [rootKey, `${index}`, key],
          value: value ? new Decimal(value) : null,
        }),
      );
    },

    onChangeStandardRateDiscount: (_key: string, value: string) => {
      const newDiscountValue = value ? new Decimal(value) : null;

      dispatch(
        updateActiveContractInput({
          key: [rootKey, `${index}`],
          value: {
            ...charge,
            standardRateDiscount: newDiscountValue,
            finalCharge: calcDiscountedFinalCharge(charge.standardRate, newDiscountValue),
          },
        }),
      );
    },

    onChangeDecimal: (key: string, value: string) => {
      dispatch(
        updateActiveContractInput({
          key: [rootKey, `${index}`, key],
          value: value ? new Decimal(value) : null,
        }),
      );
    },
  };
};
