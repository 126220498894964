import { useLocation } from 'react-router-dom';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import Badge, { BadgeColor } from 'atoms/Badge';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import TabContainer from 'components/TabContainer';
import ValuationTab from 'views/transactions/CreatorViewer/Tabs/ContractView/ValuationTab';
import { updateViewDetailsTabIndex } from 'store/contracts/contractDetailSlice';
import ContractDetailsTab from './ContractDetailsTab';
import ContractOverviewTab from './ContractOverviewTab';

export enum CONTRACT_TABS_OFFSET {
  DETAILS = 0,
  OVERVIEW = 1,
}

const getTabMenuConfig = (checkCreate: boolean): TabMenuProps[] => {
  const {
    activeContract: { id },
  } = useAppSelector((state: RootState) => state.contractDetail);
  const noDataBadge: JSX.Element = <Badge badgeColor={BadgeColor.DARK_ORANGE}>Contract data not available</Badge>;
  const contractId = id ? id : null;
  return [
    {
      key: 'contract-tab-details',
      href: '?tab=details',
      label: 'Details',
      dataTest: 'nav-details',
      customTab: <ContractDetailsTab />,
    },
    {
      key: 'contract-tab-overview',
      href: '?tab=overview',
      label: 'Overview',
      dataTest: 'nav-overview',
      isDisabled: checkCreate,
      customTab: <ContractOverviewTab />,
    },
    {
      key: 'contract-tab-valuation',
      href: '?tab=valuation',
      label: 'Comparables',
      dataTest: 'nav-contract-valuation',
      customTab: contractId ? (
        <ValuationTab contractId={contractId} />
      ) : (
        <TabContainer
          tabTitle="Comparable"
          nested={true}
        >
          {noDataBadge}
        </TabContainer>
      ),
    },
  ];
};

const ContractTabs = () => {
  const { activeTabIndex } = useAppSelector((state: RootState) => state.contractDetail);
  const location = useLocation();
  const checkCreate: boolean = location.pathname.includes('create');

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateViewDetailsTabIndex}
      tabMenu={getTabMenuConfig(checkCreate)}
    />
  );
};

export default ContractTabs;
