import Contractv2Dashboard from 'views/contracts-v2/Dashboard';
import Contractv2Details from 'views/contracts-v2/Viewer';
import ContractDashboard from 'views/contracts/Dashboard';
import ContractDetails from 'views/contracts/Viewer';
import { isAuthenticated } from './redirects';

const ContractsRoutes = () => [
  {
    path: '/tenancy',
    children: [
      {
        path: '/tenancy/create',
        element: isAuthenticated(<Contractv2Details isViewPage={false} />),
      },
      {
        path: '/tenancy/:id/details',
        element: isAuthenticated(<Contractv2Details isViewPage={true} />),
      },
      {
        path: '/tenancy/dashboard',
        element: isAuthenticated(<Contractv2Dashboard />),
      },
    ],
  },
  {
    path: '/contracts',
    children: [
      {
        path: '/contracts/create',
        element: isAuthenticated(<ContractDetails />),
      },
      {
        path: '/contracts/:id/details',
        element: isAuthenticated(<ContractDetails />),
      },
      {
        path: '/contracts/dashboard',
        element: isAuthenticated(<ContractDashboard />),
      },
    ],
  },
];

export default ContractsRoutes;
