import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { AnswerProps, SetAnswerInputResult } from 'common/api/policies';
import { setAnswerInput } from 'utils/utils-answer';

interface CreatePolicyAnswersQueryVariables {
  answers: SetAnswerInputResult[];
}

export const CREATE_POLICY_ANSWERS = async (answers: AnswerProps[]) => {
  const answersParam: SetAnswerInputResult[] = await setAnswerInput(answers);

  const variables: CreatePolicyAnswersQueryVariables = {
    answers: answersParam,
  };

  return {
    mutation: gql`
      mutation ($answers: [PolicyAnswerInput]) {
        createPolicyAnswers(answers: $answers) {
          answer
          answerType
          dateOfAnswer
          paramRef {
            parameterId
            tableId
            index
          }
          user {
            id
            firstName
            lastName
          }
          policy {
            id
          }
        }
      }
    `,
    variables,
  };
};

// createPolicyAnswers API may also save some answers that have files embedded.
// Those files are uploaded to AWS S3 buckets and we need to wait for this action.
//  => this is why there is a 'await' inside the 'client.mutate'
export const createPolicyAnswers = createAsyncThunk('policies/createPolicyAnswers', async (args, { getState }) => {
  const {
    policyDetail: { activePolicyAnswers },
  } = getState() as RootState;
  const newAnswers: AnswerProps[] = activePolicyAnswers.filter((answer: AnswerProps) => answer.blockRef !== undefined);

  const response = await client.mutate(await CREATE_POLICY_ANSWERS(newAnswers));
  return response;
});
