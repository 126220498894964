import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import SelectField from 'atoms/FormField/Select';
import {
  updateContractInput,
  updateContractLandlordId,
  updateContractStartOrEndDate,
} from 'store/contracts/contractDetailSlice';
import { contractEndDateValidations, contractStartDateValidations } from 'store/contracts/validations';
import { listProperties } from 'common/api/contracts';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { checkNotEmpty } from 'utils/tsValidator';

const PartiesOrTermForm = ({ mode }: ModeProps) => {
  const {
    activeContract: {
      startDate,
      endDate,
      landlord: { id: landlordId },
      tenantId,
    },
  } = useAppSelector((state: RootState) => state.contractDetail);

  const { landlordEntitiesOptions, tenantEntitiesOptions } = useAppSelector(
    (state: RootState) => state.contractsListing,
  );

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: string | Date) => {
    if (key === 'startDate' || key === 'endDate') {
      dispatch(
        updateContractStartOrEndDate({
          key,
          value: value ? value.toString() : value,
        }),
      );
    } else if (key === 'id') {
      dispatch(updateContractLandlordId({ key, value }));
      dispatch(listProperties({ landlordId: value as string }));
    } else if (key === 'tenantId') {
      dispatch(updateContractInput({ key, value }));
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <SelectField
            label="Landlord"
            fieldKey="id"
            dataTest="landlord-input-field"
            value={landlordId}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            options={landlordEntitiesOptions}
            onChange={onChange}
            runValidation={!checkNotEmpty(landlordId)}
            search={true}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={12}>
          <SelectField
            label="Tenant"
            fieldKey="tenantId"
            dataTest="tenant-input-field"
            value={tenantId}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            options={tenantEntitiesOptions}
            onChange={onChange}
            search={true}
            runValidation={!checkNotEmpty(tenantId)}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>
          <DateField
            label="Term start date"
            fieldKey="startDate"
            dataTest="term-start-date-input"
            value={startDate}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            runValidation={!checkNotEmpty(startDate)}
            // @ts-ignore
            validationFuncs={contractStartDateValidations(endDate)}
            validationDependant={endDate}
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <DateField
            label="Term end date"
            fieldKey="endDate"
            dataTest="term-end-date-input"
            value={endDate}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            runValidation={!checkNotEmpty(endDate)}
            // @ts-ignore
            validationFuncs={contractEndDateValidations(startDate)}
            validationDependant={startDate}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PartiesOrTermForm;
