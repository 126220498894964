import { Card } from 'primereact/card';
import { Dropdown } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import ParameterMultiLevelSelect from 'components/ParameterMultiLevelSelect';
import { handleCurrentValue, handleMultilevelReturn } from 'components/ParameterMultiLevelSelect/multiLevelInputHelper';
import { flatOptions } from 'components/ParameterMultiLevelSelect/tools';
import AnswerTypes from 'common/model/AnswerTypes';
import ConditionComparator from 'common/model/ConditionComparator';
import ConditionType from 'common/model/ConditionType';
import Conditions from 'common/model/Conditions';
import { ParamRefInputProps } from 'common/api/nodes';
import { DROPDOWN_OPTION } from 'utils/UI';
import { deepClone, handleZeroValue } from 'utils/tsHelper';
import { OptionItem } from 'utils/types/categories';
import { Icons } from 'utils/utils-icons';
import { isUUID } from 'utils/utils-string';
import ComparisonTypeSelector from './ComparisonTypeSelector';
import { LOGIC_COMPARISON, LOGIC_COMPARISON_2, LOGIC_COMPARISON_3 } from './ConditionUtils';
import InputType from './InputType';

interface ConditionEquationRowProps {
  groupIndex: number;
  index: number;
  innerList: any;
  multiLevelOptions: OptionItem[];
  value: Conditions[];
  onChange: (value: Conditions[]) => void;
}

export const checkIfNoneOrAnyComparator = (comparator: ConditionComparator) => {
  return [
    ConditionComparator.None,
    ConditionComparator.Any,
    ConditionComparator.All,
    ConditionComparator.NotAll,
  ].includes(comparator);
};

const ConditionEquationRow = ({
  groupIndex,
  index,
  innerList,
  multiLevelOptions,
  value,
  onChange,
}: ConditionEquationRowProps): JSX.Element => {
  const { answer: answerValue, paramRef, paramRef2, comparator, type } = innerList;
  const flat: OptionItem[] = flatOptions(multiLevelOptions);
  const found: OptionItem | undefined = flat.find(({ id: flatId }) => flatId === paramRef.parameterId);

  let mcqOptions = [] as any;
  let paramAnswerType!: AnswerTypes;
  let numberUnit = null;

  if (found) {
    if ([AnswerTypes.SingleChoice, AnswerTypes.MultiChoice].includes(found.selection?.answerType as any)) {
      mcqOptions = found.selection?.choices as any;
    }
    // @ts-ignore
    paramAnswerType = found.selection?.answerType;
    if (paramAnswerType === AnswerTypes.NumberUnit) {
      numberUnit = found.selection?.unit;
    }
  }

  const checkIfV2: boolean = [
    AnswerTypes.Text,
    AnswerTypes.Image,
    AnswerTypes.Boolean,
    AnswerTypes.SingleChoice,
    AnswerTypes.Duration,
  ].includes(paramAnswerType);

  const checkIfAll: boolean = [
    AnswerTypes.Date,
    AnswerTypes.Number,
    AnswerTypes.NumberUnit,
    AnswerTypes.Duration,
  ].includes(paramAnswerType);

  const checkIfV3: boolean = [AnswerTypes.MultiChoice].includes(paramAnswerType);

  const isNoneOrAnySelected: boolean = checkIfNoneOrAnyComparator(comparator);

  const checkComparator = (): DROPDOWN_OPTION[] => {
    if (type === ConditionType.ParamParam) {
      if (checkIfAll) {
        return LOGIC_COMPARISON;
      } else {
        return LOGIC_COMPARISON_2;
      }
    } else {
      if (checkIfV3) {
        return LOGIC_COMPARISON_3;
      } else if (checkIfV2) {
        return LOGIC_COMPARISON_2;
      } else {
        return LOGIC_COMPARISON;
      }
    }
  };

  const updateConditionType = (groupIndex: number, index: number, type: ConditionType) => {
    const temp = deepClone(value);
    temp[groupIndex].list[index].type = type;
    if (type !== ConditionType.ParamValue) {
      // @ts-ignore
      temp[groupIndex].list[index].answer = {
        answer: '',
      };
    }
    onChange(temp);
  };

  const updateConditionParameter = (groupIndex: number, index: number, param: any, isParamRef2: boolean) => {
    const temp = deepClone(value);
    const obj: any = {
      index: handleZeroValue(param.index),
      offset: handleZeroValue(param.offset),
      parameterId: param.parameterId,
      position: handleZeroValue(param.position),
      tableId: param.tableId ? param.tableId : null,
    };

    if (isParamRef2) {
      temp[groupIndex].list[index].paramRef2 = obj;
    } else {
      temp[groupIndex].list[index].paramRef = obj;
    }
    // @ts-ignore
    temp[groupIndex].list[index].answer = {
      answer: '',
    };
    onChange(temp);
  };

  const updateConditionOperator = (groupIndex: number, index: number, comparator: any) => {
    const temp = deepClone(value);
    temp[groupIndex].list[index].comparator = comparator;
    onChange(temp);
  };

  const deleteInnerFormCondition = (groupIndex: number, index: number) => {
    const temp = deepClone(value);
    temp[groupIndex].list = temp[groupIndex].list.filter((obj: any, idx: number) => idx !== index);
    onChange(temp);
  };

  const getMcqInput = (input: any) => {
    if (mcqOptions.length === 0 && input.answer && isUUID(input.answer)) {
      return '';
    }
    return input as any;
  };

  return (
    <Card
      className={'condition-group-box border-sm-grayish-magenta-light m-b-xs'}
      header={
        <div className={`condition-group-box-header flex justify-content-between flex-wrap p-xxs bg-gray-very-light`}>
          <ComparisonTypeSelector
            key={`comparison-type-${groupIndex}-${index}`}
            selectedValue={type}
            onChange={newComparisonType => {
              updateConditionType(groupIndex, index, newComparisonType);
            }}
          />
          <span
            className={`condition-trash ${
              [AnswerTypes.NumberUnit, AnswerTypes.NumberPercent].includes(paramAnswerType) && 'inside-number-types'
            }`}
            key={`parameter-delete-${groupIndex}-${index}`}
            data-test="condition-row-delete-button"
            onClick={() => deleteInnerFormCondition(groupIndex, index)}
          >
            <Icon icon={Icons.Trashv2} />
          </span>
        </div>
      }
    >
      <div className="condition-group-box-body p-s">
        <ParameterMultiLevelSelect
          currentValue={handleCurrentValue(innerList.paramRef)}
          dropdown={multiLevelOptions}
          onChange={result => {
            const paramRef = handleMultilevelReturn(result);
            updateConditionParameter(groupIndex, index, paramRef as ParamRefInputProps, false);
          }}
        />
        <Dropdown
          key={`group-condition-dropdown-${groupIndex}-${index}`}
          className={`dropdown-operator && ${
            [AnswerTypes.Time, AnswerTypes.Date].includes(paramAnswerType) && 'move-operators'
          }`}
          value={comparator}
          fluid={true}
          data-test="operators"
          options={checkComparator()}
          selection={true}
          selectOnBlur={false}
          onChange={(event, { value }) => {
            updateConditionOperator(groupIndex, index, value);
          }}
        />
        {!isNoneOrAnySelected && type === ConditionType.ParamValue && (
          <InputType
            paramAnswerType={paramAnswerType}
            answerValue={getMcqInput(answerValue)}
            groupIndex={groupIndex}
            index={index}
            mcqOptions={mcqOptions}
            numberUnit={numberUnit}
            value={value}
            onChange={onChange}
          />
        )}

        {!isNoneOrAnySelected && type === ConditionType.ParamParam && (
          <ParameterMultiLevelSelect
            currentValue={handleCurrentValue(innerList.paramRef2)}
            dropdown={multiLevelOptions}
            onChange={result => {
              const paramRef2 = handleMultilevelReturn(result);
              updateConditionParameter(groupIndex, index, paramRef2 as ParamRefInputProps, true);
            }}
          />
        )}
      </div>
    </Card>
  );
};

export default ConditionEquationRow;
