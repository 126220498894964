import './ClauseIndex.scss';

interface ClauseIndexProps {
  numbering?: string | number;
  title?: string;
  hasCondition?: boolean;
}

const ClauseIndex = ({ numbering, hasCondition, title }: ClauseIndexProps) => {
  const classNames = hasCondition ? 'title-row-extended' : '';

  return (
    <div className="clause-index-container">
      <div className="clause-title">
        <div className={'title-row ' + classNames}>
          {numbering || '1'}
          {hasCondition && <span>C</span>}
        </div>
        {title && <span>{title}</span>}
        <h3 className="clause-node-name">Clause Node Name</h3>
      </div>
    </div>
  );
};

export default ClauseIndex;
