import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { ID } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GetContractQueryVariables {
  currentLandlordEntityId: string;
  contractId: string;
}

export const GET_CONTRACT = (id: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) {
    throw new Error('Get Contract Revenue: Landlord Entity could not be determined. Query aborted.');
  }

  if (!id) {
    throw new Error('Get Contract Revenue: Contract ID could not be determined. Query aborted.');
  }

  const queryVariables: GetContractQueryVariables = {
    currentLandlordEntityId,
    contractId: id,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $contractId: ID!) {
        getContract(currentLandlordEntityId: $currentLandlordEntityId, contractId: $contractId) {
          id
          description
          documents {
            id
            name
            public
            url
            path
          }
          endDate
          expansionFlag
          identifier
          insertedAt
          owner {
            id
            name
          }
          premises {
            floorsStatus {
              floorId
              whole
            }
            property {
              id
              buildingName
              area {
                gross
              }
              floors {
                id
                name
                spaces {
                  id
                  name
                  grossArea
                  activationDate
                  deactivationDate
                }
              }
            }
            spaceIds
          }
          assignmentFlag
          redevelopmentFlag
          refusalFlag
          renewFlag
          rentDescriptions {
            endDate
            monthlyBaseRent
            startDate
            monthlyEffectiveRent
            validated
            charges {
              airConCharges
              governementRates
              governementRent
              managementFees
            }
            rentFreePeriods {
              endDate
              startDate
            }
            rentReview {
              cap
              collar
              reviewDate
              increment
              newRent
              type
            }
          }
          rentType
          securityAmount {
            amount
            bankGuaranteeAmount
            minCashDepositAmount
          }
          securityType
          startDate
          subletFlag
          surrenderFlag
          tenant {
            id
            name
          }
          terminationDate
          terminationDescription
          terminationReason
          updatedAt
          capitalAllowance {
            capitalAllowance
          }
          oneOffCharges {
            fitOutDeposit
            fitOutFee
          }
          reviewFlag
          reviewStatus {
            date
            user {
              id
              firstName
              lastName
            }
          }
          translationInfo {
            date
            transaction {
              id
              identifier
            }
          }
        }
      }
    `,
    variables: queryVariables,
  };
};

export const getContract = createAsyncThunk('contracts/getContract', async ({ id }: ID) => {
  const response = await client.query(GET_CONTRACT(id));
  return response;
});
