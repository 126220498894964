import { PropertyClone } from 'common/_classes';
import { Fragment, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Grid } from 'semantic-ui-react';
import SelectField from 'atoms/FormField/Select';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { DROPDOWN_OPTION } from 'utils/UI';
import PremisesSelect from './PremisesSelect';
import { PremiseSelectedProps, onUpdatePremiseProperty, refreshPremises } from './utils-premiseForm';

export type PremisesFormOnChangeFunction = (premises: PremiseSelectedProps[]) => void;

const extractPropertiesOptions = (properties: PropertyClone[]): DROPDOWN_OPTION[] =>
  properties.map((property, index) => {
    const result: DROPDOWN_OPTION = {
      key: index,
      text: property.buildingName,
      value: property.id,
    };
    return result;
  });

interface PremisesFormProps extends ModeProps {
  landlordId: string | null | undefined;
  properties: PropertyClone[];
  onChange: PremisesFormOnChangeFunction;
  value: PremiseSelectedProps[];
}

const PremisesForm = ({ mode, landlordId, properties, value, onChange }: PremisesFormProps): JSX.Element => {
  const onClick = (): void => {
    if (properties.length === 0) {
      toast.warn('Selected landlord entity has no properties');
    }
  };

  const onPropertySelectUpdate =
    (premiseIndex: number) =>
    (_key: string, propertyId: string): void => {
      const premises = onUpdatePremiseProperty(value, premiseIndex, properties, propertyId);
      onChange(premises);
    };

  useEffect(() => {
    if (mode === MODE_OPTIONS.EDIT) {
      onChange(refreshPremises());
    }
  }, [landlordId]);

  return (
    <Grid>
      {value.map((premise: PremiseSelectedProps, index: number) => {
        return (
          <Fragment key={`premise-${index + 1}`}>
            <Grid.Row className="p-b-none p-t-none">
              <Grid.Column
                width={3}
                className="p-l-none"
              >
                <SelectField
                  label="Building"
                  fieldKey="selectedPremiseId"
                  dataTest="select-premises"
                  value={premise.property?.id || ''}
                  options={extractPropertiesOptions(properties)}
                  disabled={mode === MODE_OPTIONS.READ}
                  required={mode === MODE_OPTIONS.EDIT}
                  onChange={onPropertySelectUpdate(index)}
                  onClick={onClick}
                />
              </Grid.Column>
              <Grid.Column width={13}>
                <PremisesSelect
                  mode={mode}
                  premises={value}
                  premiseIndex={index}
                  onChange={onChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Fragment>
        );
      })}
    </Grid>
  );
};
export default PremisesForm;
