import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CheckboxField from 'atoms/FormField/Checkbox';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import RateType from 'common/model/RateType';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { PAID_BY_OPTIONS, RATE_TYPE_OPTIONS } from 'utils/UI';
import { getMaxDateBoundaryForTenancies, getMinDateBoundaryForTenancies } from 'utils/utils-date';
import { onChangeHandler } from '../utils.onChange';

interface ManagementChargesFormProps extends ModeProps {
  index: number;
}

const ManagementChargesForm = ({ mode, index }: ManagementChargesFormProps) => {
  const dispatch = useAppDispatch();

  const { activeContract, activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const charges = activeContract?.managementCharges || [];

  const managementCharge = charges[index];

  const { endDate, startDate, standardRateDiscount, includeAcFee, standardRate, finalCharge, type, paidBy } =
    managementCharge;

  const isStandardType = type === RateType.Standard;
  const isDiscountType = type === RateType.Discount;
  const isFixedType = type === RateType.Fixed;

  const onChange = onChangeHandler(dispatch, 'managementCharges', index, managementCharge);

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  return (
    <div>
      <div className="grid m-t-s pm-none">
        <div className="col-2">
          <DateField
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              charges,
              index,
              'startDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              charges,
              index,
              'startDate',
            )}
            label="Start date"
            fieldKey="startDate"
            dataTest=""
            value={startDate}
            disabled={disabled}
            required={required}
            onChange={onChange.onChange}
          />
        </div>

        <div className="col-2">
          <DateField
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              charges,
              index,
              'endDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              charges,
              index,
              'endDate',
            )}
            label="End date"
            fieldKey="endDate"
            dataTest=""
            value={endDate}
            disabled={disabled}
            required={required}
            onChange={onChange.onChange}
          />
        </div>

        <div className="col-2">
          <SelectField
            label="Payable by landlord or tenant"
            fieldKey="paidBy"
            dataTest=""
            value={paidBy}
            disabled={disabled}
            required={required}
            options={PAID_BY_OPTIONS}
            onChange={onChange.onChange}
          />
        </div>
      </div>

      <div className="grid m-t-s pm-none">
        <div className="col-2">
          <SelectField
            label="Standard/discount/fixed"
            fieldKey="type"
            dataTest=""
            value={type}
            disabled={disabled}
            required={required}
            options={RATE_TYPE_OPTIONS}
            onChange={onChange.onChangeType}
          />
        </div>
        <div className="col-2">
          <InputField
            label="Standard rate (psf pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/sf/mo)"
            fieldKey="standardRate"
            value={standardRate?.toString() || null}
            disabled={disabled}
            required={required}
            onChange={onChange.onChangeStandardRate}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>
        <div className="col-2">
          <InputField
            label="Discount from standard rate"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(%)"
            fieldKey="standardRateDiscount"
            value={standardRateDiscount?.toString() || null}
            disabled={disabled || !type || isStandardType || isFixedType}
            required={required && type !== null && !isStandardType && !isFixedType}
            onChange={onChange.onChangeStandardRateDiscount}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>
        <div className="col-2">
          <InputField
            label="Final mgt. charges (psf pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/sf/mo)"
            fieldKey="finalCharge"
            value={finalCharge?.toString() || null}
            disabled={disabled || !type || isStandardType || isDiscountType}
            required={required && type !== null && !isStandardType && !isDiscountType}
            onChange={onChange.onChangeFinalCharge}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>
        <div className="col-2 field field-style">
          <label className="m-t-xs">Inclusive of AC fees?</label>
          <CheckboxField
            data-test=""
            fieldKey="includeAcFee"
            value={includeAcFee}
            toggle={true}
            onChange={onChange.onChange}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default ManagementChargesForm;
