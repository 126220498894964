import GraphWithOutLinedContainer, { GraphInputData } from 'atoms/GraphWithOutLinedContainer';
import AnalysisContext from 'common/model/AnalysisContext';
import Scale from 'common/model/Scale';
import { ContextAnalysisData, PortfolioRevenueAnalysis, PropertyRevenueAnalysis } from 'common/api/dashboard';
import { Colors } from 'utils/utils-colors';
import { getDateFormatForGraph } from 'utils/utils-date';
import { OccupancyVariancePortfolioTooltip, OccupancyVariancePropertyTooltip } from './Tooltip';
import './OccupancyVariance.scss';

interface OccupancyVarianceGraphProps {
  propertyRevenueAnalysis: PropertyRevenueAnalysis;
  portfolioRevenueAnalysis: PortfolioRevenueAnalysis;
  period: Scale;
  isLoading: boolean;
  tab: string;
  context: AnalysisContext;
}

const OccupancyVarianceGraph = ({
  propertyRevenueAnalysis,
  portfolioRevenueAnalysis,
  period,
  isLoading,
  tab,
  context,
}: OccupancyVarianceGraphProps) => {
  // switch between portfolio and property
  const itemData: ContextAnalysisData[] =
    context === AnalysisContext.Property
      ? propertyRevenueAnalysis.propertyData
      : portfolioRevenueAnalysis.portfolioData;

  const tooltip =
    context === AnalysisContext.Property
      ? OccupancyVariancePropertyTooltip(propertyRevenueAnalysis)
      : OccupancyVariancePortfolioTooltip(portfolioRevenueAnalysis, tab);

  // get the labels
  const labels: string[] = itemData.map((data: ContextAnalysisData) =>
    getDateFormatForGraph(new Date(data.month), period as Scale),
  );

  const data: GraphInputData[] = [
    {
      key: 1,
      label: 'Over performance',
      color: Colors.GREEN,
      data: itemData.map((item: ContextAnalysisData) => item.statsArea.occupancyOverPerformance.variance),
    },
    {
      key: 2,
      label: 'Under performance',
      color: Colors.RED,
      data: itemData.map((item: ContextAnalysisData) => item.statsArea.occupancyUnderPerformance.variance),
    },
  ];

  return (
    <GraphWithOutLinedContainer
      className="occupancy-variance"
      title="Occupancy Variance"
      loading={isLoading}
      labels={labels}
      data={data}
      tooltipHTML={tooltip}
      stackedBar={true}
      tooltipXAxisTolerance={context === AnalysisContext.Portfolio ? 450 : 0}
      yAxisTitle="Area (sqft)"
    />
  );
};

export default OccupancyVarianceGraph;
