import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { updateContractOneOffCharges } from 'store/contracts/contractDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const OneOffCharge = ({ mode }: ModeProps) => {
  const { fitOutDeposit, fitOutFee } = useAppSelector(
    (state: RootState) => state.contractDetail.activeContract.oneOffCharges,
  );

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: string | boolean) => dispatch(updateContractOneOffCharges({ key, value }));

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <InputField
            label="Fit-out Deposit"
            dataTest="fit-out-Deposit-input"
            type={InputFieldType.NUMBER}
            unit="(HK$)"
            fieldKey="fitOutDeposit"
            value={fitOutDeposit}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <InputField
            label="Fit-out Fee"
            dataTest="fit-out-fee-input"
            type={InputFieldType.NUMBER}
            unit="(HK$)"
            fieldKey="fitOutFee"
            value={fitOutFee}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default OneOffCharge;
