import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProvisionListingClone } from 'common/_classes';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { getClientType } from 'utils/utils-authentication';

interface ListProvisionsQueryVariables {
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  search: string | null;
}

export const LIST_PROVISIONS = (
  searchValue: string | null,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
) => {
  const variables: ListProvisionsQueryVariables = {
    first: first,
    last: last,
    after: after,
    before: before,
    search: searchValue,
  };

  return {
    query: gql`
      query ($first: Int, $last: Int, $after: String, $before: String, $search: String) {
        listProvisions(
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          filter: {
            search: $search
          }
        ) {
          edges {
            cursor
            node {
              ${ProvisionListingClone.fragments()}
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

export const listProvisions = createAsyncThunk(
  'provisions/listProvisions',
  async ({ first, last, after, before }: PaginationAPIProps) => {
    const response = await getClientType().query(LIST_PROVISIONS(null, first, last, after, before));
    return response;
  },
);
