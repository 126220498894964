import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import ContractRevenueForecastGraph from 'components/Graphs/ContractRevenueForecastGraph';
import RevenueForecastFilter from './RevenueForecastFilter';

const RevenueForecast = ({ contractID }: { contractID: string }) => {
  const { contractRevenueBaseLoading, contractTermDatesLoading, contractRevenueBase } = useAppSelector(
    (state: RootState) => state.contractDetail,
  );

  return (
    <>
      <RevenueForecastFilter contractID={contractID} />
      <ContractRevenueForecastGraph
        contractRevenueBaseLoading={contractRevenueBaseLoading}
        contractTermDatesLoading={contractTermDatesLoading}
        contractRevenueBase={contractRevenueBase}
      />
    </>
  );
};

export default RevenueForecast;
