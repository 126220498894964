import moment from 'moment';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import Tooltip from './Tooltip';

interface PeriodLineProps {
  left: string;
  right: string;
  level: number;
  startDate: Date;
  endDate: Date;
}

const PeriodLine = ({ left, right, level, startDate, endDate }: PeriodLineProps) => {
  return (
    <Tooltip
      trigger={
        <div
          className="timeline-event-period"
          style={{
            left: `calc(${left}  + 1.8rem)`,
            width: `calc(${right} - ${left})`,
            top: `calc(${level} * 1.7428rem)`,
          }}
        >
          <Icon
            className="info-icon"
            icon={Icons.InfoCircle}
          />
        </div>
      }
      content={
        <div>
          <div className="text title">Rent Free Period</div>
          <div className="text ">Start Date: {moment(startDate).format('DD/MM/YYYY')}</div>
          <div className="text">End Date: {moment(endDate).format('DD/MM/YYYY')}</div>
        </div>
      }
    />
  );
};

export default PeriodLine;
