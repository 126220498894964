import { TransactionListingClone } from 'common/_classes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import SelectField from 'atoms/FormField/Select';
import TableReactPrime, { CustomColumnProps } from 'atoms/TableReactPrime';
import { ParameterModes, updateParameterMode } from 'store/miscellaneous/miscellaneousSlice';
import { createNewTransaction } from 'store/transactions/transactionDetailSlice';
import { updateSelectedTenant } from 'store/transactions/transactionsListingSlice';
import { DocumentTypeClone, PaginationAPIProps, listDocumentTypes } from 'common/api/miscellaneous';
import {
  listLandlordTransactions,
  listTenantTransactions,
  listTenantsAndAutoSelectFirstTenant,
  listTransactions,
} from 'common/api/transactions';
import { checkIfAdminURL, checkIfTenantURL } from 'utils/utils-admin';
import TransactionsHeaderToolbar from './TransactionsHeaderToolbar';
import { TransactionRow, transactionColumnConfig } from './TransactionsTableConfig';
import './TransactionsDashboard.scss';

const TransactionsDashboard = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdmin = checkIfAdminURL();
  const isTenant = checkIfTenantURL();
  const listTransactionsAction = isAdmin
    ? listTransactions
    : isTenant
      ? listTenantTransactions
      : listLandlordTransactions;

  const { id: userId } = useAppSelector(({ auth }) => auth);
  const { isLoading, transactionsList, selectedTenant, tenantEntitiesOptions } = useAppSelector(
    (state: RootState) => state.transactionsListing,
  );
  const { notes } = useAppSelector((state: RootState) => state.transactionDetail);

  const { documentTypesList } = useAppSelector(({ miscellaneous }) => miscellaneous);

  const onViewDetails = (id: string): void => {
    if (!isAdmin) {
      const url = `/transactions/${id}/details`;
      navigate(url);
      dispatch(updateParameterMode(ParameterModes.Normal));
    }
  };

  const [columnConfig, setColumnConfig] = useState<CustomColumnProps[]>(
    transactionColumnConfig(documentTypesList, isAdmin, notes, userId, onViewDetails),
  );

  useEffect(() => {
    dispatch(listDocumentTypes({ first: 100 }));
  }, []);

  useEffect(() => {
    setColumnConfig(transactionColumnConfig(documentTypesList, isAdmin, notes, userId, onViewDetails));
  }, [documentTypesList, notes]);

  const onCreateTransaction = (): void => {
    dispatch(createNewTransaction());
    navigate('/transactions/create');
  };

  const fetchTransactions = (params: PaginationAPIProps): void => {
    dispatch(listTransactionsAction({ ...params }));
  };

  const currentList: TransactionRow[] = transactionsList.map(
    (transaction: TransactionListingClone) => new TransactionRow(transaction),
  );

  const getPageHeaderProps = (): PageHeaderProps => {
    const createNewButton: CreateNewButtonProps = {
      dataTest: 'create-transaction-button',
      url: '/transactions/create',
      btnText: 'CREATE TRANSACTION',
      onCreateNew: onCreateTransaction,
    };

    const headerButtons: PageHeaderToolbarProps = {
      createNewButton: !isAdmin && !isTenant ? createNewButton : undefined,
      headerToolbar: <TransactionsHeaderToolbar action={listTransactionsAction} />,
    };

    const breadCrumb: BreadCrumbItem[] = [{ title: 'Transactions' }];

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  useEffect(() => {
    if (isTenant) {
      dispatch(listTenantsAndAutoSelectFirstTenant());
    }
  }, [isTenant]);

  useEffect(() => {
    if (selectedTenant) {
      dispatch(listTransactionsAction({ first: 10000 }));
    }
  }, [selectedTenant]);

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      {isTenant && (
        <div className="grid align-center m-b-sm m-l-xxs">
          <h3 className="bold">Selected Tenant : </h3>
          <div className="col-2">
            <SelectField
              value={selectedTenant}
              options={tenantEntitiesOptions}
              onChange={(key, value) => dispatch(updateSelectedTenant(value))}
            />
          </div>
        </div>
      )}
      <div data-test="transactions-data-table">
        <TableReactPrime
          fetchContent={({ ...rest }) => {
            fetchTransactions(rest);
          }}
          isFetching={isLoading}
          content={currentList}
          columnConfig={columnConfig}
          onRowClick={onViewDetails}
        />
      </div>
    </PageLayout>
  );
};

export default TransactionsDashboard;
