import moment from 'moment';

interface TodayIndicatorProps {
  left: string;
  today: Date;
  maxLevel: number;
}

const TodayIndicator = ({ left, today, maxLevel }: TodayIndicatorProps) => {
  const height = `${maxLevel + 4.6} * 1.1428rem`;
  return (
    <div
      className="timeline-today-box"
      style={{
        left: `calc(${left} + 2.185rem)`,
        top: `calc(${height})`,
      }}
    >
      <div className="timeline-today-text bg-white color-blue-very-dark">
        Today
        <span>{moment(today).format('DD/MM/YYYY')}</span>
      </div>
      <div
        className="timeline-today-pole"
        style={{ top: `calc(-${height})`, height: `calc(${height})` }}
      ></div>
    </div>
  );
};

export default TodayIndicator;
