import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import AnalysisContext from 'common/model/AnalysisContext';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GeneratePropertiesEventsQueryVariables {
  currentLandlordEntityId: string | null;
  context: AnalysisContext;
  contextId: string | null;
  startDate: string;
  endDate: string;
}

const GENERATE_PROPERTIES_EVENTS = (
  context: AnalysisContext,
  contextId: string | null,
  startDate: string,
  endDate: string,
) => {
  if (!context) {
    throw new Error('Generate Properties Events: Context could not be determined. Query aborted.');
  }

  const variables: GeneratePropertiesEventsQueryVariables = {
    currentLandlordEntityId: getCurrentLandlordEntityId(),
    context,
    contextId,
    startDate,
    endDate,
  };

  return {
    query: gql`
      query (
        $currentLandlordEntityId: ID!
        $startDate: Date!
        $endDate: Date!
        $context: AnalysisContext!
        $contextId: ID
      ) {
        generatePropertiesEvents(
          currentLandlordEntityId: $currentLandlordEntityId
          context: $context
          contextId: $contextId
          endDate: $endDate
          startDate: $startDate
        ) {
          propertyId
          startDate
          endDate
          contractEvents {
            area
            contractId
            contractIdentifier
            date
            description
            event
            floors {
              floorId
              floorIndex
              floorName
            }
            tenantId
            tenantName
            units {
              floorId
              floorIndex
              floorName
              unitId
              unitName
            }
          }
          propertiesEvents {
            date
            events {
              area
              event
            }
          }
        }
      }
    `,
    variables,
  };
};

export const generatePropertiesEvents = createAsyncThunk(
  'dashboardTab/generatePropertiesEvents',
  async (args, { getState }) => {
    const {
      dashboard: { context, activePortfolio },
      dashboardTab: {
        dateFilter: { startDate, endDate },
      },
      stackingPlan: { activeProperty },
    } = getState() as RootState;

    let contextId: string | null = null;

    if (context === AnalysisContext.Property && activeProperty) {
      contextId = activeProperty.id;
    } else if (context === AnalysisContext.Portfolio && activePortfolio) {
      contextId = activePortfolio.id;
    }

    const response = await client.query(GENERATE_PROPERTIES_EVENTS(context, contextId, startDate, endDate));
    return response;
  },
);
