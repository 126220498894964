import { useState } from 'react';
import { Card, CardContent, CardHeader, Form, Grid, TextArea } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TextAreaField from 'atoms/FormField/TextArea';
import { useDebounce } from 'components/Editor/PageEditor';
import { ApprovalView, updateApprovalAnswer } from 'store/transactions/transactionDetailSlice';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import TransactionDiscussionApproval from 'common/model/TransactionDiscussionApproval';
import { updateTransactionDiscussionApproval } from 'common/api/transactions/approvals/updateTransactionDiscussionApproval';
import { ChangesBoxType } from '..';
import ChangesTable from './ChangesTable';
import MessagesBox from './MessagesBox';
import './ChangesBox.scss';

interface ChangesBox {
  type: ChangesBoxType;
  date: string;
  rows: TransactionApprovalAnswer[];
}

export enum MessageBoxSize {
  Large = 'large',
  Regular = 'regular',
}

const ChangesBox = ({ type, date, rows }: ChangesBox) => {
  const dispatch = useAppDispatch();

  const { approvalView } = useAppSelector((state: RootState) => state.transactionDetail);
  const submitter: boolean = approvalView === ApprovalView.Submitter;

  const getApproval = () => {
    if (rows.length !== 0) {
      switch (type) {
        case ChangesBoxType.Parameter:
          return rows[0].transactionParameterApproval;
        case ChangesBoxType.Discussion:
          return rows[0].transactionDiscussionApproval;
        case ChangesBoxType.Provision:
          return rows[0].transactionProvisionApproval;
      }
    }
  };

  const approval = getApproval();
  const approvalId = approval?.id;
  const draftApprovalMessage = (approval as TransactionDiscussionApproval)?.draftMessage;
  const [draftMessage, setDraftMessage] = useState(draftApprovalMessage);

  const channel = type === ChangesBoxType.Discussion ? rows[0].transactionMessage?.transactionChannel : null;
  const channelId = channel?.id;
  const channelTitle = channel?.title;

  const getBoxClass = (): string => {
    switch (type) {
      case ChangesBoxType.Discussion:
        return 'discussion-box';

      case ChangesBoxType.Provision:
        return 'provision-box';
      default:
        return '';
    }
  };

  const getMessageBoxSize = () => {
    if (rows.length > 4) {
      return MessageBoxSize.Large;
    } else {
      return MessageBoxSize.Regular;
    }
  };

  const cardTiltle = () => {
    if (type === ChangesBoxType.Discussion) {
      return `${type}: ${channelTitle}`;
    } else {
      return `${type} Changes`;
    }
  };

  const autoSaveDraft = useDebounce(() => {
    dispatch(updateTransactionDiscussionApproval({ approvalId: approvalId as string, draftMessage }));
  }, 2000);

  const onChangeApprovalMessage = (key: string, value: string) => {
    setDraftMessage(value);
    autoSaveDraft(approvalId, value, dispatch);
    dispatch(updateApprovalAnswer({ answerId: rows[0].id, key: 'draftMessage', value }));
  };

  return (
    <>
      <Card className={`changes-box ${getBoxClass()}`}>
        <CardContent>
          <CardHeader>
            {cardTiltle()} <span className="date">{date}</span> {submitter}
            <Grid>
              <Grid.Column
                className="changes-table-column"
                width={type === ChangesBoxType.Parameter ? 16 : 12}
              >
                {/*type === ChangesBoxType.Provision ? <ProvisionDetail approvalId={approvalId} /> : <></>*/}
                <ChangesTable rows={rows} />
              </Grid.Column>

              {channelId && (
                <Grid.Column
                  className="submitter-info-column"
                  width={4}
                >
                  <Grid.Row className="submitter-info-row">
                    <h2 className="header">Internal discussion</h2>
                    <MessagesBox
                      channelId={channelId}
                      approvalId={approvalId}
                      messageBoxSize={getMessageBoxSize()}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Form className="message-to-tenant">
                      {/* @ts-ignore */}
                      <TextAreaField
                        label="Message to tenant"
                        value={draftMessage as string}
                        onChange={onChangeApprovalMessage}
                      />
                    </Form>
                  </Grid.Row>
                </Grid.Column>
              )}
            </Grid>
          </CardHeader>
        </CardContent>
      </Card>
    </>
  );
};

export default ChangesBox;
