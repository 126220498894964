import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { ValidNetworkType } from './updateNetwork';

interface CreateNetworkQueryVariables {
    params: any;
}

export const CREATE_CHILD_ENTITY = (childId: string, parentId: string, sharePercentage: string) => {

    let params: any = {
        type: ValidNetworkType.tenant,
        description: [{ sharePercentage: Number(sharePercentage) }],
        childId,
        parentId
    };

    const variables: CreateNetworkQueryVariables = {
        params: JSON.parse(JSON.stringify(params)),
    };

    return {
        mutation: gql`
        mutation($params: NetworkInput!){
          createNetwork(params: $params){
            childId
            parentId
            type
        }
      }
      `,
        variables,
    };
};

export const createNetwork = createAsyncThunk(
    'entityDetail/createNetwork',
    async ({ childId, sharePercentage, parentId }: { childId: string, parentId: string, sharePercentage: any }, { getState }) => {
        const response = await client.mutate(CREATE_CHILD_ENTITY(childId, parentId, sharePercentage));
        return response;
    },
);
