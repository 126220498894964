import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import {
  giveValidationFeedback,
  incompleteFields,
  mapContractData,
  setRentReviewActiveTab,
} from 'store/contracts/contractDetailSlice';
import {
  checkInvalidFieldsInContract,
  giveUsersErrorFeedbackInContractForm,
  scrollToErrorInContractForm,
  validateContract,
} from 'store/contracts/validations';
import { createContract, getContract, listProperties, updateContract } from 'common/api/contracts';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { createPortfolio } from 'common/api/portfolio';
import { moveTo } from 'utils/utils-actions';

interface ContractDetailTabToolbarProps {
  mode: MODE_OPTIONS;
  setMode: (value: MODE_OPTIONS) => void;
}

const ContractDetailTabToolbar = ({ mode, setMode }: ContractDetailTabToolbarProps) => {
  const location = useLocation();
  const checkCreate: boolean = location.pathname.includes('create');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeContract, clickedURL } = useAppSelector((state: RootState) => state.contractDetail);
  const { id } = useParams();

  /**
   * On Update
   */
  const onUpdate = () => {
    if (checkCreate) {
      dispatch(createPortfolio());
      navigate('/portfolios/dashboard');
    } else {
      giveUsersErrorFeedbackInContractForm(dispatch, giveValidationFeedback);
      const isInValid = validateContract(activeContract);
      const inValidFields = checkInvalidFieldsInContract(activeContract);

      dispatch(incompleteFields({ inValidFields }));

      if (isInValid) {
        scrollToErrorInContractForm(
          dispatch,
          setRentReviewActiveTab,
          activeContract.rent,
          giveValidationFeedback,
          inValidFields,
        );

        toast.error('Please fill the form appropriately');
      } else {
        dispatch(updateContract()).then(() => {
          dispatch(listProperties({ landlordId: activeContract.landlord.id })).then(() => {
            dispatch(mapContractData());
          });
        });
        if (clickedURL !== '') {
          navigate(clickedURL);
        }
      }
    }
  };

  const onCreate = () => {
    giveUsersErrorFeedbackInContractForm(dispatch, giveValidationFeedback);

    const isInValid = validateContract(activeContract);
    const inValidFields = checkInvalidFieldsInContract(activeContract);

    dispatch(incompleteFields({ inValidFields }));

    if (isInValid) {
      scrollToErrorInContractForm(
        dispatch,
        setRentReviewActiveTab,
        activeContract.rent,
        giveValidationFeedback,
        inValidFields,
      );

      setMode(MODE_OPTIONS.EDIT);
      toast.error('Please fill the form appropriately');
    } else {
      moveTo({
        moveToEvent: dispatch(createContract()),
        navigate,
        url: clickedURL === '' ? '/contracts/dashboard' : clickedURL,
      });
    }
  };

  /**
   * On Save
   */
  const onSave = () => {
    if (checkCreate) {
      onCreate();
    } else {
      onUpdate();
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = () => {
    if (checkCreate) {
      navigate('/contracts/dashboard');
    } else {
      dispatch(getContract({ id: id || '' })).then(data => {
        // @ts-ignore
        dispatch(listProperties({ landlordId: data.payload.data.getContract.owner.id })).then(() => {
          dispatch(mapContractData());
        });
      });
    }
  };

  return (
    <DetailsControlButtons
      mode={mode}
      setMode={setMode}
      checkDisabled={false}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
};

export default ContractDetailTabToolbar;
