import { ModeProps } from 'common/api/miscellaneous';
import AcFees from './AcFees';
import GovernmentRates from './GovernmentRates';
import GovernmentRent from './GovernmentRent';
import ManagementCharges from './ManagementCharges';

const OtherCharges = ({ mode }: ModeProps) => {
  return (
    <div className="d-flex-column flex-gap-sm">
      <ManagementCharges mode={mode} />
      <AcFees mode={mode} />
      <GovernmentRent mode={mode} />
      <GovernmentRates mode={mode} />
    </div>
  );
};

export default OtherCharges;
