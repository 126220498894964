import { TransactionMessageClone } from 'common/_classes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dictionary, groupBy } from 'lodash';
import { Icon } from '@iconify/react';
import Badge, { BadgeColor } from 'atoms/Badge';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import ModalTemplate from 'components/ModalTemplate';
import GeneralForm from 'views/transactions/AmendmentCreator/AmendmentForm/GeneralForm';
import { resetActiveAmendment, setActiveMessage } from 'store/transactions/transactionDetailSlice';
import ThreadType from 'common/model/ThreadType';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { AnswerProps } from 'common/api/policies';
import {
  createAmendmentFromTransactionDiscussion,
  createTransactionMessage,
  getTransactionContentsOnly,
  listTransactionMessages,
} from 'common/api/transactions';
import { conditionalClassName } from 'utils/tsHelper';
import { amendmentDisabled } from 'utils/tsValidator';
import { formattedMonthDate, get12HrsTime } from 'utils/utils-date';
import { Icons } from 'utils/utils-icons';
import LinkToParametersModal from './LinkToParametersModal';

export enum DISCUSSION_TABS_OFFSET {
  SHARED = 0,
  INTERNAL = 1,
}

interface DiscussionTabsProps {
  setThread: (thread: ThreadType) => void;
  thread: ThreadType;
  checkIfAllParametersDefined: boolean;
}

const DiscussionTabs = ({ setThread, thread, checkIfAllParametersDefined }: DiscussionTabsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState<DISCUSSION_TABS_OFFSET>(DISCUSSION_TABS_OFFSET.SHARED);
  const [transactionMessageId, setTransactionMessageId] = useState<string>('');
  const [createAmendmentModalShow, setCreateAmendmentModalShow] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { id: userId } = useAppSelector((state: RootState) => state.auth);
  const {
    activeAmendment,
    transactionMessages,
    activeTransaction: { id: activeTransactionId },
    activeTransactionAnswers,
    activeTransactionChannel,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const dateWiseMessages: Dictionary<TransactionMessageClone[]> = groupBy(
    transactionMessages,
    (message: TransactionMessageClone) => formattedMonthDate(message.insertedAt.toString()),
  );

  const onTransferMessage = ({ message, amendmentFlag, amendment }: TransactionMessageClone): void => {
    const threadType: ThreadType.Common | ThreadType.Landlord =
      thread === ThreadType.Common ? ThreadType.Landlord : ThreadType.Common;
    dispatch(
      createTransactionMessage({
        message,
        amendmentFlag,
        thread: threadType,
        amendmentId: amendment?.id,
        loading: true,
        channelId: activeTransactionChannel.id,
      }),
    );
  };

  const Message = ({
    message,
    isLastMessage,
  }: {
    message: TransactionMessageClone;
    isLastMessage: boolean;
  }): JSX.Element => {
    const amendmentViewUrl = `/transactions/${activeTransactionId}/amendment/${message.amendment?.id}/details`;

    const onGotoAmendment = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      navigate(amendmentViewUrl);
    };

    const checkIfParametersLinked = (messageId: string) => {
      return (
        activeTransactionAnswers.filter((answer: AnswerProps) => answer?.transactionMessage?.id === messageId)
          .length !== 0
      );
    };

    const checkIfParametersPending = (messageId: string) => {
      return (
        activeTransactionAnswers.filter(
          (answer: AnswerProps) =>
            answer?.transactionMessage?.id === messageId &&
            [ValidApprovalStates.Pending, ValidApprovalStates.Approved, ValidApprovalStates.Rejected].includes(
              answer?.approvalState as ValidApprovalStates,
            ),
        ).length !== 0
      );
    };

    const onOpenLinkToParameters = (messageId: string) => {
      setIsOpen(true);
      dispatch(setActiveMessage({ messageId }));
    };

    const messageElement: JSX.Element = (
      <div
        className={`message
          ${userId === message.user.id ? 'user-message m-l-auto' : 'other-user-message'}
          ${conditionalClassName(message.amendmentFlag, 'amendment-message')}
          p-xs m-r-s
          ${conditionalClassName(!isLastMessage, 'm-b-s')}
        `}
        data-test="message-container"
        key={`message-${message.id}`}
      >
        {message.amendmentFlag && (
          <span
            className={`amendment-indicator m-r-xs m-b-xs ${conditionalClassName(message.amendment?.id, 'linked')}`}
            data-test="amendment-indicator"
          >
            A
          </span>
        )}
        <span
          className="person"
          data-test="user-message"
        >
          <span
            className="name"
            data-test="sender-name"
          >
            <b>{message.user.firstName}</b>
          </span>
          <span className="time m-l-xxs m-r-s">{get12HrsTime(message.insertedAt)}</span>
          {checkIfParametersLinked(message.id) ? (
            <Badge
              className="m-l-xs"
              badgeColor={checkIfAllParametersDefined ? BadgeColor.GREEN : BadgeColor.PURPLE}
              onClick={() => onOpenLinkToParameters(message.id)}
            >
              Parameter
            </Badge>
          ) : (
            <></>
          )}

          {checkIfParametersPending(message.id) ? (
            <Badge
              className="m-l-xs"
              badgeColor={BadgeColor.ORANGE}
            >
              Pending
            </Badge>
          ) : (
            <></>
          )}

          {message.amendmentFlag && message.amendment?.id && (
            <span>
              <a
                href={amendmentViewUrl}
                onClick={onGotoAmendment}
                className="link"
              >
                Link
              </a>
            </span>
          )}
          {message.amendmentDeletedFlag && <Icon icon={Icons.Delete} />}

          {message.flag && (
            <Icon
              icon={Icons.Flag}
              className="m-l-s"
            />
          )}
        </span>
        {message.amendmentFlag ? (
          <div
            className={`editor-to-html ${conditionalClassName(message.amendmentFlag, 'p-t-xs p-b-xs')}`}
            data-test="amendment-content"
            dangerouslySetInnerHTML={{
              __html: message.message,
            }}
          ></div>
        ) : (
          <pre
            className={`text ${conditionalClassName(message.amendmentFlag, 'p-t-xs p-b-xs')}`}
            data-test="message-content"
          >
            {message.message}
          </pre>
        )}
      </div>
    );

    const internal = tabIndex === DISCUSSION_TABS_OFFSET.INTERNAL;

    const ExternalBox = () => {
      return (
        <>
          <li
            className="p-xs"
            onClick={() => onOpenLinkToParameters(message.id)}
          >
            <Icon
              icon={Icons.Success}
              className="m-r-xs"
            />
            Link Parameters
          </li>
          <li
            className="p-xs"
            aria-disabled="true"
          >
            <Icon
              icon={Icons.Edit}
              className="m-r-xs"
            />
            Modify
          </li>
        </>
      );
    };

    const InternalBox = () => {
      return (
        <li
          className="p-xs"
          onClick={() => onTransferMessage(message)}
        >
          <Icon
            icon={Icons.SwapHorizontal}
            className="m-r-xs"
          />
          Transfer message to internal discussion
        </li>
      );
    };

    return (
      <Popup
        trigger={messageElement}
        flowing
        hoverable
        on="click"
        position="top right"
        className="transaction-message-popup p-none"
      >
        <ul className="menu">{internal ? <ExternalBox /> : <InternalBox />}</ul>
      </Popup>
    );
  };

  const Messages = (): JSX.Element => {
    return (
      <>
        {Object.entries(dateWiseMessages).map(([date, messages]) => (
          <div key={`date-wise-${date}`}>
            <div
              className="date m-r-sm m-l-sm m-t-xs"
              data-test="message-date"
            >
              {formattedMonthDate(date)}
            </div>

            {messages.map((message: TransactionMessageClone, index) => (
              <Message
                message={message}
                key={message.id}
                isLastMessage={index === messages.length - 1}
              />
            ))}
          </div>
        ))}
      </>
    );
  };

  const getTabMenuConfig = (): TabMenuProps[] => {
    return [
      {
        key: 'discussion-tab-external-correspondence',
        label: 'External Correspondence',
        dataTest: 'external-correspondence-tab',
        class: 'external-correspondence-tab',
        customTab: <Messages />,
      },
      {
        key: 'discussion-tab-internal',
        label: 'Internal Discussion',
        dataTest: 'internal-discussion-tab',
        customTab: <Messages />,
      },
    ];
  };

  const onTabChange = (tab: string | number | undefined): void => {
    switch (tab) {
      case DISCUSSION_TABS_OFFSET.SHARED:
        setThread(ThreadType.Common);
        break;
      case DISCUSSION_TABS_OFFSET.INTERNAL:
        setThread(ThreadType.Landlord);
        break;
    }

    setTabIndex(tab as DISCUSSION_TABS_OFFSET);
  };

  const onCancel = (): void => {
    dispatch(resetActiveAmendment());
    setCreateAmendmentModalShow(false);
  };

  const onCreate = (): void => {
    dispatch(createAmendmentFromTransactionDiscussion(transactionMessageId)).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(getTransactionContentsOnly({ id: activeTransactionId! }));
        dispatch(listTransactionMessages({ thread }));
        setCreateAmendmentModalShow(false);
        dispatch(resetActiveAmendment());
      }
    });
  };

  return (
    <>
      <TabReact
        className="discussion-tab p-t-xxs"
        activeTabIndex={tabIndex}
        customTabChangeAction={onTabChange}
        tabMenu={getTabMenuConfig()}
      />

      {/* Modal To Create Amendment From a Transaction Discussion  */}
      <ModalTemplate
        onToggleModalStatus={setCreateAmendmentModalShow}
        isOpen={createAmendmentModalShow}
        title="Create Amendment"
        className="amendment-modal"
      >
        <GeneralForm mode={MODE_OPTIONS.EDIT} />

        <div className="btn-row">
          <Button
            className="btn grey-outline"
            onClick={onCancel}
          >
            CANCEL
          </Button>
          <Button
            className="btn grey-bg"
            disabled={amendmentDisabled(activeAmendment)}
            onClick={onCreate}
          >
            SAVE
          </Button>
        </div>
      </ModalTemplate>
      <LinkToParametersModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default DiscussionTabs;
