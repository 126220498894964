import { ChartType, TooltipModel } from 'chart.js';
import moment from 'moment';
import { ContractData, ContractRevenueBase, Message } from 'common/api/contracts';
import { invertDateFormatForGraph } from 'utils/utils-date';

export const ContractRevenueForecastTooltip =
  (data: ContractRevenueBase) =>
  ({ title: [title], body }: TooltipModel<ChartType>) => {
    const [contractualRevenue, headlineRent, effectiveRent] = body.map(({ lines }) => lines);

    const date: Date = invertDateFormatForGraph(title, data.scale);

    const match: ContractData = data.contractData.find(
      item =>
        //TODO item.month should be a Date and not a String, to clean when the API automation will be done
        item.month === moment(date).format('YYYY-MM-DD'),
    ) as ContractData;

    let alertsHtml: string = match.alerts.map((item: Message) => `<div class="alert">${item.msg}</div>`).join('');
    let eventsHtml: string = match.events.map((item: Message) => `<div class="alert">${item.msg}</div>`).join('');

    return `
        <div class="inner-html revenue-forecast color-blue-very-dark">
            <h4 class="title">Revenue Forecast - ${title}</h4>
            <p><span class="circle bg-red"></span>${contractualRevenue} HK$</p>
            <p><span class="circle bg-green"></span>${headlineRent} HK$</p>
            <p><span class="circle bg-green-lime"></span>${effectiveRent} HK$</p>
            ${alertsHtml}
            ${eventsHtml}
        </div>
    `;
  };
