import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { ID } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { convertDateToISO } from 'utils/utils-date';

interface GetContractRevenueProps {
  contractId: string;
  startDate: string;
  endDate: string;
  period: string;
}

interface GetContractRevenueQueryVariables {
  contractId: string;
  currentLandlordEntityId: string;
  startDate: string | null;
  endDate: string | null;
  period: string;
}

export const GET_CONTRACT_REVENUE = ({ contractId, startDate, endDate, period }: GetContractRevenueProps) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) {
    throw new Error('Get Contract Revenue: Landlord Entity could not be determined. Query aborted.');
  }

  if (!contractId) {
    throw new Error('Get Contract Revenue: Contract ID could not be determined. Query aborted.');
  }

  const queryVariables: GetContractRevenueQueryVariables = {
    currentLandlordEntityId,
    contractId,
    startDate: convertDateToISO(startDate),
    endDate: convertDateToISO(endDate),
    period,
  };

  return {
    query: gql`
      query ($contractId: ID!, $currentLandlordEntityId: ID!, $endDate: Date!, $startDate: Date!, $period: Scale!) {
        getContractRevenue(
          contractId: $contractId
          currentLandlordEntityId: $currentLandlordEntityId
          endDate: $endDate
          startDate: $startDate
          period: $period
        ) {
          contractId
          contractIdentifier
          contractData {
            alerts {
              msg
            }
            events {
              msg
            }
            month
            stats {
              contractualRevenue
              headlineRent
              effectiveRent
            }
          }
          unitsData {
            month
            stats {
              contractualRevenue
              headlineRent
              effectiveRent
            }
            unitId
            floorName
            unitName
          }
          endDate
          scale
          startDate
        }
      }
    `,
    variables: queryVariables,
  };
};

export const getContractRevenue = createAsyncThunk('contracts/getContractRevenue', async ({ id }: ID, { getState }) => {
  const {
    contractDetail: {
      contractRevenueBase: { startDate, endDate, scale },
    },
  } = getState() as RootState;
  const response = await client.query(GET_CONTRACT_REVENUE({ contractId: id, startDate, endDate, period: scale }));
  return response;
});
