import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import { addRentPeriod } from 'store/contracts/contractDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { Icons } from 'utils/utils-icons';
import RentPeriod from './RentPeriod';
import './PeriodOverview.scss';

const PeriodAndReview = ({ mode }: ModeProps) => {
  const dispatch = useDispatch();

  const enabled: boolean = mode === MODE_OPTIONS.EDIT;

  const addPeriod = () => {
    if (enabled) {
      dispatch(addRentPeriod());
    }
  };

  return (
    <div className="rent-review-and-period-subsection bg-transparent">
      <div className="top m-b-sm d-flex">
        <h5 className="header m-none bg-transparent color-blue-very-dark font-600 m-l-m">Rent Period & Rent Review</h5>

        {enabled && (
          <div
            className="add font-600 d-flex p-xs m-l-auto color-blue-very-dark-grayish"
            role="button"
            tabIndex={0}
            onClick={addPeriod}
            data-test="add-rent-period"
          >
            <span className="icon bg-orange height-1_286 width-1_286 m-r-xs  d-flex">
              <Icon
                icon={Icons.Add}
                className="color-white"
              />
            </span>
            <span>Add rent period / rent review</span>
          </div>
        )}
      </div>

      <RentPeriod mode={mode} />
    </div>
  );
};

export default PeriodAndReview;
