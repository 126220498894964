import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TabContainer from 'components/TabContainer';
import { initiateNewContract, mapContractData } from 'store/contracts/contractDetailSlice';
import { getContract, listProperties } from 'common/api/contracts';
import { listLandlordAndTenantEntities } from 'common/api/entities';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import ContractForm from '../../ContractForm';
import ContractDetailTabToolbar from './ContractDetailTabToolbar';
import './CreatorViewer.scss';

const ContractDetailsTab = ({ contractId }: { contractId?: string }): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const checkCreate: boolean = location.pathname.includes('create');

  const landlordId = useAppSelector((state: RootState): string | null => {
    const fetchedContractData = state.contractDetail.fetchedContractData;
    // @ts-ignore
    return fetchedContractData?.owner?.id;
  });

  const [mode, setMode] = useState<MODE_OPTIONS>(checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ);
  const [calledOnce, setCalledOnce] = useState<boolean>(false);
  const { id } = useParams();
  const formRef = useRef(null);
  const contractID = contractId ? contractId : id;

  useEffect(() => {
    dispatch(listLandlordAndTenantEntities());
    if (checkCreate) {
      dispatch(initiateNewContract());
    } else {
      // @ts-ignore
      dispatch(getContract({ id: contractID }));
    }
  }, [dispatch, contractID]);

  useEffect(() => {
    if (!checkCreate && landlordId && !calledOnce) {
      dispatch(listProperties({ landlordId })).then(() => {
        dispatch(mapContractData());
        setCalledOnce(true);
      });
    }
  }, [landlordId, dispatch, calledOnce]);

  return (
    <TabContainer
      mode={mode}
      tabTitle={'Details'}
      tabToolbar={
        <ContractDetailTabToolbar
          mode={mode}
          setMode={setMode}
        />
      }
    >
      <ContractForm
        mode={mode}
        ref={formRef}
      />
    </TabContainer>
  );
};

export default ContractDetailsTab;
