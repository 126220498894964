import Duration from 'common/model/Duration';

export class CDuration {
  days: number;
  months: number;
  years: number;

  constructor(input: Duration) {
    this.days = input.days;
    this.months = input.months;
    this.years = input.years;
  }

  static fragment() {
    return `
        days
        months
        years
    `;
  }

  static toString(value: Duration | CDuration): string {
    let result = '';

    if (value.years) result += `${value.years} year${value.years > 1 ? 's' : ''}`;
    if (value.months)
      result += `${!value.years ? '' : value.days ? ', ' : ' and '} ${value.months} month${value.months > 1 ? 's' : ''}`;
    if (value.days)
      result += `${value.months || value.years ? ' and ' : ''} ${value.days} day${value.days > 1 ? 's' : ''}`;

    return result;
  }
}
