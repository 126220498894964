import DropdownSelect from 'atoms/DropdownSelect';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { checkIfNotDefined } from 'utils/tsHelper';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { RowFilterChildProps } from '.';
import { ComparablesTableFilterDropdownOptions, DataType, FilterMode, RowAction, RowProps } from '../../RowProps';

const FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [
  { key: 1, text: 'Equals', value: FilterMode.EQUALS },
  { key: 2, text: 'Greater than', value: FilterMode.GREATER_THAN },
  { key: 3, text: 'Less than', value: FilterMode.LESS_THAN },
];

export function applyNumbersFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyNumbersFilter: field key is missing');

  let value = getNestedValue(content, rowConfig.field);

  if (checkIfNotDefined(value)) {
    value = 0;
  }

  if (rowConfig.dataType === DataType.DECIMAL) {
    value = value.toNumber();
  }

  if (checkIfNotDefined(rowAction.filterValue) || Number.isNaN(rowAction.filterValue)) return true;

  switch (rowAction.filterMode) {
    case FilterMode.EQUALS:
      return value === rowAction.filterValue;
    case FilterMode.GREATER_THAN:
      return rowAction.filterValue < value;
    case FilterMode.LESS_THAN:
      return rowAction.filterValue > value;
  }
  return true;
}

const NumberFilter = ({
  filterMode,
  filterValue,
  label,
  onChange,
  dataType,
  onFilterModeChange,
}: RowFilterChildProps): JSX.Element => {
  return (
    <div className="grid">
      <div className="col-6">
        <DropdownSelect
          placeholder="Select..."
          fieldKey="mode"
          value={filterMode}
          options={FILTER_OPTIONS}
          onChange={(key, value) => onFilterModeChange(key, value as string)}
        />
      </div>
      <div className="col-6">
        <InputField
          type={InputFieldType.NUMBER}
          value={filterValue}
          placeholder={'Search By ' + label}
          onChange={(key, value) => onChange(key, Number(value))}
          fieldKey="value"
          isDecimal={dataType === DataType.DECIMAL}
        />
      </div>
    </div>
  );
};

export default NumberFilter;
