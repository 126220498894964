import ConditionComparator from 'common/model/ConditionComparator';
import ConditionsOperator from 'common/model/ConditionsOperator';
import { DROPDOWN_OPTION } from 'utils/UI';

export const PREFIX_CONDITION: DROPDOWN_OPTION[] = [
  { value: ConditionsOperator.And, text: 'AND' },
  { value: ConditionsOperator.Or, text: 'OR' },
].map((e, key: number) => ({ ...e, key }));

export const LOGIC_COMPARISON: DROPDOWN_OPTION[] = [
  { value: ConditionComparator.Equal, text: '==' },
  { value: ConditionComparator.NotEqual, text: '!=' },
  { value: ConditionComparator.GreaterThan, text: '>' },
  { value: ConditionComparator.GreaterThanOrEqual, text: '>=' },
  { value: ConditionComparator.LessThanOrEqual, text: '<=' },
].map((e, key: number) => ({ ...e, key }));

export const LOGIC_COMPARISON_2: DROPDOWN_OPTION[] = [
  { value: ConditionComparator.Equal, text: '==' },
  { value: ConditionComparator.NotEqual, text: '!=' },
].map((e, key: number) => ({ ...e, key }));

export const LOGIC_COMPARISON_3: DROPDOWN_OPTION[] = [
  { value: ConditionComparator.Equal, text: '==' },
  { value: ConditionComparator.NotEqual, text: '!=' },
  { value: ConditionComparator.None, text: 'None' },
  { value: ConditionComparator.Any, text: 'Any' },
  { value: ConditionComparator.All, text: 'All' },
  { value: ConditionComparator.NotAll, text: 'Not all' },
].map((e, key: number) => ({ ...e, key }));
