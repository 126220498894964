import { IUserClone } from 'common/_classes';
import Country from 'common/model/Country';
import DocumentType from 'common/model/DocumentType';
import ReviewStatus from 'common/model/ReviewStatus';
import { UserProps } from 'common/api/transactions';
import { DROPDOWN_OPTION } from 'utils/UI';
import { OmitMultiple } from 'utils/UI';

export * from './getPDFDocument';
export * from './getPdfHtml';
export * from './listCountries';
export * from './listDocumentTypes';
export * from './listLanguages';

export interface CountryClone extends Country {
  // TODO should extend DROPDOWN_OPTION, maybe also merge key and index
  flag?: string;
  // used for dropdown
  index: number;
  key?: string | null;
  value?: string | null;
  text?: string | null;
}

export interface DocumentProps {
  name: string;
  path: string;
  public: boolean;
  title?: string;
}

export interface DocumentIntendedToBeUploaded extends DocumentProps {
  id?: string;
  local?: boolean;
  url?: string;
  file?: any;
  cdnUrl?: string;
  uploadUrl?: string;
  insertedAt?: string;
}

export type DocumentTypeClone = OmitMultiple<DocumentType, 'insertedAt' | 'updatedAt'>;

export type ReviewStatusLocalType = {
  date: Date | null;
  user: {
    id: string | null;
    firstName: string | null;
    lastName: string | null;
  } | null;
};

export type ReviewStatusMutationType = {
  date: string | null;
  userId: string | null;
  user?: UserProps | null;
};

export type ID = { id: string };

export interface DropdownProps extends Omit<DROPDOWN_OPTION, 'key'> {
  key: string;
  selected?: boolean;
}

export enum MODAL_MODE_OPTIONS {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum MODE_OPTIONS {
  READ = 'read',
  EDIT = 'edit',
}

export interface ModeProps {
  mode: MODE_OPTIONS;
}

export interface RentReviewProps {
  mode: MODE_OPTIONS;
  rentPeriod: number;
}

export interface PaginationAPIProps {
  searchValue?: string | null;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
}

export interface setModeProps {
  setMode: (mode: MODE_OPTIONS) => void;
}

export interface TabButtonsProps extends setModeProps {
  mode: MODE_OPTIONS;
}

export interface ReviewStatusClone extends OmitMultiple<ReviewStatus, 'date' | 'user'> {
  date: string | Date | null;
  user?: UserProps | IUserClone | null;
  userId?: string | null; //TODO: check if it can be removed
}

export type ActionCreator = import('@reduxjs/toolkit').ActionCreatorWithPayload<any, string>;

export interface ViewProps {
  isViewPage?: boolean;
}

// Added here for the moment to fix the init bug

/* In the user dashboard/forecasting view, user can select between
 *  - a table view
 *  - a graph view
 */
// TODO Added here for the moment to fix the init bug
export enum DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS {
  TABLE = 'TABLE',
  GRAPH = 'GRAPH',
}

/* In the Admin Parameter view, user can view by:
 *  - group => card view
 *  - parameter => list of parameters
 *  - table of parameters => list of tables
 */
// TODO Added here for the moment to fix the init bug
export enum PARAMETERS_DASHBOARD_VIEW_OPTIONS {
  GROUP = 'GROUP',
  PARAMETER = 'PARAMETER',
  TABLE = 'TABLE',
}

/* To define the different implmentation  the Admin Parameter view, user can view by:
 *  - parameter => list of parameters
 *  - table of parameters => list of tables
 */
// TODO Added here for the moment to fix the init bug
export enum PARAMETER_TABLE_IMPLEMENTATION {
  PARAMETER = 'PARAMETER',
  TABLE = 'TABLE',
}

// to define the parameter classification options (category or group)
export enum PARAMETERS_CLASSIFICATION_OPTIONS {
  CATEGORY = 'CATEGORY',
  GROUP = 'GROUP',
}

// to defined the environments as defined in server app env variables
export enum ENVIRONMENT_TYPES {
  DEMO = 'demo',
  DEV = 'dev',
  PROD = 'prod',
  STAGING = 'preprod',
}
