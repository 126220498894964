import moment from 'moment';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { setRentReviewActiveTab } from 'store/contracts/contractDetailSlice';
import { rentFieldsValidation } from 'store/contracts/validations';
import { ModeProps } from 'common/api/miscellaneous';
import ReviewAndPeriodAndFreePeriod from './Container';

const getDate = (date: Date | string | null) => {
  return date ? moment(date).format('DD/MM/YYYY') : '';
};

const RentPeriod = ({ mode }: ModeProps) => {
  const {
    activeContract: {
      rent: { periods },
    },
    rentReviewActiveTab,
  } = useAppSelector((state: RootState) => state.contractDetail);

  const tabMenu: TabMenuProps[] = [];

  for (let i = 1; i <= periods.length; i++) {
    const { description } = periods[i - 1];

    const dateRange =
      description?.endDate && description?.startDate
        ? `(${getDate(description?.startDate)} - ${getDate(description?.endDate)})`
        : '';

    const checkError = rentFieldsValidation(periods[i - 1], i);

    tabMenu.push({
      class: checkError ? 'error-tab' : '',
      key: `rent-period-${i}`,
      href: '?tab=dashboard',
      dataTest: 'rent-period-tab',
      label: (
        <>
          Rent Period {i} <span className="date-range color-blue-very-dark-grayish m-l-xs">{dateRange}</span>
        </>
      ),
      customTab: (
        <ReviewAndPeriodAndFreePeriod
          mode={mode}
          periodIndex={i - 1}
        />
      ),
    });
  }

  return (
    <TabReact
      className="rent-period-tabs"
      activeTabIndex={rentReviewActiveTab}
      onTabChangeAction={setRentReviewActiveTab}
      tabMenu={tabMenu}
    />
  );
};

export default RentPeriod;
