import { refreshPremises } from 'components/PremisesForm/utils-premiseForm';
import RentReviewType from 'common/model/RentReviewType';
import { getNewReviewer } from 'utils/tsHelper';
import { checkNotEmpty } from 'utils/tsValidator';
import { isValidNumber } from 'utils/utils-number';

export const undoContractReviewed = (contract: any) => {
  contract.reviewStatus = {
    date: null,
    user: getNewReviewer(),
  };
  contract.reviewFlag = false;
};

export const getIncrementByPercentage = (currentValue: number, percentage: number): number => {
  return currentValue + (currentValue * percentage) / 100;
};

export const updateAllBaseRentWithIncrementReviewType = (periodIndex: number, periods: any[]): void => {
  for (let i = periodIndex + 1; i < periods.length; i++) {
    const { description: previousDescription } = periods[i - 1];

    const {
      description,
      review: { type, increment },
    } = periods[i];

    if (!type || !increment) {
      return;
    }

    const parsedIncrement = parseInt(increment);
    const newBaseRent = getIncrementByPercentage(parseInt(previousDescription.monthlyBaseRent), parsedIncrement);

    if (type === RentReviewType.Increment && isValidNumber(parsedIncrement)) {
      description.monthlyBaseRent = isValidNumber(newBaseRent) ? newBaseRent : null;
    }
  }
};

export const validateAllRents = (periodIndex: number, periods: any[]): void => {
  for (let i = periodIndex; i < periods.length; i++) {
    const { description, review } = periods[i];
    const { monthlyBaseRent } = description;
    if (i === 0) {
      const isValid = checkNotEmpty(monthlyBaseRent);
      description.validated = isValid ? true : false;
    } else {
      const { type } = review;

      const {
        description: { monthlyBaseRent: previousBaseRent, validated: previousValidated },
      } = periods[i - 1];

      if (type === RentReviewType.Increment) {
        const isValid =
          checkNotEmpty(monthlyBaseRent) &&
          checkNotEmpty(previousBaseRent) &&
          isValidNumber(previousBaseRent) &&
          isValidNumber(monthlyBaseRent) &&
          previousValidated;

        description.validated = isValid ? true : false;
      } else if (type === RentReviewType.New) {
        const isValid = checkNotEmpty(monthlyBaseRent) && isValidNumber(monthlyBaseRent);

        description.validated = isValid ? true : false;
      }
    }
  }
};

export const getNewContract = () => ({
  id: null,
  tenantId: null,
  landlord: {
    id: null,
    properties: [],
  },
  startDate: null,
  endDate: null,
  identifier: null,
  description: null,
  premises: refreshPremises(),
  rent: {
    type: 'BASE',
    periods: [
      {
        // Rent review for first period is always null
        review: null,
        description: {
          endDate: null,
          monthlyBaseRent: null,
          monthlyEffectiveRent: null,
          startDate: null,
          validated: false,
        },
        freePeriod: {
          hasRentFreePeriods: false,
          freePeriods: [],
        },
        additionalFeesAndCharges: {
          governementRates: null,
          governementRent: null,
          managementFees: null,
          airConCharges: null,
        },
      },
    ],
  },
  security: {
    type: null,
    amount: {
      bankGuaranteeAmount: null,
      depositCashAmount: null,
      otherDeposit: '',
    },
  },
  flags: {
    expansionFlag: false,
    assignmentFlag: false,
    redevelopmentFlag: false,
    refusalFlag: false,
    renewFlag: false,
    subletFlag: false,
    surrenderFlag: false,
  },
  termination: {
    terminationDate: null,
    terminationDescription: null,
    terminationReason: null,
  },
  documents: [],
  capitalAllowance: {
    flagOn: false,
    value: null,
  },
  oneOffCharges: {
    fitOutDeposit: null,
    fitOutFee: null,
  },
  reviewFlag: false,
  reviewStatus: {
    date: null,
    user: {
      id: null,
      firstName: null,
      lastName: null,
    },
  },
});

export const getTotalAreaOfSelectedFloors = (floors: any[]) =>
  floors.reduce((previousValue, { totalSpaceArea }) => previousValue + parseInt(totalSpaceArea), 0);
