import { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CheckboxField from 'atoms/FormField/Checkbox';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import {
  updateEffectiveRent,
  updateRentPeriodMonthlyBaseRent,
  updateRentPeriodValidity,
} from 'store/contracts/contractDetailSlice';
import { getTotalAreaOfSelectedFloors } from 'store/contracts/helper';
import RentReviewType from 'common/model/RentReviewType';
import { calculateEffectiveRent } from 'common/api/contracts';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { evalMeasurement } from 'utils/tsHelper';
import { validateLocalRentPeriod } from 'utils/tsValidator';
import { isValidNumber } from 'utils/utils-number';
import { addSpaceOrComma } from 'utils/utils-number';
import MonthlyBaseRentFeedBack from './MonthlyBaseRentFeedBack';
import './RentPeriod.scss';

const RentPeriodForm = ({ mode, periodIndex }: { mode: MODE_OPTIONS; periodIndex: number }) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const {
    startDate,
    endDate,
    monthlyBaseRent,
    monthlyEffectiveRent,
    validated,
    review,
    measurementUnit,
    previousBaseRent,
    selectedFloors,
    freePeriod,
    allPeriods,
  } = useAppSelector((state: RootState) => {
    const activeContract = state.contractDetail.activeContract;
    const periods = activeContract.rent.periods;
    const premises = activeContract.premises;
    const runContractValidation = state.contractDetail.runContractValidation;

    const { property, floorIds } = premises[0];
    const { measurementUnit } = property || { measurementUnit: '' };

    const previousBaseRent = periods[periodIndex - 1]?.description.monthlyBaseRent;

    return {
      ...periods[periodIndex].description,
      review: periods[periodIndex].review,
      freePeriod: periods[periodIndex].freePeriod,
      runContractValidation,
      measurementUnit: measurementUnit ? evalMeasurement(measurementUnit, null, true) : null,
      previousBaseRent,
      selectedFloors: floorIds,
      allPeriods: periods,
    };
  });

  const { type: reviewType, cap, collar } = review || { type: '', cap: '', collar: '' };

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: any) => {
    if (key === 'validated') {
      dispatch(updateRentPeriodValidity({ periodIndex, value }));
    } else if (key === 'monthlyEffectiveRent') {
      dispatch(updateEffectiveRent({ value, periodIndex }));
    } else {
      dispatch(updateRentPeriodMonthlyBaseRent({ value, periodIndex }));
    }
  };

  const isOpenMarket: boolean = reviewType === RentReviewType.OpenMarketUpDown || periodIndex === 0;

  const getRentPerSelectedArea = () => {
    const totalArea = getTotalAreaOfSelectedFloors(selectedFloors);
    const rent = parseInt(monthlyBaseRent || '0');

    const rentPerArea = isValidNumber(rent) ? rent / totalArea : null;

    return `(~ ${rentPerArea && totalArea ? addSpaceOrComma(rentPerArea) : 0} HK$ ${
      measurementUnit ? `per ${measurementUnit}` : ''
    })`;
  };

  const canDisplayFeedback = () => {
    const parseCollar = parseInt(collar);
    const parseCap = parseInt(cap);

    if (!monthlyBaseRent) {
      return false;
    }
    if (isValidNumber(parseCollar) && isValidNumber(parseCap) && parseCollar > parseCap) {
      return false;
    }

    if (
      reviewType === RentReviewType.OpenMarketUpDown &&
      isValidNumber(parseCollar) &&
      isValidNumber(parseCap) &&
      isValidNumber(previousBaseRent)
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (mode === MODE_OPTIONS.EDIT && validateLocalRentPeriod(allPeriods[periodIndex])) {
      if (timer) {
        clearTimeout(timer);
      }

      setTimer(setTimeout(() => dispatch(calculateEffectiveRent(periodIndex)), 1000));
    }
  }, [startDate, endDate, monthlyBaseRent, validated, review, freePeriod]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={3}>
          <DateField
            label="Start date"
            fieldKey="startDate"
            dataTest="start-date-input"
            value={startDate}
            disabled={true}
          />
        </Grid.Column>

        <Grid.Column width={3}>
          <DateField
            label="End date"
            fieldKey="endDate"
            dataTest="end-date-input"
            value={endDate}
            disabled={true}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={3}>
          <span className="position-left-rent-title color-blue-desaturated-dark">Rent</span>
          <InputField
            label="Monthly base rent"
            dataTest="monthly-base-rent-input"
            type={InputFieldType.NUMBER}
            unit="(HK$)"
            fieldKey="monthlyBaseRent"
            value={monthlyBaseRent ? monthlyBaseRent : null}
            disabled={mode === MODE_OPTIONS.READ || !isOpenMarket}
            onChange={onChange}
            isDecimal={true}
            required={mode === MODE_OPTIONS.EDIT && periodIndex === 0}
            delimiter="COMMA"
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <InputField
            label="Effective rent"
            dataTest="effective-rent-input"
            type={InputFieldType.NUMBER}
            unit="(HK$)"
            fieldKey="monthlyEffectiveRent"
            value={monthlyEffectiveRent ? monthlyEffectiveRent : null}
            disabled={true}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
          />
        </Grid.Column>

        <Grid.Column
          width={8}
          className="non-input-field"
        >
          <div className="wrapper">
            <span className="m-r-ml">{getRentPerSelectedArea()}</span>
            <CheckboxField
              className="m-r-xs"
              fieldKey="validated"
              dataTest="rent-values-validated-checkbox"
              value={validated}
              disabled={reviewType !== RentReviewType.OpenMarketUpDown || mode === MODE_OPTIONS.READ}
              onChange={onChange}
            />
            <span className="ml"> Rent values validated</span>
          </div>
        </Grid.Column>
      </Grid.Row>

      {canDisplayFeedback() && (
        <MonthlyBaseRentFeedBack
          collar={collar}
          cap={cap}
          previousBaseRent={previousBaseRent}
          currentBaseRent={monthlyBaseRent}
        />
      )}
    </Grid>
  );
};

export default RentPeriodForm;
