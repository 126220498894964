import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ConfirmPopup from 'atoms/ConfirmPopup';
import { setUnsavedModalStatus } from 'store/contracts/contractDetailSlice';

const UnsavedChangesModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { clickedURL, unsavedModalStatus } = useAppSelector((state: RootState) => state.contractDetail);

  const createSaveBtn = () => {
    dispatch(setUnsavedModalStatus({ status: false }));
    const btnId = window.location.pathname === '/benchmarks/create' ? 'create-benchmark-btn' : 'benchmark-save-btn';
    document.getElementById(btnId)?.click();
  };

  const onCancel = ({ clickOutside }: { clickOutside: boolean }) => {
    dispatch(setUnsavedModalStatus({ status: false }));

    if (!clickOutside) {
      navigate(clickedURL);
    }
  };

  return (
    <ConfirmPopup
      trigger={unsavedModalStatus}
      content="
          You have unsaved modifications on this page. <br /> <br />
          Do you want to save before exiting? <br /> <br />
          If YES, your modifications will be saved upon exiting the page.
        "
      confirmButtonText="YES"
      onConfirm={createSaveBtn}
      cancelButtonText="NO"
      onCancel={onCancel}
    />
  );
};

export default UnsavedChangesModal;
