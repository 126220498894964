import { ReactElement, ReactNode } from 'react';
import SortOrder from 'common/model/SortOrder';
import { DROPDOWN_OPTION } from 'utils/UI';
import { RowFilterChildProps } from './RowFilterPopup/RowFilterContent';

export enum DataType {
  STRING,
  NUMBER,
  DECIMAL,
  BOOLEAN,
  DATE,
  DURATION,
  LIST,
  PREMISE,
}

export interface ComparablesTableFilterDropdownOptions extends Omit<DROPDOWN_OPTION, 'value'> {
  value: FilterMode;
}

export enum FilterMode {
  CONTAINS = 'CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
}

export interface RowProps {
  /**
   * Unique ID
   */
  id: number;

  /**
   * Label of Row element
   */
  label: string;

  /**
   * Key for the row in content object
   */
  field?: string | string[];

  /**
   * Custom Class for row
   */
  columnClass?: string;

  /**
   * true if row is an heading item
   */
  isHeading?: boolean;

  /**
   * Data Type of row item
   *
   */
  dataType?: DataType;

  /**
   * Options for multiselect dropdown
   */
  options?: Array<{ value: string }>;

  /**
   * Custom template for row item
   * @param item
   * @returns
   */
  body?: (item: any) => string | ReactNode;

  /**
   * Custom Filter element for row item
   * @param props
   * @returns
   */
  filterElement?: (props: RowFilterChildProps) => ReactElement;

  /**
   * Custom filter function for row item
   */
  filterFunction?: (row: RowProps, content: any, rowAction?: RowAction) => boolean;

  /**
   * true if you want to hide filter
   */
  hideFilter?: boolean;

  /**
   * True if you want to hide sorting
   */
  hideSorting?: boolean;
}

/**
 * RowAction is used to saves all the actions (filtering and sorting) that needs to be done on each row.
 * When user clicks on the 'sort' button of a row, rowActions is updated.
 */
export interface RowAction {
  /**
   * Unique ID
   */
  id: number;

  /**
   * Filter mode of row filter
   * like: contains, start with
   */
  filterMode?: FilterMode;

  /**
   * Filter value of row filter
   */
  filterValue?: any;

  /**
   * Sorting type of row
   */
  sortOrder?: SortOrder;
}

/**
 *
 * @param rowConfig
 * @param rowActions
 * @returns the RowAction that corresponds to the rrowConfig.
 */
export const getRowAction = (rowConfig: RowProps, rowActions: RowAction[]): RowAction | undefined => {
  return rowActions.find(row => row.id === rowConfig.id);
};
