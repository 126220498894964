import { Chart as ChartJS, registerables } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { Colors } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import { getPercentage } from 'utils/utils-number';
import { addSpaceOrComma } from 'utils/utils-number';
import { doughnutBoxTooltip } from './tooltip';
import './DoughnutBox.scss';

export enum DoughnutBoxType {
  Occupancy = 'Occupancy',
  Availability = 'Availability',
}

ChartJS.register(...registerables);

export const options = {
  rotation: -90,
  circumference: 180,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      // Disable the on-canvas tooltip
      enabled: false,

      external: (context: any) => doughnutBoxTooltip(context, 'chartjs-tooltip-or'),
    },
  },
};

const DoughnutBox = ({ type, dataTest }: { type: DoughnutBoxType; dataTest: string }) => {
  const { summaryStats } = useAppSelector((state: RootState) => state.dashboardTab);

  const isOccupancy: boolean = type === DoughnutBoxType.Occupancy;
  const lowerArea: string = isOccupancy ? 'Vacant' : 'Available';
  const secondParam: string = isOccupancy ? 'Vacant Area' : 'Available for Lease';
  const labels: string[] = ['Leased Area', secondParam];

  let areas = {
    occupiedArea: 0,
    vacantArea: 0,
    totalArea: 0,
  };

  const CURRENT_MONTH = 1;

  if (summaryStats !== null) {
    if (summaryStats.stats.length > 0) {
      const { statsOccupancy, statsAvailability } = summaryStats.stats[CURRENT_MONTH];

      if (isOccupancy) {
        areas = statsOccupancy;
      } else {
        areas = statsAvailability;
      }
    }
  }

  const leasePercentage: number = getPercentage(Number(areas.occupiedArea), Number(areas.totalArea));
  const vacancyPercentage: number = getPercentage(Number(areas.vacantArea), Number(areas.totalArea));

  const data = {
    labels,
    datasets: [
      {
        data: [leasePercentage, vacancyPercentage],
        backgroundColor: [Colors.CYAN_MODERATE, Colors.ORANGE_SOFT],
        borderColor: [Colors.WHITE],
        borderWidth: 7,
      },
    ],
  };

  const getDegreeRotation = (): string => {
    const totalAngle = 180;
    const anglePerPercentage = (Number(leasePercentage) * totalAngle) / 100;
    return anglePerPercentage + 'deg';
  };

  return (
    <div className="doughnut-box p-m">
      <div
        className="container w-100"
        data-test={dataTest}
      >
        <h2 className="header w-100 color-blue-very-dark-grayish">
          {type}
          <Icon
            className="info-icon"
            icon={Icons.InfoCircle}
          />
        </h2>

        <div className="m-t-xs">
          <p
            className="info-text m-none m-b-xxs  color-blue-very-dark-grayish area"
            data-test="total-area"
          >
            Total area: {addSpaceOrComma(areas.totalArea, false)} sqft
          </p>
          <p
            className="info-text m-none color-blue-very-dark-grayish"
            data-test="leased-area"
          >
            <span className="circle-xs circle bg-green"></span>
            <span>Leased area: {addSpaceOrComma(areas.occupiedArea, false)} sqft</span>
            <br />
          </p>
          <p
            className="info-text m-none color-blue-very-dark-grayish"
            data-test="vacant-area"
          >
            <span className="circle-xs circle bg-orange"></span>
            <span>
              {lowerArea} area: {addSpaceOrComma(areas.vacantArea, false)} sqft
            </span>
            <br />
          </p>
        </div>

        <div className="doughnut-wrapper">
          <Doughnut
            className="doughnut-canvas"
            data={data}
            options={options}
          />
          <div
            className="round-bar bg-white"
            data-test="round-bar"
          >
            <Icon
              icon={Icons.ArrowBack}
              style={{ rotate: getDegreeRotation() }}
            />
          </div>
          <span
            className="percentage left color-green"
            data-test="leased-area-percentage-green"
          >
            {leasePercentage}%
          </span>
          <span
            className="percentage right color-orange"
            data-test="vacant-area-percentage-orange"
          >
            {vacancyPercentage}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default DoughnutBox;
