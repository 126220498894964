import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getPropertyEvents } from 'common/api/dashboard/stackingPlan';
import { PropertyFilter, PropertyTimelineEvent, TimeLineEventsUI } from 'utils/types/stackingPlan';
import { getContractEventTypeProperty } from 'utils/utils-contract';
import EventsFilter from './components/EventsFilter';
import EventsTimeline from './components/EventsTimeline';
import './StackingPlanSideSections.scss';

interface FloorEvent {
  floorIndex: string;
}
const PropertyNextEvents = () => {
  const dispatch = useAppDispatch();
  const [timeline, setTimeline] = useState<TimeLineEventsUI[]>([]);
  const { activeProperty, selectedPropertyTimeline, monitoringDate } = useAppSelector(
    (state: RootState) => state.stackingPlan,
  );
  const twoYearsAhead = new Date();
  twoYearsAhead.setFullYear(twoYearsAhead.getFullYear() + 2);
  const defaultDateRange = [new Date(), twoYearsAhead];

  useEffect(() => {
    if (activeProperty && activeProperty.id) {
      // Get Property Events for property
      dispatch(
        getPropertyEvents({
          propertyId: activeProperty.id,
          date: monitoringDate,
        }),
      );
    }
  }, [activeProperty]);

  useEffect(() => {
    if (selectedPropertyTimeline) {
      applyFilters({ range: defaultDateRange, floors: [], events: [] });
    }
  }, [selectedPropertyTimeline]);

  const isFloorMatch = (floorFilter: string[], eventFloor: FloorEvent[]): boolean => {
    const filteredFloor = eventFloor.filter(({ floorIndex }) => {
      return floorFilter.includes(floorIndex.toString());
    });
    return filteredFloor.length ? true : false;
  };

  const mapToTimeline = (events: PropertyTimelineEvent[]) => {
    let timelineEvents: TimeLineEventsUI[] = [];
    timelineEvents = events.map(({ date, event, tenantName, floors }) => {
      const floorNames = floors.map(floor => floor.floorName).join(', ');
      return {
        date,
        event,
        description: `${tenantName}, ${floorNames}`,
        color: getContractEventTypeProperty(event, 'color'),
        background: getContractEventTypeProperty(event, 'backgroundColor'),
      };
    });
    setTimeline(timelineEvents);
  };

  const applyFilters = ({ range, floors, events }: PropertyFilter) => {
    if (!(selectedPropertyTimeline && selectedPropertyTimeline.events.length)) {
      return;
    }
    let filteredTimelineEvents = JSON.parse(JSON.stringify(selectedPropertyTimeline.events));
    filteredTimelineEvents = filteredTimelineEvents.filter(
      ({ date, floors: eventFloors, event }: PropertyTimelineEvent) => {
        if (
          (!(range && range.length) || (new Date(date) > range[0] && new Date(date) < range[1])) &&
          (!floors.length || isFloorMatch(floors, eventFloors)) &&
          (!events.length || events.includes(event))
        ) {
          return true;
        }
        return false;
      },
    );
    mapToTimeline(filteredTimelineEvents);
  };

  return (
    <>
      <p className="area-head color-blue-very-dark">Property next events</p>
      <EventsFilter onChange={applyFilters} />
      <EventsTimeline timeline={timeline} />
    </>
  );
};

export default PropertyNextEvents;
