import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { DocumentsType } from '.';

interface PrepContractDocsForUploadProps {
  files: any;
  id: string | null;
}

export const PREP_CONTRACTS_DOCS_FOR_UPLOAD = ({ files, id }: PrepContractDocsForUploadProps) => {
  if (!id) {
    throw new Error('Prep Contract Document: Contract ID could not be determined. Query aborted.');
  }
  let prepFilesQuery = '';

  for (let i = 0; i < files.length; i++) {
    const { name, type } = files[i].file;

    prepFilesQuery += `
      prepContractDoc${files[i].id}: prepContractDocument(contractId: "${id}", fileName: "${name}", mimeType: "${type}"){
        cdnUrl
        uploadUrl
        path
      }
    `;
  }

  return {
    query: gql`
      query {
        ${prepFilesQuery}
      }
    `,
  };
};

export const prepContractDocsForUpload = createAsyncThunk(
  'contracts/prepContractDocsForUpload',
  async (_args, { getState }) => {
    const {
      contractDetail: {
        activeContract: { id, documents },
      },
    } = getState() as RootState;

    const filteredDocuments = documents.filter((document: DocumentsType) => document.local);

    const response = await client.query(PREP_CONTRACTS_DOCS_FOR_UPLOAD({ files: filteredDocuments, id }));
    return response;
  },
);
