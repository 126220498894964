import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import {
  getArrowDirection,
  getNumberWithSign,
  getPercentageIncrease,
  isPositive,
  shortenNumber,
} from 'utils/utils-number';
import { addSpaceOrComma } from 'utils/utils-number';
import './TotalBox.scss';

const TotalBox = ({ dataTest }: { dataTest?: string }) => {
  const { summaryStats } = useAppSelector((state: RootState) => state.dashboardTab);

  let contractsTotal: number = 0,
    tenantsTotal: number = 0,
    contractsPercent: number = 0,
    tenantsPercent: number = 0;

  const PREVIOUS_MONTH = 0;
  const CURRENT_MONTH = 1;

  if (summaryStats !== null && summaryStats.stats.length > 0) {
    contractsTotal = summaryStats.stats[CURRENT_MONTH].nbContracts;
    tenantsTotal = summaryStats.stats[CURRENT_MONTH].nbTenants;

    contractsPercent = getPercentageIncrease(
      Number(summaryStats.stats[PREVIOUS_MONTH].nbContracts),
      Number(summaryStats.stats[CURRENT_MONTH].nbContracts),
    );
    tenantsPercent = getPercentageIncrease(
      Number(summaryStats.stats[PREVIOUS_MONTH].nbTenants),
      Number(summaryStats.stats[CURRENT_MONTH].nbTenants),
    );
  }

  const formatFigure = (num: number) => {
    const { shortenedNumber, suffix } = shortenNumber(num);

    return suffix ? `${shortenedNumber}${suffix}` : addSpaceOrComma(num);
  };

  return (
    <div
      className="total-box p-m"
      data-test={dataTest}
    >
      <h2 className="header color-blue-very-dark-grayish">Total</h2>
      <div className="d-flex remaining-height">
        <div className="m-t-xs w-50 border-right p-r-m">
          <h5 className="sub-header color-blue-desaturated-dark">Tenants</h5>

          <div className="total-row m-t-s">
            <span
              className="main-count color-blue-very-dark-grayish"
              data-test="total-tenant-count"
            >
              {formatFigure(tenantsTotal)}
            </span>
            <span
              className={`sub-count ${isPositive(tenantsPercent) ? 'color-green' : 'color-red-soft'}`}
              data-test="total-tenant-variation"
            >
              {getNumberWithSign(tenantsPercent, 1)}%
              <Icon
                icon={getArrowDirection(tenantsPercent)}
                className="m-l-xxs"
              />
            </span>
          </div>
        </div>

        <div className="m-t-xs w-50 border-left p-l-m">
          <h5 className="sub-header color-blue-desaturated-dark">Leases</h5>

          <div className="total-row m-t-s">
            <span
              className="main-count color-blue-very-dark-grayish"
              data-test="total-lease-count"
            >
              {formatFigure(contractsTotal)}
            </span>
            <span
              className={`sub-count ${isPositive(contractsPercent) ? 'color-green' : 'color-red-soft'}`}
              data-test="total-lease-variation"
            >
              {getNumberWithSign(contractsPercent, 1)}%
              <Icon
                icon={getArrowDirection(contractsPercent)}
                className="m-l-xxs"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalBox;
