import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { ID } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GetContractTermDateQueryVariables {
  currentLandlordEntityId: string;
  contractId: string;
}

export const GET_CONTRACT_TERM_DATES = (id: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) {
    throw new Error('Get Contract Term Dates: Landlord Entity could not be determined. Query aborted.');
  }

  if (!id) {
    throw new Error('Get Contract Term Dates: Contract ID could not be determined. Query aborted.');
  }

  const queryVariables: GetContractTermDateQueryVariables = {
    currentLandlordEntityId,
    contractId: id,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $contractId: ID!) {
        getContract(currentLandlordEntityId: $currentLandlordEntityId, contractId: $contractId) {
          id
          endDate
          startDate
          terminationDate
        }
      }
    `,
    variables: queryVariables,
  };
};

export const getContractTermDates = createAsyncThunk('contracts/getContractTermDates', async ({ id }: ID) => {
  const response = await client.query(GET_CONTRACT_TERM_DATES(id));
  return response;
});
