import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import Clauses from './Clauses';
import Parameters from './Parameters';
import { ShowCode } from './ShowCode';
import TextBlock from './TextBlock';
import './EditorSideMenu.scss';

export enum EDITOR_INFORMATION_TABS_OFFSET {
  NONE = 0,
  CLAUSE = 1,
  PARAMETER = 2,
  TEXT_BLOCK = 3,
  SHOW_CODE = 4,
}

const EditorSideMenu = () => {
  const { activeTab } = useAppSelector((state: RootState) => state.hiddenMenu);
  const dispatch = useAppDispatch();

  const isTabActive = (EditorInformationTabsOffsetArray: EDITOR_INFORMATION_TABS_OFFSET[]): boolean => {
    return EditorInformationTabsOffsetArray.includes(activeTab) ? true : false;
  };

  const getMenuClass = (EditorInformationTabsOffsetArray: EDITOR_INFORMATION_TABS_OFFSET[]): string => {
    if (EditorInformationTabsOffsetArray.includes(activeTab)) {
      return 'disabled-tab';
    }
    return '';
  };

  const menuOptions: TabMenuProps[] = [
    {
      key: 'sidebar-tab-none',
      label: 'None',
      customTab: <></>,
    },
    {
      key: 'sidebar-tab-clause',
      label: 'Clause',
      customTab: <Clauses />,
      class: getMenuClass([EDITOR_INFORMATION_TABS_OFFSET.PARAMETER, EDITOR_INFORMATION_TABS_OFFSET.TEXT_BLOCK]),
      isDisabled: isTabActive([EDITOR_INFORMATION_TABS_OFFSET.PARAMETER, EDITOR_INFORMATION_TABS_OFFSET.TEXT_BLOCK]),
    },
    {
      key: 'sidebar-tab-parameter',
      label: 'Parameter',
      customTab: <Parameters />,
      class: getMenuClass([EDITOR_INFORMATION_TABS_OFFSET.CLAUSE, EDITOR_INFORMATION_TABS_OFFSET.TEXT_BLOCK]),
      isDisabled: isTabActive([EDITOR_INFORMATION_TABS_OFFSET.CLAUSE, EDITOR_INFORMATION_TABS_OFFSET.TEXT_BLOCK]),
    },
    {
      key: 'sidebar-tab-text-block',
      label: 'Text Block',
      customTab: <TextBlock />,
      class: getMenuClass([EDITOR_INFORMATION_TABS_OFFSET.CLAUSE, EDITOR_INFORMATION_TABS_OFFSET.PARAMETER]),
      isDisabled: isTabActive([EDITOR_INFORMATION_TABS_OFFSET.CLAUSE, EDITOR_INFORMATION_TABS_OFFSET.PARAMETER]),
    },
    {
      key: 'sidebar-tab-show-code',
      label: 'Show Code',
      customTab: <ShowCode />,
    },
  ];

  return (
    <TabReact
      className="preview-sidebar-menu-tab"
      dataTest="preview-sidebar-menu-tab"
      activeTabIndex={activeTab}
      onTabChange={(activeIndex: number) => dispatch(updateSidebarTab(activeIndex))}
      tabMenu={menuOptions}
    />
  );
};

export default EditorSideMenu;
