import { Grid } from 'semantic-ui-react';
import SectionComponent from 'layouts/SectionComponent';
import './IncompleteFieldsBox.scss';

type IncompleteFields = {
  title: string;
  values: string;
};

interface IncompleteFieldsBox {
  incompleteFields: IncompleteFields[];
}

const IncompleteFieldsBox = ({ incompleteFields }: IncompleteFieldsBox) => {
  if (incompleteFields.length === 0) {
    return <></>;
  } else {
    return (
      <Grid.Row className="incomplete-fields-box">
        <Grid.Column
          width={16}
          className="pm-none"
        >
          <SectionComponent
            title="Incomplete fields"
            className="bg-red-soft"
          >
            <p className="field-list">
              {incompleteFields.map(
                ({ title, values }: IncompleteFields, key: number) =>
                  values.length !== 0 && (
                    <span key={`invalid-fields-${key}`}>
                      <b>{title}</b> {values}
                      <br />
                    </span>
                  ),
              )}
            </p>
          </SectionComponent>
        </Grid.Column>
      </Grid.Row>
    );
  }
};

export default IncompleteFieldsBox;
