import React from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import FilesUpload from 'atoms/FormField/FilesUpload';
import IncompleteFieldsBox from 'components/IncompleteFieldsBox';
import { addContractDocs, deleteContractDocs } from 'store/contracts/contractDetailSlice';
import { prepContractDocsForUpload } from 'common/api/contracts';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { ColorNames } from 'utils/utils-colors';
import CapitalAllowance from './CapitalAllowance';
import ContractGeneralForm from './ContractGeneralForm';
import OneOffCharges from './OneOffCharges';
import PartiesOrTermForm from './PartiesOrTerm';
import Premises from './Premises';
import RentForm from './Rent';
import SecurityForm from './Security';
import SpecialClauses from './SpecialClauses';
import Termination from './Termination';
import UnsavedChangesModal from './UnsavedChangesModal';
import './ContractForm.scss';

const ContractForm = React.forwardRef<HTMLFormElement, ModeProps>(({ mode }: ModeProps, ref) => {
  const {
    incompleteFields,
    activeContract: { documents },
    translationTransaction,
  } = useAppSelector((state: RootState) => state.contractDetail);

  const transactionLink = translationTransaction !== null ? true : false;
  const transactionLinkText = transactionLink ? 'TRANSACTION' : 'UNLINKED';

  return (
    <Form ref={ref}>
      <Grid className="m-none">
        <Grid.Row className="p-none row-mb">
          <Grid.Column
            width={8}
            className="pm-none section"
          >
            <SectionComponent title="1. Parties / Term">
              <PartiesOrTermForm mode={mode} />
            </SectionComponent>
          </Grid.Column>
          <Grid.Column
            width={8}
            className="p-r-none section"
          >
            <SectionComponent
              title={
                <>
                  2. General
                  <Button className={`transaction-link-btn ${!transactionLink ? ColorNames.GRAY : ''}`}>
                    {transactionLinkText}
                  </Button>
                </>
              }
            >
              <ContractGeneralForm mode={mode} />
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="p-none row-mb">
          <Grid.Column className="pm-none section">
            <SectionComponent title="3. Premises">
              <Premises mode={mode} />
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          id="rentSection"
          className="p-none row-mb"
        >
          <Grid.Column className="pm-none section">
            <SectionComponent title="4. Rent">
              <RentForm mode={mode} />
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="p-none row-mb">
          <Grid.Column
            width={8}
            className="pm-none section"
          >
            <SectionComponent title="5. Security">
              <SecurityForm mode={mode} />
            </SectionComponent>
          </Grid.Column>
          <Grid.Column
            width={8}
            className="p-r-none"
          >
            <SectionComponent
              title="6. Special Rights"
              className="h-100 d-block"
            >
              <SpecialClauses mode={mode} />
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="p-none row-mb">
          <Grid.Column
            width={8}
            className="pm-none section"
          >
            <SectionComponent
              title="7. Capital Allowance"
              className="h-100 d-block"
            >
              <CapitalAllowance mode={mode} />
            </SectionComponent>
          </Grid.Column>
          <Grid.Column
            width={8}
            className="p-r-none section"
          >
            <SectionComponent
              title="8. One-off Charges"
              className="h-100"
            >
              <OneOffCharges mode={mode} />
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="p-none">
          <Grid.Column
            width={8}
            className="pm-none section"
          >
            <SectionComponent title="9. Exercised Surrender or Termination Date">
              <Termination mode={mode} />
            </SectionComponent>
          </Grid.Column>
          <Grid.Column
            width={8}
            className="p-r-none section"
          >
            <SectionComponent title="10. Upload Documents">
              <FilesUpload
                disabled={mode === MODE_OPTIONS.READ}
                prepUpload={prepContractDocsForUpload}
                documents={documents}
                onDelete={deleteContractDocs}
                onAdd={addContractDocs}
              />
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>
        <IncompleteFieldsBox incompleteFields={incompleteFields} />
        <UnsavedChangesModal />
      </Grid>
    </Form>
  );
});

ContractForm.displayName = 'ContractForm';

export default ContractForm;
