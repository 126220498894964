import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { ID } from 'common/api/miscellaneous';

interface GetContractTimelineQueryVariables {
  id: string;
}

export const GET_CONTRACT_TIMELINE = (id: string) => {
  if (!id) {
    throw new Error('Get Contract Timeline: Contract ID could not be determined. Query aborted.');
  }

  const queryVariables: GetContractTimelineQueryVariables = {
    id,
  };

  return {
    query: gql`
      query ($id: ID!) {
        generateContractTimeline(contractId: $id) {
          contractId
          events {
            date
            description
            event
          }
          periods {
            description
            endDate
            event
            startDate
          }
        }
      }
    `,
    variables: queryVariables,
  };
};

export const getContractTimeline = createAsyncThunk('contracts/getContractTimeline', async ({ id }: ID) => {
  const response = await client.query(GET_CONTRACT_TIMELINE(id));
  return response;
});
