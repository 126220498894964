import { Loader } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { ContractTimeline } from 'common/api/contracts';
import MapTimelineElements from './TimeLineItem/MapTimelineElements';
import './TimeLine.scss';

const TimeLine = () => {
  const { contractTimeline, contractTimelineLoading } = useAppSelector((state: RootState) => state.contractDetail);

  return (
    <div className="outlined-container-timeline border-sm-grayish-magenta-light bg-white">
      <div className="timeline-wrapper">
        {contractTimelineLoading ? (
          <Loader
            active={true}
            size="big"
          />
        ) : (
          <div className="timeline-container">
            <hr className="timeline-x-axis-line border-none bg-gray color-gray"></hr>
            {MapTimelineElements(contractTimeline)}
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeLine;
