import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ExportContractQueryVariables {
  currentLandlordEntityId: string;
}

export const EXPORT_CONTRACT = () => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!currentLandlordEntityId) {
    throw new Error('Create Contract: Landlord Entity could not be determined. Query aborted.');
  }

  const queryVariables: ExportContractQueryVariables = {
    currentLandlordEntityId: currentLandlordEntityId,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!) {
        exportContract(currentLandlordEntityId: $currentLandlordEntityId)
      }
    `,
    variables: queryVariables,
  };
};

export const exportContract = createAsyncThunk(
  'contracts/exportContract',
  async ({ landlordName }: { landlordName: string }) => {
    const response = await client.query(EXPORT_CONTRACT());
    return response;
  },
);
