import { Accordion } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import FilesUpload from 'atoms/FormField/FilesUpload';
import { addTransactionDocs, deleteTransactionDocs } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { prepTransactionDocsForUpload } from 'common/api/transactions';
import { TransactionDocument } from 'common/api/transactions';
import { capitalizeFirstLetter } from 'utils/utils-string';

const DocUploadBox = ({
  mode,
  name,
  required,
}: {
  mode: MODE_OPTIONS;
  name: string;
  required: boolean;
}): JSX.Element => {
  let viewerStatus = false;

  const {
    activeTransaction: { documents },
  } = useAppSelector((state: RootState) => state.transactionDetail);

  return (
    <div className="box-flex">
      <Accordion className={`approval-accordian ${viewerStatus && 'border-sm-green'} upload-acc-docs`}>
        <Accordion.Title
          active={true}
          index={1}
          className="p-sm"
        >
          <p className="content-title m-t-xs m-b-none">
            {capitalizeFirstLetter(name)}
            {required ? <span className="required-indicator">*</span> : <></>}
          </p>
        </Accordion.Title>
        <Accordion.Content
          active={true}
          className="p-sm p-t-xxs"
        >
          <FilesUpload
            disabled={mode === MODE_OPTIONS.READ}
            prepUpload={prepTransactionDocsForUpload}
            documents={documents
              .filter((doc: TransactionDocument) => doc.name === name)
              .map((doc: TransactionDocument) => doc.document)}
            onDelete={deleteTransactionDocs}
            onAddFunctionParams={{ name }}
            onAddInTransaction={addTransactionDocs}
          />
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default DocUploadBox;
