import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ListContractsProps {
  searchValue: string | null;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
}

interface ListContractsQueryVariables {
  currentLandlordEntityId: string;
  first: number | undefined;
  last: number | undefined;
  after: string | undefined;
  before: string | undefined;
  search: string | null;
}

const LIST_CONTRACTS = ({ searchValue, first, last, after, before }: ListContractsProps) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId === null) {
    throw new Error('List Contracts: Current Landlord Entity could not be determined. Query aborted.');
  }
  const queryVariables: ListContractsQueryVariables = {
    currentLandlordEntityId: String(currentLandlordEntityId),
    first: first,
    last: last,
    after: after,
    before: before,
    search: searchValue,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $first: Int, $last: Int, $after: String, $before: String, $search: String) {
        listContracts(
          currentLandlordEntityId: $currentLandlordEntityId
          first: $first
          last: $last
          after: $after
          before: $before
          contractFilter: { search: $search }
        ) {
          edges {
            cursor
            node {
              id
              tenant {
                name
              }
              identifier
              rentDescriptions {
                monthlyBaseRent
                monthlyEffectiveRent
                rentReview {
                  reviewDate
                }
              }
              premises {
                property {
                  buildingName
                  floors {
                    id
                    name
                    ceilingHeight
                    spaces {
                      id
                      name
                      grossArea
                      netArea
                      lettableArea
                      saleableArea
                    }
                  }
                }
                spaceIds
                floorsStatus {
                  floorId
                  whole
                }
              }
              startDate
              endDate
              renewFlag
              terminationDate
              surrenderFlag
              reviewFlag
              translationInfo {
                transaction {
                  id
                  identifier
                }
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables: queryVariables,
  };
};

export const listContracts = createAsyncThunk(
  'contracts/listContracts',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const {
      contractsListing: { search },
    } = getState() as RootState;

    const response = await client.query(LIST_CONTRACTS({ searchValue: search, first, last, after, before }));
    return response;
  },
);
