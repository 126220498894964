import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FLOOR_PORTION_OPTIONS } from 'components/PremisesForm/utils-premiseForm';
import { CONTRACT_TABS_OFFSET } from 'views/contracts/Viewer/ContractTabs';
import ContractSecurityType from 'common/model/ContractSecurityType';
import Premise from 'common/model/Premise';
import Property from 'common/model/Property';
import RentDescription from 'common/model/RentDescription';
import RentReviewType from 'common/model/RentReviewType';
import Scale from 'common/model/Scale';
import {
  ContractTimeline,
  DocumentsType,
  LocalRentFreePeriod,
  calculateEffectiveRent,
  createContract,
  getContract,
  getContractRevenue,
  getContractRevenueBase,
  getContractTermDates,
  getContractTimeline,
  listProperties,
  prepContractDocsForUpload,
  updateContract,
} from 'common/api/contracts';
import { getFrozenObjectCopy, getNewReviewer } from 'utils/tsHelper';
import { checkNotEmpty } from 'utils/tsValidator';
import { getNewRentFreePeriod, getNewRentPeriod } from 'utils/utils-contract';
import {
  getIncrementByPercentage,
  getNewContract,
  undoContractReviewed,
  updateAllBaseRentWithIncrementReviewType,
  validateAllRents,
} from './helper';

const initialState = {
  activeContract: getNewContract() as any,
  rentReviewActiveTab: 0,
  isLoading: false,
  contractIsLoading: false,
  fetchedContractData: null,
  runContractValidation: false,
  incompleteFields: [],
  unsavedModalStatus: false,
  clickedURL: '',
  oldContractVersion: null,
  activeTabIndex: CONTRACT_TABS_OFFSET.DETAILS,
  // Monitoring Contract Timeline
  contractTimeline: { contractId: '', events: [], periods: [] } as ContractTimeline,
  contractTimelineLoading: false as boolean,
  // Monitoring Contract Revenue Forecast
  contractRevenueBase: {
    contractId: '',
    contractIdentifier: '',
    contractData: [],
    unitsData: [],
    startDate: '',
    endDate: '',
    scale: Scale.Month,
  },
  contractRevenueBaseLoading: false,
  //
  premisesLoading: false,
  activeContractFreeze: null,
  translationTransaction: null,
  contractTermDatesLoading: false,
  termination: null as any,
};

const contractDetailSlice = createSlice({
  name: 'contractDetail',
  initialState,
  reducers: {
    updateViewDetailsTabIndex: (state, action) => {
      state.activeTabIndex = action.payload.tab;
    },

    // Monitoring Contract Revenue Forecast
    updateContractRevenueBaseScale: (state, action) => {
      state.contractRevenueBase.scale = action.payload.value;
    },
    updatecontractRevenueBaseDate: (state, action) => {
      const { date, type } = action.payload;
      // @ts-ignore
      state.contractRevenueBase[type] = moment(date).format('YYYY-MM-DD');
    },
    updateContractReviewedStatus: (state, action) => {
      const { reviewStatus, reviewFlag } = action.payload;
      state.activeContract.reviewStatus = reviewStatus;
      state.activeContract.reviewFlag = reviewFlag;
    },
    updateContractRentPeriodAdditionalCharges: (state, action) => {
      const { key, value, periodIndex } = action.payload;
      const { additionalFeesAndCharges } = state.activeContract.rent.periods[periodIndex];
      additionalFeesAndCharges[key] = value;
    },
    updateContractOneOffCharges: (state, action) => {
      const { key, value } = action.payload;
      state.activeContract.oneOffCharges[key] = value ? value : null;
    },
    updateContractCapitalAllowance: (state, action) => {
      const { key, value } = action.payload;
      undoContractReviewed(state.activeContract);

      const { capitalAllowance } = state.activeContract;

      capitalAllowance[key] = value;

      if (!capitalAllowance.flagOn) {
        capitalAllowance.value = null;
      }
    },
    updateContractStartOrEndDate: (state, action) => {
      const { key, value } = action.payload;

      state.activeContract[key] = value;

      // Update Rent Period 1 start date or end date too (Found in section 4)
      const {
        rent: { periods },
      } = state.activeContract;

      if (key === 'endDate') {
        const { length } = periods;
        periods[length - 1].description[key] = value;
      } else {
        periods[0].description[key] = value;
      }
    },
    giveValidationFeedback: (state, action) => {
      state.runContractValidation = action.payload.value;
    },
    deleteContractDocs: (state, action) => {
      state.activeContract.documents = state.activeContract.documents.filter(
        (doc: DocumentsType) => doc.id !== action.payload.id,
      );
    },
    addContractDocs: (state, action) => {
      state.activeContract.documents = [...action.payload.files, ...state.activeContract.documents];
    },
    clearTermination: state => {
      state.termination.terminationDate = null;
      state.termination.terminationDescription = null;
      state.termination.terminationReason = null;
    },
    updateContractTermination: (state, action) => {
      const { key, value } = action.payload;
      state.activeContract.termination[key] = value;
    },
    updateContractFlag: (state, action) => {
      const { key, value } = action.payload;
      state.activeContract.flags[key] = value;
    },
    updateContractSecurityAmount: (state, action) => {
      const { key, value } = action.payload;
      state.activeContract.security.amount[key] = value;
    },
    updateContractSecurityType: (state, action) => {
      const { value } = action.payload;

      state.activeContract.security.type = value;

      // Update amount
      const { amount } = state.activeContract.security;
      if (value === ContractSecurityType.Bank) {
        amount.depositCashAmount = null;
      } else if (value === ContractSecurityType.Cash) {
        amount.bankGuaranteeAmount = null;
      } else if (value === ContractSecurityType.None) {
        amount.bankGuaranteeAmount = null;
        amount.depositCashAmount = null;
      }
    },
    updateRentFreePeriodAdditionalFees: (state, action) => {
      const { periodIndex, rentFreePeriodIndex, value } = action.payload;
      const {
        freePeriod: { freePeriods },
      } = state.activeContract.rent.periods[periodIndex];
      freePeriods[rentFreePeriodIndex].additionalFreeItems = value;
    },
    updateRentFreePeriodDate: (state, action) => {
      const { periodIndex, rentFreePeriodIndex, key, value } = action.payload;

      const {
        freePeriod: { freePeriods },
      } = state.activeContract.rent.periods[periodIndex];

      freePeriods[rentFreePeriodIndex][key] = value;
    },
    deleteRentFreePeriod: (state, action) => {
      const { periodIndex, rentFreePeriodIndex } = action.payload;

      const { freePeriod } = state.activeContract.rent.periods[periodIndex];

      freePeriod.freePeriods = freePeriod.freePeriods.filter(
        (item: LocalRentFreePeriod, index: number) => index !== rentFreePeriodIndex,
      );
    },
    addRentFreePeriod: (state, action) => {
      const { freePeriod } = state.activeContract.rent.periods[action.payload.periodIndex];
      freePeriod.freePeriods = [...freePeriod.freePeriods, getNewRentFreePeriod()];
    },
    updateHasRentFreePeriod: (state, action) => {
      const { value, periodIndex } = action.payload;
      const { freePeriod } = state.activeContract.rent.periods[periodIndex];

      if (value) {
        freePeriod.freePeriods = [getNewRentFreePeriod()];
      } else {
        freePeriod.freePeriods = [];
      }

      freePeriod.hasRentFreePeriods = value;
    },
    deleteRentPeriod: (state, action) => {
      const { periodIndex } = action.payload;
      const {
        activeContract: { rent },
      } = state;

      // Update end date of the period before the period that is about to be deleted
      const endDateOfPeriodAboutToBeDeleted = rent.periods[periodIndex].description.endDate;

      rent.periods[periodIndex - 1].description.endDate = endDateOfPeriodAboutToBeDeleted;

      // Delete
      rent.periods = rent.periods.filter((period: LocalRentFreePeriod, index: number) => index !== periodIndex);

      // Update tab for better UX
      state.rentReviewActiveTab = periodIndex - 1;
    },
    updateRentPeriodReviewType: (state, action) => {
      const { key, value, periodIndex } = action.payload;

      const { review, description } = state.activeContract.rent.periods[periodIndex];
      review[key] = value;

      // Nullify value of corresponding monthlyBaseRent and validation
      description.monthlyBaseRent = null;
      description.validated = false;

      // Nullify values for other types that have not been selected
      const keys = [
        [RentReviewType.OpenMarketUpDown, 'cap', 'collar'],
        [RentReviewType.New, 'rent'],
        [RentReviewType.Increment, 'increment'],
      ];

      for (let i = 0; i < keys.length; i++) {
        if (value !== keys[i][0]) {
          for (let j = 1; j < keys[i].length; j++) {
            review[keys[i][j]] = null;
          }
        }
      }
      validateAllRents(periodIndex, state.activeContract.rent.periods);
    },
    updateRentReviewDate: (state, action) => {
      const { key, value, periodIndex } = action.payload;

      const { review, description } = state.activeContract.rent.periods[periodIndex];
      review[key] = value;

      // Update rent period start date
      description.startDate = value;

      // Update rent period end date for previous rent period
      const { description: previousDescription } = state.activeContract.rent.periods[periodIndex - 1];
      const previousDate = new Date(value);
      previousDate.setDate(previousDate.getDate() - 1);
      previousDescription.endDate = previousDate.toString();
    },
    updateRentPeriodReviewFixedType: (state, action) => {
      const { value, periodIndex } = action.payload;
      const { review, description } = state.activeContract.rent.periods[periodIndex];
      review.rent = value;

      // update corresponding monthlyBaseRent with fixed rent
      description.monthlyBaseRent = value;
      // description.validated = value ? true : false;

      // Update other INCREMENT that are immediately after this
      updateAllBaseRentWithIncrementReviewType(periodIndex, state.activeContract.rent.periods);
      validateAllRents(periodIndex, state.activeContract.rent.periods);
    },
    updateRentPeriodReviewIncrementType: (state, action) => {
      const { value, periodIndex } = action.payload;
      const { review, description } = state.activeContract.rent.periods[periodIndex];
      review.increment = value;

      // update corresponding monthlyBaseRent with increment rent
      const { description: previousDescription } = state.activeContract.rent.periods[periodIndex - 1];

      if (checkNotEmpty(previousDescription.monthlyBaseRent)) {
        const parsedIncrement = parseFloat(value);
        description.monthlyBaseRent = getIncrementByPercentage(
          parseInt(previousDescription.monthlyBaseRent),
          parsedIncrement,
        );

        // Update other INCREMENT that are immediately after this
        updateAllBaseRentWithIncrementReviewType(periodIndex, state.activeContract.rent.periods);

        validateAllRents(periodIndex, state.activeContract.rent.periods);
      }
    },
    updateRentPeriodReview: (state, action) => {
      const { key, value, periodIndex } = action.payload;
      const { review } = state.activeContract.rent.periods[periodIndex];
      review[key] = value;
    },
    updateRentPeriodMonthlyBaseRent: (state, action) => {
      const { value, periodIndex } = action.payload;
      const { description, review } = state.activeContract.rent.periods[periodIndex];
      description.monthlyBaseRent = value ? value : null;
      const reviewType = review?.type;

      if (periodIndex === 0 || reviewType === RentReviewType.OpenMarketUpDown) {
        // Update monthlyBaseRent of other periods
        updateAllBaseRentWithIncrementReviewType(periodIndex, state.activeContract.rent.periods);
      }

      validateAllRents(periodIndex, state.activeContract.rent.periods);
    },
    updateEffectiveRent: (state, action) => {
      const { value, periodIndex } = action.payload;
      const { description } = state.activeContract.rent.periods[periodIndex];
      description.monthlyEffectiveRent = value ? value : null;
    },
    updateRentPeriodValidity: (state, action) => {
      const { value, periodIndex } = action.payload;
      const { description } = state.activeContract.rent.periods[periodIndex];
      description.validated = value;

      validateAllRents(periodIndex, state.activeContract.rent.periods);
    },
    setRentReviewActiveTab: (state, action) => {
      state.rentReviewActiveTab = action.payload.activeIndex;
    },
    addRentPeriod: state => {
      const {
        activeContract: {
          rent: { periods },
        },
      } = state;

      const newPeriod = getNewRentPeriod();

      // Update the end date of the new period to that of the last rent period
      const indexOfPrev = periods.length - 1;
      const periodEndDate = periods[indexOfPrev].description.endDate;
      periods[indexOfPrev].description.endDate = null;
      newPeriod.description.endDate = periodEndDate;

      periods.push(newPeriod);

      // Update tab for better UX
      state.rentReviewActiveTab = indexOfPrev + 1;
    },
    updateContractPremises: (state, action) => {
      if (action.payload) {
        const { premises } = action.payload;
        state.activeContract.premises = premises;
      }
    },
    updateContractInput: (state, action) => {
      const { key, value } = action.payload;
      state.activeContract[key] = value;
    },
    updateContractLandlordId: (state, action) => {
      const { key, value } = action.payload;
      state.activeContract.landlord[key] = value;
    },
    initiateNewContract: state => {
      state.activeContract = getNewContract();
      state.incompleteFields = [];
      state.rentReviewActiveTab = 0;
      state.unsavedModalStatus = false;
      state.clickedURL = '';
      state.oldContractVersion = null;
    },
    mapContractData: state => {
      // Map data gotten from API to data structure of contract (because it is independent of structure of data from API)
      // NOTE the is a corresponding map to suit API requirement when we save or update as well
      if (state.activeContract.landlord.properties.length > 0) {
        const { activeContract, fetchedContractData }: any = state;
        const contract: any = {};

        // MAP LANDLORD ID
        // NOTE: See "listProperties" for the other mapped data for landlord
        const {
          owner: { id: landlordId },
        } = fetchedContractData;
        contract.landlord = {
          ...activeContract.landlord,
          id: landlordId,
        };

        // MAP CONTRACT ID, TENANT ID, START DATE, END DATE, IDENTIFIER, DESCRIPTION, CAPITAL ALLOWANCE, ONE-OFF CHARGES
        const {
          id,
          tenant: { id: tenantId },
          startDate,
          endDate,
          identifier,
          description,
          capitalAllowance,
          oneOffCharges,
        } = fetchedContractData;

        contract.id = id;
        contract.tenantId = tenantId;
        contract.startDate = startDate;
        contract.endDate = endDate;
        contract.identifier = identifier;
        contract.description = description;
        contract.capitalAllowance = capitalAllowance;
        contract.oneOffCharges = oneOffCharges;

        // MAP SECURITY
        const { securityType, securityAmount } = fetchedContractData;
        contract.security = {
          type: securityType,
          amount: securityAmount,
        };

        // MAP REVIEW
        const { reviewFlag, reviewStatus } = fetchedContractData;
        contract.reviewFlag = reviewFlag;
        contract.reviewStatus = reviewStatus;

        if (!reviewStatus.user) {
          contract.reviewStatus.user = getNewReviewer();
        }

        // MAP FLAGS
        const { expansionFlag, assignmentFlag, refusalFlag, renewFlag, subletFlag, surrenderFlag, redevelopmentFlag } =
          fetchedContractData;

        contract.flags = {
          expansionFlag,
          assignmentFlag,
          redevelopmentFlag,
          refusalFlag,
          renewFlag,
          subletFlag,
          surrenderFlag,
        };

        // MAP TERMINATION
        const { terminationDate, terminationDescription, terminationReason } = fetchedContractData;

        contract.termination = {
          terminationDate,
          terminationDescription,
          terminationReason,
        };

        // MAP DOCUMENTS
        const { documents } = fetchedContractData;
        contract.documents = documents;

        // MAP PREMISES
        const { premises } = fetchedContractData;
        contract.premises = premises.map((premise: Premise) => {
          /**
           * NOTE:
           * From the API, we get the floor and spaces data 'scattered'
           * This means that ALL ids of floors are in an array (floorsStatus), and ALL ids of spaces are in another array (spaceIds)
           *
           * interface FloorStatus {
           *   floorID: string;
           *   whole: boolean;
           * }
           * floorsStatus: FloorStatus[];
           * spaceIds: string[]
           *
           * The end goal of this part/block of the code is to separate all floors and units
           * existing in that floor ONLY into an object so that it takes the shape below
           *
           * {
           *   id: string;  id of the floor
           *   floorPortion:FLOOR_PORTION_OPTIONS;  "WHOLE" or "PART"
           *   spaceIds: string[]; ids of spaces that exist on a floor
           * };
           */

          const {
            floorsStatus,
            property: { id: propertyId },
            spaceIds,
          } = premise;

          // create map of spaceIds, for easy lookup
          const spaceIdsMap: { [key: string]: string } = {};
          spaceIds.forEach(id => (spaceIdsMap[id] = id));

          // Get property, this has the complete data for each floor and its spaces
          const property = activeContract.landlord.properties.find((property: Property) => property.id === propertyId);

          const separatedFloorAndItsSpacesData = floorsStatus.map((floorStatus: any) => {
            const completeFloorData = property.floors.find((floor: any) => floor.id === floorStatus.floorId);

            let allSpacesInCompleteFloorData = [];

            if (completeFloorData) {
              allSpacesInCompleteFloorData = completeFloorData.spaces;
            }

            const currentFloorSelectedSpaces = [];

            for (let y = 0; y < allSpacesInCompleteFloorData.length; y++) {
              const spaceId = allSpacesInCompleteFloorData[y].id;

              if (spaceIdsMap[spaceId]) {
                currentFloorSelectedSpaces.push(spaceId);
              }
            }

            return {
              id: floorStatus.floorId,
              floorPortion: floorStatus.whole ? FLOOR_PORTION_OPTIONS.WHOLE : FLOOR_PORTION_OPTIONS.PART,
              spaceIds: currentFloorSelectedSpaces,
            };
          });

          return {
            floorIds: separatedFloorAndItsSpacesData,
            property,
          };
        });

        // MAP RENT
        const { rentDescriptions } = fetchedContractData;

        contract.rent = {};
        contract.rent.type = 'BASE';

        contract.rent.periods = rentDescriptions.map((rentDescription: RentDescription) => {
          return {
            description: {
              endDate: rentDescription.endDate,
              startDate: rentDescription.startDate,
              monthlyBaseRent: rentDescription.monthlyBaseRent,
              monthlyEffectiveRent: rentDescription.monthlyEffectiveRent,
              validated: rentDescription.validated,
            },
            review: rentDescription.rentReview,
            freePeriod: {
              hasRentFreePeriods: rentDescription.rentFreePeriods.length > 0,
              freePeriods: rentDescription.rentFreePeriods,
            },
            additionalFeesAndCharges: rentDescription.charges,
          };
        });

        state.activeContract = contract;
        state.activeContractFreeze = getFrozenObjectCopy(contract) as any;
        state.oldContractVersion = contract;
        state.fetchedContractData = null;
        state.contractIsLoading = false;
      }
      state.contractIsLoading = false;
      state.rentReviewActiveTab = 0;
    },

    incompleteFields: (state, action) => {
      state.incompleteFields = action.payload.inValidFields;
    },
    setUnsavedModalStatus: (state, action) => {
      const { status, url } = action.payload;
      state.unsavedModalStatus = status;
      state.clickedURL = url ? url : '';
    },
  },
  extraReducers: builder => {
    // listProperties
    builder.addCase(listProperties.pending, state => {
      state.premisesLoading = true;
    });

    builder.addCase(listProperties.fulfilled, (state, action) => {
      const listProperties = action?.payload?.data?.listProperties ?? undefined;
      const propertiesList = listProperties?.edges ? listProperties.edges.map((element: any) => element.node) : [];
      state.activeContract.landlord.properties = propertiesList;
      state.premisesLoading = false;
    });
    builder.addCase(listProperties.rejected, (state, action) => {
      state.premisesLoading = false;
      console.error(action.error);
      toast.error('listProperties API request rejected');
    });

    // createContract
    builder.addCase(createContract.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(createContract.fulfilled, state => {
      state.isLoading = false;
      state.activeContract = getNewContract();
      state.incompleteFields = [];
      toast.success('Contract created successfully');
    });

    builder.addCase(createContract.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createContract API request rejected');
    });

    // getContract
    builder.addCase(getContract.pending, state => {
      state.contractIsLoading = true;
    });

    builder.addCase(getContract.fulfilled, (state, action) => {
      const contract = action.payload.data.getContract;
      state.fetchedContractData = contract;
      state.translationTransaction = contract.translationInfo !== null ? contract.translationInfo.transaction : null;
      state.activeContract = getNewContract();
      state.incompleteFields = [];
      state.unsavedModalStatus = false;
      state.clickedURL = '';
      state.contractIsLoading = false;
    });

    builder.addCase(getContract.rejected, (state, action) => {
      state.contractIsLoading = false;
      console.error(action.error);
      toast.error('getContract API request rejected');
    });

    // updateContract
    builder.addCase(updateContract.pending, state => {
      state.contractIsLoading = true;
    });

    builder.addCase(updateContract.fulfilled, (state, action) => {
      const contract = action.payload.data.updateContract;
      state.fetchedContractData = contract;
      state.incompleteFields = [];
      state.unsavedModalStatus = false;
      toast.success('Contract updated successfully');
    });

    builder.addCase(updateContract.rejected, (state, action) => {
      state.contractIsLoading = false;
      console.error(action.error);
      toast.error('updateContract API request rejected');
    });

    // prepContractDocsForUpload
    builder.addCase(prepContractDocsForUpload.fulfilled, (state, action) => {
      const documentsCopy = [...state.activeContract.documents];

      Object.keys(action.payload.data).map(key => {
        const id = key.replace('prepContractDoc', '');
        const index = documentsCopy.findIndex(doc => doc.id === id);
        documentsCopy.splice(index, 1, {
          ...documentsCopy[index],
          ...action.payload.data[key],
        });
      });

      state.activeContract.documents = documentsCopy;
    });

    builder.addCase(prepContractDocsForUpload.rejected, (state, action) => {
      console.error(action.error);
      toast.error('prepContractDocsForUpload API request rejected');
    });

    // getContractTimeline
    builder.addCase(getContractTimeline.pending, state => {
      state.contractTimelineLoading = true;
    });
    builder.addCase(getContractTimeline.fulfilled, (state, action) => {
      state.contractTimeline = action.payload.data.generateContractTimeline;
      state.contractTimelineLoading = false;
    });
    builder.addCase(getContractTimeline.rejected, (state, action) => {
      state.contractTimelineLoading = false;
      console.error(action.error);
      toast.error('getConetractTimeline API request rejected');
    });

    // getContractRevenueBase
    builder.addCase(getContractRevenueBase.pending, state => {
      state.contractRevenueBaseLoading = true;
    });
    builder.addCase(getContractRevenueBase.fulfilled, (state, action) => {
      state.contractRevenueBase = action.payload.data.getContractRevenueBase;
      state.contractRevenueBaseLoading = false;
    });
    builder.addCase(getContractRevenueBase.rejected, (state, action) => {
      state.contractRevenueBaseLoading = false;
      console.error(action.error);
      toast.error('getContactRevenueBase API request rejected');
    });

    // getContractRevenue
    builder.addCase(getContractRevenue.pending, state => {
      state.contractRevenueBaseLoading = true;
    });
    builder.addCase(getContractRevenue.fulfilled, (state, action) => {
      state.contractRevenueBase = action.payload.data.getContractRevenue;
      state.contractRevenueBaseLoading = false;
    });
    builder.addCase(getContractRevenue.rejected, (state, action) => {
      state.contractRevenueBaseLoading = false;
      console.error(action.error);
      toast.error('getContactRevenue API request rejected');
    });

    // getContractTermDates
    builder.addCase(getContractTermDates.pending, state => {
      state.contractTermDatesLoading = true;
    });
    builder.addCase(getContractTermDates.fulfilled, state => {
      state.contractTermDatesLoading = false;
    });
    builder.addCase(getContractTermDates.rejected, (state, action) => {
      state.contractTermDatesLoading = false;
      console.error(action.error);
      toast.error('getContractTermDates API request rejected');
    });

    // calculateEffectiveRent
    builder.addCase(calculateEffectiveRent.fulfilled, (state, action) => {
      const periodIndex = action.meta.arg;
      const data = action.payload.data.calculateEffectiveRent;

      const monthlyEffectiveRent = Number(data.monthlyEffectiveRent).toFixed(2);

      const periods = state.activeContract.rent.periods;

      const rentDescription = {
        ...state.activeContract.rent.periods[periodIndex].description,
      };

      rentDescription.monthlyEffectiveRent = monthlyEffectiveRent;

      periods[periodIndex].description = rentDescription;
    });
    builder.addCase(calculateEffectiveRent.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('An unexpected error occured while updating effective rent value');
    });
  },
});

export const {
  addRentFreePeriod,
  addRentPeriod,
  addContractDocs,
  clearTermination,
  deleteContractDocs,
  deleteRentPeriod,
  deleteRentFreePeriod,
  giveValidationFeedback,
  incompleteFields,
  initiateNewContract,
  mapContractData,
  setRentReviewActiveTab,
  setUnsavedModalStatus,
  updateRentPeriodReview,
  updateRentReviewDate,
  updateRentPeriodReviewType,
  updateHasRentFreePeriod,
  updateRentFreePeriodDate,
  updateRentFreePeriodAdditionalFees,
  updateContractSecurityType,
  updateContractSecurityAmount,
  updateContractFlag,
  updateContractTermination,
  updateContractStartOrEndDate,
  updateContractInput,
  updateContractLandlordId,
  updateContractPremises,
  updateRentPeriodReviewFixedType,
  updateRentPeriodReviewIncrementType,
  updateRentPeriodMonthlyBaseRent,
  updateEffectiveRent,
  updateRentPeriodValidity,
  updateContractCapitalAllowance,
  updateContractOneOffCharges,
  updateContractRentPeriodAdditionalCharges,
  updateContractReviewedStatus,
  updateViewDetailsTabIndex,
  updatecontractRevenueBaseDate,
  updateContractRevenueBaseScale,
} = contractDetailSlice.actions;

export default contractDetailSlice.reducer;
