import { createSlice } from '@reduxjs/toolkit';
import AnalysisContext from 'common/model/AnalysisContext';
import { DASHBOARD_PAGE_SCOPE, DASHBOARD_TABS_OFFSET, WholePortolioText } from 'common/api/dashboard';

const activePortfolio = {
  id: null,
  name: '',
  propertyIds: [],
};

const initialState = {
  activeTabIndex: DASHBOARD_TABS_OFFSET.DASHBOARD,
  dashboardOption: DASHBOARD_PAGE_SCOPE.ALL,
  dashboardValue: WholePortolioText,
  context: AnalysisContext.Portfolio,
  activePortfolio,
  contextId: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateActivePortfolio: (state, action) => {
      const { portfolio } = action.payload;
      state.activePortfolio = portfolio;
    },
    updateCurrentDashboardTab: (state, action) => {
      const { tab } = action.payload;
      state.activeTabIndex = tab;
    },
    setDashboardOption: (state, action) => {
      state.dashboardOption = action.payload.value;
    },
    updateSelectedContextName: (state, action) => {
      const { value, type, id } = action.payload;
      state.dashboardValue = value;
      state.contextId = id;

      if (type === AnalysisContext.Portfolio) {
        state.context = AnalysisContext.Portfolio;
      } else if (type === AnalysisContext.Property) {
        state.context = AnalysisContext.Property;
      }
    },
  },
});

export const { updateCurrentDashboardTab, setDashboardOption, updateSelectedContextName, updateActivePortfolio } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
