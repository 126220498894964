import moment from 'moment';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementDate,
  filterElementStatus,
  filterElementStatusTemplate,
  filterElementYesOrNo,
  filterElementYesOrNoTemplate,
} from 'atoms/TableReactPrime/columnTemplates';
import FloorsToolTip, { filterPremises } from 'components/FloorsToolTip';
import RentDescription from 'common/model/RentDescription';
import { PremiseProps } from 'common/api/contracts';
import {
  getContractEndDate,
  getCurrentRent,
  getEffectiveRent,
  getRentPerAreaCellValue,
  getTotalArea,
} from 'utils/utils-contract';
import { addSpaceOrComma } from 'utils/utils-number';
import { getFormattedFloorsList } from 'utils/utils-premises';
import { ContractRowActionButtons } from './ContractRowActionButtons';

type TranslationInfo = {
  transaction: {
    id: string;
    identifier: string;
  };
};

export class ContractRow {
  id: string;
  identifier: string;
  property: string;
  premises: PremiseProps;
  area: number;
  tenant: string;
  termStartDate: Date | null;
  termEndDate: Date | null;
  headlineRent: number | null;
  rentPerArea: number | null;
  effectiveRent: number | null;
  rentReviewDates: Date[];
  surrenderFlag: string;
  renewFlag: string;
  reviewFlag: string;
  floorNames: string;
  translationInfo: TranslationInfo | null;
  origin: string;

  constructor(contract: any) {
    const totalArea = getTotalArea(contract.premises);
    let currentMonthlyBaseRent = getCurrentRent(contract.rentDescriptions);
    const effectiveRent = getEffectiveRent(contract.rentDescriptions);

    this.id = contract.id;
    this.identifier = contract.identifier;
    this.property = contract.premises[0].property.buildingName;
    this.floorNames = getFormattedFloorsList(contract.premises[0]);
    this.premises = contract.premises[0];
    this.area = totalArea;
    this.tenant = contract.tenant.name;
    this.termStartDate = contract.startDate ? new Date(contract.startDate) : null;
    this.termEndDate = getContractEndDate(contract.endDate, contract.terminationDate);
    this.headlineRent = currentMonthlyBaseRent;
    this.rentPerArea = getRentPerAreaCellValue(currentMonthlyBaseRent, totalArea);
    this.effectiveRent = effectiveRent;
    this.rentReviewDates = getReviewDates(contract.rentDescriptions);
    this.surrenderFlag = contract.surrenderFlag;
    this.renewFlag = contract.renewFlag;
    this.reviewFlag = contract.reviewFlag ? statuses[0].value : statuses[1].value;
    this.translationInfo = contract.translationInfo;
    this.origin = getOriginValue(contract.translationInfo);
  }
}

const statuses: StatusBadgeArgument[] = [
  { value: 'REVIEWED', color: BadgeColor.GREEN },
  { value: 'TO BE REVIEWED', color: BadgeColor.RED },
];

const addTransactionLink = (translationInfo: TranslationInfo | null) => {
  if (translationInfo !== null && translationInfo.transaction !== null) {
    return (
      <a href={`/transactions/${translationInfo.transaction.id}/details`}>{translationInfo.transaction.identifier}</a>
    );
  } else {
    return '-N/A-';
  }
};

/*
 * Definition of the column (names, format...)
 */
export const contractColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => {
  return [
    {
      field: 'identifier',
      header: 'ID',
      filterPlaceholder: 'Search by ID',
      filter: true,
      filterField: 'identifier',
      sortable: true,
    },
    {
      field: 'property',
      header: 'Property',
      filter: true,
      filterPlaceholder: 'Search by Property',
      filterField: 'property',
      sortable: true,
      dataType: 'text',
      mandatory: true,
    },
    {
      field: 'floorNames',
      header: 'Premises',
      filterPlaceholder: 'Search by Premises',
      filter: true,
      filterField: 'floorNames',
      sortable: true,
      body: (row: ContractRow) => (
        <FloorsToolTip
          premise={row.premises as any}
          floorNames={row.floorNames}
        />
      ),
      filterMatchMode: 'custom',
      filterMatchModeOptions: [{ label: 'Contains', value: 'custom' }],
      filterFunction: (value: string, filter: string) => filterPremises(value, filter),
    },
    {
      field: 'area',
      header: 'Area (Sq.ft)',
      filter: true,
      filterPlaceholder: 'Search by Area',
      filterField: 'area',
      sortable: true,
      dataType: 'numeric',
      body: (row: ContractRow) => addSpaceOrComma(row.area, false),
    },
    {
      field: 'tenant',
      header: 'Tenant',
      filter: true,
      filterPlaceholder: 'Search by Tenant',
      filterField: 'tenant',
      sortable: true,
      dataType: 'text',
    },

    {
      field: 'termStartDate',
      header: 'Term Start Date',
      filter: true,
      filterPlaceholder: 'Search by start date',
      filterField: 'termStartDate',
      sortable: true,
      dataType: 'date',
      body: (row: ContractRow) => {
        return moment(row.termStartDate).format('DD/MM/YYYY');
      },
      filterElement: filterElementDate,
    },
    {
      field: 'termEndDate',
      header: 'Term End Date',
      filter: true,
      filterPlaceholder: 'Search by end date',
      filterField: 'termEndDate',
      sortable: true,
      body: (row: ContractRow) => {
        return moment(row.termEndDate).format('DD/MM/YYYY');
      },
      dataType: 'date',
      filterElement: filterElementDate,
    },
    {
      field: 'headlineRent',
      header: 'Headline rent (HK$/month)',
      filterPlaceholder: 'Search by Headline rent',
      filter: true,
      filterField: 'headlineRent',
      sortable: true,
      dataType: 'numeric',
      body: (row: ContractRow) => addSpaceOrComma(row.headlineRent, false),
    },
    {
      field: 'rentPerArea',
      header: 'Rent per Area (HK$/Sq.ft)',
      filter: true,
      filterPlaceholder: 'Search by Rent per Area',
      filterField: 'rentPerArea',
      sortable: true,
      dataType: 'numeric',
      body: (row: ContractRow) => addSpaceOrComma(row.rentPerArea, false),
    },
    {
      field: 'effectiveRent',
      header: 'Effective Rent (HK$/m)',
      filterPlaceholder: 'Search by Effective rent',
      filter: true,
      filterField: 'effectiveRent',
      sortable: true,
      dataType: 'numeric',
      body: (row: ContractRow) => addSpaceOrComma(row.effectiveRent, false),
    },
    {
      field: 'origin',
      header: 'Origin',
      filter: true,
      filterPlaceholder: 'Search by Origin',
      filterField: 'origin',
      sortable: true,
      dataType: 'text',
      body: (row: ContractRow) => addTransactionLink(row.translationInfo),
    },
    {
      field: 'rentReviewDates',
      header: 'Rent Review Dates',
      filter: true,
      filterPlaceholder: 'Search by date',
      filterField: 'rentReviewDates',
      sortable: false,
      filterMatchMode: 'custom',
      filterElement: filterElementDate,
      filterMatchModeOptions: [{ label: 'Equals to', value: 'custom' }],
      filterFunction: (value: Date[], filter: Date) => {
        if (!filter) {
          return true;
        }
        return value.filter(date => date.getDate() === filter.getDate()).length;
      },
      body: (row: ContractRow) => displayListOfDates(row.rentReviewDates),
    },
    {
      field: 'surrenderFlag',
      header: 'Surrender',
      filterPlaceholder: 'Search by Surrender',
      filter: true,
      filterField: 'surrenderFlag',
      sortable: true,
      showFilterMenuOptions: false,
      filterElement: options => filterElementYesOrNo(options, 'Surrender'),
      filterMatchMode: 'equals',
      dataType: 'boolean',
      body: (row: ContractRow) => filterElementYesOrNoTemplate(row.surrenderFlag),
    },
    {
      field: 'renewFlag',
      header: 'Renew',
      filterPlaceholder: 'Search by Renew',
      filter: true,
      filterField: 'renewFlag',
      sortable: true,
      dataType: 'boolean',
      showFilterMenuOptions: false,
      filterElement: options => filterElementYesOrNo(options, 'Renew'),
      filterMatchMode: 'equals',
      body: (row: ContractRow) => filterElementYesOrNoTemplate(row.renewFlag),
    },
    {
      field: 'reviewFlag',
      header: 'Status',
      filterPlaceholder: 'Search by Status',
      filter: true,
      filterField: 'reviewFlag',
      sortable: true,
      body: (row: ContractRow) => filterElementStatusTemplate(row.reviewFlag, statuses),
      filterElement: options => filterElementStatus(options, statuses),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: ContractRow) => (
        <ContractRowActionButtons
          rowId={row.id}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};

const getReviewDates = (rentDescriptions: RentDescription[]): Date[] => {
  return rentDescriptions
    .filter(rentDescription => rentDescription.rentReview)
    .map((rentDescription: RentDescription) => new Date(rentDescription.rentReview.reviewDate as Date));
};

const displayListOfDates = (dates: Date[]): string => {
  return dates.length > 0 ? dates.map(date => moment(date).format('DD/MM/YYYY')).join(', ') : '------';
};

const getOriginValue = (translationInfo: TranslationInfo | null): string => {
  return translationInfo !== null && translationInfo.transaction !== null
    ? translationInfo.transaction.identifier
    : '-N/A-';
};
