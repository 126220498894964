import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ParameterDetailClone } from 'common/_classes';
import { getClientType } from 'utils/utils-authentication';

interface GetParameterQueryVariables {
  parameterId: string;
}

export const GET_PARAMETER = (id: string) => {
  if (!id) throw new Error('GET_PARAMETER: id is required');

  const variables: GetParameterQueryVariables = { parameterId: id };

  return {
    query: gql`
      query ($parameterId: ID!) {
        getParameter(parameterId: $parameterId) {
          ${ParameterDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const getParameter = createAsyncThunk('parameters/getParameter', async ({ id }: { id: string }) => {
  const response = await getClientType().query(GET_PARAMETER(id));
  return response;
});
